import "./index.scss";

import type { ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import { Hr } from "common/core/horizontal_rule";
import { IconButton } from "common/core/button/icon_button";

type Group = {
  value: string;
  percent: number;
};

type AdminExperimentGroupsProps = {
  showGroupNamingWarningMessage: number | null;
  showPercentageWarningMessage: boolean;
  groups: Group[];
  onChangeGroupValue: (ev: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeGroupPercentage: (ev: ChangeEvent<HTMLInputElement>, index: number) => void;
  deleteGroup: (index: number) => void;
  addGroup: () => void;
};

export default function ExperimentGroups(props: AdminExperimentGroupsProps) {
  const {
    showGroupNamingWarningMessage,
    showPercentageWarningMessage,
    groups,
    onChangeGroupValue,
    onChangeGroupPercentage,
    deleteGroup,
    addGroup,
  } = props;
  const totalPercentage = groups.reduce((result, group) => result + group.percent, 0);
  return (
    <div className="AdminExperimentGroups">
      {showGroupNamingWarningMessage !== null && (
        <div className="AdminExperimentGroups--warning">All groups must have names</div>
      )}
      <div>
        {groups.map((group, index) => {
          const isControl = group.value.toLowerCase() === "control";
          return (
            <div key={`group-${index}`} className="AdminExperimentGroups--group">
              <DeprecatedStyledTextInput
                value={group.value}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  onChangeGroupValue(ev, index);
                }}
                placeholder="Group Name"
                disabled={isControl} // disable the first one because it should always be "control" for now
              />
              <DeprecatedStyledTextInput
                value={group.percent}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  onChangeGroupPercentage(ev, index);
                }}
                placeholder="0-100"
              />
              <div className="AdminExperimentGroups--group--delete">
                {!isControl && (
                  <IconButton
                    label={
                      <FormattedMessage
                        id="2dace31e-07ae-4758-a41a-a7a5a8348e0b"
                        defaultMessage="Remove group"
                      />
                    }
                    name="x-small"
                    onClick={() => deleteGroup(index)}
                    variant="tertiary"
                    buttonColor="danger"
                    buttonSize="condensed"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="AdminExperimentGroups--addButton" onClick={addGroup}>
        + Add
      </div>
      <Hr />
      <div className="AdminExperimentGroups--total">Total (Must equal 100): {totalPercentage}%</div>
      {showPercentageWarningMessage && (
        <div className="AdminExperimentGroups--warning">
          The line above literally says you must equal 100
        </div>
      )}
    </div>
  );
}

import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import { reduxForm, FieldArray } from "redux-form";

import { captureException } from "util/exception";
import { OrganizationTransactionContactRoleType } from "graphql_globals";
import compose from "util/compose";
import { composeValidators, getFormValues } from "util/form";
import { DeprecatedTextField } from "common/form/fields/text";
import { validatePresence, validatePositiveInteger } from "validators/form";
import Button from "common/core/button";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedSelectField } from "common/form/fields/select";
import { Mutation } from "util/graphql/mutation";
import UpdateReswareInstanceMutation from "common/resware_instance/update_resware_instance_mutation.graphql";
import AlertMessage from "common/core/alert_message";
import { IconButton } from "common/core/button/icon_button";

const messages = defineMessages({
  contactMappingInstructions: {
    id: "b0bd3f52-3c74-46ef-8df1-a6d549258028",
    description: "ResWare Contact Mapping instructions",
    defaultMessage:
      "Configure which partners you want added as contacts on your Proof transactions",
  },
  contactMappingInstructionsHelp: {
    id: "61a24618-527e-4c0b-9352-eb3b4f6920bc",
    description: "ResWare Contact Mapping instructions Help",
    defaultMessage:
      "Map your PartnerTypeId to the corresponding Proof Contact Role if you want that partner to be added as Contact on the Proof transaction. Contacts will receive email notifications about the transaction and certain roles will have access to video participation.",
  },
  contactRole: {
    id: "bc6516fe-c34c-466e-83fa-b68a31e28ac5",
    description: "Contact Role Header",
    defaultMessage: "Contact Role",
  },
  partnerTypeId: {
    id: "6ad45539-bb41-4c7d-9feb-0b2bec60e750",
    description: "ResWare ContactMapping PartnerTypeId header",
    defaultMessage: "PartnerTypeID",
  },
  addAContactRole: {
    id: "fb286187-29e6-4043-bc2c-e12d8b77dcd4",
    description: "Add ResWare Contact Mapping",
    defaultMessage: "+ Add a contact mapping",
  },
  deleteAContactRole: {
    id: "59dc6fc3-f6f9-49b0-a7d3-4f3d7a990828",
    defaultMessage: "Delete contact mapping",
  },
  save: {
    id: "fc8eb221-a231-4d07-a7d5-1ea043d96549",
    defaultMessage: "Save",
  },
  instanceSaveSuccess: {
    id: "d4e47871-1cfe-4d12-9e77-95405e020276",
    defaultMessage: "ResWare account saved successfully.",
  },
  instanceSaveFailure: {
    id: "d9158cb5-5000-45d4-bec5-24e173c9ff0d",
    defaultMessage: "Hmm. Looks like something went wrong. Please reach out to Proof support.",
  },
});

function validate(values, props) {
  const { intl } = props;
  const validators = [];

  for (let i = 0; i < length; i++) {
    validators.push(
      validatePresence({
        field: `contactMappings[${i}].contactRole`,
        label: intl.formatMessage(messages.contactRole),
      }),
    );
    validators.push(
      validatePresence({
        field: `contactMappings[${i}].partnerTypeId`,
        label: intl.formatMessage(messages.partnerTypeId),
      }),
    );
    validators.push(
      validatePositiveInteger({
        field: `contactMappings[${i}].partnerTypeId`,
        label: intl.formatMessage(messages.partnerTypeId),
      }),
    );
  }

  return composeValidators(...validators)(values);
}

const contactRoleOptions = Object.freeze([
  {
    label: (
      <FormattedMessage id="d067905e-7298-471e-ba81-e20721c5806d" defaultMessage="Escrow Officer" />
    ),
    value: OrganizationTransactionContactRoleType.ESCROW_OFFICER,
  },
  {
    label: (
      <FormattedMessage id="e3c46689-a8a8-4b92-a2d3-49c52f74b69f" defaultMessage="Loan Officer" />
    ),
    value: OrganizationTransactionContactRoleType.LOAN_OFFICER,
  },
  {
    label: (
      <FormattedMessage
        id="2fd80088-6e16-4ca2-be6d-b3820bad74b8"
        defaultMessage="Real Estate Agent"
      />
    ),
    value: OrganizationTransactionContactRoleType.REAL_ESTATE_AGENT,
  },
  {
    label: (
      <FormattedMessage id="505fa0a2-a981-40dd-816b-290f223c7d03" defaultMessage="Title Agent" />
    ),
    value: OrganizationTransactionContactRoleType.TITLE_AGENT,
  },
  {
    label: (
      <FormattedMessage id="3ed398c4-3849-4a48-bcc7-63891783871b" defaultMessage="Loan Processor" />
    ),
    value: OrganizationTransactionContactRoleType.LOAN_PROCESSOR,
  },
  {
    label: (
      <FormattedMessage
        id="7e94e452-c407-4237-b520-2d5ab755bcd7"
        defaultMessage="Mortgage Broker"
      />
    ),
    value: OrganizationTransactionContactRoleType.MORTGAGE_BROKER,
  },
  {
    label: <FormattedMessage id="85571ff7-2abf-4413-99a3-744652d2af71" defaultMessage="Other" />,
    value: OrganizationTransactionContactRoleType.OTHER,
  },
  {
    label: <FormattedMessage id="297aabff-1a3c-4d07-8c51-d06d94f56424" defaultMessage="Closer" />,
    value: OrganizationTransactionContactRoleType.CLOSER,
  },
]);

const ContactMappingRows = injectIntl(({ fields, intl, currentContactMappings }) => {
  return (
    <div>
      <div className="ReswareInstanceContactMappings--Table--Body">
        {fields.map((field, index) => {
          const availableOptions = currentContactMappings
            ? contactRoleOptions.filter((option) => {
                return currentContactMappings.every((contactMapping) => {
                  return (
                    currentContactMappings[index].contactRole === option.value ||
                    contactMapping.contactRole !== option.value
                  );
                });
              })
            : contactRoleOptions;
          return (
            <div className="ReswareInstanceContactMappings--Table--Row" key={index}>
              <div className="ReswareInstanceContactMappings--Table--Cell">
                <DeprecatedTextField
                  name={`${field}.partnerTypeId`}
                  placeholder={intl.formatMessage(messages.partnerTypeId)}
                  useStyledInput
                  placeholderAsLabel
                  data-automation-id="resware-contact-mapping-id"
                />
                <FormGroupErrors fields={[`${field}.partnerTypeId`]} />
              </div>
              <div className="ReswareInstanceContactMappings--Table--Cell">
                <div>
                  <DeprecatedSelectField
                    name={`${field}.contactRole`}
                    placeholder={intl.formatMessage(messages.contactRole)}
                    items={availableOptions}
                    data-automation-id="resware-contact-role"
                  />
                  <FormGroupErrors fields={[`${field}.contactRole`]} />
                </div>
              </div>
              <div className="ReswareInstanceContactMappings trashIcon">
                <IconButton
                  buttonColor="danger"
                  variant="tertiary"
                  onClick={() => fields.remove(index)}
                  automationId={`resware-contact-mapping-${index}-remove`}
                  name="delete"
                  label={intl.formatMessage(messages.deleteAContactRole)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Button
        buttonColor="action"
        variant="tertiary"
        buttonSize="condensed"
        onClick={() => fields.push({})}
        className="addContactMapping"
        automationId="resware-add-contact-mapping"
      >
        {intl.formatMessage(messages.addAContactRole)}
      </Button>
    </div>
  );
});

class ReswareInstanceContactMappingsTab extends Component {
  constructor(props) {
    super(props);
    const {
      initialize,
      organization: { reswareInstance },
    } = props;
    const contactMappings =
      reswareInstance.contactMappings.length > 0 ? reswareInstance.contactMappings : [{}];

    this.state = {
      isSubmitting: false,
      alertMessage: null,
      alertKind: null,
    };
    initialize({ contactMappings });
  }

  onSubmit = ({ contactMappings }) => {
    const {
      intl,
      updateReswareInstanceMutateFn,
      organization: { id: organizationId },
      organization: {
        reswareInstance: { id },
      },
    } = this.props;

    const input = {
      id,
      organizationId,
      contactMappings: contactMappings.map((mapping) => ({
        partnerTypeId: Number(mapping.partnerTypeId),
        contactRole: mapping.contactRole,
      })),
    };

    this.setState({ isSubmitting: true });
    return updateReswareInstanceMutateFn({ variables: { input } })
      .then(() => {
        this.setState({
          alertMessage: intl.formatMessage(messages.instanceSaveSuccess),
          alertKind: "success",
        });
      })
      .catch((err) => {
        captureException(err);
        this.setState({
          alertMessage: intl.formatMessage(messages.instanceSaveFailure),
          alertKind: "danger",
        });
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { handleSubmit, intl, formValues } = this.props;
    const { alertMessage, alertKind, isSubmitting } = this.state;

    return (
      <div className="ReswareInstanceContactMappings">
        <form
          name="reswareInstanceContactMappingsForm"
          data-automation-id="resware-instance-contact-mappings-form"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          {alertMessage && (
            <AlertMessage className="ReswareInstanceAlertBanner" kind={alertKind}>
              {alertMessage}
            </AlertMessage>
          )}

          <div>{intl.formatMessage(messages.contactMappingInstructions)}</div>
          <div className="ReswareInstanceContactMappings--HelpText">
            {intl.formatMessage(messages.contactMappingInstructionsHelp)}
          </div>

          <div className="ReswareInstanceContactMappings--Table">
            <div className="ReswareInstanceContactMappings--Table--Header">
              <div className="ReswareInstanceContactMappings--Table--Row">
                <div className="ReswareInstanceContactMappings--Table--Cell">
                  {intl.formatMessage(messages.partnerTypeId)}
                </div>
                <div className="ReswareInstanceContactMappings--Table--Cell">
                  {intl.formatMessage(messages.contactRole)}
                </div>
              </div>
            </div>
            <FieldArray
              name="contactMappings"
              component={ContactMappingRows}
              currentContactMappings={formValues.contactMappings}
              rerenderOnEveryChange
            />
          </div>

          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            className="ReswareInstanceContactMappings--Submit"
            disabled={isSubmitting || this.props.pristine}
            isLoading={isSubmitting}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </form>
      </div>
    );
  }
}

ReswareInstanceContactMappingsTab.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reswareInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contactMappings: PropTypes.arrayOf(
        PropTypes.shape({
          contactRole: PropTypes.oneOf(Object.values(OrganizationTransactionContactRoleType))
            .isRequired,
          partnerTypeId: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

function ReswareInstanceContactMappingsTabWrapper(props) {
  return (
    <Mutation mutation={UpdateReswareInstanceMutation}>
      {(updateReswareInstanceMutateFn) => (
        <ReswareInstanceContactMappingsTab
          updateReswareInstanceMutateFn={updateReswareInstanceMutateFn}
          {...props}
        />
      )}
    </Mutation>
  );
}

export default compose(
  injectIntl,
  reduxForm({ form: "reswareInstanceContactMappingsForm", validate }),
  getFormValues("reswareInstanceContactMappingsForm"),
)(ReswareInstanceContactMappingsTabWrapper);

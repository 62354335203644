import { defineMessage } from "react-intl";

import type { SignatureMethod } from "graphql_globals";

enum Steps {
  DOCUMENT_STEP = "DocumentStep",
  EMAIL_STEP = "EmailStep",
  ESIGN_AUTHENTICATION_STEP = "EsignAuthenticationStep",
  ESIGN_CONSENT_STEP = "EsignConsentStep",
  KBA_STEP = "KbaStep",
  MEETING_STEP = "MeetingStep",
  PHOTO_IDENTIFICATION_STEP = "PhotoIdentificationStep",
}

type SignerStepsType = {
  __typename: string;
};

export const esignRequiringAuthenticationWithPersona = (signerSteps: SignerStepsType[]) =>
  signerSteps.some((step) => step.__typename === Steps.ESIGN_AUTHENTICATION_STEP);

export const esignConsentRequired = (signerSteps: SignerStepsType[]) =>
  signerSteps.some((step) => step.__typename === Steps.ESIGN_CONSENT_STEP);

export const NO_NAME_MESSAGE = defineMessage({
  id: "66433fb6-433e-409f-88e0-c7dec277e6fa",
  defaultMessage: "Name not provided",
});

type SigningAsset = {
  font: string | null;
  method: SignatureMethod | null;
  png: null | { key: string | null; url: string | null };
};

export type SigningAssets = {
  font: string | null;
  signatureAsset: SigningAsset | null;
  initialsAsset: SigningAsset | null;
} | null;

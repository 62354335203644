import {
  useMemo,
  useEffect,
  useState,
  useContext,
  createContext,
  useCallback,
  type ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";

import { useViewer } from "util/viewer_wrapper";
import { encodeSearchParams } from "util/location";
import { segmentReset } from "util/segment";
import request from "util/request";
import { deleteLocalAuthEmail } from "redux/util/authentication";
import { saveUserDevicePreferences } from "util/device_preferences";
import { redirectToSubdomain } from "util/application_redirect";
import { hardNavigateToUnsafe } from "util/navigation";

type SingleShotContext = Readonly<[boolean, (newValue: boolean) => void]>;
type LogoutParams = {
  redirectUrl?: string;
  skipRedirect?: boolean;
};

const REDIRECT_KEY = "redirect";
const SINGLE_SHOT_STORAGE_KEY = "notarize-single-shot-auth";
const SINGLE_SHOT_CONTEXT = createContext<SingleShotContext>([false, () => {}]);
const ROOT_PATH = "/";
const { Provider } = SINGLE_SHOT_CONTEXT;
const SAML_POST_BINDING = "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST";
const SAML_REDIRECT_BINDING = "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect";

function initializeSingleShotState() {
  return Boolean(window.sessionStorage.getItem(SINGLE_SHOT_STORAGE_KEY));
}

/**
 * @deprecated
 * Do not use this method outside of this file, favor `useLogout` instead.
 */
export async function logout() {
  const { slo_url: sloUrl, slo_method: sloMethod } = await request("get", "oauth/logout");
  segmentReset();

  if (sloUrl) {
    await samlLogout(sloUrl, sloMethod);
  }
}

function samlLogout(sloUrl: string, sloMethod: string) {
  // this promise purposefully never resolves because the user is navigating elsewhere
  return new Promise(() => {
    if (sloMethod === SAML_REDIRECT_BINDING) {
      return hardNavigateToUnsafe(sloUrl);
    }
    if (sloMethod && sloMethod !== SAML_POST_BINDING) {
      console.warn(`Unexpected sloMethod: ${sloMethod}`); // eslint-disable-line no-console
    }

    // this seems kind of hacky, but it lets us do a POST request
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", sloUrl);
    document.body.appendChild(form);
    form.submit();
  });
}

/**
 * @param {Object} params
 *   @property {string} redirectUrl the absolute URL you want to redirect back to after logout
 *   @property {boolean} skipRedirect does not perform any redirect after logging out
 *
 */
export function useLogout(params?: LogoutParams) {
  const navigate = useNavigate();
  const { refetch } = useViewer();

  return useCallback(() => {
    deleteLocalAuthEmail();
    saveUserDevicePreferences({ phoneNumber: null });

    return logout()
      .then(refetch)
      .then(() => {
        if (params?.redirectUrl) {
          navigate(params.redirectUrl);
        } else if (!params?.skipRedirect) {
          navigate(ROOT_PATH);
        }
      });
  }, [refetch, params]);
}

/**
 * @param {string} [redirectUrl] the absolute URL you want encoded
 * @param {Object} [optionalParams] additional parameters to add to the URL search params
 *
 * @returns {string} an encoded version login URL with search params based on what was passed in
 */
export function getEncodedLoginUrlWithSearchParams({
  redirectUrl,
  optionalParams = {},
}: {
  redirectUrl?: string;
  optionalParams?: Record<string, string>;
}) {
  const searchParams = new URLSearchParams({
    ...optionalParams,
  });
  if (redirectUrl) {
    searchParams.append(REDIRECT_KEY, redirectUrl);
  }
  const search = encodeSearchParams(searchParams);
  return `/login?${search}`;
}

/**
 * This component is rendered when an unauth'd user hits a route that requires authentication
 * or the router does not recognize (it uses path="*" to catch). This includes session expired routing
 */
export function HardLoginRedirect() {
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    const previousSearchParams = new URLSearchParams(window.location.search);

    // the login page utilizes transaction_id for branding purposes. This should only affects signers,
    // which we will try to constrain to by only applying transaction_id to signer link query strings
    const transactionId = previousSearchParams.get("transaction_id");
    if (transactionId) {
      newSearchParams.append("transaction_id", transactionId);
    }

    const passwordlessEmail = previousSearchParams.get("passwordless_email");
    // if we log out a passwordless signer to allow them to create their password
    // prefill the email for the login screens
    if (passwordlessEmail) {
      newSearchParams.append("email", passwordlessEmail);
    }

    if (window.location.pathname !== ROOT_PATH) {
      // If there is a redirect search param, that is prioritized since they came from session expired. Otherwise if
      // there is a pathname, we will send user there since that is where the user was trying to go to
      const decodedRedirectUrl =
        decodeURIComponent(previousSearchParams.get(REDIRECT_KEY) || "") || window.location.href;
      // if a passwordless email is set, we want to remove it from the url
      const sanitizedDecodedRedirectUrl = decodedRedirectUrl.replace(
        /passwordless_email=[^&#]*/,
        "",
      );
      newSearchParams.set(REDIRECT_KEY, sanitizedDecodedRedirectUrl);
    }

    redirectToSubdomain(
      "app",
      { pathname: "/login", search: encodeSearchParams(newSearchParams) },
      { replace: true },
    );
  }, []);
  return null;
}

export function useIsAuthenticated(): boolean {
  const { viewer } = useViewer();
  return Boolean(viewer.user?.id);
}

export function useSingleShotAuthentication() {
  return useContext(SINGLE_SHOT_CONTEXT);
}

export function SingleShotAuthenticationProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState(initializeSingleShotState);
  const value = useMemo(
    () =>
      Object.freeze([
        state,
        (newState: boolean) => {
          if (newState) {
            window.sessionStorage.setItem(SINGLE_SHOT_STORAGE_KEY, "t");
          } else {
            window.sessionStorage.removeItem(SINGLE_SHOT_STORAGE_KEY);
          }
          setState(newState);
        },
      ] as const),
    [state],
  );
  return <Provider value={value}>{children}</Provider>;
}

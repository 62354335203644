import "./index.scss";

import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { CardHeading } from "common/core/card";
import { DeprecatedMultiSelectInput } from "common/form/inputs/multi_select";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";

import AddClosingStatePreferenceForm from "./add_interstate_preference_form";
import {
  type MortgageClosingStatePreferenceHook,
  type Options,
  type ClosingState,
  optionsToValues,
} from "./util";

type InterStateFormProps = {
  propertyStateOptions: Options[];
  eligibleClosingStates: ClosingState[];
  usStateListOptions: Options[];
  updateClosingStatePreference: MortgageClosingStatePreferenceHook["updateClosingStatePreference"];
  deleteClosingStatePreference: MortgageClosingStatePreferenceHook["deleteClosingStatePreference"];
  addClosingStatePreference: MortgageClosingStatePreferenceHook["addClosingStatePreference"];
};

export default function InterStateUpdatePreferenceForm({
  eligibleClosingStates,
  usStateListOptions,
  updateClosingStatePreference,
  deleteClosingStatePreference,
  addClosingStatePreference,
  propertyStateOptions,
}: InterStateFormProps) {
  const [display, setDisplay] = useState(false);
  const interStatePreferences = useMemo(() => {
    return eligibleClosingStates
      .filter(
        (closingState) => !closingState.intraState && closingState.specificUsStates.length > 0,
      )
      .map((closingState) => ({
        label: closingState.usState.name,
        id: closingState.id,
        specificUsStates: closingState.specificUsStates.map((usState) => {
          return { label: usState!.name, value: usState!.id };
        }),
      }));
  }, [eligibleClosingStates]);
  const emptyOptions = propertyStateOptions.length === 0;
  const emptyEligibleStates = eligibleClosingStates.length === 0;
  return (
    <>
      <CardHeading>
        <FormattedMessage
          id="e28134c7-d540-43dd-9f3c-545d12b0377f"
          defaultMessage="Interstate Requirements"
        />
      </CardHeading>
      <div className="SpecificStateTable" data-automation-id="Specific States">
        <div className="SpecificStateTable--Row">
          <div className="SpecificStateTable--Row--Header">
            <FormattedMessage
              id="573ad47c-31d9-4f53-b09f-8de9bdb26355"
              defaultMessage="Property state"
            />
          </div>
          <div className="SpecificStateTable--Row--Header">
            <FormattedMessage
              id="cb6b3f8c-ff63-4630-881f-fa2581fc071d"
              defaultMessage="Notary states"
            />
          </div>
          <div className="SpecificStateTable--Row--Header">
            <FormattedMessage id="d3c91b14-efa2-4d82-9cc0-4418661e3b16" defaultMessage="Action" />
          </div>
        </div>
        {interStatePreferences.map((closingState) => {
          return (
            <div key={closingState.id} className="SpecificStateTable--Row">
              <div className="SpecificStateTable--Row--Item">{closingState.label}</div>
              <div className="SpecificStateTable--Row--Item">
                <DeprecatedMultiSelectInput
                  placeholder="Allowed closing states"
                  clearable={false}
                  items={usStateListOptions}
                  value={closingState.specificUsStates}
                  onChange={(notaryStateOptions: Options[]) => {
                    const notaryStateIds = optionsToValues(notaryStateOptions);
                    updateClosingStatePreference(closingState.id, notaryStateIds);
                  }}
                />
              </div>
              <div className="SpecificStateTable--Row--Item">
                <Button
                  variant="tertiary"
                  buttonColor="danger"
                  onClick={() => {
                    deleteClosingStatePreference(closingState.id);
                  }}
                  aria-label="delete"
                >
                  <Icon className="SpecificStateTable--Row--Item--img" name="delete" />
                </Button>
              </div>
            </div>
          );
        })}
        {display && (
          <AddClosingStatePreferenceForm
            onReset={() => setDisplay(false)}
            propertyStateOptions={propertyStateOptions}
            usStateListOptions={usStateListOptions}
            addClosingStatePreference={addClosingStatePreference}
          />
        )}
      </div>
      <Button
        automationId="add-specific-state-button"
        buttonSize="condensed"
        buttonColor="dark"
        variant="primary"
        onClick={() => setDisplay(true)}
        disabled={display || emptyOptions}
      >
        <FormattedMessage id="06ec6757-fde1-481c-b5ab-b07e401510bd" defaultMessage="Add row" />
      </Button>
      {emptyOptions && (
        <Tooltip
          target={
            <Icon className="SpecificStateTable--Row--HelperIcon" size="large" name="question" />
          }
        >
          <span>
            {emptyEligibleStates ? (
              <FormattedMessage
                id="0b56ca61-b77d-4dfc-a71d-3fa816a67281"
                defaultMessage="You must register the State as eligible for underwriter before setting closing state preferences."
              />
            ) : (
              <FormattedMessage
                id="35f1412d-3b1f-4441-b8d4-fe06c6c9b12b"
                defaultMessage="You've filled preferences for all underwriter eligible states."
              />
            )}
          </span>
        </Tooltip>
      )}
    </>
  );
}

import { Component, forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { getFieldErrorMessageId } from "common/form/group_errors";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { DeprecatedEditableInput } from "common/form/enhancers/editable_input";
import RequiredAsterisk from "common/core/form/required-asterisk";

import { DeprecatedTextInput as TextInputBase } from "./input";

const EnhancedInput = DeprecatedEditableInput(TextInputBase);

/** @deprecated - please use components in common/core/form */
export class DeprecatedTextInput extends Component {
  render() {
    const { editable, displayRequiredAsterisk, ...otherProps } = this.props;

    return editable ? <EnhancedInput {...otherProps} /> : <TextInputBase {...otherProps} />;
  }
}

/**
 * The following component is meant to be the base Notarize styled TextInput.
 * The lower level TextInput exists as well, but is only meant to be
 * a very primitive behavioral component for simple use cases.
 * There's an existing TODO to migrate styles from the global form.scss into
 * the styling for this component, and then remove those global styles.
 * Some work will likely be needed to get other components using StyledTextInput
 * if they're not already.
 * https://notarize.atlassian.net/browse/CORE-1214
 **/

/** @deprecated - please use components in common/core/form */
class DeprecatedStyledTextInput extends Component {
  constructor(props) {
    super(props);
    this.generatedId = uuidv4();
  }

  inputRef = null;

  setRef = (input) => {
    this.inputRef = input;
    // setRef is called when unmounting the component, so only call upward if input is defined
    if (input) {
      this.props.onInputRefSet(input);

      if (typeof this.props.innerRef === "function") {
        this.props.innerRef(input);
      } else if (this.props.innerRef) {
        this.props.innerRef.current = input;
      }
    }
  };

  render() {
    const {
      value,
      disabled,
      readOnly,
      className,
      label,
      useNativeLabel,
      placeholder,
      placeholderAsLabel,
      displayRequiredAsterisk,
      labelRequiredAsterisk,
      plainPlaceholder,
      automationId,
      "aria-label": ariaLabel,
      onInputRefSet: _onInputRefSet,
      onReturn: _onReturn,
      maxlength: _maxlength,
      maskType: _maskType,
      useStyledInput: _useStyledInput,
      innerRef: _innerRef,
      ...otherProps
    } = this.props;

    const id = otherProps.id || this.generatedId;

    const labelCx = classnames("StyledText--label", {
      disabled,
    });

    const nativeLabelCx = classnames("StyledText--native-label", {
      disabled,
    });

    const inputCx = classnames("StyledText--input", className, {
      lower: (!useNativeLabel && label) || (placeholder && placeholderAsLabel),
      "plain-placeholder": plainPlaceholder,
      disabled,
    });

    const placeholderCx = classnames("StyledText--placeholder", {
      lower: label && !placeholderAsLabel,
      hover: placeholderAsLabel,
      disabled,
    });

    // eslint-disable-next-line eqeqeq -- used to be lodash.isNil
    const valueExists = value != null && value !== "";

    // Placeholder = your typical placeholder
    // Label = the small header (eyebrow) embedded inside the input
    // If you use placeholderAsLabel, then the placeholder will
    // act as the label when the placeholder isn't itself needed
    // (e.g. if there's a value in the field)

    let labelElement = null;

    if (label) {
      if (useNativeLabel) {
        labelElement = (
          <label className={nativeLabelCx} htmlFor={id}>
            {label}
            {displayRequiredAsterisk && <RequiredAsterisk />}
          </label>
        );
      } else {
        labelElement = (
          <span aria-hidden="true" className={labelCx}>
            {label}
            {labelRequiredAsterisk && <RequiredAsterisk />}
          </span>
        );
      }
    } else if (placeholder && placeholderAsLabel && valueExists) {
      labelElement = (
        <span aria-hidden="true" className={labelCx}>
          {placeholder}
          {displayRequiredAsterisk && <RequiredAsterisk />}
        </span>
      );
    }

    if (readOnly) {
      return valueExists ? (
        <div
          {...otherProps}
          data-automation-id={automationId}
          className={`StyledText StyledText__read-only ${SENSITIVE_CLASS}`}
        >
          <span aria-hidden="true" title={value} className="StyledText--text ellipsis">
            {value}
          </span>
          {labelElement}
        </div>
      ) : null;
    }

    function getAriaLabel() {
      let newAriaLabel;
      if (ariaLabel) {
        newAriaLabel = ariaLabel;
      } else if (label) {
        newAriaLabel = label;
      } else if (placeholder) {
        /*Please do not rely on placeholder as aria-label. This is a backup. aria-label should be a form label that makes sense to a vision-impaired user hearing it while focused on an input. */
        newAriaLabel = placeholder;
      } else if (!ariaLabel) {
        return undefined;
      }

      /*TO DO: remove labelRequiredAsterisk - does not appear to be doing anything different than displayRequiredAsterisk*/
      const required = Boolean(labelRequiredAsterisk || displayRequiredAsterisk);
      return `${newAriaLabel}${required ? " required" : ""}`;
    }

    return (
      <div className={`StyledText ${SENSITIVE_CLASS}`} data-automation-id={automationId}>
        {useNativeLabel && labelElement}
        <input
          {...otherProps}
          aria-label={getAriaLabel()}
          aria-describedby={
            otherProps.name
              ? classnames(getFieldErrorMessageId(otherProps.name), otherProps["aria-describedby"])
              : otherProps["aria-describedby"]
          }
          disabled={readOnly || disabled}
          value={value}
          ref={this.setRef}
          className={inputCx}
          placeholder={placeholder}
          id={id}
        />
        {!useNativeLabel && labelElement}
        {!useNativeLabel && !valueExists && !plainPlaceholder && (
          <span aria-hidden="true" className={placeholderCx}>
            {placeholder}
            {displayRequiredAsterisk && <RequiredAsterisk />}
          </span>
        )}
      </div>
    );
  }
}

export const StyledTextInputPropTypes = {
  ...DeprecatedTextInput.propTypes,
  // some other input fields (e.g. email) are built on top of TextInput
  // so they have to pass in their own type
  type: PropTypes.string,
  value: PropTypes.node,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.node,
  useNativeLabel: PropTypes.bool,
  placeholder: PropTypes.node,
  placeholderAsLabel: PropTypes.bool,
  plainPlaceholder: PropTypes.bool,
  displayRequiredAsterisk: PropTypes.bool,
  onInputRefSet: PropTypes.func,
  className: PropTypes.string,
  automationId: PropTypes.string,
};
DeprecatedStyledTextInput.propTypes = StyledTextInputPropTypes;

DeprecatedStyledTextInput.defaultProps = {
  type: "text",
  placeholderAsLabel: false,
  displayRequiredAsterisk: false,
  onInputRefSet: () => {},
  plainPlaceholder: false,
};

function DeprecatedStyledTextInputWrapper(props, ref) {
  return <DeprecatedStyledTextInput innerRef={ref} {...props} />;
}

const DeprecatedStyledTextInputWithRef = forwardRef(DeprecatedStyledTextInputWrapper);

/** @deprecated - please use components in common/core/form */
export { DeprecatedStyledTextInputWithRef as DeprecatedStyledTextInput };

import { useLocation, matchRoutes } from "react-router-dom";

import { useFeatureFlag } from "common/feature_gating";
import {
  TRANSACTION_PATH,
  USER_DASHBOARD_PATH,
  UNAUTH_RETAIL_UPLOAD_PATH,
  AUTH_RETAIL_UPLOAD_PATH,
} from "util/routes";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import PendoResourceCenterIcon from "assets/images/pendo-resource-center.svg";
import Svg from "common/core/svg";
import { isPendoReady } from "util/pendo";

import Styles from "./index.module.scss";

const pendoResourceCenterPaths = [
  { path: TRANSACTION_PATH },
  { path: USER_DASHBOARD_PATH },
  { path: UNAUTH_RETAIL_UPLOAD_PATH },
  { path: AUTH_RETAIL_UPLOAD_PATH },
  { path: "/bundle/:globalID/meeting_request" },
  { path: "/bundle/:globalID/kba-quiz" },
  { path: "/bundle/:globalID/identity-verification" },
];

export function PendoResourceCenter() {
  const location = useLocation();
  const isLenderPortal = CURRENT_PORTAL === Apps.LENDER;

  const isPendoUnavailable = !isPendoReady();
  const isDisabledViaFlag = !useFeatureFlag("pendo-rc-icon");
  // If no routes match, matchRoutes will return null
  const isCurrentPathExcluded = !matchRoutes(pendoResourceCenterPaths, location);

  if (isPendoUnavailable || isDisabledViaFlag || isLenderPortal || isCurrentPathExcluded) {
    return null;
  }

  return (
    <button
      className={Styles.resourceCenterIcon}
      type="button"
      data-automation-id="pendo-resource-center-icon"
    >
      <Svg alt="" src={PendoResourceCenterIcon} />
    </button>
  );
}

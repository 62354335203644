import type { ComponentProps } from "react";
import { useIntl, defineMessages } from "react-intl";

import { Card, readonlyField, showField } from "common/transaction_creation/v3/common";
import { useRawMutation } from "util/graphql/mutation";
import UpsertDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/upsert_document_bundle_instruction_mutation.graphql";
import DeleteDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/delete_document_bundle_instruction_mutation.graphql";
import store from "redux/store";
import { addError } from "redux/actions/errors";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { ERROR_TYPES } from "constants/errors";
import TransactionCreationFormQuery, {
  type TransactionCreationForm_transaction_OrganizationTransaction as Transaction,
  type TransactionCreationForm,
  type TransactionCreationFormVariables,
} from "common/transaction_creation/v3/query.graphql";
import { NotaryInstructionsGrid } from "common/details/instructions/instruction";

import type { SigningDetails } from "../transaction_fragment.graphql";
import type { SigningDetailsOrg } from "../organization_fragment.graphql";
import { CONFIGS, type SIGNING_DETAILS_SECTION } from "..";

type SigningDetailsSectionProps = ComponentProps<typeof SIGNING_DETAILS_SECTION.Component>;

type NotaryInstructionsGridProps = ComponentProps<typeof NotaryInstructionsGrid>;

const MESSAGES = defineMessages({
  notaryNotes: {
    id: "614136d7-5d72-4a12-9226-2c65c0940af3",
    defaultMessage: "Notary notes",
  },
  genericError: {
    id: "7d8cad82-b058-4f52-ae49-432e68508d57",
    defaultMessage: "Something went wrong",
  },
  notaryNotesDescription: {
    id: "49c048a7-762a-4878-a245-66a7f46abd0e",
    defaultMessage:
      "You can leave a note here if the signing requires any special instructions and the notary will be able to review it before they begin the signing process.",
  },
});

export function NotaryNotesCard({
  transaction,
  organization,
  config,
}: {
  transaction: SigningDetails;
  organization: SigningDetailsOrg;
  config: SigningDetailsSectionProps["config"];
}) {
  const intl = useIntl();
  const showNotaryNotes = showField(config, CONFIGS.notaryNotes);

  const [upsertBundleInstructionMutateFn] = useRawMutation(UpsertDocumentBundleInstructionMutation);
  const [deleteDocumentBundleInstructionMutateFn] = useRawMutation(
    DeleteDocumentBundleInstructionMutation,
  );

  const handleUpdateNotaryInstruction: NotaryInstructionsGridProps["onSave"] = (instruction) => {
    return upsertBundleInstructionMutateFn({
      variables: {
        input: {
          documentBundleId: transaction.documentBundle!.id,
          text: instruction.text,
          organizationId: organization.id,
          documentBundleInstructionId: instruction.id,
        },
      },
      update(cache, { data }) {
        if (instruction.id) {
          return;
        }

        const queryData = cache.readQuery<
          TransactionCreationForm,
          TransactionCreationFormVariables
        >({
          query: TransactionCreationFormQuery,
          variables: {
            transactionId: transaction.id,
            organizationId: organization.id,
          },
        });

        const cachedTransaction = queryData!.transaction as Transaction;
        const cachedDocumentBundle = cachedTransaction.documentBundle;
        const cachedInstructions = cachedDocumentBundle!.instructions;

        const newTransaction = {
          ...cachedTransaction,
          documentBundle: {
            ...cachedDocumentBundle,
            instructions: [
              ...cachedInstructions,
              data?.upsertDocumentBundleInstruction?.documentBundleInstruction,
            ],
          },
        };

        cache.writeQuery({
          query: TransactionCreationFormQuery,
          variables: { transactionId: transaction.id, organizationId: organization.id },
          data: { ...queryData, transaction: newTransaction },
        });
      },
    }).catch((error) => {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        const errorString =
          error.graphQLErrors[0].message || intl.formatMessage(MESSAGES.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(error);
      }
    });
  };

  const handleDeleteNotaryInstruction: NotaryInstructionsGridProps["onDelete"] = (id) => {
    return deleteDocumentBundleInstructionMutateFn({
      variables: { input: { id } },
    }).catch((error) => {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        const errorString =
          error.graphQLErrors[0].message || intl.formatMessage(MESSAGES.genericError);
        store.dispatch(addError(errorString, ERROR_TYPES.REGULAR));
      } else {
        captureException(error);
      }
    });
  };

  if (!showNotaryNotes) {
    return null;
  }

  return (
    <Card
      title={intl.formatMessage(MESSAGES.notaryNotes)}
      subtitle={intl.formatMessage(MESSAGES.notaryNotesDescription)}
    >
      <NotaryInstructionsGrid
        instructions={transaction.documentBundle!.instructions}
        onDelete={handleDeleteNotaryInstruction}
        onSave={handleUpdateNotaryInstruction}
        allowChanges={!readonlyField(config, CONFIGS.notaryNotes)}
      />
    </Card>
  );
}

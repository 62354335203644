import {
  useEffect,
  useState,
  type FormEvent,
  type ReactNode,
  type ComponentPropsWithoutRef,
} from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useNavigate, useParams } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import compose from "util/compose";
import { composeValidators, getFormValues } from "util/form";
import { captureException } from "util/exception";
import { usePermissions } from "common/core/current_user_role";
import { validatePresence } from "validators/form";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedTextAreaField } from "common/form/fields/text_area";
import Button from "common/core/button";
import { LongFormattedDateTime } from "common/core/format/date";
import { DeprecatedSelectField } from "common/form/fields/select";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedFormRowHelpTooltip } from "common/form/form_row_help_tooltip";
import ProfileSectionHeader from "common/settings/profile_section_header";
import LoadingIndicator from "common/core/loading_indicator";
import RegularErrorModal from "common/error_modal/regular_error_modal";
import { useQuery, useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import camelizeObject from "util/camelize_object";
import {
  Tier,
  TierVisibility,
  type PricingPlanOptionsInputType,
  type PricesInputType,
} from "graphql_globals";
import {
  ComboboxDropdown,
  ComboboxDropdownMenuOption,
  useFilterOptions,
} from "common/core/combobox_dropdown";

import TierDetailsQuery, {
  type TierDetails,
  type TierDetails_viewer_tiers_edges_node as TierNode,
  type TierDetails_viewer_tierEligibeLenderPartnerPlans as EligiblePartnerPlan,
} from "./tier_details_query.graphql";
import Styles from "./details.module.scss";
import UpsertTierMutation from "./upsert_tier_mutation.graphql";

const TIER_TITLE_TYPES = [
  Tier.TITLE_AGENT,
  Tier.TITLE_AGENCY_PRO,
  Tier.TITLE_PRO_ODN,
  Tier.TITLE_PRO_IHN,
  Tier.TITLE_AGENCY_PRO_NOTARY,
  Tier.TITLE_AGENCY_ELITE,
  Tier.GRANDFATHERED_TITLE,
];

const LENDER_TIER_TYPES = [Tier.GRANDFATHERED_LENDER];
const TierTypes = filterActiveAndDeprecatedTiers();

const MESSAGES = defineMessages({
  defaultErrorMessage: {
    id: "531b7e94-5447-4f1e-8617-bc61a658ddd5",
    defaultMessage: "Something went wrong",
  },
  newTierTitle: {
    id: "3a847b03-4ef0-4d44-83a7-1fcb7c55ca90",
    defaultMessage: "New Tier",
  },
  tierDetailsTitle: {
    id: "56ff2e6e-8f27-4f0e-bc55-eb58b744b62b",
    defaultMessage: "Tier Details",
  },
  toggleLinkText: {
    id: "012d1f63-2652-425d-97bb-0dadae300b75",
    defaultMessage: "toggle view",
  },
  notSpecifiedPlaceholder: {
    id: "972f6267-e02e-49f7-a295-a0492b688d48",
    defaultMessage: "not specified",
  },
  infoHeader: {
    id: "24230606-3563-4221-86a3-b9e6cc86aff6",
    defaultMessage: "Info",
  },
  internalTierName: {
    id: "3bada1d4-d8bf-4a10-928d-af9888f2af33",
    defaultMessage: "Internal tier name",
  },
  displayName: {
    id: "b403dcb0-76ca-44c6-bd31-d176f1fcd88f",
    defaultMessage: "Customer display name",
  },
  tierTypeTitle: {
    id: "35117a90-6e87-4f48-957e-01c8a251fc24",
    defaultMessage: "Pricing tier type",
  },
  tierTypePlaceholder: {
    id: "44b82629-138b-465d-9ba8-f70cae808a84",
    defaultMessage: "Search by a tier type or description...",
  },
  createdAtName: {
    id: "49e1f0d2-b4bd-41c0-9bef-87d6ea09f809",
    defaultMessage: "Created",
  },
  lastUpdatedAtName: {
    id: "31710aaf-c351-46ed-bffc-302c727768d9",
    defaultMessage: "Last Updated",
  },
  pricingHeader: {
    id: "2644345d-ebd9-44e4-b4bb-cd8f99aca6e3",
    defaultMessage: "Pricing",
  },
  optionsHeader: {
    id: "d62faa2b-fa7c-46e1-a7ae-caa233d66119",
    defaultMessage: "Options",
  },
  otherHeader: {
    id: "dbe7d5c6-028a-4503-a7f1-736eee458617",
    defaultMessage: "Other",
  },
  linkedPlan: {
    id: "43383851-1b66-4add-b8db-b40481b0fe44",
    defaultMessage: "Linked plan",
  },
  linkedPlanPlaceholder: {
    id: "38c5f13c-f577-49d5-b46b-fa7c5845b248",
    defaultMessage: "none",
  },
  visibility: {
    id: "7dae942e-f203-4b42-a681-b3c0f9a4668d",
    defaultMessage: "Visibility",
  },
  publicRadioText: {
    id: "68fc1439-ec22-4dc4-88db-4078aa48ea05",
    defaultMessage: "Public",
  },
  privateRadioText: {
    id: "a724a521-8112-4792-ac9b-5519eb200b6c",
    defaultMessage: "Private",
  },
  createButtonText: {
    id: "b8c061fe-ff14-47c8-ad57-d17cef0d0026",
    defaultMessage: "Create",
  },
  saveButtonText: {
    id: "de6b2776-d1c8-496b-a70b-93a6a21927b9",
    defaultMessage: "Save",
  },
  invalidJsonSyntax: {
    id: "fa422acc-c6b6-4afe-bd3e-5296145fb463",
    defaultMessage: "Invalid pricing JSON syntax",
  },
  fullEcloseFee: {
    id: "36466797-71f3-4870-9d43-3f11d95fab6e",
    defaultMessage: "ODN full eClose transaction fees",
  },
  fullEcloseLoanModFee: {
    id: "91842f82-f86e-45fb-8ee7-d7b5e4679a21",
    defaultMessage: "ODN full eClose loan mod transaction fee",
  },
  byonFullEcloseFee: {
    id: "0af4c81d-dfc1-4ac7-adac-b62a6364780e",
    defaultMessage: "IHN full eClose transaction fees",
  },
  byonFullEcloseLoanModFee: {
    id: "ba958226-88f2-486c-b80d-a6fe1624c2ea",
    defaultMessage: "IHN full eClose loan mod transaction fee",
  },
  sequentialFee: {
    id: "68e753a7-db03-4eed-9084-4036d84a592e",
    defaultMessage: "ODN sequential signing fees",
  },
  loanModSequentialFee: {
    id: "6c23d2a0-8c7c-4524-9b40-8bd1b6a7dfee",
    defaultMessage: "ODN sequential loan mod signing fee",
  },
  byonSequentialFee: {
    id: "b9908e7f-a579-4edf-bf46-f12f99c60744",
    defaultMessage: "IHN sequential signing fees",
  },
  byonLoanModSequentialFee: {
    id: "0cd2b081-ef09-4f01-8dc8-fb9c4f2a6426",
    defaultMessage: "IHN sequential loan mod signing fee",
  },
  hybridFee: {
    id: "157ba3f1-4c82-4381-8023-f013a3c02429",
    defaultMessage: "Hybrid partial eClose transaction fees",
  },
  closingConciergeFee: {
    id: "5cacb7e8-6533-41c6-bc4c-ed10896b26e7",
    defaultMessage: "Closing Concierge fee",
  },
  activeBadge: {
    id: "8b2e9276-4d81-4ea3-b094-5e7ab88b8be4",
    defaultMessage: "Active",
  },
  deprecatedBadge: {
    id: "0c1dd7b1-8100-42dd-94ce-34dd39e45a23",
    defaultMessage: "Deprecated",
  },
});

type FormValues = {
  tierName: string;
  displayName: string;
  tierType: Tier;
  prices: PricesInputType;
  pricesJson: string;

  options: PricingPlanOptionsInputType;
  optionsJson: string;

  visibility: TierVisibility;
  linkedPlan: string;
};

type Props = {
  tierDetails: TierNode;
  planList: EligiblePartnerPlan[];
  getPricingDisplayName: (itemName: string) => ReactNode;
  getOptionDisplayName: (itemName: string) => ReactNode;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type GetFormValueProps = {
  formValues: FormValues;
};

type InnerProps = FormProps & GetFormValueProps & Props;

type JsonPricesType = Record<string, number | null>;

type TierOptionType = {
  optionLabel: ReactNode;
  optionDescription: ReactNode;
  value: ReactNode;
  isActive: boolean;
};

function filterActiveAndDeprecatedTiers(): TierOptionType[] {
  const tierDescriptions: Partial<Record<keyof typeof Tier, { description: string }>> = {
    [Tier.BUSINESS_PRO_IHN]: { description: "Business Pro IHN" },
    [Tier.BUSINESS_PRO_ODN]: { description: "Business Pro ODN (Default signup tier)" },
    [Tier.ELITE]: { description: "Premium Business Tier (Larger contracts)" },
    [Tier.TITLE_PRO_ODN]: { description: "Title Pro ODN (Signup tier A)" },
    [Tier.TITLE_PRO_IHN]: { description: "Title Pro IHN (Signup tier B)" },
    [Tier.TITLE_AGENCY_ELITE]: { description: "Premium Title Agency Tier (Larger contracts)" },
    [Tier.GRANDFATHERED_LENDER]: { description: "Legacy Lender (All Lender Organizations)" },
    [Tier.NOTARY_MARKETPLACE_BASIC]: { description: "NST Tier (Default signup tier)" },
    [Tier.BASIC]: { description: "Starter tier" },
    [Tier.GRANDFATHERED_PROFESSIONAL]: { description: "Older business organizations" },
    [Tier.GRANDFATHERED_TITLE]: { description: "Older title agency" },
  };
  const DEPRECATED_TIERS = [
    Tier.BASIC,
    Tier.GRANDFATHERED_PROFESSIONAL,
    Tier.GRANDFATHERED_TITLE,
    Tier.PRO,
    Tier.PRO_TRIAL,
    Tier.TEST_ORG,
    Tier.TITLE_AGENCY_PRO,
    Tier.TITLE_AGENCY_PRO_NOTARY,
    Tier.TITLE_AGENT,
  ];
  const HIDDEN_TIERS = [
    Tier.NOTAGIG_PARTNER,
    Tier.NOTAGIG_SILVER,
    Tier.NOTAGIG_GOLD,
    Tier.NOTAGIG_PLATINUM,
  ];
  const deprecatedTierTypes = DEPRECATED_TIERS.map((x) => {
    return {
      optionDescription: tierDescriptions[x]?.description,
      optionLabel: x,
      value: x,
      isActive: false,
    };
  });
  return Object.values(Tier)
    .flatMap((tier) => {
      if (!DEPRECATED_TIERS.includes(tier) && !HIDDEN_TIERS.includes(tier)) {
        return {
          optionDescription: tierDescriptions[tier]?.description,
          optionLabel: tier,
          value: tier,
          isActive: true,
        };
      }
      return [];
    })
    .concat(deprecatedTierTypes);
}

function asNumber(value: string) {
  return value === "0" ? 0 : Number(value) || null;
}

function removeEmpty(obj: PricingPlanOptionsInputType | PricesInputType) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== null));
}

function convertDollarsToCents(price: number) {
  return Number((price * 100).toFixed(0));
}

function convertPricesToCents(prices: PricesInputType) {
  return Object.entries(prices).forEach(([key, value]) => {
    if (value) {
      prices[key as keyof PricesInputType] = convertDollarsToCents(value);
    }
  });
}

function convertCentsToDollars(price: number) {
  return parseFloat((price / 100).toFixed(2));
}

function convertPricesToDollars(prices: JsonPricesType) {
  return Object.entries(prices).forEach(([key, value]) => {
    if (value) {
      prices[key] = convertCentsToDollars(value);
    }
  });
}
// These legacy fields exist on older tiers and should not be shown
function removeUnwantedPriceFields(prices: JsonPricesType) {
  const unwantedFields = [
    "first_identity_confirmation",
    "additional_identity_confirmation",
    "notaverse_first_identity_confirmation",
    "notaverse_additional_identity_confirmation",
  ];
  unwantedFields.forEach((field) => delete prices[field]);
}

function validate(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "tierName", label: "Internal tier name" }),
    validatePresence({ field: "displayName", label: "Customer display name" }),
    validatePresence({ field: "tierType", label: "Tier type" }),
  )(values);
}

function CurrencyField(props: ComponentPropsWithoutRef<typeof DeprecatedTextField>) {
  const intl = useIntl();

  return (
    <div className={Styles.currency}>
      <DeprecatedTextField
        {...props}
        useStyledInput
        type="number"
        min={"0"}
        step={"0.01"}
        parse={asNumber}
        placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
        onWheel={(e: WheelEvent) => (e.target as HTMLElement).blur()}
      />
      <span className={props.disabled ? classnames(Styles.unit, Styles.disabled) : Styles.unit}>
        $
      </span>
    </div>
  );
}

function getTierDetails(data: TierDetails, id?: string | null): TierNode {
  if (id) {
    const tierDetails = data.viewer.tiers.edges.find((x) => x.node.id === id)?.node as TierNode;

    const prices = JSON.parse(tierDetails.pricesJson) as JsonPricesType;
    removeUnwantedPriceFields(prices);
    convertPricesToDollars(prices);

    const options = JSON.parse(tierDetails.optionsJson) as Record<string, number>;
    if (options.max_transaction_price) {
      options.max_transaction_price = convertCentsToDollars(options.max_transaction_price);
    }

    return {
      ...tierDetails,
      pricesJson: JSON.stringify(prices),
      optionsJson: JSON.stringify(options),
    };
  }
  // dummy TierDetails with empty default values to be passed to component when creating new tier
  return {
    displayName: "",
    id: "",
    linkedPlanId: null,
    createdAt: "",
    updatedAt: "",
    name: "",
    optionsJson: "{}",
    pricesJson: "{}",
    standardTier: false,
    tier: Tier.BASIC,
    used: false,
    visibility: TierVisibility.PRIVATE,
  };
}

function TierDetailsForm(props: InnerProps) {
  const navigate = useNavigate();
  const { initialize, handleSubmit, formValues, change, tierDetails, planList } = props;

  const [error, updateError] = useState<null | string>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showBasicPricing, setShowBasicPricing] = useState(true);
  const [showBasicOptions, setShowBasicOptions] = useState(true);

  const intl = useIntl();

  const { id } = useParams();
  const { hasPermissionFor } = usePermissions();
  const isNewTier = !id;
  const canEdit = id ? hasPermissionFor("editTier") : hasPermissionFor("createTier");

  const isStandard = tierDetails.standardTier;
  const isUsed = tierDetails.used;
  const allFieldsDisabled = isStandard || !canEdit;
  const advancedFieldsDisabled = isUsed || allFieldsDisabled;

  const { tierType } = formValues;
  const isTitle = TIER_TITLE_TYPES.includes(tierType);
  const isLender = LENDER_TIER_TYPES.includes(tierType);

  // removing the tier's own id from this list since no tier should be linked to itself
  // also changing tier ids starting with 'pp' to have 'ti' instead
  const filteredPlanList = planList
    .map((x) => ({
      value: `ti${x.id.slice(2)}`,
      label: x.name,
    }))
    .filter((x) => {
      return x.value !== id;
    });

  const upsertTierMutateFn = useMutation(UpsertTierMutation);

  const onSubmit = () => {
    setIsSubmitting(true);

    const prices = removeEmpty(formValues.prices);
    convertPricesToCents(prices);
    const options = removeEmpty(formValues.options);
    if (options.maxTransactionPrice) {
      options.maxTransactionPrice = convertDollarsToCents(options.maxTransactionPrice as number);
    }

    upsertTierMutateFn({
      variables: {
        input: {
          tierId: id || null,
          displayName: formValues.displayName,
          name: formValues.tierName,
          feature: formValues.tierType,
          visibility: formValues.visibility,
          linkedPlanId: formValues.linkedPlan || null,
          options,
          prices,
        },
      },
      update(cacheProxy, { data }) {
        const tier = data!.upsertTier!.tier;
        const viewer = cacheProxy.readQuery<TierDetails>({
          query: TierDetailsQuery,
        })!.viewer;
        const newViewer = {
          ...viewer,
          tiers: {
            ...viewer.tiers,
            edges: [...viewer.tiers.edges, { node: tier, __typename: "TierInfoEdge" }],
          },
        };
        cacheProxy.writeQuery({
          query: TierDetailsQuery,
          data: { viewer: newViewer },
        });
      },
    })
      .then((response) => {
        if (isNewTier) {
          navigate(`/finance/details/${response.data?.upsertTier?.tier?.id}`);
        }
      })
      .catch((error) => {
        if (isGraphQLError(error)) {
          updateError(
            error.graphQLErrors[0]?.message || intl.formatMessage(MESSAGES.defaultErrorMessage),
          );
        } else {
          updateError(intl.formatMessage(MESSAGES.defaultErrorMessage));
          captureException(error);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const updateFullEClose = (e: FormEvent<HTMLInputElement>) => {
    const value = asNumber(e.currentTarget.value);
    change("prices.refinanceTransaction", value);
    change("prices.purchaseSellerTransaction", value);
    change("prices.purchaseBuyerCashTransaction", value);
    change("prices.purchaseBuyerLoanTransaction", value);
    change("prices.helocTransaction", value);
  };

  const updateSequential = (e: FormEvent<HTMLInputElement>) => {
    const value = asNumber(e.currentTarget.value);
    change("prices.additionalRefinanceNsaMeeting", value);
    change("prices.additionalPurchaseSellerNsaMeeting", value);
    change("prices.additionalPurchaseBuyerCashNsaMeeting", value);
    change("prices.additionalPurchaseBuyerLoadNsaMeeting", value);
    change("prices.additionalHelocNsaMeeting", value);
  };

  const updateNotaverseFullEClose = (e: FormEvent<HTMLInputElement>) => {
    const value = asNumber(e.currentTarget.value);
    change("prices.notaverseRefinanceTransaction", value);
    change("prices.notaversePurchaseSellerTransaction", value);
    change("prices.notaversePurchaseBuyerCashTransaction", value);
    change("prices.notaversePurchaseBuyerLoanTransaction", value);
    change("prices.notaverseHelocTransaction", value);
  };

  const updateNotaverseSequential = (e: FormEvent<HTMLInputElement>) => {
    const value = asNumber(e.currentTarget.value);
    change("prices.notaverseAdditionalRefinanceNsaMeeting", value);
    change("prices.notaverseAdditionalPurchaseSellerNsaMeeting", value);
    change("prices.notaverseAdditionalPurchaseBuyerCashNsaMeeting", value);
    change("prices.notaverseAdditionalPurchaseBuyerLoadNsaMeeting", value);
    change("prices.notaverseAdditionalHelocNsaMeeting", value);
  };

  const updateHybrid = (e: FormEvent<HTMLInputElement>) => {
    const value = asNumber(e.currentTarget.value);
    change("prices.hybridRefinanceTransaction", value);
    change("prices.hybridTrailingDocsTransaction", value);
    change("prices.hybridPurchaseSellerTransaction", value);
    change("prices.hybridPurchaseBuyerCashTransaction", value);
    change("prices.hybridPurchaseBuyerLoanTransaction", value);
    change("prices.hybridHelocTransaction", value);
  };

  const updatePrices = (e: FormEvent<HTMLInputElement>) => {
    try {
      const prices = JSON.parse(e.currentTarget.value);
      change("prices", prices);
    } catch {}
  };

  const togglePrices = () => {
    if (showBasicPricing) {
      // formatting
      change("pricesJson", JSON.stringify(removeEmpty(formValues.prices), null, 4));
    } else {
      // syntax check
      try {
        JSON.parse(formValues.pricesJson);
      } catch {
        updateError(intl.formatMessage(MESSAGES.invalidJsonSyntax));
        return;
      }
    }
    setShowBasicPricing(!showBasicPricing);
  };

  const updateOptions = (e: FormEvent<HTMLInputElement>) => {
    try {
      change("options", JSON.parse(e.currentTarget.value));
    } catch {}
  };

  const toggleOptions = () => {
    if (showBasicOptions) {
      // formatting
      change("optionsJson", JSON.stringify(removeEmpty(formValues.options), null, 4));
    } else {
      // syntax check
      try {
        JSON.parse(formValues.optionsJson);
      } catch {
        updateError(intl.formatMessage(MESSAGES.invalidJsonSyntax));
        return;
      }
    }
    setShowBasicOptions(!showBasicOptions);
  };

  useEffect(() => {
    // loading data as the raw json, since that doesn't include default values
    // unless they are overwritten (eg. platform fee threshold)
    const prices: PricesInputType = JSON.parse(
      tierDetails.pricesJson,
      camelizeObject,
    ) as PricesInputType;
    const options: PricingPlanOptionsInputType = JSON.parse(
      tierDetails.optionsJson,
      camelizeObject,
    ) as PricingPlanOptionsInputType;

    initialize({
      tierName: tierDetails.name || undefined,
      displayName: tierDetails.displayName,
      tierType: tierDetails.tier,
      prices,
      options,
      linkedPlan: tierDetails.linkedPlanId || undefined,
      visibility: tierDetails.visibility,
    });
  }, [tierDetails]);
  const { searchQuery, setSearchQuery, filteredOptions } = useFilterOptions(TierTypes);

  const defaultTierValue = TierTypes.find((type) => tierDetails.tier === type.value);
  const [selectedTierOption, setSelectedTierOption] = useState<TierOptionType | undefined>(
    defaultTierValue,
  );

  const handleTierChange = (option: TierOptionType) => {
    if (option !== selectedTierOption) {
      setSelectedTierOption(option);
      formValues.tierType = option.value as Tier;
    }
  };
  return (
    <AppFrameInnerContainer
      title={
        isNewTier
          ? intl.formatMessage(MESSAGES.newTierTitle)
          : intl.formatMessage(MESSAGES.tierDetailsTitle)
      }
      closePath="/finance"
    >
      {error && <RegularErrorModal clearErrors={() => updateError(null)} errorString={error} />}

      <form
        className={Styles.detailsForm}
        onSubmit={handleSubmit(onSubmit)}
        data-automation-id="tier-details-form"
      >
        <div className={Styles.detailsFormSection}>
          <ProfileSectionHeader>{intl.formatMessage(MESSAGES.infoHeader)}</ProfileSectionHeader>

          <FormGroup fields={["tierName"]}>
            <label htmlFor="tierName">{intl.formatMessage(MESSAGES.internalTierName)}</label>
            <DeprecatedTextField
              useStyledInput
              id="tierName"
              name="tierName"
              displayRequiredAsterisk
              disabled={allFieldsDisabled}
            />
            <FormGroupErrors fields={["tierName"]} />
          </FormGroup>

          <FormGroup fields={["displayName"]}>
            <label htmlFor="displayName">{intl.formatMessage(MESSAGES.displayName)}</label>
            <DeprecatedTextField
              useStyledInput
              id="displayName"
              name="displayName"
              displayRequiredAsterisk
              disabled={allFieldsDisabled}
            />
            <FormGroupErrors fields={["displayName"]} />
          </FormGroup>

          <FormGroup fields={["tierType"]}>
            <label htmlFor="tierType">{intl.formatMessage(MESSAGES.tierTypeTitle)}</label>
            <ComboboxDropdown
              label={intl.formatMessage(MESSAGES.tierTypeTitle)}
              withSearch={{
                searchQuery,
                setSearchQuery,
                searchInputLabel: intl.formatMessage(MESSAGES.tierTypePlaceholder),
                searchInputPlaceholder: intl.formatMessage(MESSAGES.tierTypePlaceholder),
                filteredResultLength: filteredOptions.length,
              }}
              selectedLabel={selectedTierOption?.value}
              hasSelection={selectedTierOption !== undefined}
              onClearSelection={() => setSelectedTierOption(undefined)}
              data-automation-id="tier-type"
            >
              {({ closeMenu }) =>
                filteredOptions.map((tierType, key) => (
                  <ComboboxDropdownMenuOption
                    disabled={advancedFieldsDisabled}
                    selected={selectedTierOption === tierType}
                    key={key}
                    onChange={() => {
                      handleTierChange(tierType);
                      closeMenu();
                    }}
                    optionLabel={tierType.optionLabel}
                    optionDescription={tierType.optionDescription}
                    optionBadge={
                      tierType.isActive
                        ? { kind: "success", text: intl.formatMessage(MESSAGES.activeBadge) }
                        : { kind: "danger", text: intl.formatMessage(MESSAGES.deprecatedBadge) }
                    }
                  />
                ))
              }
            </ComboboxDropdown>
            <FormGroupErrors fields={["tierType"]} />
          </FormGroup>
        </div>

        <div className={Styles.detailsFormSection}>
          <ProfileSectionHeader>{intl.formatMessage(MESSAGES.pricingHeader)}</ProfileSectionHeader>

          <Button
            variant="tertiary"
            buttonColor="action"
            buttonSize="condensed"
            onClick={togglePrices}
            automationId="toggle-prices"
            className={Styles.toggleLink}
          >
            {intl.formatMessage(MESSAGES.toggleLinkText)}
          </Button>

          {showBasicPricing ? (
            <div>
              <FormGroup fields={["prices.platformSubscription"]}>
                <label htmlFor="prices.platformSubscription">
                  {props.getPricingDisplayName("platformSubscription")}
                </label>
                <CurrencyField
                  id="prices.platformSubscription"
                  name="prices.platformSubscription"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.platformMinimumCommitment"]}>
                <label htmlFor="prices.platformMinimumCommitment">
                  {props.getPricingDisplayName("platformMinimumCommitment")}
                </label>
                <CurrencyField
                  id="prices.platformMinimumCommitment"
                  name="prices.platformMinimumCommitment"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.commandCenter"]}>
                <label htmlFor="prices.commandCenter">
                  {props.getPricingDisplayName("commandCenter")}
                </label>
                <CurrencyField
                  id="prices.commandCenter"
                  name="prices.commandCenter"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.commandCenterLite"]}>
                <label htmlFor="prices.commandCenterLite">
                  {props.getPricingDisplayName("commandCenterLite")}
                </label>
                <CurrencyField
                  id="prices.commandCenterLite"
                  name="prices.commandCenterLite"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.firstSeal"]}>
                <label htmlFor="prices.firstSeal">{props.getPricingDisplayName("firstSeal")}</label>
                <CurrencyField
                  id="prices.firstSeal"
                  name="prices.firstSeal"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.additionalSeal"]}>
                <label htmlFor="prices.additionalSeal">
                  {props.getPricingDisplayName("additionalSeal")}
                </label>
                <CurrencyField
                  id="prices.additionalSeal"
                  name="prices.additionalSeal"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.additionalSigner"]}>
                <label htmlFor="prices.additionalSigner">
                  {props.getPricingDisplayName("additionalSigner")}
                </label>
                <CurrencyField
                  id="prices.additionalSigner"
                  name="prices.additionalSigner"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.remoteOdnWitness"]}>
                <label htmlFor="prices.remoteOdnWitness">
                  {props.getPricingDisplayName("remoteOdnWitness")}
                </label>
                <CurrencyField
                  id="prices.remoteOdnWitness"
                  name="prices.remoteOdnWitness"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.esignedBundle"]}>
                <label htmlFor="prices.esignedBundle">
                  {props.getPricingDisplayName("esignedBundle")}
                </label>
                <CurrencyField
                  id="prices.esignedBundle"
                  name="prices.esignedBundle"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.esignAuthentication"]}>
                <label htmlFor="prices.esignAuthentication">
                  {props.getPricingDisplayName("esignAuthentication")}
                </label>
                <CurrencyField
                  id="prices.esignAuthentication"
                  name="prices.esignAuthentication"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.esignKba"]}>
                <label htmlFor="prices.esignKba">{props.getPricingDisplayName("esignKba")}</label>
                <CurrencyField
                  id="prices.esignKba"
                  name="prices.esignKba"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.esignProof"]}>
                <label htmlFor="prices.esignProof">
                  {props.getPricingDisplayName("esignProof")}
                </label>
                <CurrencyField
                  id="prices.esignProof"
                  name="prices.esignProof"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.identify"]}>
                <label htmlFor="prices.identify">{props.getPricingDisplayName("identify")}</label>
                <CurrencyField
                  id="prices.identify"
                  name="prices.identify"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.trustedRefereeMeeting"]}>
                <label htmlFor="prices.trustedRefereeMeeting">
                  {props.getPricingDisplayName("trustedRefereeMeeting")}
                </label>
                <CurrencyField
                  id="prices.trustedRefereeMeeting"
                  name="prices.trustedRefereeMeeting"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              {(isLender || isTitle) && (
                <div>
                  <FormGroup fields={["prices.purchaseSellerTransaction"]}>
                    <label htmlFor="prices.purchaseSellerTransaction">
                      {intl.formatMessage(MESSAGES.fullEcloseFee)}
                    </label>
                    <CurrencyField
                      id="prices.purchaseSellerTransaction"
                      name="prices.purchaseSellerTransaction"
                      onChange={updateFullEClose}
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                    <DeprecatedFormRowHelpTooltip tooltipPlacement="top">
                      <ul>
                        <li>refinanceTransaction</li>
                        <li>purchaseSellerTransaction</li>
                        <li>purchaseBuyerCashTransaction</li>
                        <li>purchaseBuyerLoanTransaction</li>
                        <li>helocTransaction</li>
                      </ul>
                    </DeprecatedFormRowHelpTooltip>
                  </FormGroup>

                  <FormGroup fields={["prices.loanModBorrowerTransaction"]}>
                    <label htmlFor="prices.loanModBorrowerTransaction">
                      {intl.formatMessage(MESSAGES.fullEcloseLoanModFee)}
                    </label>
                    <CurrencyField
                      id="prices.loanModBorrowerTransaction"
                      name="prices.loanModBorrowerTransaction"
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                  </FormGroup>

                  <FormGroup fields={["prices.additionalPurchaseSellerNsaMeeting"]}>
                    <label htmlFor="prices.additionalPurchaseSellerNsaMeeting">
                      {intl.formatMessage(MESSAGES.sequentialFee)}
                    </label>
                    <CurrencyField
                      id="prices.additionalPurchaseSellerNsaMeeting"
                      name="prices.additionalPurchaseSellerNsaMeeting"
                      onChange={updateSequential}
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                    <DeprecatedFormRowHelpTooltip tooltipPlacement="top">
                      <ul>
                        <li>additionalRefinanceNsaMeeting</li>
                        <li>additionalPurchaseSellerNsaMeeting</li>
                        <li>additionalPurchaseBuyerCashNsaMeeting</li>
                        <li>additionalPurchaseBuyerLoadNsaMeeting</li>
                        <li>additionalHelocNsaMeeting</li>
                      </ul>
                    </DeprecatedFormRowHelpTooltip>
                  </FormGroup>

                  <FormGroup fields={["prices.additionalLoanModBorrowerNsaMeeting"]}>
                    <label htmlFor="prices.additionalLoanModBorrowerNsaMeeting">
                      {intl.formatMessage(MESSAGES.loanModSequentialFee)}
                    </label>
                    <CurrencyField
                      id="prices.additionalLoanModBorrowerNsaMeeting"
                      name="prices.additionalLoanModBorrowerNsaMeeting"
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                  </FormGroup>
                </div>
              )}

              <FormGroup fields={["prices.notaverseFirstSeal"]}>
                <label htmlFor="prices.notaverseFirstSeal">
                  {props.getPricingDisplayName("notaverseFirstSeal")}
                </label>
                <CurrencyField
                  id="prices.notaverseFirstSeal"
                  name="prices.notaverseFirstSeal"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.notaverseAdditionalSeal"]}>
                <label htmlFor="prices.notaverseAdditionalSeal">
                  {props.getPricingDisplayName("notaverseAdditionalSeal")}
                </label>
                <CurrencyField
                  id="prices.notaverseAdditionalSeal"
                  name="prices.notaverseAdditionalSeal"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              <FormGroup fields={["prices.notaverseAdditionalSigner"]}>
                <label htmlFor="prices.notaverseAdditionalSigner">
                  {props.getPricingDisplayName("notaverseAdditionalSigner")}
                </label>
                <CurrencyField
                  id="prices.notaverseAdditionalSigner"
                  name="prices.notaverseAdditionalSigner"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>

              {(isLender || isTitle) && (
                <div>
                  <FormGroup fields={["prices.notaversePurchaseSellerTransaction"]}>
                    <label htmlFor="prices.notaversePurchaseSellerTransaction">
                      {intl.formatMessage(MESSAGES.byonFullEcloseFee)}
                    </label>
                    <CurrencyField
                      id="prices.notaversePurchaseSellerTransaction"
                      name="prices.notaversePurchaseSellerTransaction"
                      onChange={updateNotaverseFullEClose}
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                    <DeprecatedFormRowHelpTooltip tooltipPlacement="top">
                      <ul>
                        <li>notaverseRefinanceTransaction</li>
                        <li>notaversePurchaseSellerTransaction</li>
                        <li>notaversePurchaseBuyerCashTransaction</li>
                        <li>notaversePurchaseBuyerLoanTransaction</li>
                        <li>notaverseHelocTransaction</li>
                      </ul>
                    </DeprecatedFormRowHelpTooltip>
                  </FormGroup>

                  <FormGroup fields={["prices.notaverseLoanModBorrowerTransaction"]}>
                    <label htmlFor="prices.notaverseLoanModBorrowerTransaction">
                      {intl.formatMessage(MESSAGES.byonFullEcloseLoanModFee)}
                    </label>
                    <CurrencyField
                      id="prices.notaverseLoanModBorrowerTransaction"
                      name="prices.notaverseLoanModBorrowerTransaction"
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                  </FormGroup>

                  <FormGroup fields={["prices.notaverseAdditionalPurchaseSellerNsaMeeting"]}>
                    <label htmlFor="prices.notaverseAdditionalPurchaseSellerNsaMeeting">
                      {intl.formatMessage(MESSAGES.byonSequentialFee)}
                    </label>
                    <CurrencyField
                      id="prices.notaverseAdditionalPurchaseSellerNsaMeeting"
                      name="prices.notaverseAdditionalPurchaseSellerNsaMeeting"
                      onChange={updateNotaverseSequential}
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                    <DeprecatedFormRowHelpTooltip tooltipPlacement="top">
                      <ul>
                        <li>notaverseAdditionalRefinanceNsaMeeting</li>
                        <li>notaverseAdditionalPurchaseSellerNsaMeeting</li>
                        <li>notaverseAdditionalPurchaseBuyerCashNsaMeeting</li>
                        <li>notaverseAdditionalPurchaseBuyerLoadNsaMeeting</li>
                        <li>notaverseAdditionalHelocNsaMeeting</li>
                      </ul>
                    </DeprecatedFormRowHelpTooltip>
                  </FormGroup>

                  <FormGroup fields={["prices.notaverseAdditionalLoanModBorrowerNsaMeeting"]}>
                    <label htmlFor="prices.notaverseAdditionalLoanModBorrowerNsaMeeting">
                      {intl.formatMessage(MESSAGES.byonLoanModSequentialFee)}
                    </label>
                    <CurrencyField
                      id="prices.notaverseAdditionalLoanModBorrowerNsaMeeting"
                      name="prices.notaverseAdditionalLoanModBorrowerNsaMeeting"
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                  </FormGroup>

                  <FormGroup fields={["prices.hybridPurchaseSellerTransaction"]}>
                    <label htmlFor="prices.hybridPurchaseSellerTransaction">
                      {intl.formatMessage(MESSAGES.hybridFee)}
                    </label>
                    <CurrencyField
                      id="prices.hybridPurchaseSellerTransaction"
                      name="prices.hybridPurchaseSellerTransaction"
                      onChange={updateHybrid}
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                    <DeprecatedFormRowHelpTooltip tooltipPlacement="top">
                      <ul>
                        <li>hybridRefinanceTransaction</li>
                        <li>hybridTrailingDocsTransaction</li>
                        <li>hybridPurchaseSellerTransaction</li>
                        <li>hybridPurchaseBuyerCashTransaction</li>
                        <li>hybridPurchaseBuyerLoanTransaction</li>
                        <li>hybridHelocTransaction</li>
                      </ul>
                    </DeprecatedFormRowHelpTooltip>
                  </FormGroup>

                  <FormGroup fields={["prices.closingConcierge"]}>
                    <label htmlFor="prices.closingConcierge">
                      {intl.formatMessage(MESSAGES.closingConciergeFee)}
                    </label>
                    <CurrencyField
                      id="prices.closingConcierge"
                      name="prices.closingConcierge"
                      disabled={advancedFieldsDisabled}
                      placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                    />
                  </FormGroup>
                </div>
              )}
            </div>
          ) : (
            <FormGroup fields={["pricesJson"]} disableFormRowStyle>
              <DeprecatedTextAreaField
                useStyledInput
                id="pricesJson"
                name="pricesJson"
                onChange={updatePrices}
                disabled={advancedFieldsDisabled}
                data-automation-id="prices-json"
              />
            </FormGroup>
          )}
        </div>

        <div className={Styles.detailsFormSection}>
          <ProfileSectionHeader>{intl.formatMessage(MESSAGES.optionsHeader)}</ProfileSectionHeader>

          <Button
            variant="tertiary"
            buttonColor="action"
            buttonSize="condensed"
            onClick={toggleOptions}
            automationId="toggle-options"
            className={Styles.toggleLink}
          >
            {intl.formatMessage(MESSAGES.toggleLinkText)}
          </Button>

          {showBasicOptions ? (
            <div>
              <FormGroup fields={["options.employeeLimit"]}>
                <label htmlFor="options.employeeLimit">
                  {props.getOptionDisplayName("employeeLimit")}
                </label>
                <DeprecatedTextField
                  id="options.employeeLimit"
                  name="options.employeeLimit"
                  type="number"
                  min="0"
                  parse={asNumber}
                  useStyledInput
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                  onWheel={(e: WheelEvent) => (e.target as HTMLElement).blur()}
                />
              </FormGroup>

              <FormGroup fields={["options.platformFeeThreshold"]}>
                <label htmlFor="options.platformFeeThreshold">
                  {props.getOptionDisplayName("platformFeeThreshold")}
                </label>
                <DeprecatedTextField
                  id="options.platformFeeThreshold"
                  name="options.platformFeeThreshold"
                  type="number"
                  min="0"
                  step={"0.01"}
                  parse={asNumber}
                  useStyledInput
                  disabled={advancedFieldsDisabled}
                  placeholder={"default"}
                  onWheel={(e: WheelEvent) => (e.target as HTMLElement).blur()}
                />
              </FormGroup>

              <FormGroup fields={["options.maxTransactionPrice"]}>
                <label htmlFor="options.maxTransactionPrice">
                  {props.getOptionDisplayName("maxTransactionFee")}
                </label>
                <CurrencyField
                  id="options.maxTransactionPrice"
                  name="options.maxTransactionPrice"
                  disabled={advancedFieldsDisabled}
                  placeholder={intl.formatMessage(MESSAGES.notSpecifiedPlaceholder)}
                />
              </FormGroup>
            </div>
          ) : (
            <FormGroup disableFormRowStyle>
              <DeprecatedTextAreaField
                useStyledInput
                id="optionsJson"
                name="optionsJson"
                onChange={updateOptions}
                disabled={advancedFieldsDisabled}
                data-automation-id="options-json"
              />
            </FormGroup>
          )}
        </div>

        <div className={Styles.detailsFormSection}>
          <ProfileSectionHeader>{intl.formatMessage(MESSAGES.otherHeader)}</ProfileSectionHeader>

          <FormGroup fields={["linkedPlan"]}>
            <label htmlFor="linkedPlan">{intl.formatMessage(MESSAGES.linkedPlan)}</label>
            <DeprecatedSelectField
              useStyledInput
              automationId=""
              name="linkedPlan"
              items={filteredPlanList}
              disabled={advancedFieldsDisabled}
              placeholder={intl.formatMessage(MESSAGES.linkedPlanPlaceholder)}
              data-automation-id="linked-plan"
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="visibility">{intl.formatMessage(MESSAGES.visibility)}</label>
            <DeprecatedRadioButtonField
              name="visibility"
              labelText={intl.formatMessage(MESSAGES.publicRadioText)}
              radioValue={TierVisibility.PUBLIC}
              disabled={allFieldsDisabled}
            />
            <DeprecatedRadioButtonField
              name="visibility"
              labelText={intl.formatMessage(MESSAGES.privateRadioText)}
              radioValue={TierVisibility.PRIVATE}
              disabled={allFieldsDisabled}
            />
          </FormGroup>
        </div>

        <Button
          buttonColor="action"
          variant="primary"
          fullwidth
          type="submit"
          isLoading={isSubmitting}
          disabled={allFieldsDisabled}
        >
          {isNewTier
            ? intl.formatMessage(MESSAGES.createButtonText)
            : intl.formatMessage(MESSAGES.saveButtonText)}
        </Button>
        {tierDetails.createdAt && (
          <div className={Styles.timestampContainer}>
            {intl.formatMessage(MESSAGES.createdAtName)}:
            <div className={Styles.timestamp}>
              <LongFormattedDateTime value={tierDetails.createdAt} />
            </div>
          </div>
        )}
        {tierDetails.updatedAt && (
          <div className={Styles.timestampContainer}>
            {intl.formatMessage(MESSAGES.lastUpdatedAtName)}:
            <div className={Styles.timestamp}>
              <LongFormattedDateTime value={tierDetails.createdAt} />
            </div>
          </div>
        )}
      </form>
    </AppFrameInnerContainer>
  );
}

const TierDetailsReduxForm = compose(
  reduxForm<FormValues, Props>({ form: "tierDetailsForm", validate }),
  getFormValues<InnerProps>("tierDetailsForm"),
)(TierDetailsForm);

function TierDetailsWrapper() {
  const { data, loading } = useQuery(TierDetailsQuery);
  const { id } = useParams();
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  const canViewPage = id ? hasPermissionFor("tierDetails") : hasPermissionFor("createTier");

  // redirect back to finance if insufficient permissions
  if (!canViewPage) {
    navigate("/finance");
  }

  const getPricingDisplayName = (itemName: string) => {
    return <>{data.viewer.pricingDisplayNames[itemName] || itemName}</>;
  };

  const getOptionDisplayName = (itemName: string) => {
    return <>{data.viewer.optionDisplayNames[itemName] || itemName}</>;
  };

  const planList = data.viewer.tierEligibeLenderPartnerPlans;

  return (
    <TierDetailsReduxForm
      tierDetails={getTierDetails(data, id)}
      planList={planList}
      getPricingDisplayName={getPricingDisplayName}
      getOptionDisplayName={getOptionDisplayName}
    />
  );
}

export default TierDetailsWrapper;

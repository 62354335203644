import { useEffect, useState, type ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import Tab from "common/core/tabs/tab";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import { useQuery, useMutation } from "util/graphql";
import { OrganizationTypeEnum } from "graphql_globals";
import { usePrependedDocumentTitle } from "util/html";

import AdminCompanyDetailsCommunicationSettingsGraph, {
  type AdminCompanyDetailsCommunicationSettings as Data,
  type AdminCompanyDetailsCommunicationSettingsVariables as Variables,
  type AdminCompanyDetailsCommunicationSettings_node_Organization as Organization,
} from "./index.query.graphql";
import CreateOrganizationBrandMutation from "./create_organization_brand.mutation.graphql";

const MESSAGES = defineMessages({
  title: {
    id: "0a75f018-7151-4427-b361-f90b70c9c96d",
    defaultMessage: "Email Customization",
  },
  newTitle: {
    id: "5c3411b8-7828-4ade-b0e7-1142e67faa2c",
    defaultMessage: "Brand Customization",
  },
  pageTitle: {
    id: "5d936392-35c4-4808-9978-026ed1ce4a8b",
    defaultMessage: "Transactions",
  },
});

export default function AdminCompanyDetailsCommunicationSettings({
  children,
}: {
  children: ({ organization }: { organization: Organization }) => ReactNode;
}) {
  const organizationId = useParams().globalID!;
  const [gettingOrCreatingOrganizationBrand, setGettingOrCreatingOrganizationBrand] =
    useState(true);
  const intl = useIntl();
  usePrependedDocumentTitle(intl.formatMessage(MESSAGES.pageTitle));

  const { data, loading } = useQuery(AdminCompanyDetailsCommunicationSettingsGraph, {
    variables: { organizationId },
  });

  const createOrganizationBrand = useMutation(CreateOrganizationBrandMutation);

  useEffect(() => {
    if (!loading && data?.node?.__typename === "Organization" && !data.node.organizationBrand) {
      createOrganizationBrand({
        variables: { input: { organizationId } },
        update(cacheProxy, { data }) {
          const { node, ...other } = cacheProxy.readQuery<Data, Variables>({
            query: AdminCompanyDetailsCommunicationSettingsGraph,
            variables: { organizationId },
          })!;
          if (node?.__typename !== "Organization") {
            throw new Error(`Expected Organization, got ${node?.__typename}.`);
          }
          if (!data?.createOrganizationBrand?.organizationBrand) {
            throw new Error("Organization brand failed to be created.");
          }
          const newNode = {
            ...node,
            organizationBrand: data.createOrganizationBrand.organizationBrand,
          };
          cacheProxy.writeQuery({
            query: AdminCompanyDetailsCommunicationSettingsGraph,
            variables: { organizationId },
            data: { ...other, node: newNode },
          });
        },
      }).then(() => {
        setGettingOrCreatingOrganizationBrand(false);
      });
    } else if (
      !loading &&
      data?.node?.__typename === "Organization" &&
      data.node.organizationBrand
    ) {
      setGettingOrCreatingOrganizationBrand(false);
    }
  }, [organizationId, data, loading]);

  if (loading || gettingOrCreatingOrganizationBrand) {
    return <LoadingIndicator />;
  }

  const organization = data ? data.node! : null;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  if (!organization) {
    throw new Error(`Expected organization, got nothing.`);
  }

  return (
    <SettingsPageWrapper>
      <SettingsHeader
        title={intl.formatMessage(MESSAGES.newTitle)}
        tabs={
          <>
            <Tab to={`/companies/${organization.id}/communication-settings/global-settings`}>
              <FormattedMessage
                id="c944c625-fc62-46ea-a566-9ca6ea90ecf6"
                defaultMessage="Global Settings"
              />
            </Tab>
            <Tab to={`/companies/${organization.id}/communication-settings/signer-emails`}>
              <FormattedMessage
                id="d03fc893-81b5-49bf-85b3-62af66a3d031"
                defaultMessage="Signer Emails"
              />
            </Tab>
            {OrganizationTypeEnum.BUSINESS !== organization.organizationType && (
              <Tab to={`/companies/${organization.id}/communication-settings/contact-emails`}>
                <FormattedMessage
                  id="1a19d8eb-fd2d-4eae-bc16-52d6c9bd56d1"
                  defaultMessage="Contact Emails"
                />
              </Tab>
            )}
          </>
        }
      />
      {children({ organization })}
    </SettingsPageWrapper>
  );
}

import "./index.scss";
import "./upload_view.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Subject, takeUntil } from "rxjs";

import { Feature, ProcessingStates } from "graphql_globals";
import { Badge } from "common/core/badge";
import Button from "common/core/button";
import Modal from "common/modal";
import { COMPLETION_REQUIREMENTS } from "constants/document_bundle";
import { Paragraph } from "common/core/typography";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { isGraphQLError } from "util/graphql/query";

import { TemplateView } from "./template";
import UploadView from "./upload_view";
import { DOCUMENT_PROPTYPE, getPreferredFileMessage } from "../document_item_util";

const UPLOAD_DOCUMENT = Symbol("Upload Document Navigation");
const UPLOAD_TEMPLATE = Symbol("Upload Template Navigation");

const QR_VERIFICATION_ERROR = "qr_code_verification_failed";

class AddDocumentModal extends PureComponent {
  state = {
    uploadedDocuments: [],
    selectedTemplates: [],
    activeNav: UPLOAD_DOCUMENT,
    waitingForComplete: false,
  };

  handleComplete = () => {
    const documentItems = this.state.uploadedDocuments
      .filter((doc) => doc.status === ProcessingStates.DONE)
      .map((doc) => doc.userDocument);
    this.setState(() => ({ waitingForComplete: true }));
    this.props
      .onUploadComplete(documentItems, this.state.selectedTemplates)
      .pipe(takeUntil(this.unmounted$))
      .subscribe({
        error: (err) => {
          const error = isGraphQLError(err) ? err.graphQLErrors[0] : err;

          const message =
            error.message === QR_VERIFICATION_ERROR ? (
              <FormattedMessage
                id="252d4773-c0e8-4d3b-b480-c85f5d12cdc4"
                defaultMessage="For a Certify transaction, your document must contain the transaction's unique QR code. Please upload a new document."
              />
            ) : (
              <FormattedMessage
                id="d8c2afb6-51cf-431d-878a-0fd0080d0418"
                defaultMessage="An issue occurred with adding the document(s). Please try again."
              />
            );
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message,
          });
          this.setState(() => ({ waitingForComplete: false }));
        },
        complete: () => {
          this.setState(() => ({ waitingForComplete: false }));
        },
      });
  };

  componentDidMount() {
    this.unmounted$ = new Subject();
    this.props.uploadedDocuments$.pipe(takeUntil(this.unmounted$)).subscribe({
      next: (uploadedDocumentsUpdaterFn) => {
        this.setState((prevState) => ({
          uploadedDocuments: uploadedDocumentsUpdaterFn(prevState.uploadedDocuments),
        }));
      },
      error: console.error, // eslint-disable-line no-console
    });
  }

  componentWillUnmount() {
    const { unmounted$ } = this;

    unmounted$.next();
    unmounted$.complete();
    this.unmounted$ = null;
  }

  validateTemplates = () => {
    const {
      defaultDocRequirements,
      organization: { templates },
    } = this.props;

    return (
      templates &&
      templates.edges
        .filter((e) => {
          const template = e.node;
          const {
            documentBundle: { completionRequirements },
          } = template;
          return !(
            defaultDocRequirements.esign &&
            (completionRequirements.includes(COMPLETION_REQUIREMENTS.NOTARIZATION) ||
              completionRequirements.includes(COMPLETION_REQUIREMENTS.PROOFING))
          );
        })
        .map((e) => e.node)
    );
  };

  handleSelectTemplates = (templateId, selected) => {
    const arrTemplateId = this.state.selectedTemplates.filter((id) => id !== templateId);
    if (selected) {
      arrTemplateId.push(templateId);
    }
    this.setState(() => ({ selectedTemplates: arrTemplateId }));
  };

  renderModalView() {
    const {
      documents,
      supportedFileTypes,
      disableSplittingManager,
      onSelectedFiles,
      onDocumentDelete,
      textTagSyntaxManager,
    } = this.props;
    const { activeNav, uploadedDocuments } = this.state;

    const validTemplates = this.validateTemplates();

    switch (activeNav) {
      case UPLOAD_TEMPLATE:
        return (
          <TemplateView
            templates={validTemplates}
            onSelectTemplates={this.handleSelectTemplates}
            selectedTemplates={this.state.selectedTemplates}
            documents={documents}
          />
        );
      case UPLOAD_DOCUMENT:
      default:
        return (
          <UploadView
            documents={uploadedDocuments}
            supportedFileTypes={supportedFileTypes}
            onSelectFiles={onSelectedFiles}
            disableSplittingManager={disableSplittingManager}
            onDocumentDelete={onDocumentDelete}
            textTagSyntaxManager={textTagSyntaxManager}
          />
        );
    }
  }

  setNavigation(navigation) {
    this.setState(() => ({ activeNav: navigation }));
  }

  render() {
    const {
      supportedFileTypes,
      onCancel,
      addButtonContent,
      organization: { featureList },
      disableTemplateUpload,
      showAcceptedDocuments,
    } = this.props;
    const { uploadedDocuments, selectedTemplates, activeNav, waitingForComplete } = this.state;
    const anyUploading = uploadedDocuments.some(
      ({ status }) => status === ProcessingStates.PENDING,
    );
    const uploadDocmentCount = uploadedDocuments.filter(
      ({ status }) => status === ProcessingStates.DONE,
    ).length;
    const hasDocuments = Boolean(uploadDocmentCount + selectedTemplates.length);
    const uploadTemplateCount = selectedTemplates.length;
    const successUploadCount = uploadDocmentCount + uploadTemplateCount;
    const allowAddByTemplate =
      featureList.includes(Feature.DOCUMENT_TEMPLATES) && !disableTemplateUpload;

    const title = (
      <FormattedMessage id="c41eedc8-0af7-4608-9f45-2290f1735ef4" defaultMessage="Add documents" />
    );
    const preferredFileMessage = getPreferredFileMessage(supportedFileTypes, showAcceptedDocuments);

    return (
      <Modal title={title} closeRoute={onCancel}>
        <div className="AddDocumentToUploaderModal--Content">
          <nav className="AddDocumentToUploaderModal--Nav">
            <button
              type="button"
              className={activeNav === UPLOAD_DOCUMENT ? "activeNav" : null}
              data-automation-id="document-uploader-nav-document"
              onClick={() => this.setNavigation(UPLOAD_DOCUMENT)}
            >
              <div className="AddDocumentToUploaderModal--Nav--buttonText">
                <FormattedMessage
                  id="115d6952-980d-4fbe-b317-7fd65b340d50"
                  defaultMessage="Document upload"
                />
              </div>
              {uploadDocmentCount > 0 && (
                <Badge kind="infoSubtle" className="AddDocumentToUploaderModal--CountBadge">
                  {uploadDocmentCount}
                </Badge>
              )}
            </button>
            {allowAddByTemplate && (
              <button
                type="button"
                className={activeNav === UPLOAD_TEMPLATE ? "activeNav" : null}
                data-automation-id="document-uploader-nav-template"
                onClick={() => this.setNavigation(UPLOAD_TEMPLATE)}
              >
                <div className="AddDocumentToUploaderModal--Nav--buttonText">
                  <FormattedMessage
                    id="59e23199-ade5-4424-bd77-743a62f3a350"
                    defaultMessage="Template upload"
                  />
                </div>
                {uploadTemplateCount > 0 && (
                  <Badge kind="infoSubtle" className="AddDocumentToUploaderModal--CountBadge">
                    {uploadTemplateCount}
                  </Badge>
                )}
              </button>
            )}
          </nav>
          <div className="AddDocumentToUploaderModal--UploadContainer">
            {activeNav === UPLOAD_DOCUMENT && (
              <Paragraph
                size="small"
                textColor="subtle"
                className="AddDocumentToUploaderModal--UploadContainer--Message"
              >
                {preferredFileMessage}
              </Paragraph>
            )}
            <div className="AddDocumentToUploaderModal--UploadContainer--Uploader">
              {this.renderModalView()}
            </div>
          </div>
        </div>
        <footer className="AddDocumentToUploaderModal--Footer">
          <Button
            buttonColor="dark"
            variant="tertiary"
            onClick={onCancel}
            automationId="document-uploader-add-modal-cancel"
          >
            <FormattedMessage
              id="1a33773f-87f0-407f-95ce-4bf6d4a3877c"
              description="cancelButton"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="primary"
            buttonColor="action"
            onClick={this.handleComplete}
            disabled={waitingForComplete || !hasDocuments || anyUploading}
            automationId="document-uploader-add-modal-finish"
          >
            {addButtonContent(successUploadCount)}
          </Button>
        </footer>
      </Modal>
    );
  }
}

AddDocumentModal.propTypes = {
  supportedFileTypes: PropTypes.array.isRequired,
  addButtonContent: PropTypes.func.isRequired,
  /** Called when user clicks done in the upload view. Will be passed array of supplied documents. */
  onSelectedFiles: PropTypes.func.isRequired,
  onDocumentDelete: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  /** Called when user cancels the add modal */
  onCancel: PropTypes.func.isRequired,
  disableSplittingManager: PropTypes.object,
  uploadedDocuments$: PropTypes.object.isRequired,
  documents: PropTypes.arrayOf(DOCUMENT_PROPTYPE),
  organization: PropTypes.object,
  disableTemplateUpload: PropTypes.bool,
  defaultDocRequirements: PropTypes.shape({
    notarizationRequired: PropTypes.bool,
    proofingRequired: PropTypes.bool,
    esign: PropTypes.bool,
    signingRequiresMeeting: PropTypes.bool,
  }),
  showAcceptedDocuments: PropTypes.bool,
  textTagSyntaxManager: PropTypes.object,
};

AddDocumentModal.defaultProps = {
  disableTemplateUpload: false,
  showAcceptedDocuments: true,
};

export default AddDocumentModal;

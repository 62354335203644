import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import compose from "util/compose";
import ContentDivider from "common/core/content_divider";
import Button from "common/core/button";
import GoogleSignInButton from "common/account/google_signin_button";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import TosV2 from "common/tos";
import { validateEmailFormat, validatePassword } from "validators/account";
import { validateIf, validatePresence, validatePhoneNumberLength } from "validators/form";
import { composeValidators, getFormValues, getFormErrors } from "util/form";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { Paragraph } from "common/core/typography";
import { useMatchScreenClass } from "common/core/responsive";
import { useProMobileOnboarding } from "util/feature_detection";

import { TrialReason } from "./fields/types";
import DomainMatchSlide from "../domain_match_slide";

const messages = defineMessages({
  creating: {
    id: "5a4ec2c5-a7aa-4ba4-8560-64c480f2c6a8",
    defaultMessage: "Creating...",
  },
  signUpWithEmail: {
    id: "e5292d64-6aba-453c-82db-2e0396a59360",
    defaultMessage: "Sign up with email",
  },
  signUp: {
    id: "a564a64f-55d5-465e-bd39-899e06689af0",
    defaultMessage: "Sign up",
  },
});

function validate(values, props) {
  return composeValidators(
    validateEmailFormat({ field: "email" }),
    validatePassword({ field: "password", label: "Password" }),
    validatePresence({ field: "firstName", label: "First name" }),
    validatePresence({ field: "lastName", label: "Last name" }),
    validateIf({
      field: "businessName",
      condition: () => props.businessRequired,
      validation: validatePresence({ field: "businessName", label: "Business name" }),
    }),
    validateIf({
      field: "phoneNumber",
      condition: () => values.phoneNumber,
      validation: validatePhoneNumberLength({
        field: "phoneNumber",
        isInternational: deprecatedIsInternational({ countryCode: values.phoneCountryCode }),
      }),
    }),
    validateIf({
      field: "docVolume",
      condition: () => values.trialReason === TrialReason.Business,
      validation: validatePresence({ field: "docVolume", label: "Document volume" }),
    }),
  )(values);
}

function handleSubmitFail(errors) {
  if (Object.keys(errors).includes("password")) {
    segmentTrack(EVENT.INVALID_PASSWORD_SUBMITTED);
  }
}

function getSubmitButtonText({ submitText, outstanding, intl, canLoginWithGoogle }) {
  if (submitText) {
    return submitText;
  }

  return outstanding
    ? intl.formatMessage(messages.creating)
    : canLoginWithGoogle
      ? intl.formatMessage(messages.signUpWithEmail)
      : intl.formatMessage(messages.signUp);
}
/** @type { any } */
function SignupForm(props) {
  const {
    invalid,
    submitting,
    submitSucceeded,
    onGoogle,
    onCommit,
    handleSubmit,
    intl,
    formValues,
    formErrors,
    submitText,
    message,
    tos,
    canLoginWithGoogle,
    Fields,
    deriveDisabledFromFormValues,
    domainMatchId,
    isCustomer,
  } = props;
  const outstanding = submitting || submitSucceeded;
  const disabled = deriveDisabledFromFormValues && deriveDisabledFromFormValues(formValues);
  const mobileOnboardingEnabled = useProMobileOnboarding();
  const isExtraSmall = useMatchScreenClass("xs") && mobileOnboardingEnabled;

  const domain = formValues?.email?.split("@")[1];

  if (domainMatchId) {
    return (
      <DomainMatchSlide
        createAccount={handleSubmit(onCommit)}
        domain={domain}
        domainMatchId={domainMatchId}
      />
    );
  }

  return (
    <>
      <form
        className="AccountSignupFormV2"
        data-automation-id="signup-form"
        autoComplete="off"
        onSubmit={handleSubmit(onCommit)}
      >
        {message && <Paragraph textColor="subtle">{message}</Paragraph>}

        <div className="AccountSignupFormV2--inputs">
          <Fields
            formValues={formValues}
            formErrors={formErrors}
            disabled={disabled}
            customerSignup={isCustomer}
          />
        </div>

        {tos ? (
          <Paragraph
            textAlign="left"
            size={isExtraSmall ? "defaultSize" : "small"}
            className="AccountSignupFormV2--terms-of-service"
          >
            {tos}
          </Paragraph>
        ) : (
          <TosV2
            textColor="subtle"
            size={isExtraSmall ? "defaultSize" : "small"}
            className="AccountSignupFormV2--terms-of-service"
            actionText={
              <FormattedMessage
                id="72fea067-e57b-4b03-a4fb-445f49876a7e"
                defaultMessage="By clicking ''{submitText}''"
                values={{ submitText }}
              />
            }
            underlined
          />
        )}

        <div className="AccountSignupFormV2--email-button">
          <Button
            type="submit"
            disabled={outstanding || invalid || disabled}
            automationId="create-account-button"
            buttonColor="action"
            variant="primary"
            buttonSize="large"
            fullwidth
          >
            {getSubmitButtonText({ submitText, outstanding, intl, canLoginWithGoogle })}
          </Button>
        </div>

        {canLoginWithGoogle && (
          <div>
            {!isExtraSmall && <ContentDivider />}
            <div className={"AccountSignupFormV2--google"}>
              <GoogleSignInButton signIn={onGoogle} forSignUp />
            </div>
          </div>
        )}
      </form>
    </>
  );
}

SignupForm.propTypes = {
  onGoogle: PropTypes.func,
  onCommit: PropTypes.func.isRequired,
  message: PropTypes.node,
  tos: PropTypes.node,
  submitText: PropTypes.node,
  canLoginWithGoogle: PropTypes.bool,
  deriveDisabledFromFormValues: PropTypes.func,
  domainMatchId: PropTypes.string,
  isCustomer: PropTypes.bool,

  // reduxForm
  handleSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
};

SignupForm.defaultProps = {
  onGoogle: null,
  message: null,
  title: null,
  tos: null,
  submitText: null,
  canLoginWithGoogle: true,
  deriveDisabledFromFormValues: null,
  businessRequired: true,
  isCustomer: false,
};

/** @type { any } */
const WrappedSignupForm = compose(
  reduxForm({
    form: "signup",
    validate,
    onSubmitFail: handleSubmitFail,
  }),

  getFormValues("signup"),
  getFormErrors("signup"),

  injectIntl,
)(SignupForm);
export { WrappedSignupForm };

import { DeprecatedSelectField } from "common/form/fields/select";
import Zones from "common/core/form/timezone/timezones";

export const DEPRECATED_ITEMS = Object.freeze(
  Zones.map((name) => ({
    label: name.replace("_", " "),
    value: name,
  })),
);

/** @deprecated - please use components in common/core/form */
export function DeprecatedTimezoneField(props) {
  const { ...otherProps } = props;
  return <DeprecatedSelectField items={DEPRECATED_ITEMS} {...otherProps} />;
}

DeprecatedTimezoneField.defaultProps = {
  searchable: true,
  clearable: true,
  placeholder: "Timezone",
};

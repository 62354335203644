import { DeprecatedSetPasswordInput } from "common/form/inputs/set_password";
import { DeprecatedFieldWrapper } from "common/form/enhancers/redux_form_field_wrapper";

function SetPasswordField({ meta, ...otherProps }) {
  return (
    <DeprecatedSetPasswordInput showRequirements={meta.visited && meta.invalid} {...otherProps} />
  );
}

SetPasswordField.defaultProps = {
  id: "password",
  placeholder: "Password",
  type: "password",
  "data-automation-id": "password-field",
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedSetPasswordField = DeprecatedFieldWrapper(SetPasswordField, {
  withMeta: true,
});

import { useEffect } from "react";

import type { Pendo } from "vendors/pendo";
import Env from "config/environment";
import { captureException } from "util/exception";
import request from "util/request";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import type { PENDO_EVENTS } from "./events";

const { pendoIdPrefix } = Env;

declare const window: Window & { pendo?: Pendo };

type PendoUser = {
  id: string;
  email?: string | null;
  organization?: { id: string } | null;
};

export function isPendoReady() {
  return window.pendo?.isReady();
}

function isAnonymousVisitor() {
  return window.pendo?.isAnonymousVisitor(window.pendo.getVisitorId());
}

export function pendoInitialize(user?: PendoUser | null) {
  if (!window.pendo) {
    return;
  }

  // Pendo is already initialized and they've already been identified - noop
  if (isPendoReady() && !isAnonymousVisitor()) {
    return;
  }

  if (user) {
    // we need to assign email to visitor id for internal employees, since that is the only way
    // we can exclude them (pendo only allows exclude by visitor id, but not other metadata fields)
    const userId =
      user.email?.includes("@proof.com") || user.email?.includes("@notarize.com")
        ? user.email
        : user.id;
    const orgId = user.organization?.id;

    const data = {
      visitor: { id: pendoIdPrefix ? `${pendoIdPrefix}-${userId}` : userId, email: user.email },
      ...(orgId && {
        account: { id: pendoIdPrefix ? `${pendoIdPrefix}-${orgId}` : orgId },
      }),
    };

    // If Pendo is already initialized but hasn't been given a user, identify the user with Pendo
    // This is specific to the pre-documents flow
    if (CURRENT_PORTAL === "customer" && isPendoReady()) {
      window.pendo.identify(data);
    } else {
      window.pendo.initialize(data);
    }
  } else {
    // Pendo-specific string for anonymous ID
    window.pendo.initialize({ visitor: { id: "VISITOR-UNIQUE-ID" } });
  }
}

export function pendoUpdateMetadata(email: string | null, gid: string | null) {
  if (window.pendo) {
    request("post", "pendo/set_metadata_fields", {
      gid,
      email,
      id_prefix: pendoIdPrefix,
    }).catch((error) => {
      // If this accidentally got called in an unathenticated context we don't want to explode.
      captureException(error);
    });
  }
}

export function pendoTrack(
  event: (typeof PENDO_EVENTS)[keyof typeof PENDO_EVENTS],
  properties: Record<string, unknown> = {},
) {
  window.pendo?.track(event, properties);
}

export type PendoTransformsArg =
  | {
      attr: "search";
      action: "AllowOnlyKeys" | "ExcludeKeys" | "AddTo" | "Replace" | "Clear";
      data: string[] | Record<string, unknown> | (() => unknown);
    }
  | {
      attr: "search" | "pathname" | "hostname" | "href";
      action: "Replace";
      data: string;
    };

// https://agent.pendo.io/advanced/location/#pendolocationaddtransforms
function pendoAddTransforms(transforms: PendoTransformsArg[]) {
  window.pendo?.location.addTransforms(transforms);
}

export function pendoAddParamsToUrl(params: Record<string, string>) {
  if (!window.pendo) {
    return;
  }
  const currentUrl = window.pendo.location.getHref();
  // Edge case found in stress testing. If location isn't reading, don't want to
  // add to it.
  if (!currentUrl) {
    return;
  }

  const pendoParams: Record<string, string> = {};
  Object.keys(params).forEach((param) => {
    if (currentUrl.includes(param)) {
      return;
    }

    pendoParams[param] = params[param];
  });

  if (Object.keys(pendoParams).length) {
    pendoAddTransforms([
      {
        attr: "search",
        action: "AddTo",
        data: () => pendoParams,
      },
    ]);
  }
}

function pendoClearTransforms() {
  if (window.pendo?.location.getHref() !== window.location.href) {
    window.pendo?.location.clearTransforms();
  }
}

export function ClearPendoTransforms() {
  useEffect(() => {
    pendoClearTransforms();
  }, []);

  return null;
}

import type { ComponentPropsWithoutRef } from "react";

import { DeprecatedFieldWrapper } from "common/form/enhancers/redux_form_field_wrapper";
import { BinaryToggle } from "common/core/form/binary_toggle";

function BinaryToggleField(props: ComponentPropsWithoutRef<typeof BinaryToggle>) {
  return <BinaryToggle {...props} value={Boolean(props.value)} />;
}

/** @deprecated - please use components in common/core/form */
export const DeprecatedBinaryToggleField = DeprecatedFieldWrapper(BinaryToggleField);

import "./enote_seed_field.scss";

import classnames from "classnames";
import type { FocusEvent } from "react";
import { subDays } from "date-fns";

import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedSelectField } from "common/form/fields/select";
import { DeprecatedDatePickerField } from "common/form/fields/datepicker";
import { DeprecatedFormRow } from "common/form/elements/row";
import { isAriaInvalid } from "common/core/form/error";

type SeedField = {
  label: string;
  valid: boolean;
  options: string[];
  format: string;
  inputType: string;
  validationErrors: string[];
  fieldName: string;
  fieldValue: string | null;
};

type Props = {
  onFieldChange: (field: SeedField, value?: string) => void;
  field: SeedField;
};

function EnoteSeedField({ onFieldChange, field }: Props) {
  const yesterday = subDays(new Date(), 1);
  const { fieldName, label, format, inputType, options, valid, validationErrors } = field;

  const validateField = (_event: FocusEvent<HTMLInputElement>, value?: string) =>
    onFieldChange(field, value);

  let fieldComponent = null;

  const formRowCx = classnames({ "validation-failed": !valid });

  switch (inputType) {
    case "select": {
      const items = options.map((option) => {
        return {
          value: option,
          label: option,
        };
      });
      fieldComponent = (
        <DeprecatedSelectField
          id={fieldName}
          data-automation-id={`${fieldName}-field`}
          name={fieldName}
          items={items}
          label={label}
          useCoreFormInput
          onChange={validateField}
          aria-invalid={isAriaInvalid(!valid)}
        />
      );
      break;
    }
    case "date": {
      fieldComponent = (
        <DeprecatedDatePickerField
          id={fieldName}
          automationId={`${fieldName}-field`}
          name={fieldName}
          placeholder={"MM/DD/YYYY"}
          label={label}
          minDate={yesterday}
          useStyledInput
          onChange={validateField}
          aria-invalid={isAriaInvalid(!valid)}
        />
      );
      break;
    }
    default: {
      fieldComponent = (
        <DeprecatedTextField
          id={fieldName}
          data-automation-id={`${fieldName}-field`}
          name={fieldName}
          onChange={() => {}}
          placeholder={format}
          label={label}
          onBlur={validateField}
          useCoreFormInput
          useStyledInput
          aria-invalid={isAriaInvalid(!valid)}
        />
      );
    }
  }

  return (
    <div className="EnoteSeedField">
      <DeprecatedFormRow className={formRowCx}>
        {fieldComponent}
        {validationErrors.length > 0 && (
          <div className="EnoteSeedField--errors">
            {validationErrors.map((error, i) => (
              <div key={i} className="validation-message">
                {error}
              </div>
            ))}
          </div>
        )}
      </DeprecatedFormRow>
    </div>
  );
}

export default EnoteSeedField;

const UA = window.navigator.userAgent.toLowerCase();

// Edge is the only browser that uses "Edge" in the useragent so this should be safe as a stopgap for now.
const IS_MS_EDGE = UA.includes("edge/");
const mQ = matchMedia("(pointer: coarse)");
const IS_IPAD =
  UA.includes("ipad") ||
  // ipad has desktop user agent by default so need diff way to check
  (window.navigator.platform === "MacIntel" &&
    window.navigator.maxTouchPoints > 2 &&
    // Fallbacks for mobile device detection
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
    ((mQ.media === "(pointer: coarse)" && mQ.matches) || "orientation" in window));
const IS_IOS = IS_IPAD || UA.includes("ipod") || UA.includes("iphone");
const IS_ANDROID = UA.includes("android");
const IS_SAFARI = UA.includes("safari") && !UA.includes("crios") && !UA.includes("chrome");

export function isMicrosoftEdge(): boolean {
  return IS_MS_EDGE;
}

export function isiOSDevice(): boolean {
  return IS_IOS;
}

export function isAndroidDevice(): boolean {
  return IS_ANDROID;
}

export function isMobileDevice(): boolean {
  return isiOSDevice() || isAndroidDevice();
}

export function isSafari(): boolean {
  return IS_SAFARI;
}

export function convertToNotarizeScheme(url: string): string {
  return url.replace(/^http[s]?/, "notarize");
}

import { Component } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { fromUnixTime, getUnixTime } from "date-fns";

import { encodeSearchParams } from "util/location";
import FilterMenu from "common/core/filter_menu";
import { SearchField } from "common/core/search_field";
import { DeprecatedDatePicker } from "common/form/inputs/datepicker";

class AnalyticsFilter extends Component {
  constructor(props) {
    super(props);
    const { queryArgs } = props;
    this.state = {
      notaryEmailFilter: queryArgs.get("notaryEmail"),
      notaryFilter: queryArgs.get("notary"),
      businessFilter: queryArgs.get("business"),
      nameFilter: queryArgs.get("name"),
      emailFilter: queryArgs.get("email"),
      afterFilter: queryArgs.get("after") ? fromUnixTime(queryArgs.get("after")) : null,
      beforeFilter: queryArgs.get("before") ? fromUnixTime(queryArgs.get("before")) : null,
    };
  }

  onUpdateFilter(property, filter) {
    this.setState({ [property]: filter.value ?? null });
  }

  onApplyFilters = () => {
    const { navigate, queryArgs } = this.props;
    const {
      notaryFilter,
      notaryEmailFilter,
      businessFilter,
      nameFilter,
      emailFilter,
      afterFilter,
      beforeFilter,
    } = this.state;
    // do not filter query params out when they are null as this causes
    // strange behaviour in `prepareVariables` where the old parameter
    // value is held onto instead of being updated to `null` as expected
    const after = afterFilter && getUnixTime(afterFilter);
    const before = beforeFilter && getUnixTime(beforeFilter);
    const search = encodeSearchParams(queryArgs, {
      notary: notaryFilter || null,
      notaryEmail: notaryEmailFilter || null,
      business: businessFilter || null,
      name: nameFilter || null,
      email: emailFilter || null,
      after: after ?? null,
      before: before ?? null,
    });
    navigate(`/analytics/page/1?${search}`, { replace: true });
  };

  changesExist() {
    const { queryArgs } = this.props;
    const {
      notaryFilter,
      notaryEmailFilter,
      businessFilter,
      nameFilter,
      emailFilter,
      afterFilter,
      beforeFilter,
    } = this.state;
    return (
      queryArgs.get("notary") !== notaryFilter ||
      queryArgs.get("notaryEmail") !== notaryEmailFilter ||
      queryArgs.get("business") !== businessFilter ||
      queryArgs.get("name") !== nameFilter ||
      queryArgs.get("email") !== emailFilter ||
      queryArgs.get("after") !== afterFilter ||
      queryArgs.get("before") !== beforeFilter
    );
  }

  render() {
    const {
      notaryFilter,
      notaryEmailFilter,
      businessFilter,
      nameFilter,
      emailFilter,
      afterFilter,
      beforeFilter,
    } = this.state;

    const listFilters = [
      <SearchField
        key="notary-filter"
        onChange={(filter) => this.onUpdateFilter("notaryFilter", filter)}
        onSearch={this.onApplyFilters}
        value={notaryFilter || ""}
        placeholder="Notary Name"
        aria-label="Filter by notary name"
        noIcon
      />,
      <SearchField
        key="notary-email-filter"
        onChange={(filter) => this.onUpdateFilter("notaryEmailFilter", filter)}
        onSearch={this.onApplyFilters}
        value={notaryEmailFilter || ""}
        placeholder="Notary Email"
        aria-label="Filter by notary email"
        noIcon
      />,
      <SearchField
        key="name-filter"
        onChange={(filter) => this.onUpdateFilter("nameFilter", filter)}
        onSearch={this.onApplyFilters}
        value={nameFilter || ""}
        placeholder="Signer Name"
        aria-label="Filter by signer name"
        noIcon
      />,
      <SearchField
        key="email-filter"
        onChange={(filter) => this.onUpdateFilter("emailFilter", filter)}
        onSearch={this.onApplyFilters}
        value={emailFilter || ""}
        placeholder="Signer Email"
        aria-label="Filter by signer email"
        noIcon
      />,
      <SearchField
        key="business-filter"
        onChange={(filter) => this.onUpdateFilter("businessFilter", filter)}
        onSearch={this.onApplyFilters}
        value={businessFilter || ""}
        placeholder="Business Name"
        aria-label="Filter by business name"
        noIcon
      />,
      <DeprecatedDatePicker
        key="after-filter"
        onChange={(newValue) => this.setState({ afterFilter: newValue })}
        value={afterFilter}
        placeholder="Started after"
      />,
      <DeprecatedDatePicker
        key="before-filter"
        onChange={(newValue) => this.setState({ beforeFilter: newValue })}
        value={beforeFilter}
        placeholder="Started before"
      />,
    ];

    return (
      <FilterMenu
        className="analytics"
        filters={listFilters}
        onApply={this.onApplyFilters}
        buttonDisabled={!this.changesExist()}
      />
    );
  }
}

export default (props) => {
  return <AnalyticsFilter {...props} navigate={useNavigate()} queryArgs={useSearchParams()[0]} />;
};

import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reduxForm, type InjectedFormProps, SubmissionError, clearSubmitErrors } from "redux-form";

import SaveButton from "common/core/save_button";
import { DeprecatedTextField } from "common/form/fields/text";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { customMessage } from "errors/form";
import type { FormError } from "errors/util";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { composeValidators } from "util/form";
import { validateIf, validatePresence, validateURL } from "validators/form";
import type { FullAdminOrganizationDetails_organization_Organization_docmagicProfile as ExistingCredentials } from "admin_portal/company/details_query.graphql";

import Styles from "./credentials_form.module.scss";
import UpsertDocmagicProfileMutation from "./upsert_docmagic_profile_mutation.graphql";

type FormValues = {
  employeeId: string;
  username: string;
  password: string;
  apiBaseUri: string | null;
  submissionError: string;
};

type Props = {
  organizationId: string;
  existingCredentials: ExistingCredentials | null;
};

type InnerProps = InjectedFormProps<FormValues, Props, FormError> & Props;

function CredentialsForm({
  existingCredentials,
  organizationId,
  handleSubmit,
  initialize,
}: InnerProps) {
  const { employeeId, username, apiBaseUri } = existingCredentials || {};
  useEffect(() => {
    initialize({ employeeId, username, apiBaseUri });
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const dispatch = useDispatch();
  const handleFieldChange = useCallback(() => {
    setCanSave(true);
    dispatch(clearSubmitErrors("CredentialsForm"));
  }, [dispatch]);

  const upsertDocmagicProfileMutateFn = useMutation(UpsertDocmagicProfileMutation);

  const onSubmit = useCallback(
    (data: FormValues) => {
      const { employeeId, username, password, apiBaseUri } = data;
      setSubmitting(true);

      return upsertDocmagicProfileMutateFn({
        variables: {
          input: {
            organizationId,
            employeeId,
            username,
            password,
            apiBaseUri,
          },
        },
      })
        .then(() => setCanSave(false))
        .catch((error) => {
          const message = isGraphQLError(error)
            ? error.message
            : "Failed to create/update DocMagic profile";
          return Promise.reject(
            new SubmissionError<FormValues, FormError>({
              submissionError: customMessage({ message }),
            }),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [organizationId, existingCredentials],
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup disableFormRowStyle fields={["employeeId"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="employeeId"
          name="employeeId"
          placeholder="Employee ID"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docmagic-employee-id"
        />
        <FormGroupErrors fields={["employeeId"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["username"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="username"
          name="username"
          placeholder="Username"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docmagic-username"
        />
        <FormGroupErrors fields={["username"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["password"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docmagic-password"
        />
        <FormGroupErrors fields={["password"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["apiBaseUri"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="apiBaseUri"
          name="apiBaseUri"
          placeholder="Custom URL (optional)"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docmagic-api-base-uri"
        />
        <FormGroupErrors fields={["apiBaseUri"]} />
      </FormGroup>

      <FormGroupErrors fields={["submissionError"]} />
      {canSave && (
        <SaveButton
          disabled={submitting}
          isLoading={submitting}
          className="is-form"
          title="Save Credentials"
        />
      )}
    </form>
  );
}

const validate = (values: FormValues) =>
  composeValidators(
    validatePresence({ field: "employeeId", label: "Employee ID" }),
    validatePresence({ field: "username", label: "Username" }),
    validatePresence({ field: "password", label: "Password" }),
    validateIf({
      field: "apiBaseUri",
      condition: (val: string) => !!val && val.length > 0,
      validation: validateURL({ field: "apiBaseUri", label: "Custom URL" }),
    }),
  )(values);

const formEnhancer = reduxForm<FormValues, Props, FormError>({
  form: "CredentialsForm",
  validate,
});

export default memo(formEnhancer(CredentialsForm));

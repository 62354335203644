import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { BUSINESS_PREMIUM_URL, BUSINESS_PRICING_URL } from "constants/marketing";
import { b } from "util/html";
import Link from "common/core/link";

import { UpsellBanner, UpsellEmptyState } from "./upsell";

export function BusinessPremiumUpsellBanner() {
  return (
    <UpsellBanner
      message={
        <FormattedMessage
          id="d6215601-cbf9-49a0-aa33-968c1d83f001"
          defaultMessage={
            "<b>Business Premium</b> - Gain more advanced identity data and insights with premium. <learnMoreLink>Learn more</learnMoreLink>"
          }
          values={{
            b,
            learnMoreLink: (msg: ReactNode) => (
              <Link white href={BUSINESS_PRICING_URL}>
                {msg}
              </Link>
            ),
          }}
        />
      }
    />
  );
}

export function BusinessPremiumUpsellEmptyState() {
  return (
    <UpsellEmptyState
      heading={
        <FormattedMessage
          id="d014bc09-3b27-4956-99a3-052cf56a0992"
          defaultMessage="Know more about your customers"
        />
      }
      message={
        <FormattedMessage
          id="bd4b7e79-def5-4b5e-805d-85321f190e6b"
          defaultMessage="Connect with our sales team about <b>Business Premium</b> to gain access to credentials, light-weight identity insights, and more."
          values={{ b }}
        />
      }
      contactSalesLink={BUSINESS_PREMIUM_URL}
      learnMoreLink={BUSINESS_PRICING_URL}
    />
  );
}

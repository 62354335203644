import { defineMessages, useIntl } from "react-intl";

import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedSetPasswordField } from "common/form/fields/set_password";

type Props = {
  disabled?: boolean;
};

const messages = defineMessages({
  password: {
    id: "d975b47b-87ce-4ffb-92b6-2cf02490e7cd",
    defaultMessage: "Create password",
  },
});

function SignupPasswordField(props: Props) {
  const { disabled } = props;
  const intl = useIntl();

  return (
    <FormGroup fields={["password"]}>
      <DeprecatedSetPasswordField
        id="password"
        name="password"
        label={intl.formatMessage(messages.password)}
        data-automation-id="password-field"
        tooltipIconOutside
        tooltipPlacementRight
        disabled={disabled}
        useNativeLabel
        useStyledInput
      />
      <FormGroupErrors fields={["password"]} />
    </FormGroup>
  );
}

export default SignupPasswordField;

import {
  blur,
  change,
  focus,
  initialize,
  touch,
  untouch,
  isSubmitting,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";

import compose from "util/compose";

/** @typedef {import("react").ComponentType} ComponentType */
/** @typedef {import("react").ReactElement} ReactElement */

/**
 * Provides access to redux form data/props without passing them down through several layers of children
 * @type {<P>(options?: { getValuesFor: string[] }) => (comp: ComponentType<P>) => (props: Omit<P, "change" | "formValues"> & { formName: string; fieldNamePrefix?: string }) => ReactElement}
 * @example
  function MySubForm({ someProp, dispatch, change, someValue }) {
    ...
  }
  const Wrapped = subForm({ getValuesFor: ["someValue"], })(MySubForm);

  function MyForm({ form }) {
    return (
      ...
      <Wrapped formName={form} someProp={someProp} />
      ...
    );
  }

  export default reduxForm({ form: "myForm" })(MyForm);
 */
export function deprecatedSubForm({ getValuesFor } = {}) {
  return (Component) => {
    function SubForm(props) {
      const {
        formName,
        formValues,
        blur,
        change,
        focus,
        initialize,
        touch,
        untouch,
        submitting,
        fieldNamePrefix,
        dispatch,
        ...otherProps
      } = props;

      return (
        <Component
          formName={formName}
          formValues={formValues}
          submitting={submitting}
          blur={blur}
          change={change}
          focus={focus}
          touch={touch}
          untouch={untouch}
          initialize={(data, options) =>
            initialize(data, {
              ...options,
              keepValues: true, // prevents values initialized elsewhere from being cleared
            })
          }
          fieldNamePrefix={fieldNamePrefix}
          dispatch={dispatch}
          {...otherProps}
        />
      );
    }

    const SubFormContainer = compose(
      // get form data from redux store via redux form selectors
      connect((state, ownProps) => {
        const fieldValueSelector =
          getValuesFor && getValuesFor.length > 0 ? formValueSelector(ownProps.formName) : null;
        const prefixedFieldNames =
          fieldValueSelector && ownProps.fieldNamePrefix
            ? getValuesFor.map((field) => `${ownProps.fieldNamePrefix}.${field}`)
            : getValuesFor;
        return {
          form: ownProps.formName, // take formName passed into subForm HOC and pass into reduxForm HOC as form
          formValues:
            fieldValueSelector &&
            fieldValueSelector(
              state,
              ...prefixedFieldNames,

              // when only a single field name is requested in formValueSelector,
              // that value is directly returned instead of returning an object of values.
              // by passing in "fakeFieldName", we ensure formValueSelector always returns values in object
              // https://redux-form.com/7.4.2/docs/api/formvalueselector.md/#-code-field-string-code-required-
              "fakeFieldName",
            ),
          submitting: isSubmitting(ownProps.formName)(state),
        };
      }),
      // set bound actions via redux form action creators
      (WrappedComponent) => {
        return function WithReduxProps(props) {
          const reduxFormActions = {
            blur: (...args) => props.dispatch(blur(props.formName, ...args)),
            change: (...args) => props.dispatch(change(props.formName, ...args)),
            focus: (...args) => props.dispatch(focus(props.formName, ...args)),
            initialize: (...args) => props.dispatch(initialize(props.formName, ...args)),
            touch: (...args) => props.dispatch(touch(props.formName, ...args)),
            untouch: (...args) => props.dispatch(untouch(props.formName, ...args)),
          };
          return <WrappedComponent {...reduxFormActions} {...props} />;
        };
      },
    )(SubForm);

    SubFormContainer.displayName = `subForm(${Component.displayName || Component.name})`;

    return SubFormContainer;
  };
}

import { useState, useEffect } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import { CardHeading } from "common/core/card";
import { DeprecatedTextField } from "common/form/fields/text";
import SaveButton from "common/core/save_button";
import { useMutation } from "util/graphql";

import Styles from "./mers_org_id.module.scss";
import UpdateMersOrgIdMutation from "./update_mers_org_id_mutation.graphql";

type FormValues = {
  mersOrgId: string;
};
type Props = {
  organization: { id: string; mersOrgId: string | null };
};
type InnerProps = Props & InjectedFormProps<FormValues, Props>;

function MersOrgId(props: InnerProps) {
  const [canPersistMersOrgId, setCanPersistMersOrgId] = useState(false);
  const [mersOrgIdChangeInProgress, setMersOrgIdChangeInProgress] = useState(false);
  const updateMersOrgIdMutateFn = useMutation(UpdateMersOrgIdMutation);
  useEffect(() => {
    props.initialize({ mersOrgId: props.organization.mersOrgId || "" });
  }, []);

  const persistMersOrgId = ({ mersOrgId }: FormValues) => {
    setMersOrgIdChangeInProgress(true);
    updateMersOrgIdMutateFn({
      variables: {
        input: {
          organizationId: props.organization.id,
          mersOrgId,
        },
      },
    }).finally(() => {
      setMersOrgIdChangeInProgress(false);
      setCanPersistMersOrgId(false);
    });
  };
  return (
    <>
      <CardHeading>
        <FormattedMessage id="b4748bf8-06a8-4f77-a617-49e0cfa9c49a" defaultMessage="MERS Org Id" />
      </CardHeading>
      <form
        className={Styles.form}
        autoComplete="off"
        onSubmit={props.handleSubmit(persistMersOrgId)}
      >
        <DeprecatedTextField
          id="mersOrgId"
          name="mersOrgId"
          className={Styles.input}
          onChange={() => setCanPersistMersOrgId(true)}
        />

        {canPersistMersOrgId && (
          <SaveButton
            disabled={mersOrgIdChangeInProgress}
            isLoading={mersOrgIdChangeInProgress}
            title={
              <FormattedMessage
                id="ac1099f9-b938-4a5c-a8cb-fd62c156a9f0"
                defaultMessage="Save MERS Org Id"
              />
            }
          />
        )}
      </form>
    </>
  );
}

export default reduxForm<FormValues, Props>({ form: "mersOrgIdForm" })(MersOrgId);

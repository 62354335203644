import "./input.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { DeprecatedTextInput } from "common/form/inputs/text";

/** @deprecated - please use components in common/core/form */
export class DeprecatedSelectInput extends Component {
  constructor(props) {
    super(props);
    this.el = null;
    this.state = {
      selectedValue: this.props.value || "",
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ selectedValue: value || "" });
    }
  }

  handleInputBlur = () => this.props.onBlur?.();

  handleFocus(ev) {
    if (this.props.onFocus) {
      this.props.onFocus(ev);
    }
  }

  updateValue(newValue) {
    const { onChange, multi, onOptionChange } = this.props;
    const selectedValue = multi
      ? newValue && newValue.map((item) => item.value)
      : newValue && newValue.value;

    this.setState({ selectedValue });
    onChange(selectedValue);

    if (onOptionChange) {
      onOptionChange(newValue);
    }
  }

  // Used by EditableInput to manually activate focus in this component
  focusInput = () => {
    this.el.focus();
  };

  render() {
    const {
      className: defaultClassName,
      items,
      hiddenInputName,
      readOnly,
      automationId,
      autoBlur,
      ...otherProps
    } = this.props;
    const { selectedValue } = this.state;
    const className = `${defaultClassName || ""} ${SENSITIVE_CLASS}`.trim();

    if (readOnly) {
      const item = (items || []).find((item) => item.value === selectedValue);
      return <DeprecatedTextInput className={className} readOnly value={item?.label} />;
    }

    return (
      <div
        className={className}
        data-automation-id={automationId || this.props["data-automation-id"]}
      >
        <ReactSelect
          {...otherProps}
          autoBlur={autoBlur}
          options={items}
          name={hiddenInputName} // name for hidden <input /> tag
          value={selectedValue}
          onChange={this.updateValue.bind(this)}
          onBlur={this.handleInputBlur}
          onFocus={(ev) => this.handleFocus(ev)}
          ref={
            this.props.onInputRefSet
              ? this.props.onInputRefSet
              : (el) => {
                  // Used by EditableInput and not when onInputRefSet is passed in
                  this.el = el;
                }
          }
        />
      </div>
    );
  }
}

DeprecatedSelectInput.propTypes = {
  autoBlur: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.node,
    }),
  ).isRequired,
  placeholder: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  hiddenInputName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  clearRenderer: PropTypes.func,
  openOnFocus: PropTypes.bool,
  multi: PropTypes.bool,
  onInputRefSet: PropTypes.object,
};

DeprecatedSelectInput.defaultProps = {
  autoBlur: true,
  openOnFocus: false,
  readOnly: false,
  searchable: false,
  clearable: false,
  clearRenderer: undefined,
  multi: false,
  matchPos: "any",
  matchProp: "any",
};

import "./add_child_organization_modal.scss";

import { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useDispatch } from "react-redux";

import { captureException } from "util/exception";
import { isGraphQLError } from "util/graphql/query";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useApolloClient, useMutation } from "util/graphql";
import type { AddressType } from "graphql_globals";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedCheckboxField } from "common/form/fields/checkbox";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import { SearchField } from "common/core/search_field";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import FormGroupErrors from "common/form/group_errors";
import AddressSubForm, {
  validationRules as addressValidationRules,
} from "common/form/sub_forms/address";
import { addError } from "redux/actions/errors";

import AdminCompanyDetailsOrgStructureRootGraph, {
  type CompanyOrgStructureRootVariables as AdminCompanyDetailsOrgStructureRootGraphVariables,
  type CompanyOrgStructureRoot as AdminCompanyDetailsOrgStructureRootGraphData,
} from "../index_root.query.graphql";
import AddChildOrganizationQuery from "./add_child_organization_query.graphql";
import CreateOrganizationMutation from "./create_organization_mutation.graphql";
import AddChildOrganizationMutation from "./add_child_organization_mutation.graphql";

const ORG_ID_LENGTH = 9;
const MESSAGES = defineMessages({
  accountName: {
    id: "163e5b71-f3ee-4351-91f1-893f19f53970",
    defaultMessage: "Account Name",
  },
  address: {
    id: "4c924f3b-418a-4ab2-949f-8415ed27ff33",
    defaultMessage: "Address",
  },
  accountOwnerEmail: {
    id: "10f07931-6a23-48e8-b685-ea7cc6cece4e",
    defaultMessage: "Account Email (not normally required)",
  },
  notifyAccountOwner: {
    id: "b4861a80-278c-4444-b4e6-9205f075244a",
    defaultMessage: "Notify Account Owner",
  },
  lookUpByOrg: {
    id: "5f98c54f-71c9-4c47-87ea-2cd06b5ff122",
    defaultMessage: "Look up by org ID",
  },
  searchLabel: {
    id: "7561ae03-9d64-44be-8101-f507d2cdadb7",
    defaultMessage: "Search for organization",
  },
  characterLengthError: {
    id: "94ca5a9e-fa48-432c-b708-112dbcf47c28",
    defaultMessage: "Org ID must be 9 characters (example: or1234567)",
  },
  cannotFindOrganizationError: {
    id: "1529202d-7f91-4fdf-a222-4739c93643df",
    defaultMessage: "Could not find organization associated with ID",
  },
  orgAlreadyLinked: {
    id: "af01700b-cea3-4ed7-a301-c819fd34948f",
    defaultMessage: "Sorry, that account is already linked to a different org",
  },
  errorWithMessage: {
    id: "2a1a3c0d-8c9c-480d-84ea-3b7fa6d1012f",
    defaultMessage: "An error occured: {error}",
  },
  genericError: {
    id: "12cab33c-44d2-4bd3-a8d9-81b531c5c7ec",
    defaultMessage:
      "There was an issue adding the subsidiary account. Please reach out to Engineering.",
  },
});

type Props = {
  parentOrganization: Organization;
  rootOrganizationId: string;
  onClose: () => void;
};
type ReduxFormProps = InjectedFormProps<FormValues, TabProps>;
type FormValues = {
  accountName: string;
  address: AddressType;
  accountOwnerEmail: string;
  notifyAccountOwner: boolean;
  organizationId: string;
};
type Organization = {
  id: string;
  name: string | null;
};
type TabProps = {
  parentOrganization: Organization;
  rootOrganizationId: string;
  onClose: () => void;
};
type CreateChildInnerProps = ReduxFormProps & {
  parentOrganization: Organization;
  rootOrganizationId: string;
  onClose: () => void;
};

const CHILD_CREATION_TYPE = {
  NEW: Symbol("new"),
  LINK: Symbol("link"),
};

function validate(values: FormValues, props: Props) {
  return composeValidators(
    validatePresence({ field: "accountName", label: "Name" }),
    validatePresence({ field: "address", label: "Address" }),
    addressValidationRules(values, props),
  )(values);
}

function CreateChildTabInner({
  parentOrganization,
  rootOrganizationId,
  onClose,
  handleSubmit,
  form,
  initialize,
}: CreateChildInnerProps) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isLoading, updateIsLoading] = useState(false);
  const [error, updateError] = useState<null | string>(null);

  const createOrganizationMutation = useMutation(CreateOrganizationMutation);
  const onCreateAccount = ({
    accountName,
    address,
    accountOwnerEmail,
    notifyAccountOwner,
  }: FormValues) => {
    updateIsLoading(true);
    return createOrganizationMutation({
      variables: {
        input: {
          organizationId: parentOrganization.id,
          name: accountName,
          notifyOwner: notifyAccountOwner,
          email: accountOwnerEmail,
          address,
        },
      },
      update(cacheProxy, { data }) {
        const { rootOrganization, ...old } = cacheProxy.readQuery<
          AdminCompanyDetailsOrgStructureRootGraphData,
          AdminCompanyDetailsOrgStructureRootGraphVariables
        >({
          query: AdminCompanyDetailsOrgStructureRootGraph,
          variables: { organizationId: rootOrganizationId },
        })!;
        if (rootOrganization?.__typename !== "Organization") {
          throw new Error(`Expected Organization, got ${rootOrganization?.__typename}.`);
        }
        const newSubsidiaryOrganizations = [
          ...rootOrganization.subsidiaryOrganizations,
          data!.createOrganization!.organization,
        ];
        const newRootOrganization = {
          ...rootOrganization,
          subsidiaryOrganizations: newSubsidiaryOrganizations,
        };
        cacheProxy.writeQuery({
          query: AdminCompanyDetailsOrgStructureRootGraph,
          variables: { rootOrganizationId },
          data: { ...old, rootOrganization: newRootOrganization },
        });
      },
    })
      .then(() => {
        updateIsLoading(false);
        onClose();
      })
      .catch((error) => {
        updateIsLoading(false);
        if (isGraphQLError(error) && error.graphQLErrors[0].code === 403) {
          updateError(
            intl.formatMessage(MESSAGES.errorWithMessage, {
              error: error.graphQLErrors[0].message,
            }),
          );
        } else {
          captureException(error);
          dispatch(addError(intl.formatMessage(MESSAGES.genericError)));
        }
      });
  };

  useEffect(() => {
    initialize({ notifyAccountOwner: true });
  }, []);

  return (
    <div className="AddChildOrganizationModal">
      <form name="AddChildOrganizationForm" className="AddChildOrganizationModal--content">
        <DeprecatedTextField
          id="accountName"
          name="accountName"
          placeholder={intl.formatMessage(MESSAGES.accountName)}
          placeholderAsLabel
          useStyledInput
          displayRequiredAsterisk
          automationId="subsidiary-account-name"
        />
        <AddressSubForm
          formName={form}
          useStyledInputs
          fieldNamePrefix="address"
          displayRequiredAsterisk
        />
        <DeprecatedTextField
          id="accountOwnerEmail"
          name="accountOwnerEmail"
          placeholder={intl.formatMessage(MESSAGES.accountOwnerEmail)}
          placeholderAsLabel
          useStyledInput
          automationId="subsidiary-account-owner-email"
        />
        <DeprecatedCheckboxField
          name="notifyAccountOwner"
          label={intl.formatMessage(MESSAGES.notifyAccountOwner)}
        />
        <FormGroupErrors fields={["accountName", "address", "accountOwnerEmail"]} />
        {error && <div className="AddChildOrganizationModal--error">{error}</div>}
      </form>
      <div className="AddChildOrganizationModal--buttons">
        <Button
          key="AddChildOrganizationModal-cancel"
          variant="tertiary"
          buttonColor="action"
          onClick={onClose}
          automationId="cancel-button"
        >
          <FormattedMessage id="5d5afeed-8398-40d9-a76f-5f0f7f9af7d5" defaultMessage="Cancel" />
        </Button>
        <Button
          key="AddChildOrganizationModal-link"
          buttonColor="action"
          variant="primary"
          onClick={handleSubmit(onCreateAccount)}
          isLoading={isLoading}
          automationId="link-button"
        >
          <FormattedMessage
            id="bbd030a3-5914-4ed5-b31c-acc855dbf91e"
            defaultMessage="Create Account"
          />
        </Button>
      </div>
    </div>
  );
}
const formEnhancer = reduxForm<FormValues, Props>({ form: "AddChildOrganizationForm", validate });
const CreateChildTab = formEnhancer(CreateChildTabInner);

function LinkChildTab({ parentOrganization, rootOrganizationId, onClose }: TabProps) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const client = useApolloClient();
  const [error, updateError] = useState<null | string>(null);
  const [queriedOrg, updateQueriedOrg] = useState<null | Organization>(null);
  const [isLoading, updateIsLoading] = useState(false);
  const [organizationId, setOrganizationId] = useState("");

  const addChildOrganizationMutation = useMutation(AddChildOrganizationMutation);
  const addChildOrganization = useCallback(() => {
    updateIsLoading(true);
    return addChildOrganizationMutation({
      variables: {
        input: {
          parentOrganizationId: parentOrganization.id,
          childOrganizationId: queriedOrg!.id,
        },
      },
      update(cacheProxy, { data }) {
        const { rootOrganization, ...old } = cacheProxy.readQuery<
          AdminCompanyDetailsOrgStructureRootGraphData,
          AdminCompanyDetailsOrgStructureRootGraphVariables
        >({
          query: AdminCompanyDetailsOrgStructureRootGraph,
          variables: { organizationId: rootOrganizationId },
        })!;
        if (rootOrganization?.__typename !== "Organization") {
          throw new Error(`Expected Organization, got ${rootOrganization?.__typename}.`);
        }
        const updatedOrganization = data!.addChildOrganization!.organization;
        const updatedOrganizationSubOrgIds = updatedOrganization.subsidiaryOrganizations.map(
          (subOrg) => subOrg.id,
        );
        const newSubsidiaryOrganizations = [
          ...rootOrganization.subsidiaryOrganizations.filter(
            (org) => !updatedOrganizationSubOrgIds.includes(org.id),
          ),
          ...updatedOrganization.subsidiaryOrganizations,
        ];
        const newRootOrganization = {
          ...rootOrganization,
          subsidiaryOrganizations: newSubsidiaryOrganizations,
        };
        cacheProxy.writeQuery({
          query: AdminCompanyDetailsOrgStructureRootGraph,
          variables: { rootOrganizationId },
          data: { ...old, rootOrganization: newRootOrganization },
        });
      },
    })
      .then(() => {
        updateIsLoading(false);
        onClose();
      })
      .catch((error) => {
        updateIsLoading(false);
        if (isGraphQLError(error) && error.graphQLErrors[0].code === 403) {
          updateError(
            intl.formatMessage(MESSAGES.errorWithMessage, {
              error: error.graphQLErrors[0].message,
            }),
          );
        } else {
          captureException(error);
          dispatch(addError(intl.formatMessage(MESSAGES.genericError)));
        }
      });
  }, [parentOrganization, onClose, queriedOrg]);

  const fetchOrganization = useCallback(async () => {
    const { data } = await client.query({
      query: AddChildOrganizationQuery,
      variables: { organizationId },
    });
    return data?.organization;
  }, [organizationId]);

  const onSearch = useCallback(() => {
    updateQueriedOrg(null);
    if (organizationId.length !== ORG_ID_LENGTH) {
      updateError(intl.formatMessage(MESSAGES.characterLengthError));
    } else {
      updateError(null);
      fetchOrganization().then((organization) => {
        if (organization?.parentOrganizationId) {
          updateError(intl.formatMessage(MESSAGES.orgAlreadyLinked));
        } else if (organization) {
          updateQueriedOrg(organization);
        } else {
          updateError(intl.formatMessage(MESSAGES.cannotFindOrganizationError));
        }
      });
    }
  }, [organizationId]);
  return (
    <div className="AddChildOrganizationModal">
      <div className="AddChildOrganizationModal--content">
        <div className="AddChildOrganizationModal--content--search">
          <SearchField
            className="AddChildOrganizationModal--content--search--field"
            onChange={(input) => {
              setOrganizationId(input.value);
            }}
            value={organizationId}
            placeholder={intl.formatMessage(MESSAGES.lookUpByOrg)}
            aria-label={intl.formatMessage(MESSAGES.searchLabel)}
            noIcon
          />
          <Button buttonColor="action" variant="primary" onClick={onSearch}>
            <FormattedMessage id="b5569a2b-d269-478e-9526-394f26a93045" defaultMessage="Search" />
          </Button>
        </div>
        {error && <div className="AddChildOrganizationModal--error">{error}</div>}
        {queriedOrg && (
          <div className="AddChildOrganizationModal--success">
            <FormattedMessage
              id="4174aac8-fec8-408b-8b3c-55fb57e845c6"
              defaultMessage="Organization ({organizationName}) found!"
              values={{ organizationName: queriedOrg.name }}
            />
          </div>
        )}
      </div>
      <div className="AddChildOrganizationModal--buttons">
        <Button
          key="AddChildOrganizationModal-cancel"
          variant="tertiary"
          buttonColor="action"
          onClick={onClose}
          automationId="cancel-button"
        >
          <FormattedMessage id="5d5afeed-8398-40d9-a76f-5f0f7f9af7d5" defaultMessage="Cancel" />
        </Button>
        <Button
          key="AddChildOrganizationModal-link"
          buttonColor="action"
          variant="primary"
          onClick={addChildOrganization}
          isLoading={isLoading}
          automationId="link-button"
          disabled={!queriedOrg?.id}
        >
          <FormattedMessage
            id="bd55e822-7ea4-4fae-844e-bc473c5f7cf4"
            defaultMessage="Link Account"
          />
        </Button>
      </div>
    </div>
  );
}

function AddChildOrganizationModal({ parentOrganization, rootOrganizationId, onClose }: Props) {
  const [childCreationType, setChildCreationType] = useState(CHILD_CREATION_TYPE.NEW);

  const onSelect = useCallback(
    (tabIndex: number) => {
      setChildCreationType(tabIndex === 0 ? CHILD_CREATION_TYPE.NEW : CHILD_CREATION_TYPE.LINK);
    },
    [childCreationType],
  );

  return (
    <WorkflowModal
      large
      title={
        <FormattedMessage
          id="db94d64d-628b-4a5d-acb6-cc8386f1b34a"
          defaultMessage="Add Subsidiary Account"
        />
      }
      footerSeparator={false}
    >
      <TabRow onSelect={onSelect}>
        <TabButton
          title={
            <FormattedMessage
              id="d20d4199-ea8a-42b7-9656-84f4ac894787"
              defaultMessage="Create New"
            />
          }
          content={
            <CreateChildTab
              rootOrganizationId={rootOrganizationId}
              parentOrganization={parentOrganization}
              onClose={onClose}
            />
          }
        />
        <TabButton
          title={
            <FormattedMessage
              id="873f966e-0060-4937-8ab4-5f73485c599c"
              defaultMessage="Link Existing"
            />
          }
          content={
            <LinkChildTab
              rootOrganizationId={rootOrganizationId}
              parentOrganization={parentOrganization}
              onClose={onClose}
            />
          }
        />
      </TabRow>
    </WorkflowModal>
  );
}

export default AddChildOrganizationModal;

import { LENDER_TRANSACTION_TYPES, TITLE_MORTGAGE_TRANSACTION_TYPES } from "constants/transaction";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import {
  BUSINESS_CONFIG_IDS,
  REAL_ESTATE_ESIGN_CONFIG_IDS,
} from "common/transaction_creation/v3/config";

export const PENDO_EVENTS = {
  // Transaction sent
  ORGANIZATION_TRANSACTION_SEND_NOTARIZATION_V3: "V3 Organization Transaction Send Notarization",
  ORGANIZATION_TRANSACTION_SEND_ESIGN_V3: "V3 Organization Transaction Send eSign",
  ORGANIZATION_TRANSACTION_SEND_PROOF_V3: "V3 Organization Transaction Send Proof",
  ORGANIZATION_TRANSACTION_SEND_IDENTIFY_V3: "V3 Organization Transaction Send Identify",
  ORGANIZATION_TRANSACTION_EDITOR_SEND_V3: "V3 Organization Transaction Editor Send",
  ORGANIZATION_TRANSACTION_SEND_REAL_CLOSING_V3:
    "V3 Organization Transaction Send Real Estate Closing",
  ORGANIZATION_TRANSACTION_SEND_REAL_HYBRID_V3:
    "V3 Organization Transaction Send Real Estate Hybrid",
  ORGANIZATION_TRANSACTION_SEND_REAL_ESIGN_V3: "V3 Organization Transaction Send Real Estate Esign",
  ORGANIZATION_TRANSACTION_SEND_REAL_PROOF_V3: "V3 Organization Transaction Send Real Estate Proof",
  ORGANIZATION_TRANSACTION_SEND_REAL_IDENTIFY_V3:
    "V3 Organization Transaction Send Real Estate Identify",
  // Notary termination
  NOTARY_TERMINATED_SIGNER_MEETING: "Notary Terminated Signer Meeting - Signer",
  // Organization activation link
  ORGANIZATION_ACTIVATION_REFERRAL_ACKNOWLEDGE:
    "Organization Activation - User acknowledged EasyLink",
} as const;

export function getEventFromFormV3(form: string) {
  // This won't work long term, when workflows become a thing and config IDs can be more variable
  // But we also might not need these at this point. They're just to trigger the Pendo surveys at the
  // moment and Pendo admins are aware of the limitations.
  if (
    Object.values({
      ...TITLE_MORTGAGE_TRANSACTION_TYPES,
      ...LENDER_TRANSACTION_TYPES,
    }).includes(form)
  ) {
    return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_CLOSING_V3;
  }

  if (isHybridTransactionType(form)) {
    return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_HYBRID_V3;
  }

  switch (form) {
    case BUSINESS_CONFIG_IDS.ESIGN:
    case BUSINESS_CONFIG_IDS.SIGN:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_ESIGN_V3;
    case BUSINESS_CONFIG_IDS.PROOF:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_PROOF_V3;
    case BUSINESS_CONFIG_IDS.IDENTIFY:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_IDENTIFY_V3;
    case BUSINESS_CONFIG_IDS.NOTARIZATION:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_NOTARIZATION_V3;
    case REAL_ESTATE_ESIGN_CONFIG_IDS.ESIGN:
    case REAL_ESTATE_ESIGN_CONFIG_IDS.SIGN:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_ESIGN_V3;
    case REAL_ESTATE_ESIGN_CONFIG_IDS.PROOF:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_PROOF_V3;
    case REAL_ESTATE_ESIGN_CONFIG_IDS.IDENTIFY:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_SEND_REAL_IDENTIFY_V3;
    default:
      return PENDO_EVENTS.ORGANIZATION_TRANSACTION_EDITOR_SEND_V3;
  }
}

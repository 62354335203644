import { useState, type ReactElement } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import { useDispatch } from "redux/util";
import compose from "util/compose";
import { getFormValues } from "util/form";
import Button from "common/core/button";
import { DeprecatedTextField } from "common/form/fields/text";
import FormGroup from "common/form/group";
import Loader from "common/core/loading_indicator/small";
import { useMutation } from "util/graphql";
import { addError } from "redux/actions/errors";
import { ERROR_TYPES } from "constants/errors";
import { OrganizationTypeEnum } from "graphql_globals";
import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";

import SuccessModal from "./success_modal";
import MergeModal from "./merge_modal";
import MergeAccountIntoOrganizationMutation from "./merge_account_into_organization_mutation.graphql";

type FormValues = { mergingOrganizationId: string };
type Props = {
  organization: {
    id: string;
    organizationType: OrganizationTypeEnum;
  };
};

const MESSAGES = defineMessages({
  merge: {
    id: "a7afa9f3-ad8d-4ff3-9526-9aebdc421b61",
    defaultMessage: "Merge",
  },
  organizationId: {
    id: "8a9793d4-1561-4de8-86f9-92e009c303a8",
    defaultMessage: "Organization ID",
  },
});

const FORM_NAME = "MergeAccountForm";
const ERRORS: Record<string, undefined | string> = {
  not_found:
    "The organization does not exist in the system. Use the 'Request Add New Member' tool on Company Details.",
  organization_type_mismatch:
    "The organization type is not compatible with the organization, must be Business or Title",
  employee_limit_reached: "The organization being merged into is at its employee limit already",
  organization_is_parent:
    "The organization is a parent organization and can not leave orphan organizations",
  same_organization: "The organization cannot be merged with itself",
};

function MergeAccount(
  props: Props & InjectedFormProps<FormValues, Props> & { formValues: FormValues },
) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const mergeAccountIntoOrganizationMutateFn = useMutation(MergeAccountIntoOrganizationMutation);
  const [isMerging, setIsMerging] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleShowMergeModal = () => {
    setShowMergeModal(true);
    setShowSuccessModal(false);
  };
  const hideModal = () => {
    setShowMergeModal(false);
    setShowSuccessModal(false);
  };
  const merge = () => {
    setIsMerging(true);
    hideModal();
    mergeAccountIntoOrganizationMutateFn({
      variables: {
        input: {
          organizationId: props.organization.id,
          mergingOrganizationId: props.formValues.mergingOrganizationId,
        },
      },
    })
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch((error: Error) => {
        const errorMessage = ERRORS[error.message] || error.message;
        dispatch(addError(errorMessage, ERROR_TYPES.REGULAR));
      })
      .finally(() => {
        setIsMerging(false);
      });
  };

  const { organizationType } = props.organization;
  if (
    organizationType !== OrganizationTypeEnum.BUSINESS &&
    organizationType !== OrganizationTypeEnum.TITLE_AGENCY
  ) {
    return null;
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="968296f1-248c-46f4-b8ab-c8a3dcea0c91"
          defaultMessage="Merge Account"
        />
      </SettingsTitle>
      <Card
        className="MergeAccount"
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            onClick={handleShowMergeModal}
            type="submit"
            isLoading={isMerging}
            automationId="merge-account-button"
          >
            {isMerging ? <Loader color="white" /> : intl.formatMessage(MESSAGES.merge)}
          </Button>
        }
      >
        <CardHeading>
          <FormattedMessage
            id="5d8286c5-00be-4472-864c-18f8f3e8b43c"
            defaultMessage="Organization ID to merge into this main account:"
          />
        </CardHeading>
        <FormGroup fields={["mergingOrganizationId"]} disableFormRowStyle>
          <DeprecatedTextField
            useStyledInput
            name="mergingOrganizationId"
            placeholder={intl.formatMessage(MESSAGES.organizationId)}
          />
        </FormGroup>
        {showMergeModal && <MergeModal onClose={hideModal} onMerge={merge} />}
        {showSuccessModal && <SuccessModal onClose={hideModal} />}
      </Card>
    </>
  );
}

export default compose(
  reduxForm({ form: FORM_NAME }),
  getFormValues(FORM_NAME),
)(MergeAccount) as unknown as (props: Props) => ReactElement;

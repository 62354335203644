import "./index.scss";

import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useOutlet } from "react-router-dom";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { CompletionRequirement } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";
import { getDocumentFromId } from "util/document_bundle";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { isNotaryDocumentDownloadProhibited } from "common/notary/capacity";
import type { LoadOptions } from "common/pdf/pspdfkit";

import DocumentsListWithActions from "./document_list/list";
import MeetingDocument from "./meeting_document";
import SupplementaryDocument from "./supplementary_document";
import type { DocumentBundleForTransactionDetails } from "./index_fragment.graphql";

type Props = {
  viewer: {
    user: null | {
      id: string;
      notaryProfile?: Parameters<typeof isNotaryDocumentDownloadProhibited>[0];
    };
  };
  bundle: DocumentBundleForTransactionDetails;
  refetch: () => void;
  viewable?: boolean;
  shareable?: boolean;
  showMergedDocs?: boolean;
  showHeader?: boolean;
  finalizedDocumentOnly?: boolean;
  readOnly?: boolean;
  showNotarialActs?: boolean;
  showResponsiveView?: boolean;
  pdfEventOptions?: LoadOptions["pdfEventOptions"];
  simpleBundleMenu?: boolean;
};
type SelectedSupplementaryDoc = { name: string; url: string };

function BundleDocumentListContainer(props: Props) {
  const { bundle, shareable, showHeader, refetch, viewer, pdfEventOptions, simpleBundleMenu } =
    props;
  const { completionRequirements, documents } = bundle;
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const outlet = useOutlet();
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    priority: isTxnDetailsRedesign ? "page" : "modal",
    title: intl.formatMessage(useDocumentTitles().transactionDetailsDocument),
  });
  const { hasPermissionFor } = usePermissions();
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [lastOpenedId, setLastOpenedId] = useState<string | null>(null);
  const [selectedSupplementaryDoc, setSelectedSupplementaryDoc] =
    useState<SelectedSupplementaryDoc | null>(null);

  useEffect(() => {
    refetch();
  }, []);

  if (
    isNotaryDocumentDownloadProhibited(viewer.user?.notaryProfile, bundle.organizationTransaction)
  ) {
    return null;
  }

  const downloadable = hasPermissionFor("downloadTransactionDocuments");

  const selectedDocument = selectedDocumentId && getDocumentFromId(documents, selectedDocumentId);
  if (selectedDocument) {
    return (
      <>
        {outlet ? (
          outlet
        ) : (
          <MeetingDocument
            document={selectedDocument}
            bundle={bundle}
            backPath={() => {
              setLastOpenedId(selectedDocument.id);
              setSelectedDocumentId(null);
            }}
            shareable={shareable}
            downloadable={downloadable}
            finalizedDocumentOnly={props.finalizedDocumentOnly}
            onDocumentSelected={(docId) => {
              setSelectedDocumentId(docId);
              setLastOpenedId(docId);
            }}
            showResponsiveView={props.showResponsiveView}
            pdfEventOptions={pdfEventOptions}
            simpleBundleMenu={simpleBundleMenu}
          />
        )}
      </>
    );
  }

  if (selectedSupplementaryDoc && downloadable) {
    return (
      <SupplementaryDocument
        document={selectedSupplementaryDoc}
        backPath={() => setSelectedSupplementaryDoc(null)}
        downloadable={downloadable}
        showResponsiveView={props.showResponsiveView}
      />
    );
  }

  return (
    <>
      {outlet ? (
        outlet
      ) : (
        <DocumentsListWithActions
          bundle={bundle}
          viewable={props.viewable && !props.readOnly}
          shareable={shareable}
          showMergedDocs={props.showMergedDocs}
          downloadable={downloadable}
          onDocumentSelected={(doc) => setSelectedDocumentId(doc.id)}
          onSupplementaryDocSelected={setSelectedSupplementaryDoc}
          showHeader={showHeader}
          showLegalInfoItem={Boolean(
            completionRequirements?.includes(CompletionRequirement.NOTARIZATION),
          )}
          showNotarialActs={props.showNotarialActs}
          lastOpenedId={lastOpenedId}
          showResponsiveView={props.showResponsiveView}
        />
      )}
    </>
  );
}

BundleDocumentListContainer.defaultProps = {
  viewable: true,
  showHeader: true,
  showMergedDocs: true,
};

export default BundleDocumentListContainer;

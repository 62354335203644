/**
 * @description Immutably update a document node in the bundle.
 */
export function updateDocumentNode(meeting, shouldUpdate, nodeUpdater) {
  const {
    documentBundle: { documents },
  } = meeting;
  const newDocumentEdges = documents.edges.map((edge) => {
    return shouldUpdate(edge.node)
      ? {
          ...edge,
          node: nodeUpdater(edge.node),
        }
      : edge;
  });
  return {
    ...meeting,
    documentBundle: {
      ...meeting.documentBundle,
      documents: {
        ...meeting.documentBundle.documents,
        edges: newDocumentEdges,
      },
    },
  };
}

/**
 * @description Return the meeting's current document node.
 * @type {<Doc extends { id: string }>(meeting: { documentBundle: null | { documents: { edges: { node: Doc }[] } } }) => Doc}
 */
export function getCurrentDocumentNode(meeting) {
  const { documentBundle, currentDocumentId } = meeting;
  const documentEdge = documentBundle.documents.edges.find((e) => e.node.id === currentDocumentId);
  if (!documentEdge) {
    const ids = documentBundle.documents.edges.map((e) => e.node.id).join(", ");
    throw new Error(
      `Could not find the current document id (${currentDocumentId}) in the bundle: ${ids}`,
    );
  }
  return documentEdge.node;
}

/**
 * @description Returns pen holder participant in notary meeting party
 */
export function getCurrentPenholderInNotaryParty({ meetingParticipants }) {
  const notaryParticipant = meetingParticipants.find((p) => p.__typename === "NotaryParticipant");
  return notaryParticipant.isCurrentPenHolder
    ? notaryParticipant
    : meetingParticipants.find((p) => p.isCurrentPenHolder && p.parentId === notaryParticipant.id);
}

/**
 * @description Returns pen holder participant outside of notary meeting party
 * @type {<Participant extends { __typename: string; id: string; parentId: null | string }>(meeting: { meetingParticipants: Participant[] }) => Participant}
 */
export function getCurrentPenholderInSignerParties({ meetingParticipants }) {
  const signerPartyParentIds = new Set(
    meetingParticipants
      .filter(
        (p) =>
          (p.__typename === "SignerParticipant" ||
            p.__typename === "WitnessParticipant" ||
            p.__typename === "IdentityVerifiedWitnessParticipant") &&
          !p.parentId,
      )
      .map((p) => p.id),
  );
  return meetingParticipants.find(
    (p) =>
      p.isCurrentPenHolder &&
      (signerPartyParentIds.has(p.id) || signerPartyParentIds.has(p.parentId)),
  );
}

/**
 * @description Returns pen holder participant in the meeting party of the given user.
 *              Returns null if user's meeting party has no current pen holder
 */
export function getCurrentPenholderParticipant(meeting, user) {
  const { meetingParticipants } = meeting;
  const userParticipant = meetingParticipants.find(
    (p) =>
      p.userId === user.id &&
      (p.__typename === "SignerParticipant" ||
        p.__typename === "NotaryParticipant" ||
        p.__typename === "WitnessParticipant" ||
        p.__typename === "IdentityVerifiedWitnessParticipant"),
  );
  if (!userParticipant) {
    return null;
  }
  if (userParticipant.isCurrentPenHolder) {
    return userParticipant;
  }
  return meetingParticipants.find((p) => p.isCurrentPenHolder && p.parentId === userParticipant.id);
}

/**
 * @description Returns participant with no parent in the meeting party of the given user.
 */
export function getPrimaryParticipant(meeting, user) {
  const { meetingParticipants } = meeting;
  const userParticipant = meetingParticipants.find((p) => p.userId === user.id);
  if (!userParticipant) {
    return null;
  }
  return userParticipant.parentId
    ? meeting.meetingParticipants.find((p) => p.id === userParticipant.parentId)
    : userParticipant;
}

/**
 * @description Returns meeting participants in the same party with the given user.
 */
export function getMeetingParticipantParty(meeting, user) {
  const { meetingParticipants } = meeting;
  const userParticipant = meetingParticipants.find((p) => p.userId === user.id);
  if (!userParticipant) {
    return [];
  }
  const parentId = userParticipant.parentId || userParticipant.id;
  return meeting.meetingParticipants.filter((p) => p.id === parentId || p.parentId === parentId);
}

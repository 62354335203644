import { FormattedMessage } from "react-intl";

import SubFormSection from "common/form/sub_form/section";
import SectionHeader from "common/form/sub_form/section/header";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import Icon from "common/core/icon";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import { Feature } from "graphql_globals";

import Styles from "./index.module.scss";

type FormValues = { personallyKnownToNotary?: boolean };

type Props = {
  organization: {
    featureList: Feature[];
  };
  personallyKnownToNotary: boolean | undefined | null;
  formName: string;
  change: (fieldName: keyof FormValues, value: unknown) => void;
  disabled: boolean | null;
};

function PersonallyKnownToNotary({
  organization,
  personallyKnownToNotary,
  change,
  disabled,
}: Props) {
  const { featureList } = organization;
  return (
    <SubFormSection fullWidth>
      <div className={Styles.personallyKnownForm}>
        <SectionHeader
          title={
            <FormattedMessage
              id="c70fd0ff-0d6a-4558-b01a-e4675f34339e"
              defaultMessage="Does the notary personally know the signer? If so, the signer does not have to complete KBA and credential analysis."
            />
          }
        />
        <div>
          <DeprecatedRadioButtonField
            name="personallyKnownToNotary"
            labelText={
              <FormattedMessage id="3ffc44e4-5d37-47bd-937e-e15835e26e87" defaultMessage="No" />
            }
            radioValue={false}
            groupValue={Boolean(personallyKnownToNotary)}
            className={Styles.radioButton}
            automationId="notPersonallyKnownTransaction"
            size="small"
            disabled={disabled}
            onChange={() => {
              change("personallyKnownToNotary", false);
            }}
          />
          <DeprecatedRadioButtonField
            name="personallyKnownToNotary"
            labelText={
              <FormattedMessage id="e304de4e-26b0-40fe-bb3b-d3fb45f8fbe9" defaultMessage="Yes" />
            }
            radioValue
            groupValue={Boolean(personallyKnownToNotary)}
            automationId="personallyKnownTransaction"
            className={Styles.radioButton}
            size="small"
            disabled={disabled}
            onChange={() => {
              change("personallyKnownToNotary", true);
            }}
          />
        </div>
        {personallyKnownToNotary && featureList.includes(Feature.ORG_NOTARY_OVERFLOW) && (
          <div className={Styles.warning}>
            <div className={Styles.icon}>
              <Icon name="disclaimer" />
            </div>
            <div className={Styles.text}>
              <FormattedMessage
                id="6a799fac-2ba2-4099-8e96-b2b913d940b1"
                defaultMessage="This transaction can be manually reassigned but will not automatically overflow to the Notarize Network. Reassignment to the Network will require the signer to complete KBA and credential analysis."
              />
            </div>
          </div>
        )}
      </div>
    </SubFormSection>
  );
}

export default deprecatedSubForm<Props>({ getValuesFor: ["personallyKnownToNotary"] })(
  PersonallyKnownToNotary,
);

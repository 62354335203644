import "./input.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { getFieldErrorMessageId } from "common/form/group_errors";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

/** @deprecated - please use components in common/core/form */
export class DeprecatedTextInput extends Component {
  el = null;

  setRef = (el) => {
    this.el = el;
    // setRef is called when unmounting the component, so only call upward if input is defined
    if (el) {
      this.props.onInputRefSet(el);
    }
  };

  focusInput = () => {
    const tmpValue = this.el.value;

    // HACK: Sets cursor to end of value in the input
    this.el.focus();
    this.el.value = "";
    this.el.value = tmpValue;
  };

  render() {
    const {
      readOnly,
      disabled,
      value,
      className,
      displayRequiredAsterisk, // not valid input prop, remove
      onInputRefSet, // not valid input prop, remove
      "aria-label": ariaLabel,
      ...otherProps
    } = this.props;
    // TODO: remove this readonly management from here, since
    // this is a "style" that should only exist on the StyledTextInput
    if (readOnly) {
      return value ? (
        <div {...otherProps} className={`Text__read-only ${SENSITIVE_CLASS}`}>
          <span title={value} className="Text--text ellipsis">
            {value}
          </span>
        </div>
      ) : null;
    }

    const cx = classnames(className, SENSITIVE_CLASS);

    function getAriaLabel() {
      let newAriaLabel;
      if (ariaLabel) {
        newAriaLabel = ariaLabel;
      } else if (otherProps.placeholder) {
        /*Please do not rely on placeholder as aria-label. This is a backup. aria-label should be a form label that makes sense to a vision-impaired user hearing it while focused on an input. */
        newAriaLabel = otherProps.placeholder;
      }
      const required = Boolean(displayRequiredAsterisk);
      return `${newAriaLabel}${required ? " required" : ""}`;
    }

    return (
      <input
        {...otherProps}
        aria-label={getAriaLabel()}
        aria-describedby={classnames(
          getFieldErrorMessageId(otherProps.name),
          otherProps["aria-describedby"],
        )}
        className={cx}
        ref={this.setRef}
        type="text"
        disabled={readOnly || disabled}
        value={value}
      />
    );
  }
}

DeprecatedTextInput.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.node,
  placeholder: PropTypes.string,
  onInputRefSet: PropTypes.func,
};

DeprecatedTextInput.defaultProps = {
  onInputRefSet: () => {},
};

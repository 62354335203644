import { defineMessages, useIntl } from "react-intl";

import FormGroupErrors from "common/form/group_errors";
import FormGroup from "common/form/group";
import { DeprecatedSelectField } from "common/form/fields/select";
import { CONTACT_SELECT_ITEMS, COLLABORATOR_SELECT_ITEMS } from "constants/points_of_contact";

export type Props = {
  fieldNamePrefix: string;
  readOnly?: boolean;
  withCollaborators?: boolean;
};

const MESSAGES = defineMessages({
  role: {
    id: "1017102a-619b-4515-b840-a49d551d30ef",
    defaultMessage: "Contact Role",
  },
});

function RoleRow({ fieldNamePrefix, readOnly, withCollaborators }: Props) {
  const intl = useIntl();
  const prefixedTitleField = `${fieldNamePrefix}.role`;
  const roleSelectItems = withCollaborators
    ? COLLABORATOR_SELECT_ITEMS.concat(CONTACT_SELECT_ITEMS)
    : CONTACT_SELECT_ITEMS;

  return (
    <FormGroup fields={[prefixedTitleField]} disableFormRowStyle>
      <DeprecatedSelectField
        id={prefixedTitleField}
        name={prefixedTitleField}
        placeholder={intl.formatMessage(MESSAGES.role)}
        data-automation-id={`${fieldNamePrefix}.poc-role-field`}
        items={roleSelectItems}
        useStyledInput
        placeholderAsLabel
        displayRequiredAsterisk
        disabled={readOnly}
      />

      <FormGroupErrors fields={[prefixedTitleField]} />
    </FormGroup>
  );
}

export default RoleRow;

import { useIntl, defineMessages } from "react-intl";
import { type ComponentProps } from "react";

import { FORM_FIELDS as ADDITIONAL_CONTACTS_FORM_FIELDS } from "common/transaction_creation/v3/sections/recipient_details/additional_contacts";
import { SpacedMultipartFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import LoadingIndicator from "common/core/loading_indicator";
import {
  Card,
  SectionHeader,
  ConfiguredField,
  showField,
} from "common/transaction_creation/v3/common";
import {
  type SectionContract,
  type SectionComponentProps,
} from "common/transaction_creation/v3/form";
import type { Address } from "common/core/form/address";
import { useQuery } from "util/graphql";
import { DEFAULT_TRANSACTION_NAME } from "constants/transaction";
import { useWatch } from "common/core/form";

import type { TransactionDetailsTransaction } from "./transaction_fragment.graphql";
import type { RealEstateTransactionDetailsOrganization as TransactionDetailsOrganization } from "./real_estate_organization_fragment.graphql";
import TransactionDetailsSectionQuery, {
  type TransactionDetailsSection_node_OrganizationTransaction_publicEligibleTitleUnderwriters as TitleUnderwriter,
} from "./real_estate_transaction_query.graphql";
import TransactionTypeField from "./transaction_type_field";
import TitleUnderwriterField from "./title_underwriter_field";
import SecondaryIdRequiredField from "./secondary_id_field";
import PropertyAddressForm from "./property_address_form";
import Styles from "./index.module.scss";
import { usersOrgCreatedTransaction } from "../../real_estate/util";
import { CCRecipientEmails } from "./cc_recipients";

export const TITLE_UNDERWRITER_FIELD_ID = "transaction-title-underwriter-field";

const MESSAGES = defineMessages({
  transactionDetailsTitle: {
    id: "e8afe48d-5ad1-4d3e-b7f6-7d239792d948",
    defaultMessage: "Transaction details",
  },
  eligibilityTitle: {
    id: "fc193475-1228-4eb9-86dc-88940ccbd77a",
    defaultMessage: "Eligibility",
  },
  additionalDetailsTitle: {
    id: "360cfdac-d409-4ded-a297-c13617ce71d9",
    defaultMessage: "Additional details",
  },
  propertyAddressTitle: {
    id: "5d00bf6b-3c17-4452-a2fa-3d1b40654280",
    defaultMessage: "Property address",
  },
  transactionNameLabel: {
    id: "c6290a0b-414f-4826-bc28-c828f1148823",
    defaultMessage: "Transaction name",
  },
  transactionTypeLabel: {
    id: "681f3dd0-6aeb-408d-82ee-d5740cb9be43",
    defaultMessage: "Transaction type",
  },
  externalIdLabel: {
    id: "6fdab25f-45ff-4c47-8759-bd1fd3966c1f",
    defaultMessage: "External ID",
  },
  titleUnderwriterLabel: {
    id: "f9a05fe6-30bd-46d4-8ebc-9b0eb34be512",
    defaultMessage: "Title underwriter",
  },
  titleAgencyLabel: {
    id: "70e8003b-f0f9-4761-94e1-f7a7e22f4a6a",
    defaultMessage: "Title agency",
  },
  fileNumberLabel: {
    id: "bd2f2733-0efe-4e51-a67b-09a61cbc7635",
    defaultMessage: "File number",
  },
  loanNumberLabel: {
    id: "482dd72d-0d6c-4986-aad4-43baf0011b18",
    defaultMessage: "Loan number",
  },
});

const ELIGIBILITY_FIELDS = ["propertyAddress", "recordingLocation"];

function useMortgageTransactionValues({
  transaction,
  skip,
}: {
  transaction: TransactionDetailsTransaction;
  organization: TransactionDetailsOrganization;
  skip: boolean;
}) {
  const { id, propertyAddress } = transaction;

  const formattedPropertyAddress = {
    city: propertyAddress?.city,
    country: propertyAddress?.country || "US",
    line1: propertyAddress?.line1,
    line2: propertyAddress?.line2,
    postal: propertyAddress?.postal,
    state: propertyAddress?.state,
  };

  // TODO REAL-9507 figure out the comment from title_portal details form
  // if (underwriterUnavailableCta && underwriters.length === 0)
  // withTitleAgencies: false - This flow is for docutech title agents
  const { data, loading, previousData } = useQuery(TransactionDetailsSectionQuery, {
    variables: {
      transactionId: id,
      propertyAddress: formattedPropertyAddress,
      searchRecordingLocations: Boolean(propertyAddress),
    },
    skip,
  });

  // only show loading indicator on initial load to prevent form from unmounting when there is a refetch
  if (loading && !previousData) {
    return { loading };
  }

  let titleUnderwriters: TitleUnderwriter[] = [];
  if (data?.node?.__typename === "OrganizationTransaction") {
    titleUnderwriters = data.node.publicEligibleTitleUnderwriters;
  }
  const initialRecordingLocations = data?.viewer.recordingLocationEligibilities || [];

  return {
    initialRecordingLocations,
    loading: false,
    titleUnderwriters,
  };
}

// fields in config won't always match up one to one w/ value in the form
export const CONFIGS = {
  externalId: "externalId",
  fileNumber: "fileNumber",
  loanNumber: "loanNumber",
  propertyAddress: "propertyAddress",
  recordingLocation: "recordingLocation",
  secondaryIdRequired: "secondaryIdRequired",
  titleUnderwriter: "titleUnderwriter",
  transactionName: "transactionName",
  transactionType: "transactionType",
  titleAgencyName: "titleAgencyName",
  ccRecipientEmail: "ccRecipientEmail",
} as const;
export const FORM_FIELDS = {
  externalId: "externalId",
  fileNumber: "fileNumber",
  loanNumber: "loanNumber",
  propertyAddress: "propertyAddress",
  recordingLocationId: "recordingLocationId",
  secondaryIdRequired: "secondaryIdRequired",
  titleUnderwriter: "titleUnderwriter",
  transactionName: "transactionName",
  transactionType: "transactionType",
  ccRecipientEmails: "ccRecipientEmails",
} as const;

function TransactionDetailsSection({
  config,
  form,
  transaction,
  organization,
}: SectionComponentProps<TransactionDetailsTransaction, TransactionDetailsOrganization, unknown>) {
  const intl = useIntl();

  const checksEligibility = ELIGIBILITY_FIELDS.some((field) =>
    showField(config, field as keyof typeof CONFIGS),
  );

  const checksAdditionalDetails = ["fileNumber", "loanNumber", "secondaryIdRequired"].some(
    (field) => showField(config, field as keyof typeof CONFIGS),
  );

  const { initialRecordingLocations, loading, titleUnderwriters } = useMortgageTransactionValues({
    transaction,
    organization,
    skip: !checksEligibility,
  });

  const placeOrder = useWatch({ control: form.control, name: "placeOrder" });

  if (loading) {
    return <LoadingIndicator />;
  }

  const additionalDetails = (
    <>
      <SpacedMultipartFormRow>
        <ConfiguredField
          config={config}
          configField={CONFIGS.fileNumber}
          data-automation-id="file-number"
          form={form}
          name={FORM_FIELDS.fileNumber}
          label={intl.formatMessage(MESSAGES.fileNumberLabel)}
          as={TextInput}
        />
        <ConfiguredField
          config={config}
          configField={CONFIGS.loanNumber}
          data-automation-id="loan-number"
          form={form}
          name={FORM_FIELDS.loanNumber}
          label={intl.formatMessage(MESSAGES.loanNumberLabel)}
          as={TextInput}
        />
      </SpacedMultipartFormRow>
      <ConfiguredField
        config={config}
        configField={CONFIGS.secondaryIdRequired}
        form={form}
        name={FORM_FIELDS.secondaryIdRequired}
        as={SecondaryIdRequiredField}
      />
    </>
  );

  return (
    <>
      <SectionHeader iconName="business">
        {intl.formatMessage(MESSAGES.transactionDetailsTitle)}
      </SectionHeader>
      <div className={Styles.card}>
        {checksEligibility ? (
          <Card title={intl.formatMessage(MESSAGES.eligibilityTitle)}>
            <ConfiguredField
              config={config}
              configField={CONFIGS.transactionType}
              data-automation-id="transaction-type"
              form={form}
              fullWidth
              name={FORM_FIELDS.transactionType}
              label={intl.formatMessage(MESSAGES.transactionTypeLabel)}
            >
              <TransactionTypeField aria-invalid="false" />
            </ConfiguredField>
            <ConfiguredField
              config={config}
              configField={CONFIGS.titleAgencyName}
              data-automation-id="title-agency-field"
              form={form}
              name={ADDITIONAL_CONTACTS_FORM_FIELDS.titleAgencyName}
              label={intl.formatMessage(MESSAGES.titleAgencyLabel)}
              fullWidth
              disabled // field should always be readonly
              as={TextInput}
            />
            <ConfiguredField
              id={TITLE_UNDERWRITER_FIELD_ID}
              config={config}
              configField={CONFIGS.titleUnderwriter}
              data-automation-id="title-underwriter-field"
              form={form}
              fullWidth
              name={FORM_FIELDS.titleUnderwriter}
              label={intl.formatMessage(MESSAGES.titleUnderwriterLabel)}
              requiredOverride={placeOrder ? false : undefined}
            >
              <TitleUnderwriterField
                aria-invalid="false"
                transaction={transaction}
                underwriters={titleUnderwriters}
              />
            </ConfiguredField>
            {showField(config, CONFIGS.propertyAddress) && (
              <PropertyAddressForm
                config={config}
                form={form}
                initialRecordingLocation={transaction.recordingLocation!}
                initialRecordingLocations={initialRecordingLocations}
                collabEnabled={organization.realEstateCollabEnabled}
              />
            )}
          </Card>
        ) : (
          <Card title={intl.formatMessage(MESSAGES.transactionDetailsTitle)}>
            <>
              <ConfiguredField
                config={config}
                configField={CONFIGS.transactionName}
                data-automation-id="transaction-name-field"
                form={form}
                fullWidth
                name={FORM_FIELDS.transactionName}
                label={intl.formatMessage(MESSAGES.transactionNameLabel)}
                as={TextInput}
              />
              <SpacedMultipartFormRow>
                <ConfiguredField
                  config={config}
                  configField={CONFIGS.transactionType}
                  data-automation-id="transaction-type"
                  form={form}
                  name={FORM_FIELDS.transactionType}
                  label={intl.formatMessage(MESSAGES.transactionTypeLabel)}
                  as={TextInput}
                  spellCheck="false"
                />
                <ConfiguredField
                  config={config}
                  configField={CONFIGS.externalId}
                  data-automation-id="external-id"
                  form={form}
                  name={FORM_FIELDS.externalId}
                  label={intl.formatMessage(MESSAGES.externalIdLabel)}
                  as={TextInput}
                />
              </SpacedMultipartFormRow>
              {additionalDetails}
            </>
          </Card>
        )}
      </div>

      <div className={Styles.card}>
        {checksAdditionalDetails && checksEligibility && (
          <Card
            title={intl.formatMessage(MESSAGES.additionalDetailsTitle)}
            data-automation-id="additional-details-section"
          >
            {additionalDetails}
          </Card>
        )}
      </div>

      <CCRecipientEmails form={form} config={config} />
    </>
  );
}

export type TransactionDetailsSectionForm = ComponentProps<
  typeof TransactionDetailsSection
>["form"];

export type TransactionDetailsTransactionType = ComponentProps<
  typeof TransactionDetailsSection
>["transaction"];

export type TransactionDetailsFormValues = {
  [FORM_FIELDS.externalId]: string | null;
  [FORM_FIELDS.fileNumber]: string | null;
  [FORM_FIELDS.loanNumber]: string | null;
  [FORM_FIELDS.propertyAddress]: Address | null;
  [FORM_FIELDS.recordingLocationId]: string | null;
  [FORM_FIELDS.secondaryIdRequired]: string | null;
  [FORM_FIELDS.titleUnderwriter]: string | null;
  [FORM_FIELDS.transactionName]: string | null;
  [FORM_FIELDS.transactionType]: string | null;
  [FORM_FIELDS.ccRecipientEmails]: { email: string }[];
};
type TransactionDetailsSubmitData = {
  externalId: string | null;
  fileNumber: string | null;
  loanNumber: string | null;
  name: string | null;
  recordingLocationId: string | null;
  secondaryIdRequired: boolean | null;
  streetAddress: Address | null;
  titleUnderwriterOrgId: string | null;
  transactionType: string | null;
  ccRecipientEmails: string[];
};
export const TRANSACTION_DETAILS_SECTION = {
  Component: TransactionDetailsSection,
  configs: CONFIGS,
  getDefaultFormValues(transaction) {
    return {
      externalId: transaction.externalId,
      fileNumber: transaction.fileNumber,
      loanNumber: transaction.loanNumber,
      propertyAddress: transaction.propertyAddress
        ? {
            line1: transaction.propertyAddress.line1,
            line2: transaction.propertyAddress.line2,
            city: transaction.propertyAddress.city,
            state: transaction.propertyAddress.state,
            postal: transaction.propertyAddress.postal,
            country: transaction.propertyAddress.country || "US",
          }
        : null,
      recordingLocationId: transaction.recordingLocation?.id || null,
      secondaryIdRequired: transaction.secondaryIdRequired ? "yes" : "no",
      ccRecipientEmails: transaction.ccRecipientEmails.map((email) => ({
        email,
      })),
      titleUnderwriter: transaction.titleUnderwriter?.id || null,
      transactionName: transaction.name === DEFAULT_TRANSACTION_NAME ? null : transaction.name,
      transactionType: transaction.transactionType,
    };
  },
  getSubmitData({ sectionFormValues }) {
    return {
      externalId: sectionFormValues.externalId,
      fileNumber: sectionFormValues.fileNumber,
      loanNumber: sectionFormValues.loanNumber,
      name: sectionFormValues.transactionName,
      recordingLocationId: sectionFormValues.recordingLocationId || null,
      secondaryIdRequired: sectionFormValues.secondaryIdRequired === "yes",
      ccRecipientEmails: sectionFormValues.ccRecipientEmails.map(
        (ccRecipient) => ccRecipient.email,
      ),
      streetAddress: sectionFormValues.propertyAddress
        ? {
            line1: sectionFormValues.propertyAddress.line1,
            line2: sectionFormValues.propertyAddress.line2,
            city: sectionFormValues.propertyAddress.city,
            state: sectionFormValues.propertyAddress.state,
            postal: sectionFormValues.propertyAddress.postal,
            country: sectionFormValues.propertyAddress.country,
          }
        : null,
      titleUnderwriterOrgId: sectionFormValues.titleUnderwriter,
      transactionType: sectionFormValues.transactionType,
    };
  },
  modifyConfig({ sectionConfig, transaction, organization, permissions }) {
    const modifiedConfig = { ...sectionConfig };

    const usersOrgOwnsTransaction = usersOrgCreatedTransaction(transaction, organization);

    if (
      !transaction.isMortgage &&
      !permissions.hasPermissionFor("createOrganizationTransactions")
    ) {
      modifiedConfig.transactionName.display = "readonly";
      modifiedConfig.transactionType.display = "readonly";
      modifiedConfig.externalId.display = "readonly";
      modifiedConfig.secondaryIdRequired.display = "readonly";
    } else if (transaction.isMortgage) {
      if (permissions.hasPermissionFor("manageOpenOrders")) {
        modifiedConfig.transactionType.display = "editable";
        modifiedConfig.propertyAddress.display = "editable";
        modifiedConfig.recordingLocation.display = "editable";
      } else if (!usersOrgOwnsTransaction) {
        modifiedConfig.loanNumber.display = "readonly";
      }
    }
    return modifiedConfig;
  },
} satisfies SectionContract<
  TransactionDetailsFormValues,
  TransactionDetailsSubmitData,
  TransactionDetailsTransaction,
  TransactionDetailsOrganization,
  unknown,
  typeof CONFIGS
>;

import { FormattedMessage } from "react-intl";
import { useState } from "react";

import { Card, CardHeading } from "common/core/card";
import { SettingsTitle } from "common/settingsv2/common";
import { MORTGAGE_TYPES } from "constants/mortgage_types";
import { useMutation } from "util/graphql";
import MersOrgId from "admin_portal/company/mortgage/mers_org_id";
import { BinaryToggle } from "common/core/form/binary_toggle";
import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "admin_portal/company/details_query.graphql";

import EvaultSetup from "../../evault_setup";
import UpdateHasExternalEvaultMutation from "./update_has_external_evault_mutation.graphql";

type Props = {
  organization: Organization;
  organizationMortgageType: string | null;
};

export function Evault({ organization, organizationMortgageType }: Props) {
  const { hasExternalEvault } = organization;
  const [loading, setLoading] = useState(false);
  const updateHasExternalEvaultMutateFn = useMutation(UpdateHasExternalEvaultMutation);

  function onHasExternalEvaultChange(hasExternalEvault: boolean) {
    setLoading(true);

    updateHasExternalEvaultMutateFn({
      variables: {
        input: { organizationId: organization.id, hasExternalEvault },
      },
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="2038e365-00cc-4157-b822-899ac9273fbe" defaultMessage="eVault" />
      </SettingsTitle>
      <Card>
        {organizationMortgageType === MORTGAGE_TYPES.LENDER && (
          <>
            <CardHeading>
              <span id="external-evault-toggle">
                <FormattedMessage
                  id="60e79bed-8712-4b7d-b734-26d92227e95b"
                  defaultMessage="Has external eVault"
                />
              </span>
            </CardHeading>
            <BinaryToggle
              aria-labelledby="external-evault-toggle"
              onChange={onHasExternalEvaultChange}
              value={Boolean(hasExternalEvault)}
              disabled={loading}
              automationId="hasExternalEvault"
            />

            <MersOrgId organization={organization} />
          </>
        )}

        <CardHeading>
          <FormattedMessage id="9000c198-7cdd-4f84-9dea-69ee8574ee55" defaultMessage="Evault" />
        </CardHeading>
        <EvaultSetup organization={organization} />
      </Card>
    </>
  );
}

import "./index.scss";

import type { ReactNode, KeyboardEvent, ComponentPropsWithoutRef } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";

import { renderIntlAsString } from "util/html";
import { getFieldErrorMessageId } from "common/form/group_errors";

type ValueType = string | number | boolean | null | undefined;
type Props<T extends ValueType> = Omit<ComponentPropsWithoutRef<"input">, "onChange" | "size"> & {
  groupValue?: T;
  onChange: (newValue: NonNullable<T>) => void;
  radioValue: NonNullable<T>;
  automationId?: string;
  className?: string;
  labelClassName?: string;
  labelText?: Parameters<typeof renderIntlAsString>[1];
  infoText?: ReactNode;
  multiline?: boolean;
  size?: "regular" | "small";
  index?: number;
};

/** @deprecated - please use components in common/core/form */
export function DeprecatedRadioButton<T extends ValueType = string | undefined | null>({
  "aria-describedby": ariaDescribedBy,
  automationId,
  id,
  className,
  labelClassName,
  labelText,
  infoText,
  groupValue,
  onChange,
  radioValue,
  multiline,
  name,
  size,
  disabled,
  index = 0,
}: Props<T>) {
  const cx = classnames("RadioButton", className, {
    "RadioButton--multiline": infoText || multiline,
    "RadioButton--small": size === "small",
    RadioButton__disabled: disabled,
  });

  const ariaDescribedByIds = ariaDescribedBy
    ? `${getFieldErrorMessageId(name)} ${ariaDescribedBy} ${radioValue}-info-text`
    : `${radioValue}-info-text`;

  const handleChange = () => {
    if (disabled) {
      return;
    }
    onChange(radioValue);
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      // prevents the space bar scrolling the page
      e.preventDefault();
      handleChange();
    }
  };

  const intl = useIntl();

  return (
    <div className={cx}>
      <div className="RadioButton--content">
        <input
          id={id}
          type="radio"
          value={radioValue as unknown as string}
          tabIndex={-1}
          // We mark the input as "readonly" since we do not use the input itself to update the value. This
          // silences a react warning.
          readOnly
          checked={radioValue === groupValue}
          name={name}
        />
        <span
          role="radio"
          aria-checked={radioValue === groupValue}
          aria-describedby={ariaDescribedByIds}
          aria-label={renderIntlAsString(intl, labelText)}
          tabIndex={index}
          className="RadioButton--circle"
          onClick={handleChange}
          onKeyDown={handleKeydown}
          data-automation-id={`answer-${radioValue}`}
        />
        {Boolean(labelText || infoText) && (
          <div className="RadioButton--text">
            <label
              htmlFor={id}
              onClick={handleChange}
              data-automation-id={`radio-${radioValue}`}
              className="RadioButton--label"
            >
              <span className={labelClassName} data-automation-id={automationId}>
                {renderIntlAsString(intl, labelText)}
              </span>
            </label>
            {infoText && (
              <p id={`${radioValue}-info-text`} className="RadioButton--info">
                {infoText}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

DeprecatedRadioButton.defaultProps = {
  automationId: "radio-button",
  style: "checkmark",
  size: "regular",
};

import classnames from "classnames";

import { DeprecatedTextarea, DeprecatedStyledTextareaInput } from "common/form/inputs/textarea";
import { DeprecatedFieldWrapper } from "common/form/enhancers/redux_form_field_wrapper";

function TextAreaField(props) {
  const { useStyledInput, className, readOnly, disabled, ...otherProps } = props;

  if (useStyledInput) {
    return (
      <DeprecatedStyledTextareaInput
        {...otherProps}
        className={classnames(className, { "Form-input--readonly": readOnly })}
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  }

  return (
    <DeprecatedTextarea
      {...otherProps}
      className={classnames(className, { "Form-input--readonly": readOnly })}
      disabled={readOnly || disabled}
    />
  );
}

TextAreaField.propTypes = {
  ...DeprecatedTextarea.propTypes,
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedTextAreaField = DeprecatedFieldWrapper(TextAreaField);

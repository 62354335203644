import { forwardRef, type ComponentPropsWithoutRef, type Ref, useMemo } from "react";

import { Select } from "common/core/form/select";
import type { AriaRequired } from "common/core/form/error";

import type { RealEstateTransactionDetailsTransaction_titleUnderwriter as TitleUnderwriter } from "./real_estate_transaction_fragment.graphql";
import type { TransactionDetailsTransactionType } from ".";

type Props = AriaRequired<ComponentPropsWithoutRef<"select">> & {
  transaction: Pick<TransactionDetailsTransactionType, "organization" | "titleUnderwriter">;
  underwriters: TitleUnderwriter[];
};

// This is for Title to potentially inject Lender-selected titleunderwriter
const uniqUnderwriters = (
  underwriters: TitleUnderwriter[],
  targetUnderwriter?: TitleUnderwriter,
) => {
  // targetUnderwriter can be nil if a transaction was created without one
  // (which is the case for most GR transactions currently)
  const duplicateUnderwriter = underwriters.find((underwriter) => {
    return underwriter.id === targetUnderwriter?.id;
  });

  // [].find() can return undefined so we need to make sure that duplicateUnderwriter is not undefined
  if (targetUnderwriter && !duplicateUnderwriter) {
    return [...underwriters, targetUnderwriter];
  }
  return [...underwriters];
};

const titleUnderwriterItems = (underwriters: TitleUnderwriter[]) =>
  underwriters.flatMap((underwriter) => {
    if (!underwriter.name) {
      return [];
    }
    return { value: underwriter.id, label: underwriter.name };
  });

function TitleUnderwriterField(
  { transaction, underwriters: initialUnderwriters, ...selectProps }: Props,
  ref: Ref<HTMLSelectElement>,
) {
  const { organization: transactionOrganization, titleUnderwriter } = transaction;

  const titleUnderwriters = useMemo(() => {
    if (transactionOrganization.lenderAccess && titleUnderwriter) {
      // The Nononboarded Title Project made it possible for lender-initiated transactions
      // to select a title agency w/o knowing beforehand its eligible titleunderwriters, instead
      // allowing the lender to choose from title underwriters by recording location state alone.
      // Since we can only toggle LD flags on the lender side(as nononboarded title agents get created outside of our
      // own control), title agencies can only see underwriters they've explicitly chosen to work with.
      // To bypass this restriction and allow lender chosen underwriters to show up on the
      // title agency side, we need to inject the chosen underwriter into the title agent's underwriter list.
      // Underwriters can be undefined if there is no recording location set from API
      return uniqUnderwriters(initialUnderwriters, titleUnderwriter);
    }
    return initialUnderwriters;
  }, [initialUnderwriters, transaction]);

  // REAL-9470 add searchability
  // If we could not load any underwriters, hide this field. This typically shouldn't happen
  // but was found to occur in rare cases.
  return titleUnderwriters.length ? (
    <Select {...selectProps} items={titleUnderwriterItems(titleUnderwriters)} ref={ref} />
  ) : null;
}

const TitleUnderwriterFieldWithRef = forwardRef(TitleUnderwriterField);

export default TitleUnderwriterFieldWithRef;

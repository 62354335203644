import { CA_ZIP_FORMAT, US_ZIP_FORMAT } from "constants/validation";

export function zipMaxLength(countryCode) {
  switch (countryCode) {
    case "US":
      return 10;
    case "CA":
      return 7;
    default:
      return -1; // unlimited
  }
}

export function checkZip(countryCode, value) {
  const format =
    countryCode === "US" ? US_ZIP_FORMAT : countryCode === "CA" ? CA_ZIP_FORMAT : /[\s\S]*/;
  return format.test(value);
}

// Checks to see if an address has required fields (excludes country since we currently require a lot of things to be US)
export function checkAddressFilledOut(address) {
  return !!address && !!(address.line1 && address.city && address.state && address.postal);
}

import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";

/** @deprecated - please use components in common/core/form */
export function DeprecatedTextarea(
  /** @type React.ComponentProps<typeof TextareaAutosize> */ props,
) {
  return (
    <div className="Textarea">
      <TextareaAutosize {...props} />
    </div>
  );
}

DeprecatedTextarea.propTypes = {
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
};

DeprecatedTextarea.defaultProps = {
  minRows: 8,
  maxRows: 50,
};

/** @deprecated - please use components in common/core/form */
export class DeprecatedStyledTextareaInput extends Component {
  inputRef = null;
  state = {
    isFocused: false,
  };

  onFocus = () => {
    this.setState(() => ({ isFocused: true }));
  };

  onBlur = () => {
    this.setState(() => ({ isFocused: false }));
  };

  render() {
    const { value, className, label, placeholder, minRows, maxRows, disabled, ...otherProps } =
      this.props;

    // Placeholder = your typical placeholder
    // Label = the small header (eyebrow) embedded inside the input

    let labelElement = null;
    if (label) {
      labelElement = <span className="StyledTextarea--label">{label}</span>;
    }

    const textareaCx = classnames("StyledTextarea--input", className, {
      lower: label,
      focused: this.state.isFocused,
      disabled,
    });

    const placeholderCx = classnames("StyledTextarea--placeholder", {
      lower: label,
      focused: this.state.isFocused,
      disabled,
    });

    return (
      <div className={`StyledTextarea ${SENSITIVE_CLASS}`}>
        <div className={textareaCx}>
          <TextareaAutosize
            minRows={minRows}
            maxRows={maxRows}
            {...otherProps}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={value}
            disabled={disabled}
          />
        </div>
        {labelElement}
        {!value && <span className={placeholderCx}>{placeholder}</span>}
      </div>
    );
  }
}

DeprecatedStyledTextareaInput.propTypes = {
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
};

DeprecatedStyledTextareaInput.defaultProps = {
  minRows: 8,
  maxRows: 50,
};

import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useParams, useNavigate, Outlet } from "react-router-dom";

import { Feature } from "graphql_globals";
import Dashboard from "common/dashboard";
import {
  Sidebar,
  SidebarTabLink,
  SidebarBackButton,
  SidebarTabButtonDropdown,
  SidebarSectionHeader,
} from "common/sidebar";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { usePermissions } from "common/core/current_user_role";

import AdminCompanyDetailsWrapperQuery, {
  type AdminCompanyDetailsWrapper_node_Organization as Organization,
} from "./wrapper.query.graphql";

const MESSAGES = defineMessages({
  unnamedOrganization: {
    id: "96dd6b15-b759-4251-a633-b21783127cbd",
    defaultMessage: "Settings",
  },
});

const INTEGRATIONS_ROUTE = "integrations";
const MEMBER_MANAGEMENT_ROUTE = "member-management";
const ACCESS_ROUTE = "access";

function AdminCompanyDetailsWrapperLoaded({ organization }: { organization: Organization }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const canViewTeamDetails = hasPermissionFor("companyTeamDetails");
  const canViewPanel = hasPermissionFor("canViewPanel");
  const notaryManagementEnabled = organization.featureList.includes(Feature.ORGANIZATION_NOTARIES);
  const customPanelsEnabled = organization.featureList.includes(Feature.CUSTOM_PANELS);

  return (
    <Dashboard>
      <Sidebar
        backButton={
          <SidebarBackButton
            onClick={() => {
              navigate("/companies/page/1");
            }}
          />
        }
      >
        <SidebarSectionHeader
          title={intl.formatMessage({
            id: "6dd89fc8-a806-40ed-9f8c-416a88733106",
            defaultMessage: "Company",
          })}
          subtitle={
            organization.organizationBrand?.companyName ||
            intl.formatMessage(MESSAGES.unnamedOrganization)
          }
        />
        <SidebarTabLink to={`/companies/${organization.id}/structure`}>
          <FormattedMessage
            id="7afcf7ca-a987-4c1d-8a0b-c3ad798eb422"
            defaultMessage="Company directory"
          />
        </SidebarTabLink>
        <SidebarTabButtonDropdown
          activeRoute={ACCESS_ROUTE}
          innerContent={
            <>
              <SidebarTabLink to={`/companies/${organization.id}/access/domains`} isSubItem>
                <FormattedMessage
                  id="24553bd1-e40b-4097-aa8b-a1191b1ab219"
                  defaultMessage="Domains"
                />
              </SidebarTabLink>
              <SidebarTabLink
                to={`/companies/${organization.id}/access/identity-providers`}
                isSubItem
              >
                <FormattedMessage
                  id="8de3caea-29b4-40f1-9e76-bc6f1c46bcbf"
                  defaultMessage="Identity Providers"
                />
              </SidebarTabLink>
            </>
          }
        >
          <FormattedMessage id="6b616003-a0b0-400f-a175-861df03d46e6" defaultMessage="Access" />
        </SidebarTabButtonDropdown>
        <SidebarSectionHeader
          title={intl.formatMessage({
            id: "ae0c7c8b-daf1-465c-a732-4c3ab1da62a3",
            defaultMessage: "Organization",
          })}
          subtitle={organization.name || intl.formatMessage(MESSAGES.unnamedOrganization)}
        />
        <SidebarTabLink to={`/companies/${organization.id}/transactions`}>
          <FormattedMessage
            id="f517e8ce-9601-4293-8b71-063c90b9a9c5"
            defaultMessage="Transactions"
          />
        </SidebarTabLink>
        <SidebarTabLink to={`/companies/${organization.id}/communication-settings`}>
          <FormattedMessage
            id="26635d0f-1fe4-4d2a-b2c9-244c8e9a795a"
            defaultMessage="Brand customization"
          />
        </SidebarTabLink>
        {canViewPanel && customPanelsEnabled && (
          <SidebarTabLink to={`/companies/${organization.id}/panels`}>
            <FormattedMessage id="26635d0f-1fe4-4d2a-b2c9-244c8e9a795b" defaultMessage="Panels" />
          </SidebarTabLink>
        )}
        {canViewTeamDetails && (
          <SidebarTabButtonDropdown
            activeRoute={MEMBER_MANAGEMENT_ROUTE}
            innerContent={
              <>
                <SidebarTabLink
                  to={`/companies/${organization.id}/member-management/user`}
                  isSubItem
                >
                  <FormattedMessage
                    id="7569b148-99e8-4778-8127-f4f03685c310"
                    defaultMessage="Organization users"
                  />
                </SidebarTabLink>
                {notaryManagementEnabled && (
                  <SidebarTabLink
                    to={`/companies/${organization.id}/member-management/notary/compliant`}
                    isSubItem
                  >
                    <FormattedMessage
                      id="7569b148-99e8-4778-8127-f4f03685c310"
                      defaultMessage="In-house notaries"
                    />
                  </SidebarTabLink>
                )}
              </>
            }
          >
            <FormattedMessage id="63320514-d744-4c47-8315-2fc1d1a0f59d" defaultMessage="Users" />
          </SidebarTabButtonDropdown>
        )}
        <SidebarTabLink to={`/companies/${organization.id}/details`}>
          <FormattedMessage id="c7818211-95b5-40d7-95a4-d443b2150184" defaultMessage="Details" />
        </SidebarTabLink>
        <SidebarTabLink to={`/companies/${organization.id}/pricing-and-billing`}>
          <FormattedMessage
            id="6572806b-f688-45e0-a2a4-e0a1b586fa63"
            defaultMessage="Pricing & billing"
          />
        </SidebarTabLink>
        {!organization.lenderAccess && !organization.titleAgencyAccess ? (
          <SidebarTabLink to={`/companies/${organization.id}/${INTEGRATIONS_ROUTE}/adobe`}>
            <FormattedMessage
              id="cef921c2-2f09-4aa6-98f4-76848ade2be6"
              defaultMessage="Integrations"
            />
          </SidebarTabLink>
        ) : (
          <SidebarTabButtonDropdown
            activeRoute={INTEGRATIONS_ROUTE}
            innerContent={
              <>
                {organization.lenderAccess && (
                  <SidebarTabLink
                    to={`/companies/${organization.id}/${INTEGRATIONS_ROUTE}/encompass`}
                    isSubItem
                  >
                    <FormattedMessage
                      id="8eaa2c7d-087b-4fbe-9a92-966fb2890ea3"
                      defaultMessage="Encompass"
                    />
                  </SidebarTabLink>
                )}
                {organization.titleAgencyAccess && (
                  <SidebarTabLink
                    to={`/companies/${organization.id}/${INTEGRATIONS_ROUTE}/resware`}
                    isSubItem
                  >
                    <FormattedMessage
                      id="b7b13a23-cc78-41d1-9838-0a6432a21f13"
                      defaultMessage="Resware"
                    />
                  </SidebarTabLink>
                )}
                <SidebarTabLink
                  to={`/companies/${organization.id}/${INTEGRATIONS_ROUTE}/adobe`}
                  isSubItem
                >
                  <FormattedMessage
                    id="d418c758-4540-4458-8321-d5c49a67afa2"
                    defaultMessage="Adobe"
                  />
                </SidebarTabLink>
              </>
            }
          >
            <FormattedMessage
              id="09fb8920-5ac6-4085-a780-698ab7e010f8"
              defaultMessage="Integrations"
            />
          </SidebarTabButtonDropdown>
        )}

        <SidebarTabLink to={`/companies/${organization.id}/logs`}>
          <FormattedMessage id="5a9dc62a-4ce3-4ac7-9a6c-d0274e2aba81" defaultMessage="Audit log" />
        </SidebarTabLink>
        {hasPermissionFor("viewOrgFlagsUpdateForm") && (
          <SidebarTabLink to={`/companies/${organization.id}/flags`}>
            <FormattedMessage
              id="2ebf168b-9fb5-4fa3-856c-a00db4fddbd7"
              defaultMessage="Organization flags"
            />
          </SidebarTabLink>
        )}
        {organization.id === organization.rootOrganizationId && (
          <SidebarTabLink to={`/companies/${organization.id}/data-security`}>
            <FormattedMessage
              id="5c4e1377-73ff-45aa-a8ac-bf4a29ff1a75"
              defaultMessage="Data Security"
            />
          </SidebarTabLink>
        )}
      </Sidebar>
      <Outlet />
    </Dashboard>
  );
}

export default function AdminCompanyDetailsWrapperContainer() {
  const { globalID } = useParams();
  const { data, loading } = useQuery(AdminCompanyDetailsWrapperQuery, {
    variables: { organizationId: globalID! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data!.node;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}.`);
  }

  return <AdminCompanyDetailsWrapperLoaded organization={organization} />;
}

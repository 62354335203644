import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type TransactionEventActivityLogFragment = ActivityLog & {
  properties: {
    event: string;
    details: {
      key: string | null;
      value: string | null;
    }[];
  };
};
type Props = {
  activityLog: TransactionEventActivityLogFragment;
};

export const TransactionEventLabel = memo(() => {
  return (
    <FormattedMessage
      id="c96791b8-1fc6-4b52-9b49-6672dc43dbec"
      defaultMessage="Transaction Event"
    />
  );
});

export const TransactionEventSummary = memo(
  ({
    activityLog: {
      properties: { event },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="479a6335-5e51-4962-8e2f-e371a8f2c2d3"
        defaultMessage="{event}"
        values={{ event }}
      />
    );
  },
);

export const TransactionEvent = memo(
  ({
    activityLog: {
      properties: { event, details },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div
          key="event-name"
          data-automation-id="transaction_details"
          className="ActivityLogItemDetailType--list-item"
        >
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="a4a1417f-6795-42a5-b31f-866d690b29ee" defaultMessage="Event:" />
          </span>
          <span className="ActivityLogItemDetailType--nonRangeValue">{event}</span>
        </div>
        {details.map((detail) => {
          const { key, value } = detail;

          return (
            <div
              key={key}
              data-automation-id="transaction_details"
              className="ActivityLogItemDetailType--list-item"
            >
              <span className="ActivityLogItemDetailType--label">{key}:</span>
              <span className="ActivityLogItemDetailType--nonRangeValue">{value}</span>
            </div>
          );
        })}
      </div>
    );
  },
);

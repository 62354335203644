import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { isPast, isFuture } from "date-fns";

import { AutomaticInvoiceType, InvoiceProviderTypes } from "graphql_globals";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { Container } from "common/core/responsive";
import { usePermissions } from "common/core/current_user_role";
import { useQuery } from "util/graphql";

import ChangeTier from "./change_tier";
import TierCard from "./tier_card";
import AvailableTiersQuery from "./available_tiers_query.graphql";
import CommonStyles from "../index.module.scss";
import type {
  AdminCompanyDetailsOrganization_organization_Organization as Organization,
  AdminCompanyDetailsOrganization_organization_Organization_scheduledTiers as Tier,
} from "../organization_query.graphql";

export default function AdminCompanyDetailsPricingAndBillingPricing({
  organization,
}: {
  organization: Organization;
}) {
  const [showChangeTierModal, setShowChangeTierModal] = useState(false);

  const { hasPermissionFor } = usePermissions();
  const canUpdateTier = hasPermissionFor("editCompanyDetailsTier");

  const organizationType = organization.organizationType.toLowerCase();
  const { data, loading } = useQuery(AvailableTiersQuery, {
    variables: { organizationType },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const tiers = data!.viewer.tiers.edges.map((tier) => tier.node);

  const activeTier = organization.scheduledTiers.find((tier: Tier) => {
    return tier.active === true && !tier.replacedAt;
  }) as Tier;
  const pastTiers = organization.scheduledTiers.filter((tier) => {
    return tier.active === false && isPast(new Date(tier.startAt)) && !tier.replacedAt;
  });
  const upcomingTiers = organization.scheduledTiers.filter((tier) => {
    return isFuture(new Date(tier.startAt));
  });

  return (
    <Container fluid style={{ width: "100%" }}>
      <div className={CommonStyles.heading}>
        <FormattedMessage id="f1378140-76d1-4e4a-b34e-892078a77b61" defaultMessage="Pricing" />
        <Button
          disabled={!canUpdateTier}
          buttonColor="action"
          variant="primary"
          onClick={() => {
            setShowChangeTierModal(true);
          }}
          automationId="change-tier-button"
        >
          <FormattedMessage
            id="0b32fcd0-b177-4915-89e7-2178456ce49b"
            defaultMessage="Change tier"
          />
        </Button>
      </div>
      {showChangeTierModal && (
        <ChangeTier
          organizationId={organization.id}
          hasBatchPayment={organization.automaticInvoiceType === AutomaticInvoiceType.BATCHED}
          hasInvoiceProvider={organization.defaultInvoiceProvider !== InvoiceProviderTypes.NOTARIZE}
          activeTier={activeTier}
          tiers={tiers}
          pricingDisplayNames={data!.viewer.pricingDisplayNames}
          optionDisplayNames={data!.viewer.optionDisplayNames}
          onCancel={() => {
            setShowChangeTierModal(false);
          }}
        />
      )}
      <div className={CommonStyles.tiersContainer}>
        <div className={CommonStyles.activeTier}>
          <div className={CommonStyles.subheading}>
            <FormattedMessage
              id="17b9ea90-5d00-4085-a524-e02ace516a89"
              defaultMessage="Active tier"
            />
          </div>
          <TierCard orgId={organization.id} tier={activeTier} cardType="active" />
        </div>
        <div>
          {upcomingTiers.length > 0 && (
            <>
              <div className={CommonStyles.subheading}>
                <FormattedMessage
                  id="c5e74bb8-a27e-4990-a562-41b6acde61da"
                  defaultMessage="Upcoming tiers"
                />
              </div>
              {upcomingTiers.map((tier) => {
                return (
                  <TierCard orgId={organization.id} tier={tier} cardType="upcoming" key={tier.id} />
                );
              })}
            </>
          )}
          {pastTiers.length > 0 && (
            <>
              <div className={CommonStyles.subheading}>
                <FormattedMessage
                  id="3ecc7720-571c-44e6-a3bb-69edd22a9d3f"
                  defaultMessage="Past tiers"
                />
              </div>
              {pastTiers.map((tier) => {
                return (
                  <TierCard orgId={organization.id} tier={tier} cardType="past" key={tier.id} />
                );
              })}
            </>
          )}
        </div>
      </div>
    </Container>
  );
}

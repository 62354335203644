import type { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationDesignationType, DocumentBundleMembershipRole } from "graphql_globals";
import Icon from "common/core/icon";
import { designationTypeAssociatedSelectedUserHandle } from "util/user";

function getIconName(
  designation: {
    signerRole: { role: DocumentBundleMembershipRole };
    type: AnnotationDesignationType;
  },
  isDisabled?: boolean,
): string | null {
  switch (designation.type) {
    case AnnotationDesignationType.COMMISSION_EXPIRY:
      return "expiry";
    case AnnotationDesignationType.COUNTY:
      return "county";
    case AnnotationDesignationType.DATE_SIGNED:
      return "date";
    case AnnotationDesignationType.INITIALS:
      return "initials";
    case AnnotationDesignationType.NOTARY_ID:
      return "id";
    case AnnotationDesignationType.NOTARY_NAME:
      return "name";
    case AnnotationDesignationType.NOTARY_SIGNATURE:
    case AnnotationDesignationType.SIGNATURE:
      return isDisabled ? "signature-disabled" : "signature";
    case AnnotationDesignationType.SEAL:
      return "seal";
    case AnnotationDesignationType.STATE:
      return designation.signerRole.role === DocumentBundleMembershipRole.NOTARY ? "state" : null;
    default:
      return null;
  }
}

export function DesignationIcon(props: {
  designation: Parameters<typeof getIconName>[0];
  isDisabled?: boolean;
  className?: string;
}) {
  const iconName = getIconName(props.designation, props.isDisabled);
  return iconName ? <Icon aria-hidden className={props.className} name={iconName} /> : null;
}

export function DesignationContent({
  designation,
}: {
  designation: {
    signerRole: { role: DocumentBundleMembershipRole };
    hint: Parameters<typeof designationTypeAssociatedSelectedUserHandle>[1];
    type: AnnotationDesignationType;
  };
}) {
  if (designation.hint) {
    return designationTypeAssociatedSelectedUserHandle(
      designation.type,
      designation.hint,
    ) as ReactElement;
  }
  switch (designation.type) {
    case AnnotationDesignationType.ADDRESS1:
      return (
        <FormattedMessage
          id="07ef4660-1dce-44a2-9600-5840bab91ee4"
          defaultMessage="Address Line 1"
        />
      );
    case AnnotationDesignationType.ADDRESS2:
      return (
        <FormattedMessage
          id="f3ac69e5-d476-4934-9884-f497b9044d24"
          defaultMessage="Address Line 2"
        />
      );
    case AnnotationDesignationType.CITY:
      return <FormattedMessage id="d2b0d635-4f4a-49c3-85ca-bf9ff22c5ac5" defaultMessage="City" />;
    case AnnotationDesignationType.COMMISSION_EXPIRY:
      return (
        <FormattedMessage
          id="fda72763-84dd-4400-b0b8-22d3d45c5ea3"
          defaultMessage="Notary Commission Expiry"
        />
      );
    case AnnotationDesignationType.COMMISSION_COUNTY:
      return (
        <FormattedMessage
          id="e43f86f7-c245-40fe-9630-9ee6b16c644a"
          defaultMessage="Commission County"
        />
      );
    case AnnotationDesignationType.COUNTY:
      return (
        <FormattedMessage
          id="4c9c643b-2a1e-49c3-8224-638164d2f2ff"
          defaultMessage="Notary County"
        />
      );
    case AnnotationDesignationType.DOB:
      return (
        <FormattedMessage
          id="99c16d1b-4b23-4a4f-b798-0e3682f300d3"
          defaultMessage="Date of Birth"
        />
      );
    case AnnotationDesignationType.DATE_SIGNED:
      return (
        <FormattedMessage id="2695aa01-cf18-4978-936f-713dc50a445c" defaultMessage="Today's Date" />
      );
    case AnnotationDesignationType.DAY_SIGNED:
      return <FormattedMessage id="5357b3fb-f054-420e-ac3d-38005bc497f0" defaultMessage="Day" />;
    case AnnotationDesignationType.MONTH_SIGNED:
      return <FormattedMessage id="edbd6638-35fc-418e-bd29-320904366fa8" defaultMessage="Month" />;
    case AnnotationDesignationType.YEAR_SIGNED:
      return <FormattedMessage id="96db2b57-2400-4328-957b-68ed85e37660" defaultMessage="Year" />;
    case AnnotationDesignationType.NAME:
      return (
        <FormattedMessage id="96db2b57-2400-4328-957b-68ed85e37661" defaultMessage="Full Name" />
      );
    case AnnotationDesignationType.EMAIL:
      return <FormattedMessage id="c3c74661-a5e8-4d47-b686-a875cac086a9" defaultMessage="Email" />;
    case AnnotationDesignationType.FIRST_NAME:
      return (
        <FormattedMessage id="6054b471-5a15-476a-bb5a-95feebe8920c" defaultMessage="First Name" />
      );
    case AnnotationDesignationType.FREE_TEXT:
      return (
        <FormattedMessage id="b303c88d-9a1e-4579-ad8e-a8a9c8bf316a" defaultMessage="Fill In Here" />
      );
    case AnnotationDesignationType.LAST_NAME:
      return (
        <FormattedMessage id="7ee173ec-2edd-46c0-be47-345982f15057" defaultMessage="Last Name" />
      );
    case AnnotationDesignationType.MIDDLE_NAME:
      return (
        <FormattedMessage id="3b76b93c-7720-4cea-a12b-85faa988f535" defaultMessage="Middle Name" />
      );
    case AnnotationDesignationType.NOTARY_CITY:
      return (
        <FormattedMessage id="aad21095-b0c8-4662-95d5-84031e9d0eb3" defaultMessage="Notary City" />
      );
    case AnnotationDesignationType.NOTARY_ID:
      return (
        <FormattedMessage id="0be3a401-14dc-4529-9217-9cead1aaeced" defaultMessage="Notary ID" />
      );
    case AnnotationDesignationType.NOTARY_NAME:
      return (
        <FormattedMessage id="2603efcc-e0f7-4290-a00a-acaa7b6337de" defaultMessage="Notary Name" />
      );
    case AnnotationDesignationType.NOTARY_SIGNATURE:
      return (
        <FormattedMessage
          id="df12e448-5c34-499e-b899-6ad558f97216"
          defaultMessage="Notary Signature"
        />
      );
    case AnnotationDesignationType.DISCLOSURE:
      return (
        <FormattedMessage
          id="1fc42c8b-d100-421a-b2fc-1aba3511684c"
          defaultMessage="Notary Disclosure"
        />
      );
    case AnnotationDesignationType.PRINCIPAL_ID_TYPE:
      return (
        <FormattedMessage id="c9b3c3ed-2a4c-4062-9a05-507a89f1eb97" defaultMessage="ID Type" />
      );
    case AnnotationDesignationType.SEAL:
      return <FormattedMessage id="725df1a4-e3d2-4960-9fcc-6a37a5cb1735" defaultMessage="Seal" />;
    case AnnotationDesignationType.STATE:
      if (designation.signerRole.role === DocumentBundleMembershipRole.NOTARY) {
        return (
          <FormattedMessage
            id="008b35cc-7af7-4f94-b304-b7e44b14c0c2"
            defaultMessage="Notary State"
          />
        );
      }
      return <FormattedMessage id="28fff80d-32cb-4d82-b524-57582ce1f706" defaultMessage="State" />;
    case AnnotationDesignationType.CHECKMARK:
    case AnnotationDesignationType.RADIO_CHECKMARK:
      return null;
    case AnnotationDesignationType.ZIP:
      return (
        <FormattedMessage id="b492d624-73c4-45c2-a4d6-47bc4f13cc3e" defaultMessage="ZIP Code" />
      );
    default:
      return designationTypeAssociatedSelectedUserHandle(
        designation.type,
        designation.hint,
      ) as ReactElement;
  }
}

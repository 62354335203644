import { useState } from "react";
import classnames from "classnames";

import { Card, CardHeading, CardSection } from "common/core/card";
import { TextInput } from "common/core/form/text";
import { formatDurationStrict } from "common/core/format/date";
import Button from "common/core/button";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import SaveButton from "common/core/save_button";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useMutation } from "util/graphql";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import { DeprecatedRadioGroupContainer } from "common/form/inputs/radio/radio_group_container";
import OrgFlags from "admin_portal/company/details/org_flags/org_flags_query.graphql";
import type { SettingGroupName } from "graphql_globals";
import SettingValueUpdaterMutation from "common/settingsv2/sidebar_settings/transaction/template_settings/setting_value_update_mutation.graphql";
import { DeprecatedStyledSelectInput } from "common/form/inputs/select";

import SettingGroups from "../group/setting_groups_query.graphql";
import Styles from "./index.module.scss";

enum FieldTypes {
  BOOLEAN = "boolean",
  STRING = "string",
  INTEGER = "integer",
  DECIMAL = "decimal",
  JSON = "json",
  ARRAY = "array",
  ENUM = "enum",
}

type Setting = {
  name: string;
  description: string | null;
  displayName: string;
  valueType: string;
  values: Value[];
  defaultValue: string | boolean | null;
  enumValues: string[] | null;
};

type Value = {
  updatedAt?: string | null;
  value: string | boolean | null;
};

type Props = {
  groupName: SettingGroupName;
  setting: Setting;
  canEdit?: boolean;
  orgId?: string;
};

const getKeyTitle = (key: string) => {
  const split = key.split("_").join(" ");
  return split.charAt(0).toUpperCase() + split.substring(1);
};

function SettingsCard({ groupName, setting, canEdit, orgId }: Props) {
  const {
    name,
    description,
    displayName,
    defaultValue,
    valueType: fieldType,
    values,
    enumValues,
  } = setting;
  let value: string | boolean | null = values[0]?.value;
  if (fieldType === "json" && value) {
    value = JSON.parse(value as string) as string | boolean | null;
  }

  const updatedAt = values[0]?.updatedAt || null;
  const [inputValue, setInputValue] = useState(value);
  const [reason, setReason] = useState("");
  const [editing, setEditing] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(!value);

  const settingsUpdaterMutation = useMutation(SettingValueUpdaterMutation);

  const updateSetting = async () => {
    setLoading(true);
    const inputString = inputValue!.toString();
    let mutationValue;
    if (removing) {
      mutationValue = null;
    } else if (fieldType === FieldTypes.BOOLEAN) {
      mutationValue = inputString || null;
    } else if (
      [FieldTypes.INTEGER, FieldTypes.DECIMAL, FieldTypes.ARRAY].includes(fieldType as FieldTypes)
    ) {
      mutationValue = inputString;
    } else {
      mutationValue = JSON.stringify(inputString);
    }
    try {
      await settingsUpdaterMutation({
        variables: {
          input: {
            name,
            groupName,
            groupId: orgId || null,
            value: mutationValue,
            reason,
          },
        },
        refetchQueries: [
          {
            query: orgId ? OrgFlags : SettingGroups,
            variables: orgId ? { organizationId: orgId } : { groupName },
          },
        ],
      });
      setIsDefault(!mutationValue);
      setEditing(false);
      setLoading(false);
      setReason("");
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: "Setting Updated",
      });
    } catch {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: "Error Updating Setting",
      });
    }
  };

  const handleFieldChange = (newValue: boolean | string | null) => {
    setInputValue(newValue);
    if (newValue === null) {
      setInputValue("");
      setEditing(true);
      setRemoving(true);
    } else {
      setInputValue(newValue);
      setEditing(newValue.toString() !== value?.toString());
      setRemoving(false);
    }
  };

  const defaultValueDisplayText = (defaultValue: boolean | string | null) => {
    if (!defaultValue) {
      return fieldType === FieldTypes.BOOLEAN ? "Off" : "None";
    } else if (defaultValue === "true") {
      return "On";
    } else if (defaultValue === "false") {
      return "Off";
    }
    return defaultValue;
  };

  const defaultPlaceholderText = `${defaultValue?.toString().replace(/"/g, "")} (default)`;
  const inputValueString = inputValue?.toString().replace(/"/g, "");

  const header = (
    <CardHeading>
      <div className={Styles.header}>
        <div className={Styles.settingName}>{displayName}</div>
        {description && (
          <div className={Styles.headerTooltip}>
            <Tooltip
              target={
                <span>
                  <Icon name="question" />
                </span>
              }
              placement="rightBottom"
            >
              <span>{description}</span>
            </Tooltip>
          </div>
        )}
        <div className={Styles.statusPills}>
          {isDefault && <div className={Styles.headerStatusPillDefault}>Default</div>}
          {fieldType === FieldTypes.BOOLEAN && (
            <div
              className={classnames(
                Styles.headerStatusPill,
                value?.toString() === "true" || (!value && defaultValue?.toString() === "true")
                  ? Styles.headerStatusPillOn
                  : Styles.headerStatusPillOff,
              )}
            >
              {value?.toString() === "true" || (!value && defaultValue?.toString() === "true")
                ? "On"
                : "Off"}
            </div>
          )}
        </div>
      </div>
    </CardHeading>
  );

  return (
    <Card
      automationId={`${name}-card`}
      header={header}
      footer={
        canEdit &&
        fieldType !== FieldTypes.JSON && (
          <>
            {value && fieldType !== FieldTypes.BOOLEAN && (
              <Button
                automationId={"reset-button"}
                className={Styles.resetButton}
                onClick={() => handleFieldChange(null)}
              >
                Clear value (default: {defaultValueDisplayText(defaultValue)})
              </Button>
            )}
            <SaveButton onClick={updateSetting} isLoading={loading} disabled={reason === ""} />
          </>
        )
      }
      collapsibleElement="icon"
    >
      {updatedAt && (
        <div className={Styles.timestamp}>
          Last updated {formatDurationStrict({ from: updatedAt, to: new Date() })} ago
        </div>
      )}
      <div className={Styles.formArea}>
        {fieldType === FieldTypes.BOOLEAN && (
          <DeprecatedRadioGroupContainer className={Styles.radioContainer}>
            <DeprecatedRadioButton
              className={Styles.radioButton}
              aria-describedby={`${displayName}-instructions`}
              radioValue="true"
              labelText={"On"}
              onChange={() => handleFieldChange(true)}
              groupValue={inputValue?.toString()}
              disabled={!canEdit}
            />
            <DeprecatedRadioButton
              className={Styles.radioButton}
              aria-describedby={`${displayName}-instructions`}
              radioValue="false"
              labelText={"Off"}
              onChange={() => handleFieldChange(false)}
              groupValue={inputValue?.toString()}
              disabled={!canEdit}
            />
            <DeprecatedRadioButton
              className={Styles.radioButton}
              aria-describedby={`${displayName}-instructions`}
              radioValue={""}
              labelText={`Default (${defaultValueDisplayText(defaultValue)})`}
              onChange={() => handleFieldChange(null)}
              groupValue={inputValue?.toString() || ""}
              disabled={!canEdit}
            />
          </DeprecatedRadioGroupContainer>
        )}
        {fieldType === FieldTypes.STRING && (
          <TextInput
            aria-invalid={false}
            value={inputValueString}
            disabled={!canEdit}
            placeholder={defaultPlaceholderText}
            onChange={(evt) => handleFieldChange(evt.target.value)}
            className={`config-value-field`}
          />
        )}
        {fieldType === FieldTypes.ARRAY && (
          <TextInput
            aria-invalid={false}
            value={inputValue?.toString()}
            disabled={!canEdit}
            placeholder={defaultPlaceholderText}
            onChange={(evt) => handleFieldChange(evt.target.value)}
            className={`config-value-field`}
          />
        )}
        {fieldType === FieldTypes.JSON && typeof value === "object" && !Array.isArray(value) && (
          <div>
            {Object.entries(value!).map(([key, settingValue]) => {
              return (
                <CardSection key={key}>
                  <CardHeading>{getKeyTitle(key)}</CardHeading>
                  {Object.entries(settingValue).map(([key, settingValue]) => {
                    return (
                      <div className={Styles.fieldContainer} key={key}>
                        <div className={Styles.fieldLabel}>{getKeyTitle(key)}</div>
                        {(typeof settingValue === "string" || typeof settingValue === "object") && (
                          <TextInput
                            aria-invalid={false}
                            value={settingValue?.toString().replace(/"/g, "") || "null"}
                            className={`config-value-field`}
                            readOnly
                          />
                        )}
                      </div>
                    );
                  })}
                </CardSection>
              );
            })}
          </div>
        )}
        {fieldType === FieldTypes.JSON &&
          (!value || value === "{}" || value === "{} (default)" || Array.isArray(value)) && (
            <TextInput
              aria-invalid={false}
              value={inputValueString}
              disabled={!canEdit}
              placeholder={defaultPlaceholderText}
              onChange={(evt) => handleFieldChange(evt.target.value)}
              className={`config-value-field`}
            />
          )}
        {(fieldType === FieldTypes.INTEGER || fieldType === FieldTypes.DECIMAL) && (
          <TextInput
            value={inputValueString}
            aria-invalid={false}
            disabled={!canEdit}
            placeholder={defaultPlaceholderText}
            onChange={(evt) => handleFieldChange(evt.target.value)}
            className={`config-value-field`}
          />
        )}
        {fieldType === FieldTypes.ENUM && (
          <DeprecatedStyledSelectInput
            value={{ value: inputValueString, label: inputValueString }}
            items={enumValues?.map((enumValue) => {
              return { value: enumValue, label: enumValue };
            })}
            searchable={false}
            clearable={false}
            onChange={(value: string) => handleFieldChange(value)}
            disabled={!canEdit}
          />
        )}
      </div>
      {editing && (
        <TextInput
          className={Styles.reasonField}
          placeholder="Reason for change"
          value={reason}
          aria-invalid={false}
          onChange={(evt) => setReason(evt.target.value)}
        />
      )}
    </Card>
  );
}

export default SettingsCard;

import { FormattedMessage } from "react-intl";

import { DeprecatedDateTimeField } from "common/form/fields/datetime";
import { LongFormattedDateTime } from "common/core/format/date";
import { composeValidators } from "util/form";
import { validatePresence, validateIf, validateTime, validateOption } from "validators/form";
import Button from "common/core/button";

type Props = {
  fieldPrefix: string;
  showEditTimes?: boolean;
  isEditing: boolean;
  handleEditAction: () => void;
  time: string;
  timezone: string;
  handleSave: () => void;
  formValues: Record<string, unknown>;
};

export function validationRules(props: { fieldPrefix?: string }) {
  return composeValidators(
    // formats
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validateTime({
        field: `${props.fieldPrefix}Hour`,
        label: "Time",
        hourField: `${props.fieldPrefix}Hour`,
        minuteField: `${props.fieldPrefix}Minutes`,
        meridiemField: `${props.fieldPrefix}Meridiem`,
      }),
    }),

    // presence
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Hour`, label: "Hour" }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Minutes`, label: "Minute" }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validateOption({
        field: `${props.fieldPrefix}Meridiem`,
        label: "AM/PM",
        format: { AM: "AM", PM: "PM" },
      }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Date`, label: "Date" }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Timezone`, label: "Timezone" }),
    }),
  );
}

function DateTimeFormElements({
  showEditTimes = true,
  isEditing,
  handleEditAction,
  fieldPrefix,
  time,
  timezone,
  handleSave,
  formValues,
}: Props) {
  const formFields = isEditing ? (
    // @ts-expect-error -- this is typed incorrectly from javascript
    <DeprecatedDateTimeField
      formValues={formValues}
      fieldPrefix={fieldPrefix}
      containerClassname="form-fields"
      automationPrefix={fieldPrefix}
    />
  ) : null;

  const formAction = isEditing ? (
    <Button
      variant="tertiary"
      buttonColor="action"
      buttonSize="condensed"
      data-automation-id="datetimes-done-editing-button"
      onClick={handleSave}
    >
      <FormattedMessage id="44cb6e39-2590-43df-ae10-cae856f892b7" defaultMessage="Done" />
    </Button>
  ) : (
    <Button
      variant="tertiary"
      buttonColor="action"
      buttonSize="condensed"
      data-automation-id="datetimes-edit-button"
      onClick={handleEditAction}
    >
      <FormattedMessage id="fa995ad4-a04c-4a49-8651-67823b8d611a" defaultMessage="Edit" />
    </Button>
  );

  return (
    <div>
      <span data-automation-id="datetime-label">
        <LongFormattedDateTime value={time} asTimeZone={timezone} />
      </span>
      {showEditTimes && formAction}
      {formFields}
    </div>
  );
}

export default DateTimeFormElements;

import type { DocumentNode } from "graphql";

import { useApolloClient } from "util/graphql";
import { MobilePdfToolbar, MobilePdfTool, MobilePdfAssetTool } from "common/mobile_pdf";
import SROnly from "common/core/screen_reader";
import type { SigningAssets } from "common/signer/utils";
import { AnnotationSubtype, type SignatureMethod } from "graphql_globals";
import { useToolbar } from "common/meeting/context/toolbar";
import type { GraphicCache, useGraphicCache } from "common/meeting/context/graphic_cache";
import { updateMeetingParticipantSigningAssets } from "common/meeting/pdf/annotation/asset";
import { SIGNER_TOOLS_CONFIG, WITNESS_TOOLS_CONFIG } from "common/meeting/toolbar/tools";
import type { VectorGraphicSubtype } from "common/pdf/interaction";
import { forceAssetRecreation, type SignatureOptionsOrganization } from "util/signature_options";

import type {
  BeholderHeader_meetingParticipants_SignerParticipant as SignerParticipant,
  BeholderHeader_meetingParticipants_WitnessParticipant as WitnessParticipant,
  BeholderHeader_meetingParticipants_IdentityVerifiedWitnessParticipant as IdentityVerifiedWitnessParticipant,
} from "../beholder/header/index_fragment.graphql";

type Props = {
  meetingQuery: DocumentNode;
  onDone: () => void;
  getAsset: (
    vectorGraphicSubtype: VectorGraphicSubtype,
    participant: {
      id: string;
      userId: string | null;
      signingAssets: SigningAssets;
    } & (
      | {
          __typename: "WitnessParticipant";
          witnessProfileId: string;
        }
      | {
          __typename: "IdentityVerifiedWitnessParticipant";
          witnessProfileId: string;
        }
      | {
          __typename: "SignerParticipant";
          signerIdentityId: string;
        }
    ),
    cache: GraphicCache,
    createNew?: boolean,
  ) => Promise<{
    font: string | null;
    method: SignatureMethod | null;
    key: string;
    url: string;
    size: { height: number; width: number };
  } | null>;
  assetCache: ReturnType<typeof useGraphicCache>;
  currentPenHolderParticipant:
    | SignerParticipant
    | WitnessParticipant
    | IdentityVerifiedWitnessParticipant;
  meetingId: string;
  document: {
    id: string;
    annotations: {
      edges: {
        node: {
          id: string;
          authorId: string;
        };
      }[];
    };
  };
  lockSignerName: boolean;
  organization?: SignatureOptionsOrganization;
};

function MobileMeetingAnnotationsBar({
  meetingId,
  meetingQuery,
  document,
  currentPenHolderParticipant,
  onDone,
  getAsset,
  assetCache,
  lockSignerName,
  organization,
}: Props) {
  const { cache: apolloCache } = useApolloClient();
  const { currentTool, toggleTool } = useToolbar();
  const handleDone = () => {
    toggleTool("");
    onDone();
  };

  const toolList =
    currentPenHolderParticipant.__typename === "SignerParticipant"
      ? SIGNER_TOOLS_CONFIG
      : WITNESS_TOOLS_CONFIG;

  return (
    <MobilePdfToolbar
      currentToolType={currentTool as AnnotationSubtype | null}
      document={document}
      signerIds={[currentPenHolderParticipant.userId!]}
      onDone={handleDone}
    >
      {({ handleToolClick }) => {
        return toolList.map(({ tool, label, iconName, hideIconOnMobile, hideLabelOnMobile }) => {
          const onToolSelect = () => {
            handleToolClick();
            toggleTool(tool);
          };
          const isSelected = currentTool === tool;
          if (tool === AnnotationSubtype.SIGNATURE || tool === AnnotationSubtype.INITIALS) {
            let asset = null;
            if (
              !lockSignerName &&
              !forceAssetRecreation({
                organization,
                signingAssets: currentPenHolderParticipant.signingAssets,
                type: tool,
              })
            ) {
              asset =
                tool === AnnotationSubtype.SIGNATURE
                  ? currentPenHolderParticipant.signingAssets?.signatureAsset?.png
                  : currentPenHolderParticipant.signingAssets?.initialsAsset?.png;
            }
            const onEditAsset = async () => {
              const newAsset = await getAsset(tool, currentPenHolderParticipant, assetCache, true);
              const partialSigningAssets = {
                __typename: "SigningAssets",
                [tool === AnnotationSubtype.SIGNATURE ? "signatureAsset" : "initialsAsset"]: {
                  font: newAsset?.font ?? null,
                  method: newAsset?.method ?? null,
                  __typename: "SigningAsset",
                  png: {
                    url: newAsset?.url ?? null,
                    key: newAsset?.key ?? null,
                    __typename: "SecureUrl",
                  },
                },
              };
              updateMeetingParticipantSigningAssets(meetingQuery, apolloCache, {
                meetingId,
                userId: currentPenHolderParticipant.userId!,
                signingAssets: partialSigningAssets,
              });
            };
            return (
              <MobilePdfAssetTool
                key={tool}
                asset={asset}
                selected={isSelected}
                assetType={tool}
                onEditAsset={onEditAsset}
                onSelect={onToolSelect}
              />
            );
          }
          return (
            <MobilePdfTool
              key={tool}
              iconName={hideIconOnMobile ? null : iconName}
              label={hideLabelOnMobile ? <SROnly>{label}</SROnly> : label}
              selected={isSelected}
              onClick={onToolSelect}
            />
          );
        });
      }}
    </MobilePdfToolbar>
  );
}

export default MobileMeetingAnnotationsBar;

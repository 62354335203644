import PropTypes from "prop-types";
import ReactTextMask, { conformToMask } from "react-text-mask";
import classnames from "classnames";

import {
  DeprecatedStyledTextInput,
  StyledTextInputPropTypes,
  DeprecatedTextInput,
} from "common/form/inputs/text";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import {
  TextInput as CoreTextInput,
  StyledTextInput as StyledCoreTextInput,
} from "common/core/form/text";
import { useId } from "util/html";

const COUNTRY_CODE_MAX_DIGIT = 3;
const MASK_TYPE = {
  US_PHONE: "usPhone",
  COUNTRY_CODE: "countryCode",
  NUMBER: "number",
  SSN: "ssn",
  ZIP: "zip",
};
const usPresetMask = [
  "(",
  " ",
  /[1-9]/,
  /\d/,
  /\d/,
  " ",
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  "-",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const ssnPresetMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export function deprecatedUnmaskPhone(phoneNumber) {
  return phoneNumber.replace(/\+|\D/g, "");
}

export function deprecatedMaskPhone(phoneNumber) {
  return conformToMask(phoneNumber, usPresetMask).conformedValue;
}

function countryCodeMask(input) {
  return ["+", ...numberMask(COUNTRY_CODE_MAX_DIGIT, input)];
}

function numberMask(maxLength, input) {
  input = !input ? "" : input;
  const number = input
    .replace(/\D/g, "")
    .split("")
    .map(() => /\d/);

  return maxLength ? number.slice(0, maxLength) : number;
}

function numberBlanksMask(length) {
  return Array(length).fill(/\d/);
}
function numberBlanksPlaceholder(length) {
  return Array(length).fill("_").join("");
}

/** @deprecated - please use components in common/core/form */
export function DeprecatedMaskedInput(props) {
  const {
    maskType,
    className,
    type,
    mask,
    maxLength,
    placeholder,
    value,
    onChange,
    useStyledInputs,
  } = props;

  let presetType;
  let presetMask;
  let presetPlaceholder;

  switch (maskType) {
    case MASK_TYPE.US_PHONE:
      presetType = "tel";
      presetMask = usPresetMask;
      presetPlaceholder = "( ___ ) ___ - ____";
      break;
    case MASK_TYPE.COUNTRY_CODE:
      presetType = "tel";
      presetMask = countryCodeMask;
      break;
    case MASK_TYPE.NUMBER:
      presetType = "tel";
      presetMask = numberMask.bind(null, maxLength);
      break;
    case MASK_TYPE.SSN:
      presetMask = ssnPresetMask;
      presetPlaceholder = "___ - __ - ____";
      break;
    case MASK_TYPE.ZIP:
      presetType = "tel";
      presetMask = numberBlanksMask(5);
      presetPlaceholder = numberBlanksPlaceholder(5);
      break;
    default:
      break;
  }

  const cx = classnames(SENSITIVE_CLASS, className);
  const inputId = useId();

  return (
    <ReactTextMask
      {...props}
      className={cx}
      type={type || presetType}
      mask={mask || presetMask || false}
      placeholder={placeholder || presetPlaceholder}
      value={value}
      onChange={onChange}
      render={(ref, maskProps) => {
        const {
          onReturn,
          maxLength,
          maskType,
          useStyledInputs: _innerUseStyledInputs,
          plainPlaceholder,
          useCoreFormInput,
          label,
          ...props
        } = maskProps;

        if (useStyledInputs) {
          return useCoreFormInput ? (
            <StyledCoreTextInput {...props} id={inputId} label={label} ref={ref} />
          ) : (
            <DeprecatedStyledTextInput
              {...props}
              label={label}
              onInputRefSet={ref}
              plainPlaceholder
            />
          );
        }
        return useCoreFormInput ? (
          <CoreTextInput {...props} id={inputId} ref={ref} />
        ) : (
          <DeprecatedTextInput {...props} label={label} onInputRefSet={ref} />
        );
      }}
    />
  );
}

DeprecatedMaskedInput.propTypes = {
  ...StyledTextInputPropTypes,
  maskType: PropTypes.oneOf(Object.values(MASK_TYPE)),
  mask: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  maxLength: PropTypes.number,
  useStyledInputs: PropTypes.bool,
  displayRequiredAsterisk: PropTypes.bool,
  label: PropTypes.node,
  useCoreFormInput: PropTypes.bool,
};

DeprecatedMaskedInput.defaultProps = {
  maskType: null,
  mask: null,
  maxLength: null,
  useStyledInputs: true,
  useCoreFormInput: false,
};

import { useState, useEffect, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { TierVisibility } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import AlertMessage from "common/core/alert_message";
import ActionButton from "common/core/action_button";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { fromZonedTime, getDateOrdinal } from "common/core/format/date";
import { pushNotification } from "common/core/notification_center/actions";
import { DeprecatedDatePicker } from "common/form/inputs/datepicker";
import { DeprecatedSelectInput } from "common/form/inputs/select";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { useMutation } from "util/graphql";

import UpdateOrganizationTierMutation from "./update_organization_tier_mutation.graphql";
import OrganizationQuery, {
  type AdminCompanyDetailsOrganization_organization_Organization_scheduledTiers as Tier,
} from "../organization_query.graphql";
import TierDetails from "./tier_details";
import Styles from "./change_tier.module.scss";
import type { AdminCompanyDetailsAvailableTiers_viewer_tiers_edges_node as TierOption } from "./available_tiers_query.graphql";

const messages = defineMessages({
  changeTierSuccess: {
    id: "36bcb314-f7ce-4ce7-b446-96f09580eb1a",
    defaultMessage: "Tier change scheduled",
  },
  changeTierError: {
    id: "8913292e-2f05-4e34-9d9b-3d9bf1a763cc",
    defaultMessage: "Error scheduling tier",
  },
});

type Props = {
  organizationId: string;
  activeTier: Tier;
  tiers: TierOption[];
  hasBatchPayment: boolean;
  hasInvoiceProvider: boolean;
  pricingDisplayNames: Record<string, unknown>;
  optionDisplayNames: Record<string, unknown>;
  onCancel: () => void;
};

function renderTierOption(option: { label: string; value: string; visibility: string }) {
  return (
    <div className={Styles.tierChangeOption}>
      <div className={Styles.tierChangeOptionDisplayName}>{option.label}</div>
      <div className={Styles.tierChangeOptionName}>{option.value}</div>
    </div>
  );
}

export default function ChangeTier({
  organizationId,
  activeTier,
  tiers,
  hasBatchPayment,
  hasInvoiceProvider,
  pricingDisplayNames,
  optionDisplayNames,
  onCancel,
}: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTier, setCurrentTier] = useState<Tier | TierOption>(activeTier);
  const [tierName, setTierName] = useState(activeTier.name);
  const [tierDate, setTierDate] = useState<Date | null>(null);
  const [showTierDetails, setShowTierDetails] = useState(false);
  const [showEndOfMonthMessage, setShowEndOfMonthMessage] = useState(false);

  const updateOrganizationTierMutation = useMutation(UpdateOrganizationTierMutation);

  useEffect(() => {
    const selectedTier = tiers.find((tier) => tier.name === tierName);
    selectedTier && setCurrentTier(selectedTier);
  }, [tierName]);

  function getBatchDisplayDate(date: Date): string {
    const adjustedTierDate = new Date(date);
    adjustedTierDate.setDate(date.getDate() + 1);
    return `${adjustedTierDate.getDate().toString()}${getDateOrdinal(adjustedTierDate.getDate())}`;
  }

  function handleTierDateChange(tierDate: Date | null) {
    setShowEndOfMonthMessage(Boolean(tierDate && tierDate.getDate() >= 28));
    setTierDate(tierDate);
  }

  const changeOrganizationTier = async () => {
    setIsLoading(true);
    try {
      await updateOrganizationTierMutation({
        variables: {
          mutationInput: {
            organizationId,
            tierName,
            startAt: tierDate ? fromZonedTime(tierDate, "EST").toISOString() : null,
          },
        },
        refetchQueries: [
          {
            query: OrganizationQuery,
            variables: { organizationId },
          },
        ],
      });
      setTierDate(null);
      setIsLoading(false);
      onCancel();
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.changeTierSuccess),
      });
    } catch {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(messages.changeTierError),
      });
    }
  };

  const activeTierList = [
    {
      label: activeTier.displayName,
      value: activeTier.name,
      visibility: activeTier.visibility,
    },
  ];

  const tiersByOrgType = tiers
    .filter(
      (tier) =>
        // already have active tier in the list
        tier.name !== activeTier.name && tier.visibility === TierVisibility.PUBLIC,
    )
    .map((tier) => ({
      label: tier.displayName,
      value: tier.name,
      visibility: tier.visibility,
    }));

  // combine active tier and applicable non-active tiers
  const tiersList = [...activeTierList, ...tiersByOrgType];

  return (
    <WorkflowModal
      className={Styles.changeTierModal}
      closeBehavior={{ tag: "without-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="a7885a41-d585-4c03-bcbd-29db00411e41"
          defaultMessage="Upcoming tiers"
        />
      }
      buttons={[
        <Button
          key="cancel"
          variant="tertiary"
          color="dark"
          onClick={onCancel}
          automationId="close-change-tier-modal-button"
        >
          <FormattedMessage id="95c69e18-a127-452a-bfc5-b72066eed74b" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          onClick={changeOrganizationTier}
          isLoading={isLoading}
          automationId="change-tier-save-button"
        >
          <FormattedMessage id="95c69e18-a127-452a-bfc5-b72066eed74b" defaultMessage="Save" />
        </Button>,
      ]}
      footerSeparator
    >
      <DeprecatedSelectInput
        automationId="tier-change-select"
        items={tiersList}
        onChange={(newTierName: string) => setTierName(newTierName)}
        value={tierName}
        valueRenderer={renderTierOption}
        optionRenderer={renderTierOption}
        autosize={false}
        disabled={isLoading}
        searchable
      />

      <>
        <div className={Styles.tierChangeStartAt}>
          <h2>
            <FormattedMessage id="c9be7da9-76da-424e-9f12-075c6b93be69" defaultMessage="Start at" />
          </h2>
          <div className={Styles.tierChangeDatePicker}>
            <DeprecatedDatePicker
              value={tierDate}
              automationId="tier-date-toggle"
              placeholder={
                <FormattedMessage id="4f11112f-72c9-472a-9ec5-7cea02100757" defaultMessage="Now" />
              }
              onChange={(newTierDate) => handleTierDateChange(newTierDate)}
            />
            {tierDate && (
              <p className="tier-change--time-note">
                <FormattedMessage
                  id="e10f7378-d3cb-4c8a-aedf-502d93527b22"
                  defaultMessage="Start time is midnight EST"
                />
              </p>
            )}
          </div>
        </div>
        {showEndOfMonthMessage && (
          <AlertMessage kind="info">
            <FormattedMessage
              id="9b17a43f-c0c2-46c5-8978-57caa91d8248"
              defaultMessage="As of July 1st 2022, by selecting this date the org will be billed on the last day of each month."
            />
          </AlertMessage>
        )}
        {hasBatchPayment && tierDate && (
          <AlertMessage kind="info">
            <FormattedMessage
              id="347b03bd-a8d2-4ed7-9bd9-aac74dcbe437"
              defaultMessage="For organizations using batching, {paymentsOrInvoices} will be sent out on the {batchDisplayDate} of each month"
              values={{
                batchDisplayDate: getBatchDisplayDate(tierDate),
                paymentsOrInvoices: hasInvoiceProvider ? "invoices" : "payments",
              }}
            />
          </AlertMessage>
        )}
      </>
      <div className={Styles.detailsToggle}>
        <ActionButton onClick={() => setShowTierDetails(!showTierDetails)}>
          <FormattedMessage
            id="8e8dd095-ea1e-4887-8a45-d5de35151bb7"
            defaultMessage="Tier details"
          />
          <Icon name={showTierDetails ? "caret-up" : "caret-down"} />
        </ActionButton>
      </div>
      {showTierDetails && (
        <TierDetails
          tier={currentTier.tier}
          prices={currentTier.prices}
          showHeaders={false}
          pricingDisplayNames={pricingDisplayNames as Record<string, ReactNode>}
          optionDisplayNames={optionDisplayNames as Record<string, ReactNode>}
          pricesJson={currentTier.pricesJson}
        />
      )}
    </WorkflowModal>
  );
}

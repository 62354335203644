import { useMemo, useState, type ReactNode } from "react";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { useMutation } from "util/graphql";
import type { PageTypes } from "graphql_globals";
import Env from "config/environment";
import { isOptimistic } from "common/meeting/pdf/annotation";
import { Hidden } from "common/core/responsive";
import LoadingIndicator from "common/core/loading_indicator";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";

import RotateDocumentMutation from "./rotate_mutation.graphql";
import { ZoomInButton, ZoomOutButton, FitToWidthButton } from "../zoom";
import RotateButton from "../rotate_button";
import { PageNumber } from "../page_number";
import type { LoadOptions, PageInfo } from "..";
import { PDFBase, PageNavigationToggleButton } from "../util";
import type { PspdfkitDocument as ApolloDocument } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

const getErrorMessage = (requestError: string, pageIndex: number) => {
  switch (requestError) {
    case "cannot_rotate_with_existing_annotations_or_designations":
      return "Cannot rotate page with existing fields";
    case "enotes_and_consent_forms_cannot_be_rotated":
      return "eNotes and consent forms cannot be rotated";
    case "invalid_rotation_pages_or_degrees":
      return "Invalid rotation pages or degrees";
    default:
      return `Page ${pageIndex + 1} could not be rotated`;
  }
};

type Document = Omit<ApolloDocument, "versionedLooseLeafCertificates"> & {
  versionedLooseLeafCertificates?: ApolloDocument["versionedLooseLeafCertificates"];
};

type Props = {
  children?: ReactNode;
  document: Document;
  readOnly?: boolean;
  onPagePress?: LoadOptions["onPagePress"];
  onSelectedAnnotationOrDesignationChange?: LoadOptions["onSelectedAnnotationOrDesignationChange"];
  onPermissionError?: LoadOptions["onPermissionError"];
  stableRenderPageSplits?: LoadOptions["renderPageSplits"];
  onAnnotationWillChange?: LoadOptions["onAnnotationWillChange"];
  className?: string;
  hideToolbar?: boolean;
  pdfEventOptions?: LoadOptions["pdfEventOptions"];
  setPageInfoCb?: (
    pageInfo: {
      pageInfo: PageInfo;
      documentId: string;
    } | null,
  ) => void;
};

type ControlledRotationProps = Props & {
  handleRotate: () => void;
  pageInfo: PageInfo | null;
  setPageInfo: (newInfo: PageInfo | null) => void;
  loading: boolean;
};

const { apiHost } = Env;
const MESSAGES = defineMessages({
  toolbarLabel: {
    id: "7273704e-079e-424d-9fc8-057bf07e513f",
    defaultMessage: "PDF document controls",
  },
});

function getDocumentUrl(doc: Document): string {
  if (doc.isEnote) {
    // Enotes are special. We want to reload them whenever annotations change. Let's abuse
    // this fact by appending the annotations length to the URL so it is unique to the
    // annotation's "version." Annotations cannot be removed on an enote so this should
    // work (famous last words).
    const { id, annotations, annotationDesignations } = doc;
    // Don't include optimistic edges in this count since we want to wait for the mutation to
    // succeed fully before reloading the PDF.
    const designationIdsFulfilledByOptimisticAnnotations = annotations.edges
      .filter(({ node }) => isOptimistic(node) && node.annotationDesignationId)
      .map(({ node }) => node.annotationDesignationId);
    const fulfilledDesignations = annotationDesignations.edges.filter(
      ({ node }) =>
        node.fulfilled && !designationIdsFulfilledByOptimisticAnnotations.includes(node.id),
    );
    return `${apiHost}/documents/${id.slice(2)}/enote.pdf?version=${fulfilledDesignations.length}`;
  }
  return doc.s3OriginalAsset!.url!;
}

function getStableDocumentUrl(doc: Document): string {
  const url = getDocumentUrl(doc);
  if (doc.isEnote) {
    // For enotes, the "stable" url is the normal URL.
    return url;
  }
  // Document s3 urls change all the time because of all the qargs and auth
  // signatures. This just looks at origin and pathname so that these don't
  // trigger react re-renders too often.
  const { origin, pathname } = new window.URL(url);
  return `${origin}/${pathname}/${doc.assetVersion}`;
}

export function PDFDocumentContainerWithControlledRotation({
  document,
  onPagePress,
  onSelectedAnnotationOrDesignationChange,
  children,
  stableRenderPageSplits,
  onPermissionError,
  readOnly,
  onAnnotationWillChange,
  className,
  hideToolbar,
  handleRotate,
  setPageInfo,
  pageInfo,
  loading,
  pdfEventOptions,
}: ControlledRotationProps) {
  const { versionedLooseLeafCertificates } = document;
  const hasAnnotationsOrDesignations =
    document.annotationDesignations.edges.length !== 0 || document.annotations.edges.length !== 0;

  const intl = useIntl();
  const documentUrl = useMemo(() => getDocumentUrl(document), [getStableDocumentUrl(document)]);
  const stableLooseLeafs = useMemo(
    () =>
      versionedLooseLeafCertificates?.map((llp) => ({
        url: llp.asset.url!,
        pageType: llp.actType as unknown as PageTypes,
      })),
    [versionedLooseLeafCertificates?.map((cert) => cert.actType).join("")],
  );
  return (
    <div className={classnames(Styles.pdfContainerWithZoom, className)}>
      <div className={Styles.pageNumberContainer}>
        {pageInfo !== null && (
          <PageNumber index={pageInfo.pageIndex} totalCount={pageInfo.totalPages} />
        )}
      </div>
      {!hideToolbar && (
        <div
          role="toolbar"
          aria-label={intl.formatMessage(MESSAGES.toolbarLabel)}
          className={Styles.zoomControlContainer}
        >
          <PageNavigationToggleButton />
          <Hidden xs>
            <ZoomInButton />
            <ZoomOutButton />
          </Hidden>
          {!loading && pageInfo !== null && (
            <RotateButton
              disabled={hasAnnotationsOrDesignations}
              onClick={() => (handleRotate as () => void)()}
            />
          )}
          <FitToWidthButton />
        </div>
      )}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <PDFBase
          className={Styles.pdfContainer}
          stableMainPdfUrl={documentUrl}
          stableAppendedPdfs={stableLooseLeafs}
          initialLoadPage={pageInfo?.pageIndex}
          onPagePress={onPagePress}
          onSelectedAnnotationOrDesignationChange={onSelectedAnnotationOrDesignationChange}
          onPageChange={setPageInfo}
          onPermissionError={onPermissionError}
          stableRenderPageSplits={stableRenderPageSplits}
          readOnly={readOnly}
          onAnnotationWillChange={onAnnotationWillChange}
          pdfEventOptions={pdfEventOptions}
        />
      )}
      {children}
    </div>
  );
}

export const usePageRotate = ({
  pageInfoContainer,
  setIsPDFLoading,
  hasAnnotationsOrDesignations,
}: {
  pageInfoContainer: {
    pageInfo: PageInfo;
    documentId: string;
  } | null;
  hasAnnotationsOrDesignations: boolean;
  setIsPDFLoading?: (loading: boolean) => void;
}) => {
  const rotateDocFn = useMutation(RotateDocumentMutation);

  return async () => {
    const pageIndex = pageInfoContainer?.pageInfo.pageIndex;
    const documentId = pageInfoContainer?.documentId;
    if (pageIndex === undefined || !documentId) {
      return;
    }

    if (hasAnnotationsOrDesignations) {
      pushNotification({
        position: "topCenter",
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: getErrorMessage(
          "cannot_rotate_with_existing_annotations_or_designations",
          pageIndex,
        ),
      });
      return;
    }

    setIsPDFLoading?.(true);
    try {
      await rotateDocFn({
        variables: {
          input: {
            documentId,
            degrees: 90,
            pages: [pageIndex], // eventually can send as many pages as we need in one call
          },
        },
      });
    } catch (err) {
      const errorMessage = getErrorMessage((err as Error).message, pageIndex);
      pushNotification({
        position: "topCenter",
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: errorMessage,
      });
    }
    setIsPDFLoading?.(false);
  };
};

/** Display a PDF from a document with standard controls */
export function PDFDocumentContainer({
  document,
  onPagePress,
  onSelectedAnnotationOrDesignationChange,
  children,
  stableRenderPageSplits,
  onPermissionError,
  readOnly,
  onAnnotationWillChange,
  className,
  hideToolbar,
  setPageInfoCb,
}: Props) {
  const [pageInfoContainer, setPageInfoContainer] = useState<{
    pageInfo: PageInfo;
    documentId: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const setPageInfo = (pageInfo: PageInfo | null) => {
    setPageInfoContainer(pageInfo && { pageInfo, documentId: document.id });
    setPageInfoCb?.(pageInfo ? { pageInfo, documentId: document.id } : null);
  };
  const hasAnnotationsOrDesignations =
    document.annotationDesignations.edges.length !== 0 || document.annotations.edges.length !== 0;

  const handleRotate = usePageRotate({
    hasAnnotationsOrDesignations,
    pageInfoContainer,
    setIsPDFLoading: setLoading,
  });
  return (
    <PDFDocumentContainerWithControlledRotation
      document={document}
      // We don't pass page info for stale documents user is no longer looking at
      pageInfo={pageInfoContainer?.documentId === document.id ? pageInfoContainer.pageInfo : null}
      setPageInfo={setPageInfo}
      handleRotate={handleRotate}
      onPagePress={onPagePress}
      onSelectedAnnotationOrDesignationChange={onSelectedAnnotationOrDesignationChange}
      stableRenderPageSplits={stableRenderPageSplits}
      onPermissionError={onPermissionError}
      readOnly={readOnly}
      onAnnotationWillChange={onAnnotationWillChange}
      className={className}
      hideToolbar={hideToolbar}
      loading={loading}
    >
      {children}
    </PDFDocumentContainerWithControlledRotation>
  );
}

import { useIntl, defineMessages } from "react-intl";

import Icon from "common/core/icon";
import { isiOSDevice } from "util/support";
import type { LocalParty, RemoteParty } from "common/video_conference";
import { Volume } from "common/tech_check/audio/audio_indicator";

import Styles from "./audio_status.module.scss";

type Props = {
  party: LocalParty<{ fullName: string | null }> | RemoteParty<{ fullName: string | null }>;
};

const MESSAGES = defineMessages({
  local: {
    id: "4bc8f5ca-8153-4013-95df-6cc78ef2c774",
    defaultMessage: "You are {muted, select, true{} other{not }}muted",
  },
  remote: {
    id: "c0863ff2-c587-4468-9ede-6b9457b74c81",
    defaultMessage: "{name} is {muted, select, true{} other{not }}muted",
  },
});

function VolumeContainer({ party }: { party: Props["party"] }) {
  const volume = party.useVolume();

  return <Volume volume={volume} />;
}

function ParticipantAudioStatus({ party }: Props) {
  const intl = useIntl();
  const muted = party.useMuted();
  return (
    <span className={Styles.audioStatus}>
      {muted || isiOSDevice() ? (
        <Icon
          aria-label={intl.formatMessage(MESSAGES[party.isLocal ? "local" : "remote"], {
            muted,
            name: party.participants[0].fullName,
          })}
          className={muted ? Styles.muted : Styles.unmuted}
          name={muted ? "microphone-off" : "microphone"}
          size="large"
        />
      ) : (
        <VolumeContainer party={party} />
      )}
    </span>
  );
}

export default ParticipantAudioStatus;

import "./index.scss";

import { Fragment, useEffect, useCallback, type ComponentProps, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import { Hr } from "common/core/horizontal_rule";
import { DeprecatedSelectField } from "common/form/fields/select";
import { DeprecatedTextAreaField } from "common/form/fields/text_area";
import Button from "common/core/button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { LongFormattedDateTime } from "common/core/format/date";
import { DocumentNoteContext } from "graphql_globals";
import { useQuery, useMutation } from "util/graphql";
import { composeValidators, getFormValues } from "util/form";
import { validateIf, validatePresence } from "validators/form";
import compose from "util/compose";

import CreateDocumentNoteMutation from "./create_document_note_mutation.graphql";
import DocumentFeedbackQuery, {
  type DocumentFeedback_document_Document as Document,
  type DocumentFeedback_document_Document_notes as DocumentNote,
} from "./document_feedback_query.graphql";

type Props = {
  documentId: string;
};
type FormValues = {
  category: Category | null;
  text: string | null;
};
type GetFormValueProps = {
  formValues: FormValues;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps & GetFormValueProps;
type FormProps = {
  handleSubmit: InnerProps["handleSubmit"];
  onSubmit: (values: FormValues) => Promise<void>;
  invalid: InnerProps["invalid"];
  submitting: InnerProps["submitting"];
  formValues: InnerProps["formValues"];
  isSubmitting: boolean;
};
enum Category {
  IncorrectDisplayName = "incorrect_display_name",
  IncorrectRequirement = "incorrect_requirement",
  IncorrectDesignationLocation = "incorrect_designation_location",
  IncorrectDesignation = "incorrect_designation",
  SplitIncorrectly = "split_incorrectly",
  Other = "other",
}
type FeedbackListProps = {
  notes: DocumentNote[];
};

const CATEGORY_LABEL = {
  [Category.IncorrectDisplayName]: (
    <FormattedMessage
      id="7061c9a4-b33d-4f56-9a9d-014a14a7b37b"
      defaultMessage="Incorrect display name"
    />
  ),
  [Category.IncorrectRequirement]: (
    <FormattedMessage
      id="917fd1b4-dbd3-42d0-a76d-fef75ec25987"
      defaultMessage="Incorrect requirement (esign / wet)"
    />
  ),
  [Category.IncorrectDesignationLocation]: (
    <FormattedMessage
      id="d7c1613f-8592-4c36-acc4-78925152c1df"
      defaultMessage="Incorrect designation location(s)"
    />
  ),
  [Category.IncorrectDesignation]: (
    <FormattedMessage
      id="3ca04b46-f04d-474a-8928-56d1d9238ff6"
      defaultMessage="Incorrect or missing designation(s)"
    />
  ),
  [Category.SplitIncorrectly]: (
    <FormattedMessage
      id="3ca04b46-f04d-474a-8928-56d1d9238ff7"
      defaultMessage="Split Incorrectly"
    />
  ),
  [Category.Other]: (
    <FormattedMessage id="4132e75d-b046-4d56-b420-ae55a009c168" defaultMessage="Other" />
  ),
};

const CATEGORY_ITEMS = Object.freeze([
  {
    value: Category.IncorrectDisplayName,
    label: CATEGORY_LABEL[Category.IncorrectDisplayName],
  },
  {
    value: Category.IncorrectRequirement,
    label: CATEGORY_LABEL[Category.IncorrectRequirement],
  },
  {
    value: Category.IncorrectDesignationLocation,
    label: CATEGORY_LABEL[Category.IncorrectDesignationLocation],
  },
  {
    value: Category.IncorrectDesignation,
    label: CATEGORY_LABEL[Category.IncorrectDesignation],
  },
  {
    value: Category.SplitIncorrectly,
    label: CATEGORY_LABEL[Category.SplitIncorrectly],
  },
  {
    value: Category.Other,
    label: CATEGORY_LABEL[Category.Other],
  },
]);

const MESSAGES = defineMessages({
  commentPlaceholder: {
    id: "a8455014-de7c-4d42-97bf-c35c5637ec39",
    defaultMessage: "Comment",
  },
});

function validate(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "category", label: "Category" }),
    validateIf({
      field: "text",
      condition: () => values.category === Category.Other,
      validation: validatePresence({
        field: "text",
        label: "Comment",
      }),
    }),
  )(values);
}

function selectOptionRenderer({ label }: { label: string }) {
  return (
    <p className="DocumentFeedback--form--select-option" title={label}>
      {label}
    </p>
  );
}

function FeedbackList(props: FeedbackListProps) {
  const { notes } = props;

  return (
    <div className="DocumentFeedback--feedback-list">
      {notes.map((note, index) => (
        <Fragment key={note.createdAt}>
          {index !== 0 && <Hr className="DocumentFeedback--feedback-list--divider" />}
          <div className="DocumentFeedback--feedback-list--item" role="listitem">
            <p className="DocumentFeedback--feedback-list--item--category">
              {CATEGORY_LABEL[note.category as Category]}
            </p>
            {note.text && (
              <p className="DocumentFeedback--feedback-list--item--text">{note.text}</p>
            )}
            <p className="DocumentFeedback--feedback-list--item--name">{note.userName}</p>
            <p className="DocumentFeedback--feedback-list--item--date">
              <LongFormattedDateTime value={note.createdAt} />
            </p>
          </div>
        </Fragment>
      ))}
    </div>
  );
}

function Form(props: FormProps) {
  const { onSubmit, invalid, isSubmitting, formValues } = props;

  const intl = useIntl();

  return (
    <form>
      <FormGroup
        className="DocumentFeedback--form--group"
        fields={["category"]}
        disableFormRowStyle
      >
        <DeprecatedSelectField
          automationId="feedback-category-select"
          name="category"
          placeholder={
            <FormattedMessage
              id="cd289b1e-70fb-4a42-ada6-8e1dd0f11d69"
              defaultMessage="Add feedback"
            />
          }
          items={CATEGORY_ITEMS}
          optionRenderer={selectOptionRenderer}
          valueRenderer={selectOptionRenderer}
          disabled={isSubmitting}
          clearable={false}
          searchable={false}
          useStyledInput
        />
        <FormGroupErrors fields={["category"]} />
      </FormGroup>

      {formValues.category && (
        <>
          <FormGroup
            className="DocumentFeedback--form--group"
            fields={["text"]}
            disableFormRowStyle
          >
            <DeprecatedTextAreaField
              data-automation-id="feedback-comment"
              className="DocumentFeedback--form--text-area"
              name="text"
              placeholder={intl.formatMessage(MESSAGES.commentPlaceholder)}
              disabled={isSubmitting}
            />
            <FormGroupErrors fields={["text"]} />
          </FormGroup>

          <Button
            onClick={() => {
              onSubmit(formValues);
            }}
            disabled={invalid || isSubmitting}
            isLoading={isSubmitting}
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage id="6f8f5f43-fae9-4b6b-8ea9-7e722026a081" defaultMessage="Submit" />
          </Button>
        </>
      )}
    </form>
  );
}

function DocumentFeedbackContainer(props: InnerProps) {
  const { documentId, handleSubmit, invalid, submitting, formValues, reset } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reset();
  }, [documentId]);

  const { data, loading } = useQuery(DocumentFeedbackQuery, { variables: { documentId } });
  const createDocumentNoteMutateFn = useMutation(CreateDocumentNoteMutation);

  const createDocumentNote = useCallback<ComponentProps<typeof Form>["onSubmit"]>(
    (values) => {
      setIsSubmitting(true);
      return createDocumentNoteMutateFn({
        variables: {
          input: {
            documentId,
            context: DocumentNoteContext.AUTO_SPLIT_TAG,
            category: values.category!,
            text: values.text,
          },
        },
      })
        .then(reset)
        .finally(() => setIsSubmitting(false));
    },
    [documentId],
  );

  return (
    <div className="DocumentFeedback">
      <Form
        onSubmit={createDocumentNote}
        handleSubmit={handleSubmit}
        invalid={invalid}
        submitting={submitting}
        formValues={formValues}
        isSubmitting={isSubmitting}
      />

      {loading ? (
        <LoadingIndicator positionRelative />
      ) : (
        <FeedbackList
          notes={(data!.document as Document).notes.filter(
            (note) => note.context === DocumentNoteContext.AUTO_SPLIT_TAG,
          )}
        />
      )}
    </div>
  );
}

export default compose(
  reduxForm<FormValues, Props>({ form: "documentFeedback", validate }),
  getFormValues<InnerProps>("documentFeedback"),
)(DocumentFeedbackContainer);

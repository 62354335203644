export const PAYMENT_CARD = "CARD";
export const PAYMENT_ACH = "ACH";

export const AVAILABLE_FEATURES = Object.freeze({
  SHOW_PICTURE_IDS: "show_picture_ids",
  CONCURRENT_SIGNING: "concurrent_signing",
  WAITING_ROOM: "waiting_room",
  DOCS_PROVIDED_EASYLINK_EXPT: "doc-preview-login-experiment",
  EASYLINK_DOCUMENT_PARAM_PREVIEW: "easylink-document-param-preview",
});

import PropTypes from "prop-types";

/**
 * Class representing the "Column" in a Multipart "Row."
 *
 * To be used in MultipartRow like this:
 * <MultipartRow>
 *  <MultipartColumn width={2}> <Text/> </MultipartColumn>
 *  <MultipartColumn width={6}> <Text/> </MultipartColumn>
 *  <MultipartColumn width={4}> <Text/> </MultipartColumn>
 * </MultipartRow>
 *
 */

/** @deprecated - please use components in common/core/form */
export function DeprecatedMultipartColumn({ children, width }) {
  return <div className={`MultipartColumn width-${width.toString()}`}>{children}</div>;
}

DeprecatedMultipartColumn.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

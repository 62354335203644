import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import {
  PhotoIdVerificationStatus,
  UserRole,
  ValidationProvider,
  VerificationMethod,
} from "graphql_globals";
import { isCredentialAnalysisRequired } from "util/meeting";
import { AVAILABLE_FEATURES } from "constants/organization";
import { usePermissions } from "common/core/current_user_role";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Icon from "common/core/icon";
import { FormattedValidationSource } from "common/core/format/formatted_validation_source";
import { FormattedVerificationMethod } from "common/core/format/formatted_verification_method";
import { PROOF_TRANSACTIONS } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DescriptionListItem } from "common/core/description_list";
import { PassFailStatus } from "common/transactions/details/common";

import PhotoId from "./photo_id";
import { LivenessCheck } from "./liveness_check";
import { CredentialStatus } from "./credential_status";
import { ValidationStatus } from "./validation_status";
import { BiometricVerificationResults } from "./biometric_verification_results";
import type { SignerIdentityForValidation as SignerIdentity } from "./signer_validation_fragment.graphql";

type Props = {
  signerIdentity: SignerIdentity;
  notaryState?: ComponentProps<typeof CredentialStatus>["notaryState"];
  completionRequirements?: ComponentProps<typeof ValidationStatus>["completionRequirements"];
  organizationFeatures: string[];
  validatedByCredibleWitness?: boolean;
  showIdentityInfo?: boolean;
};

function PersonallyKnownStatus({
  passed,
  isTxnDetailsRedesign,
}: {
  passed?: boolean;
  isTxnDetailsRedesign: boolean;
}) {
  const personallyKnownLabel = (
    <FormattedMessage
      id="3ee464cc-6acc-46df-b17d-b1d1978aff92"
      defaultMessage="Personally Known to Notary"
    />
  );
  const personallyKnownContent = (
    <FormattedMessage
      id="3ee464cc-6acc-46df-b17d-b1d1978aff92"
      defaultMessage="{icon} {passed, select, true{Confirmed} other{Unconfirmed}}"
      values={{
        icon: <Icon name={passed ? "tick" : "x"} />,
        passed: Boolean(passed),
      }}
    />
  );
  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={personallyKnownLabel} definition={personallyKnownContent} />
  ) : (
    <DeprecatedDetailGridRow title={personallyKnownLabel}>
      {personallyKnownContent}
    </DeprecatedDetailGridRow>
  );
}

function CredibleWitnessStatus({ isTxnDetailsRedesign }: { isTxnDetailsRedesign: boolean }) {
  const credibleWitnessLabel = (
    <FormattedMessage
      id="fd666467-e7f5-4876-a02e-6bdc38c4876f"
      defaultMessage="Identification by Credible Witness"
    />
  );
  const credibleWitnessContent = <PassFailStatus status="pass" />;
  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={credibleWitnessLabel} definition={credibleWitnessContent} />
  ) : (
    <DeprecatedDetailGridRow title={credibleWitnessLabel}>
      {credibleWitnessContent}
    </DeprecatedDetailGridRow>
  );
}

function VerificationMethodRow(props: {
  verificationMethod: ComponentProps<typeof FormattedVerificationMethod>["verificationMethod"];
  isTxnDetailsRedesign: boolean;
}) {
  const verificationMethodLabel = (
    <FormattedMessage
      id="9323f990-91bb-4e96-99e9-6247b79ce850"
      defaultMessage="Verification Method"
    />
  );
  const verificationMethodContent = (
    <FormattedVerificationMethod verificationMethod={props.verificationMethod} />
  );

  return props.isTxnDetailsRedesign ? (
    <DescriptionListItem term={verificationMethodLabel} definition={verificationMethodContent} />
  ) : (
    <DeprecatedDetailGridRow title={verificationMethodLabel}>
      {verificationMethodContent}
    </DeprecatedDetailGridRow>
  );
}

function ValidationSource(props: {
  validationSource: ComponentProps<typeof FormattedValidationSource>["validationSource"];
  isTxnDetailsRedesign: boolean;
}) {
  const validationSourceLabel = (
    <FormattedMessage
      id="788edc7e-3d0a-4fc2-b050-2e1cfca9ca0a"
      defaultMessage="Validation Source"
    />
  );
  const validationSourceContent = (
    <FormattedValidationSource validationSource={props.validationSource} />
  );
  return props.isTxnDetailsRedesign ? (
    <DescriptionListItem term={validationSourceLabel} definition={validationSourceContent} />
  ) : (
    <DeprecatedDetailGridRow title={validationSourceLabel}>
      {validationSourceContent}
    </DeprecatedDetailGridRow>
  );
}

function Verification(props: {
  signerIdentity: SignerIdentity;
  notaryState: ComponentProps<typeof CredentialStatus>["notaryState"];
}) {
  const { signerIdentity, notaryState } = props;
  const { photoId } = signerIdentity;
  const esignAuthEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  if (esignAuthEnabled && photoId?.verificationMethod === VerificationMethod.BIOMETRIC) {
    return <BiometricVerificationResults photoId={photoId} />;
  }
  if (
    Boolean(notaryState && isCredentialAnalysisRequired(notaryState)) ||
    photoId?.statusV2 === PhotoIdVerificationStatus.SUCCESS
  ) {
    return <CredentialStatus signerIdentity={signerIdentity} notaryState={notaryState} />;
  }
  return null;
}

function SignerValidationWrapper({
  isTxnDetailsRedesign,
  children,
}: {
  isTxnDetailsRedesign: boolean;
  children: React.ReactNode;
}) {
  return isTxnDetailsRedesign ? (
    <>{children}</>
  ) : (
    <DeprecatedDetailGridSection>{children}</DeprecatedDetailGridSection>
  );
}

function SignerValidation({
  signerIdentity,
  notaryState,
  completionRequirements,
  organizationFeatures,
  validatedByCredibleWitness,
  showIdentityInfo = true,
}: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const { photoId } = signerIdentity;
  const { hasPermissionFor, currentUserRole } = usePermissions();
  const canViewPhotoId =
    (hasPermissionFor("viewPhotoId") ||
      organizationFeatures.includes(AVAILABLE_FEATURES.SHOW_PICTURE_IDS)) &&
    showIdentityInfo;
  const selfiePicture = photoId?.selfiePicture;
  const esignAuthEnabled = useFeatureFlag(PROOF_TRANSACTIONS);

  return (
    <SignerValidationWrapper isTxnDetailsRedesign={isTxnDetailsRedesign}>
      {validatedByCredibleWitness ? (
        <CredibleWitnessStatus isTxnDetailsRedesign={isTxnDetailsRedesign} />
      ) : signerIdentity.personallyKnownToNotary ? (
        <PersonallyKnownStatus
          isTxnDetailsRedesign={isTxnDetailsRedesign}
          passed={signerIdentity.notaryConfirmedPersonallyKnownToNotary}
        />
      ) : (
        <>
          {signerIdentity.kbaRequired && (
            <ValidationStatus completionRequirements={completionRequirements!} />
          )}
          {(currentUserRole !== UserRole.ADMIN || hasPermissionFor("credentialVerification")) && (
            <Verification signerIdentity={signerIdentity} notaryState={notaryState} />
          )}
          {photoId?.verificationMethod && (
            <VerificationMethodRow
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              verificationMethod={photoId.verificationMethod}
            />
          )}
          {photoId?.provider === ValidationProvider.PERSONA && (
            <ValidationSource
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              validationSource={photoId.provider}
            />
          )}
        </>
      )}
      {canViewPhotoId && photoId?.frontPicture && photoId.backPicture && (
        <PhotoId
          signerIdentity={
            signerIdentity as {
              secondaryId: null | { url: string };
              photoId: { frontPicture: string; backPicture: string };
            }
          }
        />
      )}
      {esignAuthEnabled && canViewPhotoId && selfiePicture && (
        <LivenessCheck selfiePicture={selfiePicture} />
      )}
    </SignerValidationWrapper>
  );
}

export default SignerValidation;

import PropTypes from "prop-types";

import { DeprecatedRadioButton } from "common/form/inputs/radio";
import { DeprecatedFieldWrapper } from "common/form/enhancers/redux_form_field_wrapper";

function RadioButtonField(props) {
  const groupValue = props.groupValue ?? props.value;
  return <DeprecatedRadioButton {...props} groupValue={groupValue} />;
}

RadioButtonField.propTypes = {
  ...DeprecatedRadioButton.propTypes,
  // overrides the default radio button prop types
  onChange: PropTypes.func,
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedRadioButtonField = DeprecatedFieldWrapper(RadioButtonField);

import "./index.scss";

import { useState, useCallback, useLayoutEffect, useRef, type ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";
import { merge, map, lastValueFrom } from "rxjs";

import { ProcessingStates } from "graphql_globals";
import Button from "common/core/button";
import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import { Hr } from "common/core/horizontal_rule";
import { useMutation } from "util/graphql";
import { useDocumentPoller } from "common/document/state_poller";

import SplitDocumentMutation from "./split_document_mutation.graphql";
import type { DocumentSplitManager } from "./manager";

type Document = {
  id: string;
  metadata: { pagesTotal: number };
};
type Props = {
  document: Document;
  documentSplitManager: DocumentSplitManager;
};
type DocumentSplitItemProps = {
  split: DocumentSplitManager["splits"][number];
  onRename: DocumentSplitManager["renameDocumentSplit"];
  pagesTotal: number;
  disabled: boolean;
};

function DocumentSplitItem(props: DocumentSplitItemProps) {
  const { split, onRename, pagesTotal, disabled } = props;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    ref.current!.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onRename({ start: split.start, name: event.target.value });
  };

  const start = split.start + 1;
  const end = split.end !== null ? split.end + 1 : pagesTotal;
  const label =
    start === end ? (
      <FormattedMessage
        id="be2de5fb-c64b-44f7-8eaa-58b0335f6e63"
        defaultMessage="Page {page}"
        values={{ page: start }}
      />
    ) : (
      <FormattedMessage
        id="f102c1b4-551f-4301-814f-3ed8b6c54aa7"
        defaultMessage="Pages {start} - {end}"
        values={{ start, end }}
      />
    );

  return (
    <div ref={ref} className="DocumentSplits--split" role="listitem">
      <DeprecatedStyledTextInput
        label={label}
        placeholder={
          <FormattedMessage
            id="6f710e08-d6ee-4ca1-a83d-9889fb01b326"
            defaultMessage="Document name"
          />
        }
        value={split.name || ""}
        onChange={handleChange}
        disabled={disabled}
        autoFocus
      />
    </div>
  );
}

export default function DocumentSplits({ document, documentSplitManager }: Props) {
  const {
    splits,
    clearDocumentSplits,
    renameDocumentSplit,
    onSplitComplete,
    isLoading,
    setIsLoading,
  } = documentSplitManager;
  const startPolling = useDocumentPoller();

  const [error, setError] = useState<string | null>(null);

  const splitDocumentMutateFn = useMutation(SplitDocumentMutation);

  const splitDocument = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const { data } = await splitDocumentMutateFn({
          variables: { input: { documentId: document.id, splits } },
        });
        const allDocStatus$ =
          data!.splitDocument?.documents.map((doc) => startPolling(doc.id)) || [];
        const throwIfFailed$ = merge(...allDocStatus$).pipe(
          map((status) => {
            if (status.processingState === ProcessingStates.FAILED) {
              throw new Error(status.processingError || "Unknown document processing error");
            }
            return status;
          }),
        );
        await lastValueFrom(throwIfFailed$, { defaultValue: null });

        onSplitComplete();
      } catch (e) {
        const graphMsg = (e as { graphQLErrors?: ({ message: string } | undefined)[] })
          .graphQLErrors?.[0]?.message;
        setError(graphMsg || (e as Error).message);
        setIsLoading(false);
      }
    }
  }, [document, splits, isLoading]);

  return (
    <div className="DocumentSplits">
      <p className="DocumentSplits--info">
        <FormattedMessage
          id="d6e01295-ba00-4396-a4a3-2e7440091713"
          defaultMessage="Split this document into several smaller documents"
        />
      </p>

      <div className="DocumentSplits--splits">
        {splits.map((split) => (
          <DocumentSplitItem
            key={split.start}
            split={split}
            onRename={renameDocumentSplit}
            pagesTotal={document.metadata.pagesTotal}
            disabled={isLoading}
          />
        ))}
      </div>

      <div className="DocumentSplits--footer">
        <Hr className="DocumentSplits--divider" />

        <div className="DocumentSplits--buttons">
          <Button
            className="DocumentSplits--split-button"
            onClick={splitDocument}
            isLoading={isLoading}
            buttonColor="action"
            variant="primary"
            fullwidth
          >
            <FormattedMessage id="0e16d79b-bf2f-4b7c-b0cc-094b1caae84e" defaultMessage="Split" />
          </Button>
          <Button
            className="DocumentSplits--cancel-button"
            onClick={clearDocumentSplits}
            disabled={isLoading}
            variant="tertiary"
            buttonColor="action"
            fullwidth
          >
            <FormattedMessage id="4c4281fd-7997-4b2a-8b08-3cd62f654046" defaultMessage="Cancel" />
          </Button>
        </div>

        {error && (
          <p className="DocumentSplits--error" role="alert">
            <FormattedMessage
              id="a3bd97a3-7991-4a2b-a150-61becbb07d6e"
              defaultMessage="Error: {error}"
              values={{ error }}
            />
          </p>
        )}
      </div>
    </div>
  );
}

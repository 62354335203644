import { useState, useMemo, type ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import ActionButton from "common/core/action_button";
import WorkflowModal from "common/modals/workflow_modal";
import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import { FormattedDate } from "common/core/format/date";
import { CardHeading } from "common/core/card";

import Styles from "./index.module.scss";
import type {
  FullAdminOrganizationDetails_organization_Organization as Organization,
  FullAdminOrganizationDetails_organization_Organization_featureFlags as FeatureFlag,
} from "../details_query.graphql";

type Props = {
  organization: Organization;
};

export function FeatureFlags(props: Props) {
  const { organization } = props;

  const [showFlags, setShowFlags] = useState(false);
  const [query, setQuery] = useState("");
  const filteredFlags = useMemo(() => {
    return organization.featureFlags
      .filter((flag) => (flag.name + flag.key).toLowerCase().includes(query.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [organization.featureFlags, query]);
  const [selectedFlag, setSelectedFlag] = useState<FeatureFlag | null>();

  return (
    <>
      <CardHeading>
        <FormattedMessage id="b0c87d13-7e90-4fc4-b836-764402c3e6cf" defaultMessage="LaunchDarkly" />
      </CardHeading>

      <ActionButton onClick={() => setShowFlags(!showFlags)}>
        {showFlags ? (
          <FormattedMessage id="f900f6bf-4cc8-4203-934b-df6ee184533e" defaultMessage="Hide flags" />
        ) : (
          <FormattedMessage id="87c07d07-8bb6-44aa-8d3d-c596d8022df1" defaultMessage="Show flags" />
        )}
      </ActionButton>
      {showFlags && (
        <>
          <div className={Styles.search}>
            <DeprecatedStyledTextInput
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setQuery(e.target.value);
              }}
              value={query}
              placeholder="Search for flag"
              placeholderAsLabel
            />
          </div>
          <div className={Styles.container}>
            {filteredFlags.map((flag) => (
              <div key={flag.key} className={Styles.flag} onClick={() => setSelectedFlag(flag)}>
                <p className={Styles.name}>
                  {flag.name}
                  <span className={Styles.value}>{flag.value}</span>
                </p>
              </div>
            ))}
          </div>
        </>
      )}

      {selectedFlag && (
        <WorkflowModal
          className={Styles.details}
          closeBehavior={{ tag: "with-button", onClose: () => setSelectedFlag(null) }}
          title={selectedFlag.name}
        >
          <p className={Styles.detailsHeader}>
            <FormattedMessage id="2c39590b-4efc-4a5f-9198-d1bdb58ce696" defaultMessage="Value" />
          </p>
          {selectedFlag.value}

          <p className={Styles.detailsHeader}>
            <FormattedMessage
              id="94afddca-6444-461e-8f47-32a07750a05b"
              defaultMessage="Description"
            />
          </p>
          {selectedFlag.description || (
            <FormattedMessage
              id="724684fb-da23-47b5-8c01-7f0b2e30531a"
              defaultMessage="Not provided"
            />
          )}

          <p className={Styles.detailsHeader}>
            <FormattedMessage id="8df35a15-a12a-4e76-a365-8cd2374e398e" defaultMessage="Created" />
          </p>
          <FormattedDate value={selectedFlag.createdAt} />

          <p className={Styles.detailsHeader}>
            <FormattedMessage
              id="742625ac-611a-488d-a8cf-ef715a1fc51d"
              defaultMessage="Manually Targeted"
            />
          </p>
          {selectedFlag.manuallyTargeted.toString()}

          <Link className={Styles.link} href={selectedFlag.link}>
            <FormattedMessage
              id="e03a65c1-eae5-4fb5-9716-edb90c125a6a"
              defaultMessage="View in LaunchDarkly"
            />
          </Link>
        </WorkflowModal>
      )}
    </>
  );
}

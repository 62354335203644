import PropTypes from "prop-types";

import { DeprecatedTextInput, DeprecatedStyledTextInput } from "common/form/inputs/text";
// Allow for current form input styling on old redux components
import {
  TextInput as CoreTextInput,
  StyledTextInput as StyledCoreTextInput,
} from "common/core/form/text";
import { DeprecatedFieldWrapper } from "common/form/enhancers/redux_form_field_wrapper";

// Use this exported component if you would like the styled input, but you do not need the value to be saved in
// Redux-Forms
/** @deprecated - please use components in common/core/form */
export function DeprecatedTextComponent(props) {
  const { useStyledInput, useCoreFormInput, placeholderAsLabel, label, ...otherProps } = props;

  const styledProps = {
    placeholderAsLabel,
    label,
  };

  if (useStyledInput) {
    return useCoreFormInput ? (
      <StyledCoreTextInput {...otherProps} {...styledProps} />
    ) : (
      <DeprecatedStyledTextInput {...otherProps} {...styledProps} />
    );
  }

  return useCoreFormInput ? (
    <CoreTextInput {...otherProps} />
  ) : (
    <DeprecatedTextInput {...otherProps} />
  );
}

DeprecatedTextComponent.propTypes = {
  ...DeprecatedTextInput.propTypes,
  useStyledInput: PropTypes.bool,
  useCoreFormInput: PropTypes.bool,
};

// This is the redux-formsified component
/** @deprecated - please use components in common/core/form */
export const DeprecatedTextField = DeprecatedFieldWrapper(DeprecatedTextComponent);

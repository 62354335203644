import "./index.scss";

import {
  useEffect,
  useState,
  forwardRef,
  type ComponentProps,
  type ChangeEvent,
  type ReactNode,
  type FocusEvent,
  type Ref,
} from "react";
import { isValid, isSameDay } from "date-fns";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { NativeLabel } from "common/core/form/text";
import { Calendar } from "common/core/calendar";
import ClickOutside from "common/core/click_outside";
import { DeprecatedTextInput, DeprecatedStyledTextInput } from "common/form/inputs/text";
import { format } from "common/core/format/date";
import { parse } from "common/core/parse/date";
import SROnly from "common/core/screen_reader";
import { useId } from "util/html";
import { useAriaErrorDescribedId } from "common/core/form/error";

type CalProps = ComponentProps<typeof Calendar>;
type Props = {
  id?: string;
  readOnly?: boolean;
  placeholder?: ComponentProps<typeof DeprecatedStyledTextInput>["placeholder"];
  label?: ReactNode;
  name?: string;
  disabled?: boolean;
  placeholderAsLabel?: boolean;
  displayRequiredAsterisk?: boolean;
  plainPlaceholder?: boolean;
  useStyledInput?: boolean;
  maxDetail?: CalProps["maxDetail"];
  minDate?: CalProps["minDate"] | null;
  maxDate?: CalProps["maxDate"] | null;
  onFocus?: (evt: FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onChange: (newValue: Date | null) => void;
  value: null | Date;
  automationId?: string;
  "aria-label"?: string;
  "aria-describedby"?: string;
  "aria-invalid"?: string;
};

const MESSAGES = defineMessages({
  dateInstruction: {
    id: "31af4d22-9513-48a2-9d83-51aac00828ad",
    defaultMessage: "Please format as MM/DD/YYYY",
  },
});

const FORMAT_STYLE = "P";
const noop = () => {};

function formatDateForInput(value: null | Date): string {
  return format({ value, formatStyle: FORMAT_STYLE }) || "";
}

function DatePicker(props: Props, ref: Ref<HTMLInputElement>) {
  const {
    id,
    value,
    onFocus,
    automationId,
    "aria-label": ariaLabel,
    "aria-invalid": ariaInvalid,
    label,
    displayRequiredAsterisk,
    disabled,
    useStyledInput = false,
  } = props;
  const dateInstructionId = useId();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [textValue, setTextValue] = useState("");

  const nativeLabelElement =
    label && typeof label === "string" && useStyledInput ? (
      <NativeLabel
        {...{ label, displayRequiredAsterisk, disabled, htmlFor: dateInstructionId, ariaInvalid }}
      />
    ) : null;

  const useStyledProps = useStyledInput ? {} : { label };

  const ariaDescribedBy = useAriaErrorDescribedId(props);

  useEffect(() => {
    setTextValue(formatDateForInput(value));
    // Looks like redux form passes empty string, which is not correct:
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  }, [value && value.getTime()]);
  if (props.readOnly) {
    return <DeprecatedTextInput onClick={noop} readOnly value={formatDateForInput(value)} />;
  }

  // this component only calls onChange during onBlur, so always call them together
  function onChangeAndBlur(date: Date | null) {
    props.onChange(date);
    // hook form is not handling onChange and onBlur being called together properly
    // validation is called (upon onBlur) w/o the new value
    // workaround for now is to force onChange to process before onBlur via setTimeout
    // an alternate solution is to fix this one level up, by manually calling setValue
    // but that breaks other things like `deps` (triggering validation of other fields)
    // this workaround keeps things more inline w/ how hook form is supposed to work
    setTimeout(() => {
      // We don't call onblur with evt since it messes with redux form.
      props.onBlur?.();
    }, 0);
  }

  return (
    <ClickOutside onClickOutside={() => setIsOpen(false)}>
      <div className="CalendarInput">
        <SROnly>
          <span id={dateInstructionId}>{intl.formatMessage(MESSAGES.dateInstruction)}</span>
        </SROnly>

        {nativeLabelElement}

        <DeprecatedStyledTextInput
          {...useStyledProps}
          id={id}
          disabled={props.disabled}
          placeholderAsLabel={props.placeholderAsLabel}
          placeholder={props.placeholder}
          plainPlaceholder={props.plainPlaceholder}
          displayRequiredAsterisk={props.displayRequiredAsterisk}
          onFocus={(evt: FocusEvent<HTMLInputElement>) => {
            setIsOpen(true);
            onFocus?.(evt);
          }}
          onBlur={(evt: FocusEvent<HTMLInputElement>) => {
            const textBoxValue = evt.target.value.trim();
            if (!textBoxValue) {
              return onChangeAndBlur(null);
            }
            const parsedDate = parse(textBoxValue, FORMAT_STYLE);

            const valid = isValid(parsedDate);
            if (!valid) {
              setTextValue("");
              onChangeAndBlur(null);
            }

            if (valid && (!value || !isSameDay(parsedDate, value))) {
              onChangeAndBlur(parsedDate);
            }
          }}
          value={textValue}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            setTextValue(evt.target.value);
          }}
          autoComplete="nope"
          automationId={automationId || "date-picker-input"}
          name={props.name}
          aria-describedby={classnames(ariaDescribedBy, dateInstructionId)}
          aria-label={ariaLabel}
          aria-invalid={ariaInvalid}
          innerRef={ref}
        />
        {isOpen && (
          <div className="CalendarInput--Cal">
            <Calendar
              minDate={props.minDate || undefined}
              maxDate={props.maxDate || undefined}
              value={value || undefined}
              maxDetail={props.maxDetail}
              onChange={(value) => {
                const newValue = value as Date | null;
                onChangeAndBlur(newValue);
                setTextValue(format({ value: newValue, formatStyle: FORMAT_STYLE }) || "");
                setIsOpen(false);
              }}
            />
          </div>
        )}
      </div>
    </ClickOutside>
  );
}

/** @deprecated - please use components in common/core/form */
const DatePickerWithRef = forwardRef(DatePicker);

export { DatePickerWithRef as DeprecatedDatePicker };

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import DocumentErrorImage from "assets/images/signer/document-error.svg";

function RegularErrorModal({
  errorString,
  clearErrors,
  title,
}: {
  errorString: ReactNode;
  clearErrors: () => void;
  title?: ReactNode;
}) {
  const buttons = [
    <Button
      buttonColor="action"
      variant="primary"
      onClick={clearErrors}
      key="close-error-modal"
      automationId="close-error-modal"
    >
      <FormattedMessage id="0f7e067b-b0b4-4ec9-8d45-ad97a6144b57" defaultMessage="Continue" />
    </Button>,
  ];

  return (
    <WorkflowModal
      autoFocus
      closeBehavior={{ tag: "with-button", onClose: clearErrors }}
      image={{ src: DocumentErrorImage, alt: "" }}
      buttons={buttons}
      title={
        title || (
          <FormattedMessage id="9fa750c7-8640-4a7f-ac1f-4b875876b551" defaultMessage="Error" />
        )
      }
    >
      {errorString}
    </WorkflowModal>
  );
}

export default RegularErrorModal;

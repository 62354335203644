import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { Badge } from "common/core/badge";
import { Heading, Paragraph } from "common/core/typography";
import Link from "common/core/link";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import EmptyState from "assets/images/defend/identity-tab-empty-state.svg";

import Styles from "./upsell.module.scss";

export function UpsellBanner({ message }: { message: ReactNode }) {
  return (
    <div className={Styles.upsellBanner}>
      <Badge kind="new">
        <FormattedMessage id="51857df5-0ec6-4960-89a8-fd789e7a36f8" defaultMessage="Upgrade" />
      </Badge>
      <Paragraph>{message}</Paragraph>
    </div>
  );
}

export function UpsellEmptyState({
  contactSalesLink,
  heading,
  learnMoreLink,
  message,
}: {
  contactSalesLink: string;
  heading: ReactNode;
  learnMoreLink: string;
  message: ReactNode;
}) {
  return (
    <div className={Styles.emptyState}>
      <img src={EmptyState} alt="" />
      <Heading level="h3" textStyle="headingFour">
        {heading}
      </Heading>
      <Paragraph className={Styles.emptyStateBody} textColor="subtle">
        {message}
      </Paragraph>
      <ButtonStyledLink
        buttonColor="action"
        variant="primary"
        className={Styles.learnMore}
        fullwidth
        href={contactSalesLink}
      >
        <FormattedMessage
          id="de78c9b5-f265-401c-95ab-fe1ea923a0f3"
          defaultMessage="Contact Sales"
        />
      </ButtonStyledLink>
      <Link secondary underlined={false} href={learnMoreLink}>
        <FormattedMessage id="44cb5f15-b6fd-4467-bec4-1f016e756aa7" defaultMessage="Learn more" />
      </Link>
    </div>
  );
}

import { defineMessages, useIntl } from "react-intl";
import type { DocumentNode } from "graphql";

import { pushNotification } from "common/core/notification_center/actions";
import { useMutation } from "util/graphql";
import { updateMeetingParticipantSigningAssets } from "common/meeting/pdf/annotation/asset";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import DeleteSigningsMarkMutation from "./delete_signings_mark_mutation.graphql";

const MESSAGES = defineMessages({
  signingAssetsDeleted: {
    id: "c2db0c6c-4658-4f2e-8fbe-475556f667cf",
    defaultMessage: "We've reset your signature and initials",
  },
  signingAssetsDeleteError: {
    id: "7febbf6a-2121-42af-b616-47480333eeea",
    defaultMessage: "We failed to reset your signature and initials, please try again.",
  },
});

export function useClearSigningAssets(meetingId: string, query: DocumentNode) {
  const intl = useIntl();
  const deleteSigningsMarkMutateFn = useMutation(DeleteSigningsMarkMutation, {
    onCompleted() {
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.signingAssetsDeleted),
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
      });
    },
  });
  return function handleClearAssets(userId: string) {
    const signingAssets = {
      __typename: "SigningAssets",
      signatureAsset: {
        __typename: "SigningAsset",
        png: null,
        font: null,
        method: null,
      },
      initialsAsset: { __typename: "SigningAsset", png: null, font: null, method: null },
    } as const;
    return deleteSigningsMarkMutateFn({
      variables: { input: { userId } },
      optimisticResponse: {
        deleteSigningsMark: {
          __typename: "DeleteSigningsMarkPayload",
          user: {
            id: userId,
            __typename: "User",
            signingAssets,
          },
        },
      },
      update(cacheProxy) {
        updateMeetingParticipantSigningAssets(query, cacheProxy, {
          meetingId,
          userId,
          signingAssets,
        });
      },
    });
  };
}

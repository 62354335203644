import { useState, type ReactNode } from "react";

import { CardHeading } from "common/core/card";
import { BinaryToggle } from "common/core/form/binary_toggle";
import { useId } from "util/html";

type Props = {
  title: ReactNode;
  onChange: () => Promise<unknown>;
  disabled: boolean;
  value: boolean;
  automationId?: string;
};

export function FeatureToggle({ title, onChange, disabled, value, automationId }: Props) {
  const [loading, setLoading] = useState(false);
  const featureToggleLabel = useId();

  function handleOnChange() {
    setLoading(true);
    onChange().finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <CardHeading>
        <span id={featureToggleLabel}>{title}</span>
      </CardHeading>
      <BinaryToggle
        aria-labelledby={featureToggleLabel}
        onChange={handleOnChange}
        value={value}
        disabled={disabled || loading}
        automationId={automationId}
      />
    </>
  );
}

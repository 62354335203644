import "./new_modal.scss";
import { useState, useEffect, type ChangeEvent } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import { useMutation } from "util/graphql";
import AdminExperimentGroups from "admin_portal/experiments/groups";

import CreateExperimentMutation from "./create_experiment_mutation.graphql";
import ExperimentsViewerQuery from "./experiments_viewer_query.graphql";

type Props = {
  onClose: () => void;
};

export default function NewModal({ onClose }: Props) {
  const createExperimentMutateFn = useMutation(CreateExperimentMutation);
  const [experimentValue, setExperimentValue] = useState("");
  const [experimentDescription, setExperimentDescription] = useState("");
  const [showPercentageWarningMessage, setShowPercentageWarningMessage] = useState(false);
  const [showGroupNamingWarningMessage, setShowGroupNamingWarningMessage] = useState<number | null>(
    null,
  );
  const [showValueWarningMessage, setShowValueWarningMessage] = useState(false);
  const [groups, setGroups] = useState([
    {
      value: "control",
      percent: 100,
    },
    {
      value: "",
      percent: 0,
    },
  ]);

  function onNameChange(ev: ChangeEvent<HTMLInputElement>) {
    setExperimentValue(ev.target.value);
  }

  function onDescriptionChange(ev: ChangeEvent<HTMLInputElement>) {
    setExperimentDescription(ev.target.value);
  }

  function onChangeGroupValue(ev: ChangeEvent<HTMLInputElement>, groupNumber: number) {
    const newGroups = groups;
    newGroups[groupNumber].value = ev.target.value;
    setGroups([...newGroups]);
  }

  function onChangeGroupPercentage(ev: ChangeEvent<HTMLInputElement>, groupNumber: number) {
    const parsedInput = parseInt(ev.target.value, 10);
    if (!isNaN(parsedInput)) {
      const newGroups = groups;
      newGroups[groupNumber].percent = parsedInput;
      setGroups([...newGroups]);
    } else if (ev.target.value === "") {
      const newGroups = groups;
      newGroups[groupNumber].percent = 0;
      setGroups([...newGroups]);
    }
  }

  function addGroup() {
    setGroups([
      ...groups,
      {
        value: "",
        percent: 0,
      },
    ]);
  }

  function deleteGroup(index: number) {
    const newGroups = groups;
    newGroups.splice(index, 1);
    setGroups([...newGroups]);
  }

  const totalPercentage = groups.reduce((result, group) => result + group.percent, 0);

  function createExperiment() {
    let hasErrors = false;
    if (experimentValue === "") {
      setShowValueWarningMessage(true);
      hasErrors = true;
    }
    if (totalPercentage > 100) {
      setShowPercentageWarningMessage(true);
      hasErrors = true;
    }
    if (groups.length < 2) {
      hasErrors = true;
    }
    groups.forEach((group, index) => {
      if (group.value === "") {
        setShowGroupNamingWarningMessage(index);
        hasErrors = true;
      }
    });

    if (!hasErrors) {
      createExperimentMutateFn({
        variables: {
          input: {
            name: experimentValue,
            description: experimentDescription ? experimentDescription : null,
            groups,
          },
        },
        refetchQueries: [
          {
            query: ExperimentsViewerQuery,
          },
        ],
      }).then(() => {
        onClose();
      });
    }
  }

  useEffect(() => {
    if (totalPercentage <= 100) {
      setShowPercentageWarningMessage(false);
    }
  }, [groups]);

  useEffect(() => {
    if (experimentValue !== "") {
      setShowValueWarningMessage(false);
    }
  }, [groups]);

  return (
    <WorkflowModal
      title="Your New Experiment"
      titleIcon={{ name: "dob" }}
      footerSeparator={false}
      headerSeparator
      closeBehavior={{ tag: "without-button", onClose }}
      buttons={[
        <Button key="cancel" onClick={onClose} buttonColor="dark" variant="tertiary">
          Cancel
        </Button>,
        <Button
          key="add-experiment"
          onClick={createExperiment}
          buttonColor="action"
          variant="primary"
        >
          Create
        </Button>,
      ]}
    >
      <DeprecatedStyledTextInput
        value={experimentValue}
        onChange={onNameChange}
        placeholder="Name"
        displayRequiredAsterisk
        placeholderAsLabel
      />
      {showValueWarningMessage && (
        <div className="AdminExperimentsNewModal--warning">You need a name for your experiment</div>
      )}
      <div className="AdminExperimentsNewModal--description">
        <DeprecatedStyledTextInput
          value={experimentDescription}
          onChange={onDescriptionChange}
          placeholder="Description"
          placeholderAsLabel
        />
      </div>
      <div className="AdminExperimentsNewModal--section">
        <div>Groups</div>
        <AdminExperimentGroups
          showGroupNamingWarningMessage={showGroupNamingWarningMessage}
          showPercentageWarningMessage={showPercentageWarningMessage}
          groups={groups}
          onChangeGroupValue={onChangeGroupValue}
          onChangeGroupPercentage={onChangeGroupPercentage}
          deleteGroup={deleteGroup}
          addGroup={addGroup}
        />
      </div>
    </WorkflowModal>
  );
}

import "./index.scss";

import { useMemo, useCallback, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl, type IntlShape } from "react-intl";

import { DeprecatedSelectField } from "common/form/fields/select";
import FormGroup from "common/form/group";
import Link from "common/core/link";
import AppSubdomains from "constants/app_subdomains";
import { redirectToSubdomain } from "util/application_redirect";

import { TrialReason } from "../types";

type Props = {
  trialReason?: TrialReason;
};

const messages = defineMessages({
  business: {
    id: "5cf59b3f-d2d4-417a-ac5b-49b7155f40de",
    defaultMessage: "I’m evaluating it for business use",
  },
  title: {
    id: "3a59da5f-7468-45a8-9f0c-a6d8befd5bee",
    defaultMessage: "I’m a title agent and want to do online closings",
  },
  retail: {
    id: "a195f0cb-9e60-4221-9b32-fa0c9e908299",
    defaultMessage: "I just need to notarize a document today",
  },
  tryingBecause: {
    id: "6914b77d-e7e2-41dd-98fe-c70f10939cd6",
    defaultMessage: "I'm trying Proof because...",
  },
});

function getItems(intl: IntlShape) {
  return [
    { value: TrialReason.Business, label: intl.formatMessage(messages.business) },
    { value: TrialReason.TitleAgent, label: intl.formatMessage(messages.title) },
    { value: TrialReason.Retail, label: intl.formatMessage(messages.retail) },
  ];
}

function SignupTrialReasonField(props: Props) {
  const { trialReason } = props;
  const intl = useIntl();
  const items = useMemo(() => getItems(intl), []);

  const goToGuestUpload = useCallback(() => {
    redirectToSubdomain(AppSubdomains.customer, "/signup/upload");
  }, []);

  const goToTitleSignup = useCallback(() => {
    redirectToSubdomain(AppSubdomains.title_agency, "/signup/title-agency");
  }, []);

  return (
    <>
      <FormGroup fields={["trialReason"]}>
        <DeprecatedSelectField
          automationId="trial-reason-field"
          name="trialReason"
          placeholder={intl.formatMessage(messages.tryingBecause)}
          items={items}
        />
      </FormGroup>
      {trialReason === TrialReason.Retail && (
        <div className="SignupTrialReasonField--warning">
          <FormattedMessage
            id="a18330fb-763d-4aec-8f9f-a668ee8334e9"
            defaultMessage="Just looking to notarize a document? To get started, <link>click here</link>."
            values={{
              link: (msg: ReactNode[]) => (
                <Link onClick={goToGuestUpload} white>
                  {msg}
                </Link>
              ),
            }}
          />
        </div>
      )}
      {trialReason === TrialReason.TitleAgent && (
        <div className="SignupTrialReasonField--warning">
          <FormattedMessage
            id="450e9c89-ed47-4198-8074-bb727a7ae146"
            defaultMessage="Looking to use Proof for online closings? To sign up for a free title account, <link>click here</link>."
            values={{
              link: (msg: ReactNode[]) => (
                <Link onClick={goToTitleSignup} white>
                  {msg}
                </Link>
              ),
            }}
          />
        </div>
      )}
    </>
  );
}

export default SignupTrialReasonField;

import { useState, useCallback, useEffect, useContext, createContext, type ReactNode } from "react";
import {
  Subject,
  BehaviorSubject,
  Observable,
  EMPTY,
  of,
  defer,
  forkJoin,
  combineLatest,
  map,
  switchMap,
  filter,
  startWith,
  take,
  tap,
} from "rxjs";
import { useIntl, defineMessages, type IntlShape } from "react-intl";

import { PageTypes, AnnotationSubtype } from "graphql_globals";
import Env from "config/environment";
import { getAuthorizationHeaders } from "util/http";
import { blobFromUrl, retryBackoffWithCaptureException } from "util/rxjs";
import IconsFontFile from "assets/icons/font/notarize-icons.ttf";
import QuickstampPreviewBackgourndImage from "assets/images/quickstamp_preview.svg";
import { isMobileDevice } from "util/support";
import { focusForIOs } from "util/html";
import ColorCSSFile from "common/styles/design-tokens/_colors.css?asURL";

import { attachEventListeners } from "./event";
import {
  getPspPageInfo,
  getNotarizeIdFromPspId,
  type KitModule,
  type KitInstance,
  type PageInformation,
  type KitAnnotationWillChangeEvent,
  type KitAnnotation,
} from "./util";
import { expiringNotarizeDecorationContext, type DecorationContext } from "./decoration";
import { checkMarkAttachmentIdFromInstance } from "./annotation/image";
import { makePreviewLocationHook } from "./preview";
import { makeZoomCallback } from "./zoom";
import FrameCSSFile from "./frame.scss?asURL";

type KitConfiguration = Parameters<KitModule["load"]>[0];
type KitStandaloneConfiguration = Parameters<KitModule["preloadWorker"]>[0];
type ZoomRef = { current: Exclude<KitConfiguration["initialViewState"], undefined>["zoom"] };
type PSPLoadCallbackRefs = {
  getRenderFn: DecorationContext["getRenderFn"];
  tooltip: Exclude<KitConfiguration["annotationTooltipCallback"], undefined>;
  isEditable: Exclude<KitConfiguration["isEditableAnnotation"], undefined>;
};
type InitialContext = {
  loadInto: (options: LoadOptions) => () => void;
  useTogglePageNavigation: ReturnType<typeof makeTogglePageNavigationHook>;
  usePreviewLocation: ReturnType<typeof makePreviewLocationHook>;
};
type LoadedContextCallbacks = {
  addAnnotation: DecorationContext["addAnnotation"];
  addDesignation: DecorationContext["addDesignation"];
  addIndicator: DecorationContext["addIndicator"];
  setFocused: DecorationContext["setFocused"];
  zoom: ReturnType<typeof makeZoomCallback>;
  jump: ReturnType<typeof makeJumpCallback>;
  getPageInfo: (index: number) => ReturnType<typeof getPspPageInfo>;
};
type Context = Readonly<
  | (InitialContext & { [k in keyof LoadedContextCallbacks]?: undefined })
  | (InitialContext & LoadedContextCallbacks)
>;
type JumpAction =
  | { to: "node"; pageIndex: number; pageType: PageTypes; id: string; zoom?: number }
  | {
      to: "page";
      pageIndex: number;
      pageType: PageTypes;
      point?: { x: number; y: number };
      zoom?: number;
    };
type ClickInformation = {
  pageType: PageTypes;
  pageIndex: number;
  point: { x: number; y: number };
  shiftKey: boolean;
};
export type PageInfo = { pageIndex: number; totalPages: number };
export type PageSplitInfo = { pageIndex: number; node: HTMLElement };
export type LoadOptions = {
  mainPdfUrl: string;
  container: HTMLElement;
  appendedPdfs?: { pageType: PageTypes; url: string }[];
  onPermissionError?: () => void;
  onPagePress?: (clickInformation: ClickInformation) => void;
  onPageChange?: (pageInfo: PageInfo | null) => void;
  onSelectedAnnotationOrDesignationChange?: (annotationOrDesignationId: string | null) => void;
  renderPageSplits?: (splits: PageSplitInfo[]) => void;
  initialLoadPage?: number;
  readOnly?: boolean;
  onAnnotationWillChange?: (event: KitAnnotationWillChangeEvent) => void;
  pdfEventOptions?: { disableKeyEventForwarding?: boolean };
};

const { apiHost } = Env;
const DOCUMENT_TITLE = "Uploaded PDF Document";
const LICENSE_KEY = getLicenseKey();
const CUSTOM_STYLE_TAG = getInjectedStyleTag();
const LABELS = defineMessages({
  delete: {
    id: "8758af08-ce3d-4335-9bd2-1c0a1184f0c3",
    defaultMessage: "Delete {labelText}annotation",
  },
  reassign: { id: "4d970005-18aa-42fc-97b8-3da3956b4767", defaultMessage: "Reassign" },
  addToGroup: { id: "401ffe3a-2014-4c06-8929-ac189c1d3d4c", defaultMessage: "Add to group" },
  addConditional: {
    id: "2c8f73dd-6ad7-413b-988c-798630be6af7",
    defaultMessage: "Add conditional fields",
  },
  setAsOptional: { id: "bf6ac884-e654-424c-8166-4690b851e5d4", defaultMessage: "Set as optional" },
  decreaseSize: { id: "a0312065-90ae-4386-b8b9-819cd3fd4cdf", defaultMessage: "Decrease size" },
  increaseSize: { id: "0ce9433e-8861-4ed2-97f0-5d5515bd1e57", defaultMessage: "Increase size" },
  edit: { id: "30b20a39-0e4e-4019-b847-2d13101637ac", defaultMessage: "Edit" },
});

const noop = () => {};
const RETRY_CONFIG = { startWait: 100 };
const DEFAULT_CONTEXT: Readonly<InitialContext> = Object.freeze({
  useTogglePageNavigation: () => ({ toggleState: "unknown" as const, toggle: noop }),
  usePreviewLocation: () => null,
  loadInto: () => {
    throw new Error("Unexpected call before PSPDFKit is ready");
  },
});
const requireWrapper = () => {
  throw new Error("Attempted to use this context without the required <PDFWrapper />.");
};
const PDFContext = createContext<Context>(
  Object.freeze({
    useTogglePageNavigation: requireWrapper,
    usePreviewLocation: requireWrapper,
    loadInto: requireWrapper,
    addAnnotation: requireWrapper,
    addDesignation: requireWrapper,
    addIndicator: requireWrapper,
    setFocused: requireWrapper,
    zoom: requireWrapper,
    jump: requireWrapper,
    getPageInfo: requireWrapper,
  }),
);
const DEFAULT_INSTANCE_CALLBACKS: Readonly<PSPLoadCallbackRefs> = Object.freeze({
  getRenderFn: () => undefined,
  tooltip: () => [],
  isEditable: () => false,
});
const isMobile = isMobileDevice();

function getInjectedStyleTag(): HTMLStyleElement {
  const tag = document.createElement("style");
  tag.appendChild(
    document.createTextNode(`
@font-face {
  font-family: "notarize-icons";
  font-weight: normal;
  font-style: normal;
  src: url("${IconsFontFile}") format("truetype");
}
[data-annotation-id*="quickstamp-preview|"][data-annotation-id*="indicator|"] {
  background-image: url("${QuickstampPreviewBackgourndImage}");
}
`),
  );
  return tag;
}

function getLicenseKey(): string {
  const { hostname } = window.location;
  if (hostname === "localhost") {
    return "YGsyHcycexmMSl7-kEkppMxBEjrZOsCtVuwlhz4rmkA7EBXpr1Hq9vV5gb0q6AiE9blXxbAdyeuBzKmrAqocXikNbUFvNzllEp91O54IfbN7j187K1EaVQZu8_WZwYRyjzCzgr0WR-B8d2E4EY2q6b1zjId7oul2NR52G5yC15p3q7k8NCDKaABIiFHUorL7kWv8iNHzJ5RKKXOtxEg_Rrwagk6UUSQzrNVmqJmyCPdOtNX3n_VWoWYC2IFoq3uqgGCMUwIsH8G1YwzNMMjhA9JQGS8BiUQQJtzuvmjf90NbXDcaAElPictp3NHR3y8I5HXxOMAzXaPCakxkfeEEW4z8FxmRrH6lwRP_s_u-JFLUDIEu6a0khZ5A_L2_zMjUGLX6M-4qEtk4pCHiwEYuBiFIaKm9jkfguexHJV38-qfV2OrXa5Ad1LwDPYyFWacud0o6co5M6Mi-XgQRIBQG3VYSxXX4YPMfyj4pc9VCzted-E-w4fZLmT7tVjGqmU91r1fMkcltbUzUW47XhAl9K8lIJyp9IsHRsAg5E2Pw-4VNy8uHM1Vt6RvKvPAF4zdqFBbVYL72Z-E3ML5vTrcZZwiPKwrgFZ1U0OeWCxWLFshUbVweIQyqOE4n8NeaWl7OlOa_cTnsdzfgW0pvARIZm7PNJhMXwnCvZM4TSE3LldM=";
  } else if (hostname.endsWith("notarize.rocks")) {
    return "CPWvbKRcSNpPmleqjE9xDNmeRbFuGry9waU65knFYqN8fFayM3PaOEWDSic8p55DP94IS8wgvbIZDa65VkVetihvx9GjjLw-nlfaFca7gr8vC5KxH8BioiHNOEAnmjkKptPChyty8foOcpIupw88JJoWVrp0SrOkRk5IgIgfeE4CQsLaT7cteOn-4XlS84LUm6WEdVTF-P13Dd5m9lFy3NQrootrvH4VYGtDAdg0zaeXdHvms91fjbV73YvhSe3Eswiw8JafjE8EgU6ghgn0T3MNtLmcxFsdOP0jeS26wi3-v_oMu1n4JZ8DzVvD49QeMjx7W9vP2RQnx_fOEB8uZmGka5q-elJ-JWn2F4wp-ypkdBLF2bOArNjAxGGmAQ8XuQUOJYLqdYi7ifR84ZERJHbY5KsdkFtdvg3a-IKWmd8N6dqNlNITXXdi_CuG-kVFwbdMUOgD-X0hJCGaKMTMGOu-MIijs79-zPmwRxf8wZe1O9SYqQKRsNNrm9fvzBUvfsUG0FtMRXle1JhuwMu3888HBwbD8EMGnn90EAtqcxrCli4LvYY_RECFKel8SfmAx-GempF4-DeCqDolbetwtEVSHIqW2iG0JwFUJJLMmzr1cWdebYc-1UfDbvbxZ96ZGk6NoE1XuyHn6cHMYmw2TJO3ERvMtZn4e1SBO5vrgnc=";
  } else if (hostname.endsWith("dev-notarize.com")) {
    return "DyhVkiXTdQYO7Bq6SEJuFhPcdyLZsL-u4EiAXV7RfkuBYHfgWxaO4Kk5J8aMr11kdEb1F4ZAPsYUq_kf-wiZF8TdrxTfTjS8CuDYgI-cHIBk-VW79yhX0HSfr4YmZWc4_6IJwDeLTpprAx80az67ICOTQnAO1ajrXoB33AtshUgmb8bXnQro1T58X9mL692MMNjJUt3-0uaopPfftu7-5MCykAHRtRaS0QqM18HZXsAOGUhIvabe6Y8fVCCFVdM0AW32vLTFHc1fd2VqW5OJtha3WwjdjBOoGGCyvLYvxxNlgj985p3NXVcYBBXPdf-aRo98jmiYpOlJ061T0o8r8wtkST3y-qO4sq1b06eUERSPqf0ewyKrHVVaNwwu6vNhcMZmf7PP4iEyq_9T9smsmO-Hj_YonxPDgPc1bG49MNoG2eS1jzDF2rmC79HPWigR4Q178ilkyqOZFqvRH1G_Rc9q8D-uHVlN25NA2ZOUsyzxx73xeG6Ck_0o3q4jKySgxewfALAHM9KDt7mLBCJskxqu4cj8q9rfiNdAC3McAx2Ldf-uyJLDQWBVct03v2kC7-LymokesTXvEEUsNzVj_rdjzyNDmry7eKXlgni96ZCx6DB_2mPv_mX8VS6_bMq4jG5QIlmdzPsRYBc4qo_sMP1hSawrU057PENqfhJ5Pdg=";
  } else if (hostname.endsWith("notarize.com")) {
    return "tCQxxpB2glnO9uDfASYMqzPnt3luZxZwmPDbxkrgI_yntkqwM2sFe7_DaqgMaxxDL-EElORXRvxRXUe8fAWq5IAKL15L2hcEUJ2Mog92RVC5TudeRu9pUNsPXhel1A7a6pSYjHXfe2FPmRyCq4Z9LmWAA1jJHxO3wZeddWyYJ9o2Bi1BV_IHtm1IaVRSxZtkxxJzyogtK-WuKQzQ3owDAPgwXghlDmvEuJDxZlJ8bED8z0Tm7PpVWtddzsRtbiWnf1dBJx23f57cwy8HW0g6qx4VUSlm8-RhxIixB3R8Zo4GBAtY0Gmb-Nxgo-RwsAx8v2PMvSMD32pRun4_7qIvtk9CCezXuMyYwccuuHWgf2PFQgXjsbfeE18QQYkJA_hxM5mpyc7eE0L5Y70NYArdNGV5OZ9OfrVWnKMMTPMBg1lGfn-f9Hdc7Ahw8N5rq5fmczXrC3eMBS8Pw5ZLig6N4gkP2oBXQWX9R5Y4ts923gN6pOPupBsGV7yqrb-zE5Z3qsSU1N7tQ3z_vveDIKoLMwmJXiwEZZ4qw2CBcdRRKtbVbI-Fgc7L8GAwT-yoI3aWnoIW2zVIWtEAplWSCRW9mpIEzc-o-tDvsnqJ1cb6DGQavfHXL4paAcJT0r9Mtlhm8OcJo4sv8GNBHpcsLu3gRQ==";
  } else if (hostname.endsWith("proof.coach")) {
    return "gj8py-EWJq6dwYVgGrCVc5BRG_ZsNU9OLDrsVwFItsxvli4ajjZTc9j0d1m5XSHCPt7_4dj8hAxxLRDiB4GIe9UKtT9vhyqGPBFUiXgvKK7Shq6KHb7YYdJqeYVDMIPxCjz-ZqGUyRF0UCOFz1KUNlsgLLCZN4gtJ912qIFdw2UeYR1ESI3Xy7g1TPwkzDhKfgIciIgRK8GJEgkx-2Hw4eoe6NVBHI-TZOCLr7UW6XyJsTCCngKfhhK_Npv-3pEXSy8Ca7iqE3PXSVtjz0f8zYYABCnD8Of1pqDO7xeR2_g4_dDxtjPeZxVVsu8-xz5uyTz3pO27F789RLO7RmqN8EH4vGlgKNUZX0CA6kD3kXgkp59J64-Tzcw-92eTbsiuM0q-sBU_SKubla7tzY1ovM8oh47z_qXSaoMwep6CLbi8aS8v1ZXwVtRVz0KRdTO4I4-lLWbeh8atVuR6y4FDPQskJ55RFR_lr0wr_iho98au4ydOHZ6VShxb9pfLA-D-SUm0Kgz5F3hUbBEPVNNG-SlCbP0k6Buf0mXjFNZTOZ2iPR2KY6FOi3OVZPwR6P-z4I5LY5NmKQrCbJy89VZP19GtGAEkig_HSHJwdgGPufg7QKgCEfGmrmrU-GaC9gyOA8Ly_cdF9_WJgDomTp_-ay56-LzYNOfX6OxE45BNFkw=";
  }
  // default, for proof.com
  return "RTenVDT_ngear4tKAA37oxHhsg8lsz69IeeWrgDxmiyDQXa9CXjCutod9-MH94Yqe2CixnkYvyXF1ERtrcBQne4Jy-Ap0TiSgnm_pgUgq9JhzxaTbNXEGumoUBfELGMQj3rdrPr1dvDYXIBfvP0DbGbdPltX3RFmmI1ePgiXPTdIq7U0OXpcKFm02sJSWTcq89es0rjGwg4-5IoA7T7jz48tGO7LUQ16a8J2fF-kWsTynlN8PsYGQWWrhmsNXYdbgMOrAzmwfiGzIwaZeNkW5jX36Yfp75ntaKoFeuAVD7rs24ql3hExGAJt3inOcd3XrOjV0Ux0jckOWwzNRs8iBR7Z6EPcyAuY7uid_xSFZGCPLhM8S3zgoDxjRViuHm_9r9nMjDjbICYnlMmaCZ9CYUie9sLxRcVXJ6ZDB8E6XISS1spL2D2E-OK2lPxaMB4VGVZ2vstaY8WzlDZ_KowkheU_nhorn3u5yT3pJ8oiES7mdUlMDE4BqlVRuuooY9tRaHw2mKMmMeo8izmYPRF1GJo0f09b8BXFsRAlK4ATlCshT5uL4SU5ECF7dfD7xdQvsyIn7v_6-heGrLwX0_WDMwN6oQqng8i5B3K7BWkBHYhc3LRXyPI0ZFKTcXHc2cC6dFqoBombvQpgKo9l8XD4yA==";
}

function makeTogglePageNavigationHook(
  sidebarMode: KitModule["SidebarMode"],
  instance?: KitInstance,
) {
  return function useTogglePageNavigation() {
    const [toggleState, setToggleState] = useState<"unknown" | "open" | "closed">(
      !instance ? "unknown" : instance.viewState.sidebarMode ? "open" : "closed",
    );
    useEffect(() => {
      setToggleState(!instance ? "unknown" : instance.viewState.sidebarMode ? "open" : "closed");
    }, [instance]);
    return {
      toggleState,
      toggle: useCallback(() => {
        if (instance) {
          instance.setViewState((oldState) =>
            oldState.set("sidebarMode", oldState.sidebarMode ? null : sidebarMode.THUMBNAILS),
          );
          setToggleState(instance.viewState.sidebarMode ? "open" : "closed");
        }
      }, [instance]),
    };
  };
}

function makeJumpCallback(
  module: KitModule,
  instance: KitInstance,
  pageInformationLookup: PageInformation,
) {
  return async (action: JumpAction) => {
    const realIndex = pageInformationLookup.getBasisPageIndex(action.pageType) + action.pageIndex;
    switch (action.to) {
      case "page": {
        const point = action.point
          ? {
              top: getPspPageInfo(instance, realIndex).height - action.point.y,
              left: action.point.x,
              width: 1,
              height: 1,
            }
          : undefined;
        if (action.zoom && action.zoom > 0) {
          return instance.jumpAndZoomToRect(
            realIndex,
            new module.Geometry.Rect(point).grow(action.zoom),
          );
        }
        return instance.jumpToRect(realIndex, new module.Geometry.Rect(point));
      }
      case "node": {
        const pageAnnotations = await instance.getAnnotations(realIndex);
        const annotation = pageAnnotations.find(
          (annotation) => getNotarizeIdFromPspId(annotation.id) === action.id,
        );
        if (action.zoom && action.zoom > 0) {
          return (
            annotation &&
            instance.jumpAndZoomToRect(realIndex, annotation.boundingBox.grow(action.zoom))
          );
        }
        // Jump to the center of the annotation,
        // so if the annotation is less than 50% visible we will jump it into full view
        const center = annotation?.boundingBox.getCenter();
        const rect = new module.Geometry.Rect({
          top: center?.y,
          left: center?.x,
          width: 1,
          height: 1,
        });
        return annotation && instance.jumpToRect(realIndex, rect);
      }
    }
  };
}

function useLazyPSPDFKit() {
  const [module, setModule] = useState<null | KitModule>(null);
  useEffect(() => {
    import("pspdfkit").then((mod) => setModule(mod.default));
  }, []);
  return module;
}

function semanticallyHideKitAnnotation(annotationEl: Element | null | undefined) {
  if (annotationEl) {
    annotationEl.setAttribute("aria-label", "");
    annotationEl.setAttribute("aria-hidden", "true");
    annotationEl.setAttribute("tabindex", "-1");
  }
}

function renderCustomAnnotation(
  cache: Map<string, HTMLDivElement>,
  { getRenderFn }: PSPLoadCallbackRefs,
  annotation: KitAnnotation,
) {
  const renderFn = getRenderFn(annotation);
  if (!renderFn) {
    return null;
  }

  let node = cache.get(annotation.id);
  if (node) {
    // clear our aria default label
    const annotationEl = node.parentElement?.parentElement?.querySelector(
      `[data-annotation-id='${annotation.id}']`,
    );
    semanticallyHideKitAnnotation(annotationEl);
  } else {
    node = window.document.createElement("div");
    node.classList.add("Notarize-Custom-Annotation-Container");
    const renderObserver = new MutationObserver((records) => {
      // We want to hide the regular buttton for screen readers so that there's only one button for
      // these users to interact with.
      const neighborButton = records[0]?.target.parentElement?.previousElementSibling;
      if (neighborButton) {
        const annotationEl = neighborButton.firstChild;
        semanticallyHideKitAnnotation(annotationEl as Element | null);
        renderObserver.disconnect();
      }
    });

    renderObserver.observe(node, { childList: true });
    cache.set(annotation.id, node);
  }

  renderFn(node);
  return {
    node,
    noZoom: false,
    append: true,
    onDisappear: noop,
  };
}

function getPDFBytes(pdfUrl: LoadOptions["mainPdfUrl"]): Observable<ArrayBuffer | null> {
  return defer(() => {
    const options = pdfUrl.startsWith(apiHost)
      ? { credentials: "include" as const, headers: getAuthorizationHeaders() }
      : undefined;
    return fetch(pdfUrl, options).then((res) => {
      if (res.ok) {
        return res.arrayBuffer();
      } else if (res.status === 403) {
        // This often means (for s3 links) that they have an expired URL/token. Not all documents come from s3
        // but most do so we give up on this kind of error.
        return null;
      }
      throw new Error(`Error in pdf request: ${pdfUrl}`);
    });
  }).pipe(retryBackoffWithCaptureException(RETRY_CONFIG));
}

function instanceFromOptions(
  module: KitModule,
  pdfBytes: ArrayBuffer,
  { container, readOnly, initialLoadPage }: LoadOptions,
  containerIsReady$: BehaviorSubject<boolean>,
  zoomRef: ZoomRef,
) {
  return new Observable<{ instance: KitInstance; instanceCallbacks: PSPLoadCallbackRefs }>(
    (observer) => {
      let localInstance: undefined | KitInstance;
      let done = false;
      const instanceCallbacks = { ...DEFAULT_INSTANCE_CALLBACKS };
      containerIsReady$.next(false);
      const unload = () => {
        module.unload(container);
        containerIsReady$.next(true);
      };
      const customRenderAnnotationCache = new Map<string, HTMLDivElement>();
      module
        .load({
          container,
          // Without manually specifiying this baseUrl, PSPDFKit seems to have a Firefox bug with auto detection and
          // internal chunk loading fails.
          baseUrl: `${window.location.origin}/`,
          customRenderers: {
            Annotation: ({ annotation }) =>
              renderCustomAnnotation(customRenderAnnotationCache, instanceCallbacks, annotation),
          },
          disableMultiSelection: true,
          document: pdfBytes,
          licenseKey: LICENSE_KEY,
          // IMPORTANT NOTE: Its crucial that these functions return falsy for annotations created outside of Notarize.
          // Things like form fields must be "read only" and non-Notarize annotations cannot be moved since we have no
          // way to persist changes. We don't want users to use Notarize to make changes to these.
          isEditableAnnotation: (pspAnnotation) =>
            !readOnly && instanceCallbacks.isEditable(pspAnnotation),
          annotationTooltipCallback: (pspAnnotation) =>
            readOnly ? [] : instanceCallbacks.tooltip(pspAnnotation),
          styleSheets: [ColorCSSFile, FrameCSSFile],
          initialViewState: new module.ViewState({
            allowPrinting: false,
            allowExport: false,
            zoom: zoomRef.current,
            showComments: false,
            showToolbar: false,
            enableAnnotationToolbar: false,
            showAnnotationNotes: false,
            // @ts-expect-error -- pspdfkit wants an immutable object that we can't construct
            viewportPadding: { horizontal: 16, vertical: 16 },
            readOnly,
            currentPageIndex: initialLoadPage || 0,
          }),
        })
        .then((instance) => {
          if (done) {
            unload();
          } else {
            // We do not call observer.complete() to keep the instance "live" and only unload() when downstream unsubscribe().
            localInstance = instance;
            observer.next({ instance, instanceCallbacks });
            // We include this inline style tag to include the inline data/base64 for the icons font.
            const { contentDocument, contentWindow } = instance;
            contentDocument.head.appendChild(CUSTOM_STYLE_TAG);
            contentWindow.frameElement?.setAttribute("title", DOCUMENT_TITLE);
            contentDocument.title = DOCUMENT_TITLE;
          }
        })
        .catch((error) => {
          unload();
          observer.error(error);
        });
      return () => {
        done = true;
        if (localInstance) {
          unload();
        }
      };
    },
  ).pipe(retryBackoffWithCaptureException(RETRY_CONFIG));
}

function preloadWASM(module: KitModule) {
  // preloadWorker works without container and document specified,
  // but shares much of it's type definitions with load (where it is required),
  // so we force cast it here to avoid passing in a dummy container and document.
  module.preloadWorker({
    baseUrl: `${window.location.origin}/`,
    licenseKey: LICENSE_KEY,
  } as KitStandaloneConfiguration);
}

const SPREAD_SPACING = 32;
function preventPageSpreadScale(instance: KitInstance) {
  instance.setViewState((viewState) =>
    viewState.set("spreadSpacing", SPREAD_SPACING / instance.currentZoomLevel),
  );
}

function renderCustomOverlays(
  module: KitModule,
  instance: KitInstance,
  { renderPageSplits }: LoadOptions,
) {
  if (renderPageSplits) {
    preventPageSpreadScale(instance);

    const splits = [...Array(instance.totalPageCount - 1).keys()].map((i) => {
      const pageIndex = i + 1;
      const split = document.createElement("div");
      split.classList.add("PageSplitContainer");
      split.style.marginTop = `-${SPREAD_SPACING / 2}px`;

      const item = new module.CustomOverlayItem({
        id: `page-split-${pageIndex}`,
        node: split,
        pageIndex,
        position: new module.Geometry.Point({
          x: instance.pageInfoForIndex(pageIndex)!.width / 2,
          y: 0,
        }),
        onAppear: () => {
          // ignore overflow: hidden from page
          (split.offsetParent as HTMLElement).style.position = "fixed";
        },
        disableAutoZoom: true,
      });

      instance.setCustomOverlayItem(item);

      return { pageIndex, node: split };
    });

    instance.addEventListener("viewState.change", () => {
      preventPageSpreadScale(instance);
    });

    renderPageSplits(splits);
  }
}

function appendedPdfsFromInstance(
  instance: KitInstance,
  { appendedPdfs }: LoadOptions,
): Observable<PageInformation> {
  if (!appendedPdfs?.length) {
    return of({
      getBasisPageIndex: () => 0,
      getNormalizedPageInfo: (index) => ({ normalizedIndex: index, pageType: PageTypes.DOCUMENT }),
    });
  }
  const appendedPdfsConfigs$ = appendedPdfs.map(({ url, pageType }) => {
    return blobFromUrl(url).pipe(
      retryBackoffWithCaptureException(RETRY_CONFIG),
      map((blob) => ({ blob, pageType })),
    );
  });
  return forkJoin(appendedPdfsConfigs$).pipe(
    switchMap((appendedPdfsConfigs) => {
      const totalPagesBeforeAppending = instance.totalPageCount;
      const operations = appendedPdfsConfigs.map(({ blob }, index) => ({
        type: "importDocument" as const,
        treatImportedDocumentAsOnePage: false,
        afterPageIndex: index + totalPagesBeforeAppending - 1,
        document: blob,
      }));
      const applyOperations$ = defer(() => instance.applyOperations(operations));
      return applyOperations$.pipe(
        map(() => {
          // WARNING: This supposes 1 page appended pdfs (like loose leafs)
          const getBasisPageIndex = (pageType: PageTypes) => {
            if (pageType === PageTypes.DOCUMENT) {
              return 0;
            }
            const foundIndex = appendedPdfs.findIndex((pdf) => pdf.pageType === pageType);
            return foundIndex === -1 ? 0 : foundIndex + totalPagesBeforeAppending;
          };
          return {
            getBasisPageIndex,
            getNormalizedPageInfo: (index: number) => {
              if (index < totalPagesBeforeAppending) {
                return { normalizedIndex: index, pageType: PageTypes.DOCUMENT };
              }
              const diff = index - totalPagesBeforeAppending;
              if (diff >= appendedPdfs.length) {
                throw new Error(
                  `Could not find ${index} with ${appendedPdfs.length} docs and total ${totalPagesBeforeAppending}`,
                );
              }
              const found = appendedPdfs[diff];
              return { normalizedIndex: 0, pageType: found.pageType };
            },
          };
        }),
      );
    }),
  );
}

function fullyLoadedContextFromOptions(
  module: KitModule,
  options: LoadOptions,
  loadInto: Context["loadInto"],
  containerIsReady$: BehaviorSubject<boolean>,
  zoomRef: ZoomRef,
  intl: IntlShape,
): Observable<Required<Context>> {
  const lock$ = containerIsReady$.pipe(filter(Boolean), take(1));
  const mwebAnnotationResizingSettings = { increment: 10, minFontSize: 8, minHeight: 10 };
  return combineLatest([getPDFBytes(options.mainPdfUrl), lock$]).pipe(
    switchMap(([pdfBytes]) => {
      if (pdfBytes) {
        return instanceFromOptions(module, pdfBytes, options, containerIsReady$, zoomRef);
      }
      options.onPermissionError?.();
      return EMPTY;
    }),
    switchMap(({ instance, instanceCallbacks }) => {
      // NOTE: It might seem more efficient to do checkmark attachment and appended pdfs in parallel but pspdfkit
      // can't seem to handle `createAttachment` and `applyOperations` at the same time, so we don't forkJoin.
      // Its also purposeful to put appending pdfs first.
      return appendedPdfsFromInstance(instance, options).pipe(
        switchMap((pageInformationLookup) => {
          return checkMarkAttachmentIdFromInstance(instance).pipe(
            switchMap((checkmarkAttachmentId) => {
              return expiringNotarizeDecorationContext({
                module,
                instance,
                checkmarkAttachmentId,
                pageInformationLookup,
              });
            }),
            tap((decorationContext) => {
              renderCustomOverlays(module, instance, options);
              attachEventListeners(
                module,
                instance,
                pageInformationLookup,
                intl,
                {
                  onPagePress: options.onPagePress,
                  onPageChange: options.onPageChange,
                  onSelectedAnnotationOrDesignationChange:
                    options.onSelectedAnnotationOrDesignationChange,
                  onZoom: (zoom) => {
                    zoomRef.current = zoom;
                  },
                  onAnnotationWillChange: options.onAnnotationWillChange,
                },
                options.pdfEventOptions,
              );
              instanceCallbacks.tooltip = (pspAnno) => {
                const notarizeId = getNotarizeIdFromPspId(pspAnno.id);
                const isTextAnnotation = pspAnno instanceof module.Annotations.TextAnnotation;
                const reassignFn = decorationContext.getReassignFn(pspAnno);
                const addToGroupFn = decorationContext.getAddToGroupFn(pspAnno);
                const addConditionalFn = decorationContext.getAddConditionalFn(pspAnno);
                const setOptionalFn = decorationContext.getSetOptionalFn(pspAnno);
                const deleteFn = decorationContext.getDeleteFn(pspAnno);
                const isLockedDocument = decorationContext.getIsLockedDocument(pspAnno);
                const incrementalResizeFn = decorationContext.getIncrementalResizeFn(pspAnno);
                const tooltips = [];
                if (reassignFn) {
                  tooltips.push({
                    type: "custom" as const,
                    title: intl.formatMessage(LABELS.reassign),
                    onPress: reassignFn,
                  });
                }
                if (addToGroupFn) {
                  tooltips.push({
                    type: "custom" as const,
                    title: intl.formatMessage(LABELS.addToGroup),
                    onPress: addToGroupFn,
                  });
                }
                if (addConditionalFn) {
                  tooltips.push({
                    type: "custom" as const,
                    title: intl.formatMessage(LABELS.addConditional),
                    onPress: addConditionalFn,
                  });
                }
                if (setOptionalFn) {
                  tooltips.push({
                    type: "custom" as const,
                    title: intl.formatMessage(LABELS.setAsOptional),
                    onPress: setOptionalFn,
                  });
                }
                if (incrementalResizeFn) {
                  const isDisabled = isTextAnnotation
                    ? pspAnno.fontSize <= mwebAnnotationResizingSettings.minFontSize
                    : pspAnno.boundingBox.height <= mwebAnnotationResizingSettings.minHeight;
                  const { increment } = mwebAnnotationResizingSettings;
                  tooltips.push({
                    type: "custom" as const,
                    title: intl.formatMessage(LABELS.decreaseSize),
                    disabled: isDisabled,
                    className: `Icon icon-text Notarize-annotation-tooltip-icon Notarize-decrease-size ${
                      isDisabled ? "Notarize-annotation-tooltip-disabled" : ""
                    }`,
                    onPress: () =>
                      incrementalResizeFn(
                        increment * -1,
                        isTextAnnotation,
                        mwebAnnotationResizingSettings,
                      ),
                  });
                  tooltips.push({
                    type: "custom" as const,
                    className:
                      "Icon icon-text Notarize-annotation-tooltip-icon Notarize-increase-size",
                    title: intl.formatMessage(LABELS.increaseSize),
                    onPress: () =>
                      incrementalResizeFn(
                        increment,
                        isTextAnnotation,
                        mwebAnnotationResizingSettings,
                      ),
                  });
                }
                if (notarizeId && isTextAnnotation && isMobile) {
                  tooltips.push({
                    type: "custom" as const,
                    className:
                      "Icon icon-pencil-line Notarize-annotation-tooltip-icon Notarize-increase-size",
                    title: intl.formatMessage(LABELS.edit),
                    onPress: () => {
                      focusForIOs();
                      decorationContext.setFocused(notarizeId);
                    },
                  });
                }
                if (deleteFn && !isLockedDocument) {
                  const node = document.createElement("button");
                  const getAnnoLabel = (text: string | undefined, subtype?: AnnotationSubtype) => {
                    if (text) {
                      return `${text} `;
                    }
                    switch (subtype) {
                      case AnnotationSubtype.CHECKMARK:
                        return "checkmark ";
                      case AnnotationSubtype.SIGNATURE:
                        return "signature ";
                      case AnnotationSubtype.INITIALS:
                        return "initials ";
                      default:
                        return "";
                    }
                  };
                  const labelText = getAnnoLabel(
                    pspAnno.text,
                    pspAnno.customData?.subtype as AnnotationSubtype | undefined,
                  );
                  node.classList.add("Icon", "icon-delete", "Notarize-annotation-tooltip-icon");
                  node.setAttribute("type", "button");
                  node.setAttribute("title", "Delete");
                  node.setAttribute("aria-label", intl.formatMessage(LABELS.delete, { labelText }));
                  node.addEventListener("keydown", (e) => {
                    if (e.key === "Enter") {
                      deleteFn();
                    }
                  });
                  tooltips.push({
                    type: "custom" as const,
                    className: "Notarize-annotation-tooltip-icon-wrapper",
                    id: "Notarize-delete-tooltip",
                    onPress: deleteFn,
                    node,
                  });
                }
                return tooltips;
              };
              instanceCallbacks.isEditable = decorationContext.isEditable;
              instanceCallbacks.getRenderFn = decorationContext.getRenderFn;
            }),
            map((decorationContext) => {
              return Object.freeze({
                loadInto, // we reuse this since its the same function
                useTogglePageNavigation: makeTogglePageNavigationHook(module.SidebarMode, instance),
                usePreviewLocation: makePreviewLocationHook(module, {
                  instance,
                  pageInformationLookup,
                }),
                zoom: makeZoomCallback(module.ZoomMode, instance),
                jump: makeJumpCallback(module, instance, pageInformationLookup),
                setFocused: decorationContext.setFocused,
                addDesignation: decorationContext.addDesignation,
                addAnnotation: decorationContext.addAnnotation,
                addIndicator: decorationContext.addIndicator,
                getPageInfo: (index: number) => getPspPageInfo(instance, index),
              });
            }),
          );
        }),
      );
    }),
  );
}

function contextFromKitModule(module: KitModule, intl: IntlShape): Observable<Context> {
  return new Observable((observer) => {
    const loadRequests$ = new Subject<LoadOptions | null>(); // null represents a "start"
    const loadInto = (options: LoadOptions) => {
      loadRequests$.next(options);
      return () => loadRequests$.next(null);
    };
    const containerIsReady$ = new BehaviorSubject<boolean>(true);
    const zoomRef = { current: module.ZoomMode.FIT_TO_WIDTH };
    return loadRequests$
      .pipe(
        startWith(null),
        tap((options) => {
          if (options) {
            options.onPageChange?.(null);
          }
        }),
        switchMap((options) => {
          return options
            ? fullyLoadedContextFromOptions(
                module,
                options,
                loadInto,
                containerIsReady$,
                zoomRef,
                intl,
              )
            : of(
                Object.freeze({
                  loadInto,
                  useTogglePageNavigation: makeTogglePageNavigationHook(module.SidebarMode),
                  usePreviewLocation: makePreviewLocationHook(module),
                }),
              );
        }),
      )
      .subscribe(observer);
  });
}

export function PDFWrapper({ children }: { children: ReactNode }) {
  const intl = useIntl();
  const module = useLazyPSPDFKit();
  const [context, setContext] = useState(DEFAULT_CONTEXT);

  useEffect(() => {
    if (module) {
      // Checking if object is frozen to avoid "Cannot assign to read only property" error after initially setting IGNORE_DOCUMENT_PERMISSIONS to true
      if (!Object.isFrozen(module.Options)) {
        module.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
        module.Options.PDF_JAVASCRIPT = false;
        // when SELECTION_OUTLINE_PADDING is lower, you can tell that the outline does not perfectly match dimensions of designation
        module.Options.SELECTION_OUTLINE_PADDING = () => 4;
        module.Options.SELECTION_STROKE_WIDTH = 3;
        module.Options.MIN_SHAPE_ANNOTATION_SIZE = 8;
      }
      const sub = contextFromKitModule(module, intl).subscribe(setContext);
      return () => sub.unsubscribe();
    }
  }, [module]);
  return (
    <PDFContext.Provider value={context}>
      {context === DEFAULT_CONTEXT ? null : children}
    </PDFContext.Provider>
  );
}

/**
 * This hook can be used to preload the PSPDFKit WASM file, which is of considerable size over the network.
 * Preloading the worker can help to reduce the time it takes to load other PSPDFKit viewers when it is first used,
 * since the WASM is already cached, and often already running.
 */
export function usePreloadPSPDFKitWorker() {
  const module = useLazyPSPDFKit();
  useEffect(() => {
    if (module) {
      preloadWASM(module);
    }
  }, [module]);
}

export function usePDFContext() {
  return useContext(PDFContext);
}

export function useIsPdfLoaded(): boolean {
  return "jump" in usePDFContext();
}

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import { DeprecatedMaskedInput, deprecatedUnmaskPhone } from "common/form/inputs/masked_input";

const noop = () => {};

const messages = defineMessages({
  phoneNumber: {
    id: "d6765b09-5785-488c-9a6c-a960329dfcd0",
    defaultMessage: "Phone number",
  },
});

/** @deprecated - please use components in common/core/form */
export function deprecatedIsValid({ isInternational = false, phoneNumber }) {
  return (
    phoneNumber &&
    ((isInternational && phoneNumber.length > 0) || (!isInternational && phoneNumber.length >= 10))
  );
}

class PhoneNumberInput extends PureComponent {
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.onReturn();
    }
  };

  handleChange = (event) => {
    this.props.onChange(deprecatedUnmaskPhone(event.target.value));
  };

  handleBlur = (event) => {
    this.props.onBlur(deprecatedUnmaskPhone(event.target.value));
  };

  render() {
    const { intl, isInternational, placeholder, label, ...otherProps } = this.props;

    const defaultPlaceholder = isInternational ? intl.formatMessage(messages.phoneNumber) : null;
    const defaultLabel = intl.formatMessage(messages.phoneNumber);

    return (
      <DeprecatedMaskedInput
        {...otherProps}
        aria-label={defaultLabel}
        maskType={isInternational ? "number" : "usPhone"}
        placeholder={placeholder || defaultPlaceholder}
        plainPlaceholder={!placeholder}
        label={label || defaultLabel}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyPress={this.handleKeyPress}
      />
    );
  }
}

PhoneNumberInput.propTypes = {
  ...DeprecatedMaskedInput.propTypes,
  onReturn: PropTypes.func,
  isInternational: PropTypes.bool,
  displayRequiredAsterisk: PropTypes.bool,
  label: PropTypes.string,
  useCoreFormInput: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  // injectIntl
  intl: PropTypes.object.isRequired,
};

PhoneNumberInput.defaultProps = {
  useCoreFormInput: false,
  onReturn: noop,
  onChange: noop,
  onBlur: noop,
  isInternational: false,
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedPhoneNumberInput = injectIntl(PhoneNumberInput);

import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedRadioButton } from "common/form/inputs/radio";

import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "../details_query.graphql";
import type {
  OrganizationEvaultSetup_evaultIntegration_DocMagicIntegration as DocMagicIntegration,
  OrganizationEvaultSetup_evaultIntegration_EoriginalIntegration as EoriginalIntegration,
} from "./index_fragment.graphql";
import EOriginalCredentials from "./eoriginal";
import DocMagicCredentials from "./docmagic";

type Props = {
  organization: Organization;
};

type EvaultType = DocMagicIntegration["__typename"] | EoriginalIntegration["__typename"];

function EvaultSetup({ organization }: Props) {
  const { evaultIntegration, lenderAccess } = organization;
  // For lenders, we prefer to show DocMagic if org has not evault setup yet
  const initEvaultType = lenderAccess
    ? evaultIntegration?.__typename || "DocMagicIntegration"
    : "EoriginalIntegration";
  const [evaultType, setEvaultType] = useState<EvaultType>(initEvaultType);
  return (
    <>
      {lenderAccess && (
        <>
          <DeprecatedRadioButton
            radioValue="DocMagicIntegration"
            size="small"
            labelText={
              <FormattedMessage
                id="5e2ce83b-dc2f-4a54-b23a-11d642773c59"
                defaultMessage="DocMagic"
              />
            }
            onChange={() => setEvaultType("DocMagicIntegration")}
            groupValue={evaultType}
            automationId="evault-radio-docmagic"
          />
          <DeprecatedRadioButton
            radioValue="EoriginalIntegration"
            size="small"
            labelText={
              <FormattedMessage
                id="5ac11862-c84a-4d82-bb06-628e29440c56"
                defaultMessage="eOriginal"
              />
            }
            onChange={() => setEvaultType("EoriginalIntegration")}
            groupValue={evaultType}
            automationId="evault-radio-eoriginal"
          />
        </>
      )}
      {evaultType === "EoriginalIntegration" ? (
        <EOriginalCredentials
          eOriginalCredentials={evaultIntegration as EoriginalIntegration | null}
          organizationId={organization.id}
        />
      ) : (
        <DocMagicCredentials
          docMagicCredentials={evaultIntegration as DocMagicIntegration | null}
          organizationId={organization.id}
        />
      )}
    </>
  );
}

export default EvaultSetup;

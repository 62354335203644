import { useIntl, defineMessages } from "react-intl";

import FormGroup from "common/form/group";
import { DeprecatedCheckboxField } from "common/form/fields/checkbox";

const MESSAGES = defineMessages({
  accessToTransaction: {
    id: "80bbe1ef-97f6-4f1c-9618-12b451134944",
    defaultMessage: "Access to documents",
  },
});

type Props = {
  fieldNamePrefix: string;
  readOnly?: boolean;
  accessToTransaction?: boolean;
};

function AccessToTransactionRow({ fieldNamePrefix, readOnly }: Props) {
  const intl = useIntl();
  const prefixedAccessToTransactionField = `${fieldNamePrefix}.accessToTransaction`;

  return (
    <FormGroup fields={[prefixedAccessToTransactionField]} disableFormRowStyle>
      <DeprecatedCheckboxField
        automationId={`${fieldNamePrefix}.access-to-documents`}
        name={prefixedAccessToTransactionField}
        label={intl.formatMessage(MESSAGES.accessToTransaction)}
        disabled={readOnly}
      />
    </FormGroup>
  );
}

export default AccessToTransactionRow;

import { Component, forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import CoreIcon from "common/core/icon";
import { defaultPlaceholderRenderer, defaultLabelRenderer } from "util/input";

import { DeprecatedMultiSelectInputBase } from "./input";

/**
 * This is the multi select input in the new Notarize form styles. This can be used when the user
 * has to select a lot of different values that all relate to one concept.
 * It has the capability of displaying an "All" option which will populate the control
 * with all the options possible.
 *
 * To style some of the more intricate parts of this component, refer to the rendering functions contained
 * in the util/ directory of the react-select repository: https://github.com/JedWatson/react-select
 * For example, we can override the menu item rendering if we want, but we have to pass in some custom rendering functions.
 */

/** @deprecated - please use components in common/core/form */
export class DeprecatedMultiSelectInput extends Component {
  state = {
    inputValue: "",
    selected: false,
  };

  /**
   * Keep track of a reference to the actual input so we can
   * focus it if the user clicks the label.
   */
  inputRef;

  // Helper Functions

  /**
   * Whether or not the user has selected any values from the multi select.
   */
  hasValue = () => {
    const { value } = this.props;
    return value && value !== "" && value.length > 0;
  };

  /**
   * Whether or not the input box (for searching) has a value in it.
   */
  hasInputValue = () => {
    const { inputValue } = this.state;
    return inputValue && inputValue !== "";
  };

  focusInput = () => {
    if (this.props.hookFormRef) {
      // For focusing when using with hook form Controller
      // We can't manually call focus() on hook form's ref and need to use
      // the provided setFocus
      this.props?.setFocus(this.props.name);
      return;
    }
    this.inputRef.focus();
  };

  setInputRef = (ref) => {
    this.inputRef = ref;
  };

  // Rendering

  /**
   * Render the search icon for the left side of the multiselect. When the user is active
   * inside of the control, this will "activate". When the user has put value in the control,
   * the icon will "lower".
   */
  renderSearchIcon = () => {
    const { selected, styledInput } = this.state;

    const searchIconCx = classnames("MultiSelect--searchIcon", {
      active: selected,
      lower: styledInput && (selected || this.hasValue() || this.hasInputValue()),
    });

    return (
      <span className={searchIconCx} onClick={this.focusInput}>
        <CoreIcon name="search" />
      </span>
    );
  };

  render() {
    const {
      className,
      clearable,
      label,
      placeholder,
      hasAllOption,
      allOptionLabel,
      labelRenderer,
      placeholderRenderer,
      value,
      displayRequiredAsterisk,
      styledInput,
      setFocus,
      "aria-invalid": ariaInvalid,
      ...otherProps
    } = this.props;

    const { selected } = this.state;

    const cx = classnames(className, "MultiSelect", {
      selected,
      hasValue: this.hasValue() && !selected,
      styledInput,
      ariaInvalid: ariaInvalid === "true",
    });

    const inputCx = classnames("MultiSelect--input", {
      lower: selected && !this.hasValue(),
      withValues: value && value.length > 0,
    });

    return (
      <div className={cx}>
        <DeprecatedMultiSelectInputBase
          {...otherProps}
          value={value}
          // styling overrides
          className={inputCx}
          placeholder={""}
          searchable
          clearable={clearable}
          closeOnSelect={false}
          arrowRenderer={() => {
            return null;
          }} // i.e. no dropdown arrow
          onInputChange={(inputValue) => {
            this.setState({ inputValue });
          }}
          onFocus={() => {
            this.setState({ selected: true });
          }}
          onBlur={() => {
            this.setState({ selected: false });
          }}
          hasAllOption={hasAllOption}
          allOptionLabel={allOptionLabel}
          inputRef={this.setInputRef}
        />
        {this.renderSearchIcon()}
        {labelRenderer({
          className: "MultiSelect",
          label,
          selected,
          displayRequiredAsterisk,
          hasValue: this.hasValue() || this.hasInputValue(),
          onClick: this.focusInput,
        })}
        {placeholderRenderer({
          className: "MultiSelect",
          selected,
          placeholder,
          hasValue: this.hasValue() || this.hasInputValue(),
        })}
      </div>
    );
  }
}

/**
 * @prop {bool} hasAllOption Whether or not we want the menu to feature an item that will select
 * all the items
 * @prop {string} allOptionLabel What we want the option that selects all items to say in the menu
 */
DeprecatedMultiSelectInput.propTypes = {
  ...DeprecatedMultiSelectInputBase.propTypes,
  clearable: PropTypes.bool,
  label: PropTypes.node,
  hasAllOption: PropTypes.bool,
  allOptionLabel: PropTypes.string,
  styledInput: PropTypes.bool,
  // Hook form props
  hookFormRef: PropTypes.func,
  setFocus: PropTypes.func,
  "aria-invalid": PropTypes.string,
};

DeprecatedMultiSelectInput.defaultProps = {
  ...DeprecatedMultiSelectInputBase.defaultProps,
  clearable: true,
  hasAllOption: false,
  allOptionLabel: "All Items",
  placeholderRenderer: defaultPlaceholderRenderer,
  labelRenderer: defaultLabelRenderer,
  value: [],
  styledInput: true,
  // Hook form props
  hookFormRef: undefined,
  setFocus: undefined,
  "aria-invalid": "false",
};

// Main use case for passing ref is for hook form
// If using with hook form, must also pass in setFocus
function MultiSelectInputHookForm(props, ref) {
  return <DeprecatedMultiSelectInput {...props} hookFormRef={ref} />;
}

const MultiSelectInputHookFormWithRef = forwardRef(MultiSelectInputHookForm);
/** @deprecated - please use components in common/core/form */
export { MultiSelectInputHookFormWithRef as DeprecatedMultiSelectInputHookForm };

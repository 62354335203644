import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";
import { subDays } from "date-fns";

import { format } from "common/core/format/date";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedBinaryToggleField } from "common/form/fields/binary_toggle";
import { DeprecatedDatePickerField } from "common/form/fields/datepicker";
import { DeprecatedTimezoneField } from "common/form/fields/timezone";
import { DeprecatedTextInput } from "common/form/inputs/text";
import { DeprecatedFormRowHelpTooltip } from "common/form/form_row_help_tooltip";
import { EXPIRY_DATE_FORMAT } from "constants/transaction";

const MESSAGES = defineMessages({
  notSet: {
    id: "685365cc-2457-455f-ba71-7bf1bbcc436f",
    defaultMessage: "Not set",
  },
});

class YesterdayMinDatePicker extends Component {
  render() {
    const { fieldPrefix, minDate } = this.props;
    return (
      <DeprecatedDatePickerField
        name={`${fieldPrefix}Date`}
        placeholder="MM/DD/YYYY"
        minDate={minDate || subDays(new Date(), 1)}
      />
    );
  }
}

/** @deprecated - please use components in common/core/form */
export function DeprecatedDateTimeField({
  formValues,
  fieldPrefix,
  containerClassname,
  label,
  tooltipContent,
  readOnly,
  automationPrefix,
  minDate,
}) {
  const intl = useIntl();
  if (readOnly) {
    return (
      <FormGroup>
        <label htmlFor={fieldPrefix}>{label}</label>
        <DeprecatedTextInput
          id={fieldPrefix}
          readOnly
          value={
            formValues[fieldPrefix]
              ? format({
                  value: formValues[fieldPrefix],
                  formatStyle: EXPIRY_DATE_FORMAT,
                  asTimeZone: formValues[`${fieldPrefix}Timezone`],
                })
              : intl.formatMessage(MESSAGES.notSet)
          }
        />
      </FormGroup>
    );
  }

  const toggleValue = formValues[`${fieldPrefix}Toggle`];

  const containerCx = classnames("Datetime", containerClassname);

  const dateCx = classnames("Datetime-Date", {
    hidden: !toggleValue,
  });
  const timezoneCx = classnames("Datetime-Timezone", {
    hidden: !toggleValue,
  });

  return (
    <div className={containerCx} data-automation-id={`${automationPrefix}-container`}>
      <FormGroup fields={[`${fieldPrefix}Toggle`]}>
        {label && <span id={`${fieldPrefix}Toggle`}>{label}</span>}

        <div className="Datetime-ToggleContainer">
          <DeprecatedBinaryToggleField
            aria-labelledby={`${fieldPrefix}Toggle`}
            name={`${fieldPrefix}Toggle`}
            automationId={automationPrefix}
          />

          {!!tooltipContent && (
            <DeprecatedFormRowHelpTooltip>{tooltipContent}</DeprecatedFormRowHelpTooltip>
          )}
        </div>
      </FormGroup>

      <FormGroup fields={[`${fieldPrefix}Timezone`]} className={timezoneCx}>
        <DeprecatedTimezoneField
          name={`${fieldPrefix}Timezone`}
          placeholder="Timezone"
          data-automation-id={`${automationPrefix}-timezone`}
        />

        <FormGroupErrors fields={[`${fieldPrefix}Timezone`]} />
      </FormGroup>

      <FormGroup fields={[`${fieldPrefix}Date`]} className={dateCx}>
        <YesterdayMinDatePicker minDate={minDate} fieldPrefix={fieldPrefix} />

        <FormGroupErrors fields={[`${fieldPrefix}Date`]} />
      </FormGroup>
    </div>
  );
}

DeprecatedDateTimeField.propTypes = {
  formValues: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string.isRequired,
  containerClassname: PropTypes.string,
  label: PropTypes.string,
  tooltipContent: PropTypes.node,
  readOnly: PropTypes.bool,
  automationPrefix: PropTypes.string,
};

DeprecatedDateTimeField.defaultProps = {
  automationPrefix: "",
};

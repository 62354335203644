import { forwardRef, useEffect, useMemo, type ComponentPropsWithoutRef, type Ref } from "react";
import { defineMessages, useIntl, type IntlShape, FormattedMessage } from "react-intl";

import {
  type RegisterOptions,
  type UseFormReturn,
  type FieldPath,
  useWatch,
} from "common/core/form";
import { StyledTextInput } from "common/core/form/text";
import { Select } from "common/core/form/select";
import { useMobileScreenClass } from "common/core/responsive";
import { FormattedFieldError, useNestedError, isAriaInvalid } from "common/core/form/error";
import { COUNTRIES_WITHOUT_POSTCODES } from "constants/form";
import { COUNTRIES } from "constants/countries";
import { CA_PROVINCES } from "constants/ca_provinces";
import { US_STATES_AND_TERRITORIES } from "constants/us_states_and_territories";
import GoogleLookupWrapper from "common/form/sub_forms/address/google_lookup_wrapper";
import { checkZip, zipMaxLength } from "util/address";

import Styles from "./index.module.scss";
import LayoutStyles from "../layout/index.module.scss";
import RequiredAsterisk from "../required-asterisk";

export type CountryCode = (typeof COUNTRIES)[number]["value"];
export type Address = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postal?: string;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  country: CountryCode | string; // TODO: Completely convert this field to use the CountryCode union type
};
export type CountryStateAddress = Pick<Address, "state" | "country">;
type FormValuesContraint = Record<string, unknown>;
type BaseProps<
  Key extends FieldPath<FormValues> & string,
  FormValues extends FormValuesContraint,
> = {
  name: Key;
  form: Pick<UseFormReturn<FormValues>, "clearErrors" | "control" | "register" | "setValue">;
  showIsCurrentLocationLabels?: boolean;
};
type AddressInputProps<
  Key extends FieldPath<FormValues> & string,
  FormValues extends FormValuesContraint,
> = BaseProps<Key, FormValues> & {
  showAddressLookup?: boolean;
  countryValidation?: (v: string, intl: IntlShape) => true | string;
  autocompleteOptions?: Record<string, unknown>;
  disabled?: boolean;
  hideCountry?: boolean;
  required?: boolean;
};

const MESSAGES = defineMessages({
  line1: {
    id: "149e8025-95fb-455b-96bc-58a0ee6e303e",
    defaultMessage: "Street Address",
  },
  line2: {
    id: "88ef6171-3735-411c-8923-da6f2b361ac2",
    defaultMessage: "Apartment, suite, etc (optional)",
  },
  city: {
    id: "900a89de-8465-48ff-a6db-54ce9e78440d",
    defaultMessage: "City or town",
  },
  state: {
    id: "8f1d3995-c0c0-4be7-85fb-61e5b88d81e0",
    defaultMessage: "State",
  },
  postal: {
    id: "f9ea2f37-eada-4aca-9397-c266132824ae",
    defaultMessage: "ZIP {isUS, select, true{} other{or Postal }}Code",
  },
  country: {
    id: "7f981831-becc-4410-a68a-87f2c55865ee",
    defaultMessage: "Country",
  },
  selectCountry: {
    id: "a5553bb2-67b9-4cf8-bf90-546733363167",
    defaultMessage: "Select country",
  },
  selectState: {
    id: "dcd32c50-562f-4798-8ed8-de6f7daa845b",
    defaultMessage: "Select state",
  },
  selectStateOrTerritory: {
    id: "87e42d25-1bc5-4ddd-9809-fc1ce1bf8912",
    defaultMessage: "Select state or territory",
  },
  zipCodeInvalid: {
    id: "3db21ae6-74fd-410e-b901-32d68cf19c76",
    defaultMessage: "Invalid ZIP {isUS, select, true{} other{or Postal }}Code",
  },
  streetRequired: {
    id: "f4971bf6-ba8d-4fd3-89a9-1c55125ade21",
    defaultMessage: "Street address is required",
  },
  cityRequired: {
    id: "5b840256-cbd0-4f3a-957c-d989b0ef3ab1",
    defaultMessage: "City or town is required",
  },
  stateRequired: {
    id: "64144758-9331-4092-90f1-a873cd98a5d5",
    defaultMessage: "State is required",
  },
  postalRequired: {
    id: "d59653ce-6a85-4de5-a464-9a8cb78fe39c",
    defaultMessage: "ZIP Code is required",
  },
  countryRequired: {
    id: "d59653ce-6a85-4de5-a464-9a8cb78fe39c",
    defaultMessage: "Country is required",
  },
  usAddrRequired: {
    id: "88ab7205-9f95-4c27-9e27-97203ab77019",
    defaultMessage: "US address is required",
  },
});

const STATE_LOOKUP = Object.freeze({
  US: US_STATES_AND_TERRITORIES,
  CA: CA_PROVINCES,
});

export function validateAddressIsUS(code: string, intl: ReturnType<typeof useIntl>) {
  return code === "US" || intl.formatMessage(MESSAGES.usAddrRequired);
}

function addressFieldPath<Key extends string>(props: { name: Key }, key: keyof Address) {
  return `${props.name}.${key}` as const;
}

function setAddressField<
  Key extends FieldPath<FormValues> & string,
  FormValues extends FormValuesContraint,
>(props: BaseProps<Key, FormValues>, key: keyof Address, value: Address[typeof key]) {
  // @ts-expect-error -- this fieldpath type from react-hook-form is hard to work with
  props.form.setValue(addressFieldPath(props, key), value);
}

function setAllAddressFields<
  Key extends FieldPath<FormValues> & string,
  FormValues extends FormValuesContraint,
>(props: BaseProps<Key, FormValues>, address: Address) {
  for (const [key, value] of Object.entries(address)) {
    setAddressField(props, key as keyof Address, value);
  }
}

function registerAddressField<
  Key extends FieldPath<FormValues> & string,
  FormValues extends Record<string, unknown>,
>(props: BaseProps<Key, FormValues>, key: keyof Address, options?: RegisterOptions) {
  // @ts-expect-error -- this fieldpath type from react-hook-form is hard to work with
  return props.form.register(addressFieldPath(props, key), options);
}

function useWatchAddressField<
  Key extends FieldPath<FormValues> & string,
  FormValues extends Record<string, unknown>,
>(props: BaseProps<Key, FormValues>, key: keyof Address): Address[typeof key] {
  // @ts-expect-error -- this fieldpath type from react-hook-form is hard to work with
  return useWatch({ control: props.form.control, name: addressFieldPath(props, key) });
}

function isStateRequiredForCountry(
  country: string | undefined,
): country is keyof typeof STATE_LOOKUP {
  return Boolean(country && Object.keys(STATE_LOOKUP).includes(country));
}

function isStateRequiredForCountryLocation(
  country: string | undefined,
): country is keyof typeof STATE_LOOKUP {
  return Boolean(country && country === "US");
}

function isPostalRequiredForCountry(country: string | undefined): boolean {
  return Boolean(country && !(COUNTRIES_WITHOUT_POSTCODES as readonly string[]).includes(country));
}

function stateIsInCountry(country: string, state: string): boolean {
  if (country !== "US" && country !== "CA") {
    return false;
  }
  return STATE_LOOKUP[country].some(({ value }) => value === state);
}

function CountrySelect(
  props: Omit<ComponentPropsWithoutRef<typeof Select>, "items" | "aria-label">,
  ref: Ref<HTMLSelectElement>,
) {
  const intl = useIntl();
  const items = useMemo(
    () =>
      Object.freeze([
        { label: intl.formatMessage(MESSAGES.selectCountry), value: "" },
        ...COUNTRIES,
      ]),
    [],
  );
  return <Select {...props} label={intl.formatMessage(MESSAGES.country)} items={items} ref={ref} />;
}

const CountrySelectWithRef = forwardRef(CountrySelect);

function StateSelect(
  props: Omit<ComponentPropsWithoutRef<typeof Select>, "aria-label" | "items"> & {
    country: keyof typeof STATE_LOOKUP;
  },
  ref: Ref<HTMLSelectElement>,
) {
  const { country } = props;
  const intl = useIntl();
  const label = intl.formatMessage(
    country === "US" ? MESSAGES.selectStateOrTerritory : MESSAGES.selectState,
  );
  const items = useMemo(() => [{ label, value: "" }, ...STATE_LOOKUP[country]], [country]);
  return <Select {...props} ref={ref} label={intl.formatMessage(MESSAGES.state)} items={items} />;
}

const StateSelectWithRef = forwardRef(StateSelect);

export function AddressInput<
  Key extends FieldPath<FormValues> & string,
  FormValues extends FormValuesContraint,
>(props: AddressInputProps<Key, FormValues>) {
  const {
    countryValidation,
    disabled = false,
    hideCountry = false,
    required = false,
    form,
    name,
  } = props;
  const intl = useIntl();
  const errors = useNestedError<FormValues, Address>({
    control: form.control,
    name,
  });
  const address = useWatch({
    control: form.control,
    name,
  });
  // Some forms do not have default value for address so need a default value until fields are registered
  const { line1, line2, city, state, postal, country } = (address || {}) as Address;
  const isStateRequired = isStateRequiredForCountry(country);
  const isPostalRequired = isPostalRequiredForCountry(country);
  const isUS = country === "US";
  // When one field has been filled in, make sure rest of them are filled in so that we save
  // with complete address.
  const requireFields =
    required || !!(line1 || line2 || city || state || postal || (country && !hideCountry));

  useEffect(() => {
    if (country && state && !stateIsInCountry(country, state)) {
      setAddressField(props, "state", "");
    }
  }, [country]);

  useEffect(() => {
    // if user types US zip longer than 5 digits with no separator, add "-".
    if (isUS && postal && postal.length > 5 && postal[5] !== "-" && postal[5] !== " ") {
      setAddressField(props, "postal", `${postal.substring(0, 5)}-${postal.substring(5)}`);
    }
  }, [postal]);

  const setFields = (place: Address) => {
    setAllAddressFields(props, place);
    props.form.clearErrors(
      // @ts-expect-error -- this fieldpath type from react-hook-form is hard to work with
      (["line1", "line2", "city", "state", "country", "postal"] as const).map((key) =>
        addressFieldPath(props, key),
      ),
    );
  };

  const isMobile = useMobileScreenClass();

  return (
    <div className={LayoutStyles.inputContainer}>
      <div className={Styles.inputSection}>
        {!hideCountry && (
          <div className={Styles.inputSection}>
            <CountrySelectWithRef
              aria-invalid={isAriaInvalid(errors?.country)}
              autoComplete="country-name"
              {...registerAddressField(props, "country", {
                required: requireFields || intl.formatMessage(MESSAGES.countryRequired),
                validate: countryValidation && ((v) => countryValidation(v, intl)),
              })}
              aria-required={requireFields}
              displayRequiredAsterisk={requireFields}
              disabled={disabled}
              data-automation-id="country-field"
            />
            {errors?.country && (
              <FormattedFieldError
                inputName={addressFieldPath(props, "country")}
                error={errors.country}
              />
            )}
          </div>
        )}
        {!disabled && props.showAddressLookup ? (
          <GoogleLookupWrapper onAddressSelect={setFields} allowChangeInput>
            {(initializeLookup) => {
              const registration = registerAddressField(props, "line1", {
                required: requireFields ? intl.formatMessage(MESSAGES.streetRequired) : false,
              });
              return (
                <StyledTextInput
                  aria-invalid={isAriaInvalid(errors?.line1)}
                  disabled={disabled}
                  label={intl.formatMessage(MESSAGES.line1)}
                  {...registration}
                  ref={(elm) => {
                    if (elm) {
                      initializeLookup(elm, props.autocompleteOptions);
                      registration.ref(elm);
                    }
                  }}
                  aria-required={requireFields}
                  displayRequiredAsterisk={requireFields}
                  data-automation-id="line1-field"
                />
              );
            }}
          </GoogleLookupWrapper>
        ) : (
          <StyledTextInput
            aria-invalid={isAriaInvalid(errors?.line1)}
            autoComplete="address-line1"
            disabled={disabled}
            label={intl.formatMessage(MESSAGES.line1)}
            {...registerAddressField(props, "line1", {
              required: requireFields ? intl.formatMessage(MESSAGES.streetRequired) : false,
            })}
            aria-required={requireFields}
            displayRequiredAsterisk={requireFields}
            data-automation-id="line1-field"
          />
        )}
        {errors?.line1 && (
          <FormattedFieldError inputName={addressFieldPath(props, "line1")} error={errors.line1} />
        )}
      </div>
      <div className={!isMobile ? Styles.rowSection : undefined}>
        <div className={isMobile ? Styles.mobileRow : Styles.inputRow}>
          <StyledTextInput
            aria-invalid="false"
            aria-required="false"
            autoComplete="address-line2"
            disabled={disabled}
            label={intl.formatMessage(MESSAGES.line2)}
            {...registerAddressField(props, "line2")}
            data-automation-id="line2-field"
          />
        </div>
        <div className={isMobile ? Styles.mobileRow : Styles.inputRow}>
          <StyledTextInput
            aria-invalid={isAriaInvalid(errors?.city)}
            autoComplete="address-level2"
            disabled={disabled}
            label={intl.formatMessage(MESSAGES.city)}
            {...registerAddressField(props, "city", {
              required: requireFields ? intl.formatMessage(MESSAGES.cityRequired) : false,
            })}
            aria-required={requireFields}
            displayRequiredAsterisk={requireFields}
            data-automation-id="city-field"
          />
          {errors?.city && (
            <FormattedFieldError inputName={addressFieldPath(props, "city")} error={errors.city} />
          )}
        </div>
      </div>
      <div className={Styles.rowSection}>
        {isStateRequired && (
          <div className={Styles.inputRow}>
            <StateSelectWithRef
              country={country}
              autoComplete="address-level1"
              aria-invalid={isAriaInvalid(errors?.state)}
              disabled={disabled}
              {...registerAddressField(props, "state", {
                required: requireFields ? intl.formatMessage(MESSAGES.stateRequired) : false,
                shouldUnregister: true,
              })}
              aria-required={requireFields}
              displayRequiredAsterisk={requireFields}
              data-automation-id="state-field"
            />
            {errors?.state && (
              <FormattedFieldError
                inputName={addressFieldPath(props, "state")}
                error={errors.state}
              />
            )}
          </div>
        )}

        {isPostalRequired && (
          <div className={Styles.inputRow}>
            <StyledTextInput
              aria-invalid={isAriaInvalid(errors?.postal)}
              autoComplete="postal-code"
              disabled={disabled}
              label={intl.formatMessage(MESSAGES.postal, { isUS })}
              inputMode={isUS ? "numeric" : undefined}
              maxLength={zipMaxLength(country)}
              {...registerAddressField(props, "postal", {
                required: requireFields ? intl.formatMessage(MESSAGES.postalRequired) : false,
                validate: (value) => {
                  if (!value) {
                    return true;
                  }
                  return (
                    checkZip(country, value) ||
                    intl.formatMessage(MESSAGES.zipCodeInvalid, { isUS })
                  );
                },
                shouldUnregister: true,
              })}
              aria-required={requireFields}
              displayRequiredAsterisk={requireFields}
              data-automation-id="postal-field"
            />
            {errors?.postal && (
              <FormattedFieldError
                inputName={addressFieldPath(props, "postal")}
                error={errors.postal}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function CountryStateProvinceInput<
  Key extends FieldPath<FormValues> & string,
  FormValues extends FormValuesContraint,
>(props: BaseProps<Key, FormValues>) {
  const errors = useNestedError<FormValues, CountryStateAddress>({
    control: props.form.control,
    name: props.name,
  });
  const country = useWatchAddressField(props, "country");
  useEffect(() => {
    setAddressField(props, "state", "");
  }, [country]);
  return (
    <div>
      {props.showIsCurrentLocationLabels && (
        <div>
          <FormattedMessage
            tagName="h3"
            id="adda47a6-9912-4672-89d3-77dc5f92dc3b"
            defaultMessage="What country are you currently in? {asterisk}"
            values={{ asterisk: <RequiredAsterisk /> }}
          />
        </div>
      )}
      <CountrySelectWithRef
        aria-invalid={isAriaInvalid(errors?.country)}
        {...registerAddressField(props, "country", { required: true })}
      />
      {isStateRequiredForCountryLocation(country) && (
        <>
          {props.showIsCurrentLocationLabels && (
            <div>
              <FormattedMessage
                tagName="h3"
                id="1cf68d47-efdf-457f-b0cf-e0e50f1c25c4"
                defaultMessage="What state are you currently in? {asterisk}"
                values={{ asterisk: <RequiredAsterisk /> }}
              />
            </div>
          )}
          <StateSelectWithRef
            country={country}
            aria-invalid={isAriaInvalid(errors?.state)}
            {...registerAddressField(props, "state", { required: true })}
          />
        </>
      )}
    </div>
  );
}

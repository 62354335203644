import Env from "config/environment";

declare const window: Window & {
  analytics?: {
    initialized: boolean;
  };
};

type Payload = {
  obj: {
    properties?: {
      url?: string;
      search?: string;
      referrer?: string;
    };
    context?: {
      page?: {
        url: string;
        search: string;
        referrer: string;
      };
    };
  };
};

function removeSearch(url?: string) {
  if (!url) {
    return "";
  }

  return url.split("?")[0];
}

function cleanPayload(payload: Payload) {
  if (payload.obj.properties) {
    const propertiesUrl = payload.obj.properties.url;
    const propertiesSearch = payload.obj.properties.search;
    const propertiesReferrer = payload.obj.properties.referrer;

    if (propertiesUrl) {
      payload.obj.properties.url = removeSearch(propertiesUrl);
    }
    if (propertiesSearch) {
      payload.obj.properties.search = "";
    }
    if (propertiesReferrer) {
      payload.obj.properties.referrer = removeSearch(propertiesReferrer);
    }
  }

  if (payload.obj.context?.page) {
    const contextUrl = payload.obj.context.page.url;
    const contextReferrer = payload.obj.context.page.referrer;
    payload.obj.context.page.url = removeSearch(contextUrl);
    payload.obj.context.page.search = "";
    payload.obj.context.page.referrer = removeSearch(contextReferrer);
  }

  return payload;
}

/**
 * This is a (slightly) modified version of the "Segment snippet," copied from the non-minified
 * source from docs link below. Note and compare the `SNIPPET_VERSION` below to know when to update.
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
 */
function initialize(segmentId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (window.analytics ||= [] as any);

  if (analytics.initialize) {
    return;
  } else if (analytics.invoked) {
    // eslint-disable-next-line no-console
    return console.error("Segment snippet included twice.");
  }

  analytics.invoked = true;

  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "screen",
    "once",
    "off",
    "on",
    "addSourceMiddleware",
    "addIntegrationMiddleware",
    "setAnonymousId",
    "addDestinationMiddleware",
    "register",
  ] as const;
  analytics.factory = function (method: string) {
    return function (...args: unknown[]) {
      if (window.analytics?.initialized) {
        // Sometimes users assigned analytics to a variable before analytics is done loading, resulting in a stale reference.
        // If so, proxy any calls to the 'real' analytics instance.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (window.analytics as any)[method].apply(window.analytics, args);
      }

      // Add buffered page context object so page information is always up-to-date
      if (["track", "screen", "alias", "group", "page", "identify"].includes(method)) {
        const canoicalLink = document.querySelector("link[rel='canonical']");
        args.push({
          __t: "bpc",
          c: canoicalLink?.getAttribute("href") || undefined,
          p: location.pathname,
          u: location.href,
          s: location.search,
          t: document.title,
          r: document.referrer,
        });
      }

      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  for (const method of analytics.methods) {
    analytics[method] = analytics.factory(method);
  }
  analytics.load = function (key: string, options: unknown) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

    const [first] = document.getElementsByTagName("script");
    first.parentNode?.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = segmentId;
  analytics.SNIPPET_VERSION = "5.2.0";

  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/middleware/#adding-middlewares-to-analyticsjs
  analytics.addSourceMiddleware(
    ({ payload, next }: { payload: Payload; next: (payload: Payload) => void }) => {
      // Remove the search params from URLs to prevent PII from being sent to Segment
      const cleanedPayload = cleanPayload(payload);

      next(cleanedPayload);
    },
  );

  analytics.load(segmentId);
  // Snippet wants us to call analytics.page(), but we instead expect our router integration to do this
}

if (Env.segmentId) {
  initialize(Env.segmentId);
}

import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  type ComponentProps,
  type ReactNode,
  type ReactElement,
} from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { AuthTypes, Channel } from "graphql_globals";
import { DeprecatedMaskedField } from "common/form/fields/masked";
import Button from "common/core/button";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { useMutation } from "util/graphql";
import { withMinDelay } from "util/rxjs";
import { Paragraph } from "common/core/typography";
import AlertMessage from "common/core/alert_message";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

import InitiateChannelAuthenticationMutation from "./initiate_channel_authentication_mutation.graphql";
import { AuthGateModal, AuthGateForm, AuthGateAlertBanner, useAuthGateForm } from "..";
import Styles from "./index.module.scss";

type VerifyBannerProps = {
  error: ReturnType<typeof useAuthGateForm>["error"];
  last4: string;
  actionButton: ReactNode;
};

type SmsAuthGateAction<Actions> = {
  type: Actions;
  buttonProps: {
    onClick: () => void;
    disabled: boolean;
    isLoading: boolean;
  };
  testModeButtonProps: {
    onClick: () => void;
    disabled: boolean;
    isLoading: boolean;
  } | null;
};

const messages = defineMessages({
  tooltipTriggerLabel: {
    id: "ff92a27b-2c3c-49d0-92dd-a5e54750936e",
    defaultMessage: "More details about phone number verification",
  },
});

const EASYLINK_MFA_SEND_TEXT = "easylink-mfa-send-text";

export function enableAutoSend() {
  window.sessionStorage.setItem(EASYLINK_MFA_SEND_TEXT, "t");
}

function ResendCode({
  sendText,
  buttonClass,
  loadingClass,
}: {
  sendText: () => Promise<unknown>;
  buttonClass: string;
  loadingClass: string;
}) {
  const [loading, setLoading] = useState(false);
  return loading ? (
    <Paragraph className={loadingClass}>
      <FormattedMessage id="5bd5b398-d5d3-4676-a77a-a9f08d5aaf47" defaultMessage="Sending..." />
    </Paragraph>
  ) : (
    <Button
      variant="tertiary"
      buttonColor="action"
      className={buttonClass}
      onClick={() => {
        setLoading(true);
        withMinDelay(sendText())
          .subscribe()
          .add(() => setLoading(false));
      }}
    >
      <FormattedMessage
        id="92a0302b-ad4a-474c-98ca-a6d7044d219a"
        defaultMessage="Send a new code"
      />
    </Button>
  );
}

function VerifyBanner({ error, last4, actionButton }: VerifyBannerProps) {
  if (!error) {
    return (
      <AuthGateAlertBanner kind="success">
        <p>
          <FormattedMessage
            id="34784d6b-b4af-476b-999d-7f1c5755655d"
            defaultMessage="We’ve sent a text to <sensitive>***-***-{last4}</sensitive>."
            values={{ last4, sensitive: (text) => <b className={SENSITIVE_CLASS}>{text}</b> }}
          />
          {actionButton}
        </p>
      </AuthGateAlertBanner>
    );
  }

  if (error.type === "invalid") {
    return (
      <AuthGateAlertBanner kind="danger">
        <p>
          <FormattedMessage
            id="3cc26b9e-51af-4c93-81e3-1dd8575bf41f"
            defaultMessage="Incorrect code."
          />
          {actionButton}
        </p>
      </AuthGateAlertBanner>
    );
  }

  return (
    <AuthGateAlertBanner kind="danger">
      <p>
        {error.message}
        {actionButton}
      </p>
    </AuthGateAlertBanner>
  );
}

type SmsAuthGateProps = {
  subjectId: string;
  tooltip: ReactNode;
  last4: string;
  onSuccess?: ComponentProps<typeof AuthGateForm>["onSuccess"];
  children: (args: {
    content: ReactNode;
    action: SmsAuthGateAction<"initiate" | "submit">;
  }) => ReactElement;
  onUpdatePhoneNumber?: () => Promise<void>;
  password?: string;
};

function SmsAuthGate({
  subjectId,
  tooltip,
  last4,
  onSuccess,
  children,
  onUpdatePhoneNumber,
  password,
}: SmsAuthGateProps) {
  const intl = useIntl();
  // enable auto send text with enableAutoSend()
  const autoSendText = Boolean(window.sessionStorage.getItem(EASYLINK_MFA_SEND_TEXT));
  const resetPhoneEnabled = useMemo(() => autoSendText, []);
  const userCanUpdatePhone = resetPhoneEnabled && onUpdatePhoneNumber;

  const [step, setStep] = useState<"info" | "verify">(autoSendText ? "verify" : "info");
  const { error, invalid, submitting, submit, populateTestCode } = useAuthGateForm();
  const initiateChannelAuthenticationMutateFn = useMutation(InitiateChannelAuthenticationMutation);
  const sendText = useCallback(
    () =>
      initiateChannelAuthenticationMutateFn({
        variables: {
          input: {
            channel: Channel.SMS,
            subjectId,
          },
        },
      }),
    [subjectId],
  );

  useEffect(() => {
    if (autoSendText) {
      sendText();
      window.sessionStorage.removeItem(EASYLINK_MFA_SEND_TEXT);
    }
  }, []);

  const content = (
    <>
      {step === "info" && (
        <p>
          <FormattedMessage
            id="88900673-2b6f-4891-9832-bdbc6cad4495"
            defaultMessage="Please first verify your phone number <sensitive>(***-***-{last4})</sensitive>"
            values={{ last4, sensitive: (text) => <b className={SENSITIVE_CLASS}>{text}</b> }}
          />

          <Tooltip
            containerClassName={Styles.tooltipContainer}
            className={Styles.tooltip}
            target={<Icon className={Styles.tooltipIcon} name="question" />}
            triggerButtonLabel={intl.formatMessage(messages.tooltipTriggerLabel)}
            selfManagePlacement
          >
            {tooltip}
          </Tooltip>
          <p className={Styles.disclaimer}>
            <FormattedMessage
              id="45a9c65c-e092-4519-996b-794921e7a975"
              defaultMessage="Message and data rates may apply."
            />
          </p>
        </p>
      )}
      {step === "verify" && (
        <>
          <VerifyBanner
            error={error}
            last4={last4}
            actionButton={
              userCanUpdatePhone ? (
                <Button
                  variant="tertiary"
                  buttonColor="action"
                  className={Styles.bannerDisplayResendButton}
                  onClick={onUpdatePhoneNumber}
                >
                  <Paragraph>
                    <FormattedMessage
                      id="43efc562-348e-4462-b704-b9c576993515"
                      defaultMessage="Wrong number?"
                    />
                  </Paragraph>
                </Button>
              ) : (
                <ResendCode
                  sendText={sendText}
                  buttonClass={Styles.bannerDisplayResendButton}
                  loadingClass={Styles.bannerDisplayLoading}
                />
              )
            }
          />
          <AuthGateForm
            authType={AuthTypes.SMS}
            subjectId={subjectId}
            onSuccess={onSuccess}
            password={password}
            renderField={(formProps) => (
              <DeprecatedMaskedField
                {...formProps}
                label={
                  <FormattedMessage
                    id="4917932e-da83-4893-9429-a9f3fda589a2"
                    defaultMessage="Enter 6-digit verification code"
                  />
                }
                maskType="number"
                maxlength={6}
                placeholder="000000"
                useStyledInput
              />
            )}
          />
          {userCanUpdatePhone && (
            <ResendCode
              sendText={sendText}
              buttonClass={Styles.resendButton}
              loadingClass={Styles.loading}
            />
          )}
        </>
      )}
    </>
  );

  const action =
    step === "info"
      ? {
          type: "initiate" as const,
          buttonProps: {
            onClick: () => {
              sendText();
              setStep("verify");
            },
            disabled: false,
            isLoading: false,
          },
          testModeButtonProps: {
            onClick: () => {
              initiateChannelAuthenticationMutateFn({
                variables: {
                  input: {
                    channel: Channel.SMS,
                    subjectId,
                    testMode: true,
                  },
                },
              });
              setStep("verify");
              populateTestCode();
            },
            disabled: false,
            isLoading: false,
          },
        }
      : {
          type: "submit" as const,
          buttonProps: {
            onClick: () => {
              submit();
            },
            disabled: invalid,
            isLoading: submitting,
          },
          testModeButtonProps: null,
        };

  return children({ content, action });
}

type SmsAuthGateModalProps = {
  subjectId: string;
  title: ReactNode;
  tooltip: ReactNode;
  last4: string;
  refetch: () => Promise<unknown>;
  onUpdatePhoneNumber: () => Promise<void>;
  testMode: boolean;
};

function SmsAuthGateModal({
  refetch,
  subjectId,
  title,
  tooltip,
  last4,
  onUpdatePhoneNumber,
  testMode,
}: SmsAuthGateModalProps) {
  return (
    <SmsAuthGate
      onSuccess={refetch}
      subjectId={subjectId}
      tooltip={tooltip}
      last4={last4}
      onUpdatePhoneNumber={onUpdatePhoneNumber}
    >
      {({ content, action }) => (
        <AuthGateModal
          title={title}
          actionButton={
            action.type === "initiate" ? (
              <>
                <Button {...action.buttonProps} buttonColor="action" variant="primary">
                  <FormattedMessage
                    id="530ffe9f-6d22-438e-abcb-41b066ba3f85"
                    defaultMessage="Send verification text"
                  />
                </Button>
                {testMode && (
                  <AlertMessage className={Styles.alertMessage} kind="info" centerText>
                    <div className={Styles.testBoxContainer}>
                      <div className={Styles.text}>
                        <FormattedMessage
                          id="b8a02db9-ca5b-4786-aa52-0dc82ccf227f"
                          defaultMessage="Skip using code. Visible in test environments only."
                        />
                      </div>
                      <Button
                        {...action.testModeButtonProps}
                        buttonColor="action"
                        variant="primary"
                      >
                        <FormattedMessage
                          id="dbd74569-3445-4926-85dc-e6defb23e69a"
                          defaultMessage="Use test code"
                        />
                      </Button>
                    </div>
                  </AlertMessage>
                )}
              </>
            ) : (
              <Button {...action.buttonProps} buttonColor="action" variant="primary">
                <FormattedMessage
                  id="f4244942-56ed-46e4-935a-ac30217f123c"
                  defaultMessage="Verify"
                />
              </Button>
            )
          }
        >
          {content}
        </AuthGateModal>
      )}
    </SmsAuthGate>
  );
}

export { SmsAuthGateModal, SmsAuthGate, VerifyBanner, ResendCode };

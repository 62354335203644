import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import { DeprecatedEmailField } from "common/form/fields/email";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import EmailTypoWarningMessage from "common/core/warnings/email_typo";
import { EMAIL_DOMAIN_RESTRICTED_ERROR } from "errors/account";
import { type FormError } from "errors/util";

import Styles from "./index.module.scss";

type Props = {
  disabled?: boolean;
  placeholder?: string;
  email?: string;
  emailError?: FormError;
  fieldName?: string;
};

const messages = defineMessages({
  emailAddress: {
    id: "ecd064e0-dde0-470f-bc40-cdf823188737",
    defaultMessage: "Email address",
  },
  email: {
    id: "7154e3eb-b7f0-4e48-baf3-9a9ad8be1d4c",
    defaultMessage: "Email",
  },
});

function SignupEmailField({
  disabled,
  placeholder,
  email,
  emailError,
  fieldName = "email",
}: Props) {
  const intl = useIntl();

  return (
    <FormGroup
      fields={[fieldName]}
      className={classnames(
        Styles.signupEmailField,
        Styles.inputWrapper,
        emailError?.type === EMAIL_DOMAIN_RESTRICTED_ERROR && Styles.signupEmailFieldLargeError,
      )}
    >
      <DeprecatedEmailField
        name={fieldName}
        data-automation-id={`${fieldName}-field`}
        label={placeholder || intl.formatMessage(messages.emailAddress)}
        disabled={disabled}
        useNativeLabel
        useStyledInput
      />
      <FormGroupErrors fields={[fieldName]} />
      <EmailTypoWarningMessage
        email={email}
        disabled={Boolean(emailError) || disabled}
        className={Styles.typoWarning}
      />
    </FormGroup>
  );
}

export default SignupEmailField;

import { memo, type ComponentProps, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";

import Icon from "common/core/icon";
import { BUNDLE_PROCESSING_STATES } from "constants/document_bundle";
import { usePermissions } from "common/core/current_user_role";
import { DocumentBundleMenu } from "common/document_bundle/menu";
import DocumentNavigator from "common/document_bundle/document_navigator";
import SignAheadSimpleDocumentMenu from "common/document_bundle/sign_ahead.menu.simple";
import { ANNOTATION_SOURCES } from "constants/annotations";
import { downloadAs } from "util/http";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { swapExtension, swapExtensionForOriginalAsset } from "util/archive";
import type { LoadOptions } from "common/pdf/pspdfkit";
import { IconButton } from "common/core/button/icon_button";

import { DocumentBundleActionsContainer } from "./bundle_actions";
import DocumentDetailsPreviewHeader from "./document_details_preview_header";
import { TransactionDetailsPdf } from "./pspdfkit";
import type { DocumentBundleForTransactionDetailsMeetingDocument } from "./meeting_document_bundle_fragment.graphql";
import type { DocumentForTransactionDetailsMeetingDocument } from "./meeting_document_document_fragment.graphql";

type Props = {
  bundle: DocumentBundleForTransactionDetailsMeetingDocument;
  document: DocumentForTransactionDetailsMeetingDocument;
  onDocumentSelected: ComponentProps<typeof DocumentBundleMenu>["onDocumentSelected"];
  backPath?: () => void;
  downloadable?: boolean;
  shareable?: boolean;
  finalizedDocumentOnly?: boolean;
  showResponsiveView?: boolean;
  pdfEventOptions?: LoadOptions["pdfEventOptions"];
  simpleBundleMenu?: boolean;
};

const MESSAGES = defineMessages({
  documents: {
    id: "95b558b8-8ae8-4acd-9716-afa2322cd225",
    defaultMessage: "Documents",
  },
  back: {
    id: "60a3f7d2-0c54-4779-ad10-f18d092dcec0",
    defaultMessage: "Back",
  },
});

function MeetingDocument(props: Props) {
  const {
    document,
    bundle,
    backPath,
    finalizedDocumentOnly,
    showResponsiveView,
    pdfEventOptions,
    simpleBundleMenu,
  } = props;
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();
  const intl = useIntl();
  const location = useLocation();
  const [documentToggleSelected, setDocumentToggleSelected] = useState(false);

  if (finalizedDocumentOnly && !bundle.releasable) {
    return (
      <div className="NotarizationDetails-not-available">
        <div className="NotarizationDetails-info">
          <Icon className="icon" name="no-document" />
          <strong>
            {!bundle.paid ? (
              <FormattedMessage
                id="d9693f8c-fc52-4c6c-89c0-c997e962210d"
                defaultMessage="Documents not paid for yet."
              />
            ) : bundle.processingState === BUNDLE_PROCESSING_STATES.UNSIGNED ? (
              <FormattedMessage
                id="35652fbf-decc-4d79-8da7-c20634f6a8cd"
                defaultMessage="Not all documents signed yet."
              />
            ) : null}
          </strong>
        </div>
      </div>
    );
  }

  if (finalizedDocumentOnly && document.state === "processing") {
    return (
      <div className="NotarizationDetails-not-available">
        <div className="NotarizationDetails-info">
          <Icon className="icon" name="no-document" />
          <FormattedMessage
            id="fb07de0c-02b0-4900-b2ba-1753d215f89c"
            defaultMessage="Document has not finished processing yet."
            tagName="strong"
          />
        </div>
      </div>
    );
  }

  const showUnfinalizedDoc = !finalizedDocumentOnly && !bundle.releasable;
  const { s3UploadedAsset, finalAsset } = document;
  const finalAssetFileName = swapExtension(finalAsset?.filename ?? "");
  const uploadedAssetKey = swapExtensionForOriginalAsset(s3UploadedAsset?.key ?? "");

  return (
    <div
      className={
        showResponsiveView
          ? "NotarizationDetails-responsive NotarizationDetails-responsive-meeting-document-wrapper"
          : ""
      }
    >
      {backPath && !showResponsiveView && (
        <DocumentDetailsPreviewHeader documentName={document.name} backPath={backPath} />
      )}
      {showResponsiveView && backPath && (
        <DeprecatedDetailGridSection>
          <DeprecatedDetailGridRow
            className="NotarizationDetails-responsive-title"
            title={bundle.transaction.name || bundle.name || intl.formatMessage(MESSAGES.documents)}
          />
          <div className="NotarizationDetails-responsive-document-header">
            <IconButton
              name="arrow-left"
              onClick={backPath}
              variant="tertiary"
              buttonColor="action"
              buttonSize="condensed"
              label={intl.formatMessage(MESSAGES.back)}
              className={"back-button"}
            />
            {document.name}
          </div>
        </DeprecatedDetailGridSection>
      )}
      <div
        className={`DocumentBundleMenuContainer ${pdfEventOptions?.disableKeyEventForwarding ? "document-focus-state" : ""}`}
      >
        {simpleBundleMenu ? (
          <div className="simple-document-bundle-menu-wrapper">
            <DocumentNavigator
              selectedDocument={document}
              bundle={bundle}
              onDocumentSelected={props.onDocumentSelected}
              onDocumentListToggle={() => setDocumentToggleSelected(!documentToggleSelected)}
              documentToggleSelected={documentToggleSelected}
              hideShowAllButton={showResponsiveView}
              fluid={false}
            />

            {documentToggleSelected && (
              <SignAheadSimpleDocumentMenu
                hidden={!documentToggleSelected}
                bundle={bundle}
                onDocumentSelected={(d) => props.onDocumentSelected(d.id)}
                selectedDocument={document}
                disabled={false}
                isAnimated
              />
            )}
          </div>
        ) : (
          <DocumentBundleMenu
            readOnly
            bundle={bundle}
            selectedDocument={document}
            annotationSource={ANNOTATION_SOURCES.NONE}
            onDocumentSelected={props.onDocumentSelected}
            hideShowAllButton={showResponsiveView}
            complex
          />
        )}
        <div
          className={`NotarizationDetails-document ${
            bundle.documents.totalCount > 1 ? "NotarizationDetails-document-bundle" : ""
          } restrict-height`}
        >
          <TransactionDetailsPdf
            document={document}
            documentBundle={bundle}
            hideZoomButtons={showResponsiveView}
            hidePageInfo={showResponsiveView}
            pdfEventOptions={pdfEventOptions}
          />
        </div>
      </div>
      <DocumentBundleActionsContainer
        shareAction={
          props.shareable ? () => navigate(`docShare/${document.id}${location.search}`) : undefined
        }
        // if we're displaying an unfinalized doc, there is no final asset to download
        downloadAction={
          !showUnfinalizedDoc && props.downloadable && finalAsset?.url
            ? () => downloadAs(finalAsset.url!, finalAssetFileName!)
            : undefined
        }
        uploadedAssetDownloadAction={
          hasPermissionFor("assetDownloadAction") && s3UploadedAsset?.url
            ? () => downloadAs(s3UploadedAsset.url!, uploadedAssetKey!)
            : undefined
        }
        showDownloadWarning={bundle.downloadWarningEnabled}
      />
    </div>
  );
}

export default memo(MeetingDocument);

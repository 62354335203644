import { DeprecatedTextField } from "common/form/fields/text";

/** @deprecated - please use components in common/core/form */
export function DeprecatedPasswordField(props) {
  return <DeprecatedTextField {...props} />;
}

DeprecatedPasswordField.defaultProps = {
  id: "password",
  placeholder: "Password",
  type: "password",
  "data-automation-id": "password-field",
  name: "password",
};

DeprecatedPasswordField.propTypes = {
  ...DeprecatedTextField.propTypes,
};

import { useEffect, useState, type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import classnames from "classnames";

import {
  Column,
  Container,
  Row,
  useMatchScreenClass,
  useMobileScreenClass,
} from "common/core/responsive";
import Icon from "common/core/icon";
import {
  PopoutMenuMultilineDividerItem,
  PopoutMenuMultilineItem,
} from "common/core/popout_menu/multiline";
import PopoutMenu from "common/core/popout_menu";
import DownloadDocumentsWarningModal from "common/modals/download_documents_warning_modal";
import LoadingIndicator from "common/core/loading_indicator";
import { PointOfContactModal } from "common/core/point_of_contact_display";
import { OrgTransactionStates } from "graphql_globals";
import { useLogout } from "common/authentication";
import { useQuery } from "util/graphql";
import { useRetireSignerIdentities } from "common/signer/identity/retire";
import { openSupportChat, StandaloneChat, useSupportChatDisabled } from "common/support/chat";
import Button from "common/core/button";
import { SETTINGS_PATH } from "util/routes";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import WorkflowModal from "common/modals/workflow_modal";
import { segmentTrack } from "util/segment";

import { LogoNav } from "../logo_nav";
import type { OrganizationTransactionAppFrameDocumentBundle as DocumentBundle } from "./index.document_bundle.fragment.graphql";
import type { OrganizationTransactionAppFrameViewer as Viewer } from "./index.viewer.fragment.graphql";
import OrganizationTransactionAppFrameQuery from "./index.query.graphql";
import Styles from "./index.module.scss";
import { CustomerFacingWarning } from "../header/dev";

type ExitPreflowModalProps = {
  onClose: () => void;
  closeRoute: "logout" | "settings" | "dashboard";
};

const BLOCKED_PATH = "bundle/:documentBundleId/*";
const EXCLUDED_PATH = "bundle/:documentBundleId/view";

function ExitPreflowModal({ onClose, closeRoute }: ExitPreflowModalProps) {
  const navigate = useNavigate();
  const logout = useLogout();
  const retireSignerIdentities = useRetireSignerIdentities();
  return (
    <WorkflowModal
      autoFocus
      isSensitive={false}
      footerSeparator={false}
      title={
        <FormattedMessage
          id="8b2097ff-81e9-42bd-b01a-df34e028ddf9"
          defaultMessage="Are you sure you want to exit this transaction?"
        />
      }
      buttons={[
        <Button
          key="cancel"
          automationId="cancel-exit"
          variant="tertiary"
          buttonSize="large"
          onClick={() => {
            segmentTrack("Exit transaction warning modal - cancel clicked");
            return onClose();
          }}
        >
          <FormattedMessage id="468478a0-fefe-481e-bf90-734130e363dd" defaultMessage="Cancel" />
        </Button>,
        <Button
          automationId="exit-preflow"
          key="exit"
          buttonColor="danger"
          variant="primary"
          buttonSize="large"
          onClick={() => {
            retireSignerIdentities({ ignoreNoActive: true });
            segmentTrack("Exit transaction warning modal - exit clicked");
            return closeRoute === "settings"
              ? navigate(SETTINGS_PATH)
              : closeRoute === "logout"
                ? logout()
                : navigate("/");
          }}
        >
          <FormattedMessage id="1acaccf5-f808-4f91-af72-6e4c012ba8a8" defaultMessage="Exit" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="192a4db6-2956-4ec6-b753-aa6b9df3c788"
        defaultMessage="All progress will be lost."
      />
    </WorkflowModal>
  );
}

type Props = {
  viewer: Viewer;
  documentBundle: DocumentBundle;
  children: ReactNode;
  readOnly?: boolean;
  content?: ReactNode;
  enableReferralBranding?: boolean;
  removeNavLink?: boolean;
};

function RenderMenuTarget(open: boolean) {
  const intl = useIntl();

  return (
    <Button
      className={classnames(Styles.helpDropdown, {
        [Styles.open]: open,
      })}
      variant="tertiary"
      buttonColor="dark"
      aria-label={intl.formatMessage({
        id: "9002ea09-7ab9-4bbc-a864-1e2eedd218a5",
        defaultMessage: "Get help and account settings",
      })}
    >
      <div>
        <div className={Styles.verticallyCenter} data-automation-id="help-menu-dropdown-button">
          <Icon className={Styles.mainIcon} name="question" />
          <Icon className={Styles.caret} name={open ? "caret-up" : "caret-down"} />
        </div>
      </div>
    </Button>
  );
}

export function OrganizationTransactionAppFrame(props: Props) {
  // Anywhere using the OrganizationTransactionAppFrame will have the Zendesk support widget automatically hidden
  // since we give the user the option to open from the navbar dropdown.
  useEffect(() => {
    window.zE?.("webWidget", "hide");
  }, []);
  const [downloadWarningModalOpen, setDownloadWarningModalOpen] = useState(false);
  const [pointOfContactsModalOpen, setPointOfContactsModalOpen] = useState(false);
  const isLarge = useMatchScreenClass("lg", "xl", "xxl");
  const isMedium = useMatchScreenClass("xs", "sm", "md");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const shouldBlockExit =
    // do not block for /view route
    !matchPath({ path: EXCLUDED_PATH }, pathname) &&
    // block on all other premeeting routes
    matchPath({ path: BLOCKED_PATH }, pathname);
  useEffect(() => {
    if (isLarge) {
      setPointOfContactsModalOpen(false);
    }
  }, [isLarge]);

  const {
    children,
    documentBundle: { fullPackageDocument, organizationTransaction, brandingInfo, participants },
    readOnly,
    content,
    viewer,
    enableReferralBranding,
    removeNavLink,
  } = props;
  const [exitPreflowModalAction, setExitPreflowModalAction] = useState<
    "dashboard" | "logout" | "settings" | null
  >(null);
  // need to fall back to referralInfo since bundle is only linked to referral after signer identity is created
  const orgBrand =
    brandingInfo || (enableReferralBranding ? viewer.referralInfo?.organizationBrand : null);
  const headerStyle = orgBrand?.header;
  const logoUrl = orgBrand?.organizationLogoUrl;
  const orgName = orgBrand?.organizationName;
  const visibleContacts = organizationTransaction.contacts.filter(
    (contact) => contact.shownToSigner,
  );
  const logout = useLogout();
  const currentPortal = AppSubdomains[CURRENT_PORTAL];
  const isMobile = useMobileScreenClass();
  const isBundleParticipant = participants?.some(
    (participant) => participant?.userId === viewer.user?.id,
  );
  const canDownloadDocuments =
    isBundleParticipant &&
    fullPackageDocument &&
    ![OrgTransactionStates.COMPLETED, OrgTransactionStates.COMPLETED_WITH_REJECTIONS].includes(
      organizationTransaction.state!,
    );

  const allowOpeningOfContactsModal = Boolean(visibleContacts.length > 0 && isMedium);
  const supportchatDisabled = useSupportChatDisabled(!organizationTransaction.isRetail);

  return (
    <>
      <CustomerFacingWarning />
      <div className={Styles.container}>
        <Container fluid style={{ height: "100%" }}>
          <Row style={{ height: "100%" }}>
            <Column className={Styles.column}>
              <LogoNav
                shouldShowEnvIndicator
                readOnly={readOnly || removeNavLink}
                orgName={orgName}
                logoUrl={logoUrl}
                headerStyle={headerStyle}
              >
                {!isMobile && content}
              </LogoNav>
              <PopoutMenu
                className={Styles.popoutMenu}
                target={RenderMenuTarget}
                placement="bottomRight"
              >
                {() => (
                  <>
                    {!supportchatDisabled && (
                      <PopoutMenuMultilineItem
                        onClick={() => {
                          if (!readOnly) {
                            openSupportChat();
                          }
                        }}
                        primaryContent={
                          <FormattedMessage
                            id="a094ee7e-a941-42de-8fae-f18252d0c37d"
                            defaultMessage="Chat with support"
                          />
                        }
                      />
                    )}
                    {allowOpeningOfContactsModal && (
                      <PopoutMenuMultilineItem
                        onClick={() => {
                          if (!readOnly) {
                            setPointOfContactsModalOpen(true);
                          }
                        }}
                        primaryContent={
                          <FormattedMessage
                            id="db339f26-5595-4fa6-927c-aa0302bca25f"
                            defaultMessage="View contacts"
                          />
                        }
                      />
                    )}
                    {canDownloadDocuments && (
                      <PopoutMenuMultilineItem
                        onClick={() => {
                          if (!readOnly) {
                            setDownloadWarningModalOpen(true);
                          }
                        }}
                        primaryContent={
                          <FormattedMessage
                            id="4f72eb76-5e64-414d-aac2-fa0ef47c6d8a"
                            defaultMessage="Download all"
                          />
                        }
                      />
                    )}
                    {!viewer.limitedSession && (
                      <PopoutMenuMultilineItem
                        onClick={() => {
                          if (!readOnly) {
                            if (shouldBlockExit && currentPortal === AppSubdomains.customer) {
                              segmentTrack("Exit transaction warning modal shown");
                              setExitPreflowModalAction("settings");
                            } else {
                              navigate(SETTINGS_PATH);
                            }
                          }
                        }}
                        primaryContent={
                          <FormattedMessage
                            id="81f74ae4-c025-4adf-83b3-ad3954e9da99"
                            defaultMessage="Account settings"
                          />
                        }
                      />
                    )}
                    {!viewer.limitedSession && currentPortal === AppSubdomains.customer && (
                      <PopoutMenuMultilineItem
                        data-automation-id="my-documents-button"
                        onClick={() => {
                          if (!readOnly) {
                            if (shouldBlockExit) {
                              segmentTrack("Exit transaction warning modal shown");
                              setExitPreflowModalAction("dashboard");
                            } else {
                              navigate("/");
                            }
                          }
                        }}
                        primaryContent={
                          <FormattedMessage
                            id="36294d59-d882-413d-a1f1-256b22dfda8c"
                            defaultMessage="My documents"
                          />
                        }
                      />
                    )}
                    <PopoutMenuMultilineDividerItem />
                    <PopoutMenuMultilineItem
                      onClick={() => {
                        if (!readOnly) {
                          if (shouldBlockExit && currentPortal === AppSubdomains.customer) {
                            segmentTrack("Exit transaction warning modal shown");
                            setExitPreflowModalAction("logout");
                          } else {
                            logout();
                          }
                        }
                      }}
                      primaryContent={
                        <FormattedMessage
                          id="dc3e8dda-cfe4-467f-a2e6-8498eea543cd"
                          defaultMessage="Log out"
                        />
                      }
                    />
                  </>
                )}
              </PopoutMenu>
            </Column>
          </Row>
        </Container>
        <StandaloneChat showSupportButton={false} />
      </div>

      {downloadWarningModalOpen && (
        <DownloadDocumentsWarningModal
          onClose={() => {
            setDownloadWarningModalOpen(false);
          }}
          mergedDocument={fullPackageDocument!}
        />
      )}

      {exitPreflowModalAction !== null && (
        <ExitPreflowModal
          onClose={() => setExitPreflowModalAction(null)}
          closeRoute={exitPreflowModalAction}
        />
      )}

      {pointOfContactsModalOpen && (
        <PointOfContactModal
          contacts={visibleContacts}
          onClose={() => {
            setPointOfContactsModalOpen(false);
          }}
        />
      )}
      {isMobile && content}
      {children}
    </>
  );
}

export default function OrganizationTransactionAppFrameWrapper(props: {
  documentBundleId: string;
  children?: ReactNode;
  readOnly?: boolean;
  content?: ReactNode;
  enableReferralBranding?: boolean;
  removeNavLink?: boolean;
}) {
  const { documentBundleId, children, readOnly, content, enableReferralBranding, removeNavLink } =
    props;

  const { data, loading } = useQuery(OrganizationTransactionAppFrameQuery, {
    variables: { documentBundleId },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const { viewer, documentBundle } = data!;

  if (documentBundle?.__typename !== "DocumentBundle") {
    throw new Error(`Expected document bundle, got ${documentBundle?.__typename}.`);
  }

  return (
    <OrganizationTransactionAppFrame
      documentBundle={documentBundle}
      viewer={viewer}
      readOnly={readOnly}
      content={content}
      enableReferralBranding={enableReferralBranding}
      removeNavLink={removeNavLink}
    >
      {children}
    </OrganizationTransactionAppFrame>
  );
}

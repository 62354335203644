import { memo, useCallback, useEffect, useState } from "react";
import { reduxForm, type InjectedFormProps, SubmissionError, clearSubmitErrors } from "redux-form";
import { useDispatch } from "react-redux";

import { composeValidators } from "util/form";
import { validateIf, validatePresence, validateURL } from "validators/form";
import FormGroup from "common/form/group";
import SaveButton from "common/core/save_button";
import { DeprecatedTextField } from "common/form/fields/text";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import FormGroupErrors from "common/form/group_errors";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";

import UpsertDocmagicIntegrationMutation from "./upsert_docmagic_integration_mutation.graphql";
import type { DocMagicCredentials } from "./docmagic_fragment.graphql";
import Styles from "./docmagic.module.scss";

type Props = {
  organizationId: string;
  docMagicCredentials: DocMagicCredentials | null;
};

type InnerProps = InjectedFormProps<FormValues, Props, FormError> & Props;

type FormValues = DocMagicCredentials & { password: string; submissionError: string };

function DocMagicCredentialsForm({
  docMagicCredentials,
  organizationId,
  handleSubmit,
  initialize,
}: InnerProps) {
  const { username, accountId, apiBaseUri } = docMagicCredentials || {};
  useEffect(() => {
    initialize({ username, accountId, apiBaseUri });
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const dispatch = useDispatch();
  const handleFieldChange = useCallback(() => {
    setCanSave(true);
    dispatch(clearSubmitErrors("docMagicCredentials"));
  }, [dispatch]);

  const updateDocMagicCredentialsMutateFn = useMutation(UpsertDocmagicIntegrationMutation);
  const onSubmit = useCallback(
    (data: FormValues) => {
      const { username, accountId, password, apiBaseUri } = data;
      setSubmitting(true);
      return updateDocMagicCredentialsMutateFn({
        variables: {
          mutationInput: {
            organizationId,
            docmagicAccountId: accountId,
            docmagicUsername: username,
            docmagicPassword: password,
            docmagicApiBaseUri: apiBaseUri,
          },
        },
      })
        .then(() => setCanSave(false))
        .catch((error) => {
          const message = isGraphQLError(error)
            ? error.message
            : "Failed to update DocMagic credentials";
          return Promise.reject(
            new SubmissionError<FormValues, FormError>({
              submissionError: customMessage({ message }),
            }),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [organizationId, docMagicCredentials],
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup disableFormRowStyle fields={["username"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="username"
          name="username"
          placeholder="DocMagic Username"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docMagic-username"
        />
        <FormGroupErrors fields={["username"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["accountId"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="accountId"
          name="accountId"
          placeholder="DocMagic Account Id Number"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docMagic-accountId"
        />
        <FormGroupErrors fields={["accountId"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["apiBaseUri"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="apiBaseUri"
          name="apiBaseUri"
          placeholder="Custom URL (optional)"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docMagic-apiBaseUri"
        />
        <FormGroupErrors fields={["apiBaseUri"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["password"]}>
        <DeprecatedTextField
          className={Styles.input}
          id="password"
          name="password"
          type="password"
          placeholder="DocMagic Password"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="docMagic-password"
        />
        <FormGroupErrors fields={["password"]} />
      </FormGroup>
      <FormGroupErrors fields={["submissionError"]} />
      {canSave && (
        <SaveButton
          disabled={submitting}
          isLoading={submitting}
          className="is-form"
          title="Save Credentials"
        />
      )}
    </form>
  );
}

const validate = (values: FormValues) =>
  composeValidators(
    validatePresence({ field: "accountId", label: "DocMagic Account Id" }),
    validatePresence({ field: "username", label: "DocMagic Username" }),
    validateIf({
      field: "apiBaseUri",
      condition: (val: string) => !!val && val.length > 0,
      validation: validateURL({ field: "apiBaseUri", label: "DocMagic Custom URL" }),
    }),
    validatePresence({ field: "password", label: "DocMagic Password" }),
  )(values);

const formEnhancer = reduxForm<FormValues, Props, FormError>({
  form: "docMagicCredentials",
  validate,
});

export default memo(formEnhancer(DocMagicCredentialsForm));

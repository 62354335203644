import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { DeprecatedMaskedInput } from "common/form/inputs/masked_input";

type Props = {
  autoComplete: string;
  name: string;
  placeholder: string;
};

/** @deprecated - please use components in common/core/form */
export function DeprecatedSocialSecurityNumber(props: Props) {
  return (
    <DeprecatedMaskedInput {...props} maskType="ssn" className={`ssn-input ${SENSITIVE_CLASS}`} />
  );
}

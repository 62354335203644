import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { DeprecatedEditableInput } from "common/form/enhancers/editable_input";
import RequiredAsterisk from "common/core/form/required-asterisk";

import { DeprecatedSelectInput as SelectInputBase } from "./input";

const EnhancedInput = DeprecatedEditableInput(SelectInputBase);

/**
 * The following component is meant to be the base Notarize styled SelectInput.
 * The lower level SelectInput exists as well, but is only meant to be
 * a very primitive behavioral component for simple use cases.
 * There's an existing TODO to migrate styles from the global form.scss into
 * the styling for this component, and then remove those global styles.
 * Some work will likely be needed to get other components using StyledSelectInput
 * if they're not already.
 * https://notarize.atlassian.net/browse/CORE-1214
 **/

/** @deprecated - please use components in common/core/form */
export class DeprecatedStyledSelectInput extends Component {
  constructor(props) {
    super(props);
    this.generatedId = uuidv4();
  }

  state = {
    inputValue: "",
  };

  render() {
    const {
      className,
      value,
      label,
      useNativeLabel,
      placeholder,
      placeholderAsLabel,
      displayRequiredAsterisk,
      disabled,
      searchable,
      clearable,
      onInputRefSet,
      automationId,
      onFocus,
      onBlur,
      onInputChange,
      ...otherProps
    } = this.props;

    const id = otherProps.id || this.generatedId;

    const { inputValue, selected } = this.state;

    // Placeholder = your typical placeholder
    // Label = the small header (eyebrow) embedded inside the input
    // If you use placeholderAsLabel, then the placeholder will
    // act as the label when the placeholder isn't itself needed
    // (e.g. if there's a value in the field)

    const cx = classnames(className, "StyledSelect", SENSITIVE_CLASS, {
      "is-disabled": disabled,
      "is-searchable": searchable,
      "use-native-label": useNativeLabel,
    });

    const inputCx = classnames("StyledSelect--input", {
      lower: (!useNativeLabel && label) || (placeholder && placeholderAsLabel),
    });

    const placeholderCx = classnames("StyledSelect--placeholder", {
      lower: !useNativeLabel && label && !placeholderAsLabel,
      hover: placeholderAsLabel && (value || selected),
      "use-native-label": useNativeLabel,
    });

    let labelElement = null;

    if (label) {
      if (useNativeLabel) {
        labelElement = (
          <label className="StyledSelect--native-label" htmlFor={id}>
            {label}
            {displayRequiredAsterisk && <RequiredAsterisk />}
          </label>
        );
      } else {
        labelElement = (
          <span aria-hidden="true" className="StyledSelect--label">
            {label}
          </span>
        );
      }
    }

    let placeholderElement = null;
    if (
      placeholder &&
      (!inputValue || (inputValue && placeholderAsLabel)) &&
      (!value || (value && placeholderAsLabel))
    ) {
      placeholderElement = (
        <span aria-hidden="true" className={placeholderCx}>
          {placeholder}
          {!useNativeLabel && displayRequiredAsterisk && <RequiredAsterisk />}
        </span>
      );
    }

    if (otherProps.multi && label) {
      // WARN: According to designers, there's no design solution yet for fitting
      // the multiselect tags in a Select box that already has a label in it.
      // It'll work, but it won't look good: so I have a promise from the designers
      // to figure something out, but to not use it in the meantime.
    }

    return (
      <div className={cx} data-automation-id={automationId}>
        {useNativeLabel && labelElement}
        <SelectInputBase
          {...otherProps}
          value={value}
          disabled={disabled}
          // styling overrides
          className={inputCx}
          placeholder={null}
          searchable={searchable}
          clearable={clearable}
          onInputRefSet={onInputRefSet}
          onInputChange={(inputValue) => {
            this.setState({ inputValue }, () => {
              onInputChange(inputValue);
            });
          }}
          onFocus={(e) => {
            onFocus?.(e);
            this.setState({ selected: true });
          }}
          onBlur={(e) => {
            onBlur?.(e);
            this.setState({ selected: false });
          }}
          id={id}
        />
        {!useNativeLabel && labelElement}
        {placeholderElement}
      </div>
    );
  }
}

DeprecatedStyledSelectInput.propTypes = {
  ...SelectInputBase.propTypes,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  useNativeLabel: PropTypes.bool,
  placeholderAsLabel: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  onInputRefSet: PropTypes.object,
};

DeprecatedStyledSelectInput.defaultProps = {
  ...SelectInputBase.defaultProps,
  searchable: true,
  clearable: true,
  onInputChange: () => {},
};

// And here's the current default
/** @deprecated - please use components in common/core/form */
export class DeprecatedSelectInput extends Component {
  render() {
    const { editable, ...otherProps } = this.props;

    // TODO: move this editable enhancer onto the StyledSelect
    // because it's a style, not a behavioral thing
    // https://notarize.atlassian.net/browse/CORE-1214
    return editable ? <EnhancedInput {...otherProps} /> : <SelectInputBase {...otherProps} />;
  }
}

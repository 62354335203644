import "./eoriginal_testing_form.scss";

import { useState, type ReactElement } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";

import compose from "util/compose";
import { DeprecatedTextField } from "common/form/fields/text";
import FormGroup from "common/form/group";
import SaveButton from "common/core/save_button";
import { useMutation } from "util/graphql";
import asyncJobsEnhancer from "common/core/async_jobs/async_jobs_enhancer";
import { getFormValues } from "util/form";

import EOriginalTestingFormErrorModal from "./eoriginal_testing_form_error_modal";
import type { AdminEoriginalTestingFormOrganization as Organization } from "./eoriginal_testing_form_fragment.graphql";
import CreateTestEnoteMutation from "./create_test_enote_mutation.graphql";

type FormValues = {
  minValue: string;
};
type Props = {
  organization: Organization;
  refetch: () => Promise<unknown>; // inside asyncJobsEnhancer
};
type InnerProps = Props &
  InjectedFormProps<FormValues, Props> & {
    pendingAsyncJobs: unknown[];
    asyncJobErrors: unknown[];
    dismissAsyncJobErrors: () => void;
  };

function EOriginalTestingForm(props: InnerProps) {
  const [submitting, setSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const createTestEnoteMutateFn = useMutation(CreateTestEnoteMutation);

  const submitMinNumber = (values: FormValues) => {
    setSubmitting(true);
    createTestEnoteMutateFn({
      variables: {
        input: {
          organizationId: props.organization.id,
          minNumber: values.minValue,
        },
      },
    }).finally(() => {
      setSubmitting(false);
      setCanSubmit(false);
    });
  };

  return (
    <form
      className="EOriginalTestingForm--form"
      autoComplete="off"
      onSubmit={props.handleSubmit(submitMinNumber)}
    >
      <FormGroup>
        <DeprecatedTextField
          id="minValue"
          name="minValue"
          className="EOriginalTestingForm--field"
          placeholder="MIN Number"
          onChange={() => setCanSubmit(true)}
        />
      </FormGroup>
      {canSubmit && (
        <SaveButton
          disabled={Boolean(submitting || props.pendingAsyncJobs.length)}
          isLoading={submitting}
          className="is-form EOriginalTestingForm--submit-button"
          title="Create eNote"
        />
      )}
      <EOriginalTestingFormErrorModal
        errors={props.asyncJobErrors}
        isOpen={Boolean(props.asyncJobErrors.length)}
        onClose={props.dismissAsyncJobErrors}
      />
    </form>
  );
}

export default compose(
  asyncJobsEnhancer,
  reduxForm({ form: "eoriginalTestingFormForm" }),
  getFormValues("eoriginalTestingFormForm"),
)(EOriginalTestingForm) as unknown as (props: Props) => ReactElement;

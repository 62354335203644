import "./lender_eligibility.scss";

import { useState } from "react";

import type { MortgageTransactionType } from "graphql_globals";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedSelectInput } from "common/form/inputs/select";
import LenderEligibilityRow from "admin_portal/company/mortgage/lender_eligibility_row";
import { useMutation } from "util/graphql";

import UpdateLenderEligibilitiesMutation from "./update_lender_eligibilities_mutation.graphql";

type Props = {
  viewer: { usStates: ({ id: string; name: string | null } | null)[] | null };
  organization: {
    id: string;
    lenderEligibilities: {
      transactionTypes: (MortgageTransactionType | null)[];
      usState: { id: string; name: string | null };
    }[];
  };
};

function LenderEligibilities(props: Props) {
  const { organization } = props;
  const [currentEligibilities, setCurrentEligibilities] = useState(
    organization.lenderEligibilities,
  );
  const [selectedUsStates, setSelectedUsStates] = useState(
    organization.lenderEligibilities.map((eligibility) => eligibility.usState.id),
  );
  const updateLenderEligiblitiesMutateFn = useMutation(UpdateLenderEligibilitiesMutation);

  const updateStateLevelEligibilities = (oldUsStateId: string) => {
    setCurrentEligibilities((c) =>
      c.filter((eligibility) => eligibility.usState.id !== oldUsStateId),
    );
    setSelectedUsStates((c) => c.filter((usStateId) => usStateId !== oldUsStateId));
  };

  const destroyStateLevelEligibility = async (oldUsStateId: string) => {
    await updateLenderEligiblitiesMutateFn({
      variables: {
        input: {
          organizationId: organization.id,
          transactionTypes: [],
          usStateId: oldUsStateId,
        },
      },
    });
    updateStateLevelEligibilities(oldUsStateId);
  };

  const onUsStatesChange = (updatedUsStates: string[]) => {
    const newUsStateId = updatedUsStates
      .filter((usStateId) => !selectedUsStates.includes(usStateId))
      .pop();

    if (newUsStateId) {
      const newUsState = props.viewer.usStates!.find((usState) => usState!.id === newUsStateId)!;
      const newEligibility = { usState: newUsState, transactionTypes: [] };
      setCurrentEligibilities((c) => [newEligibility, ...c]);
      setSelectedUsStates(updatedUsStates);
      return;
    }
    // we've removed a US State
    const oldUsStateId = selectedUsStates
      .filter((usStateId) => !updatedUsStates.includes(usStateId))
      .pop();
    destroyStateLevelEligibility(oldUsStateId!);
  };

  return (
    <div>
      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow className="LenderEligibilityRow">
          <div className="multiSelectLabel">
            <span className="multiSelectLabel--text">Us States of Operation</span>
          </div>
          <DeprecatedSelectInput
            items={props.viewer.usStates!.map((usState) => ({
              label: usState!.name,
              value: usState!.id,
            }))}
            placeholder="US States"
            onChange={onUsStatesChange}
            value={selectedUsStates}
            autosize={false}
            multi
          />
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>

      {currentEligibilities.map((eligibility) => (
        <LenderEligibilityRow
          key={eligibility.usState.name}
          eligibility={eligibility}
          organization={organization}
          removeStateCallback={updateStateLevelEligibilities}
        />
      ))}
    </div>
  );
}

export default LenderEligibilities;

import { type ReactNode, useRef, type KeyboardEvent } from "react";

import { clamp } from "util/number";

type Props = {
  children: ReactNode;
  className: string;
};

/** @deprecated - please use components in common/core/form */
export function DeprecatedRadioGroupContainer({ children, className }: Props) {
  const indexRef = useRef(0);

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).getAttribute("role") !== "radio") {
      return;
    }
    const items = e.currentTarget.querySelectorAll<HTMLElement>('[role="radio"]');
    const max = items.length - 1;
    let index = indexRef.current;

    if (e.key === "ArrowDown" || e.key === "ArrowRight") {
      //prevent default behavior of tab key to trap tabbing within menu
      e.preventDefault();
      index = index >= max ? 0 : clamp(index + 1, 0, max);
      indexRef.current = index;
      items[index].focus();
      items[index].click();
    } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
      e.preventDefault();
      index = index === 0 ? max : clamp(index - 1, 0, max);
      indexRef.current = index;
      items[index].focus();
      items[index].click();
    }
  };

  return (
    <div className={className} role="radiogroup" onKeyDown={handleKeyDown}>
      {children}
    </div>
  );
}

import { memo, PureComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { useSelector } from "redux/util";
import { useViewer } from "util/viewer_wrapper";
import { hardNavigateTo } from "util/navigation";
import { useIsAuthenticated } from "common/authentication";
import { login, loginReset, googleLogin } from "redux/actions/authentication";
import { segmentAddAnonymousIdToUrl, segmentTrack } from "util/segment";
import { pendoInitialize } from "util/pendo";
import { computeDefaultType } from "common/account/util";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import type Apps from "constants/applications";
import type { ReduxState } from "redux/reducers/app";
import { useAppDispatch } from "redux/hooks";

import Login, { LOGIN_ATTEMPTS_EXCEEDED } from "./login";
import type { AuthCode } from "../google_signin_button";

type Props = {
  isAuthenticated: boolean;
  dispatch: ReturnType<typeof useAppDispatch>;
  entry: (typeof Apps)[keyof typeof Apps];
  authentication: ReduxState["authentication"];
  email: string | null;
  redirect: string | null;
  refetchViewer: () => Promise<unknown>;
  signUpPortal?: (typeof Apps)[keyof typeof Apps] | null;
};

class AccountLogin extends PureComponent<Props> {
  componentDidMount() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.redirectAfterAuthenticated();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isAuthenticated } = this.props;

    if (isAuthenticated !== prevProps.isAuthenticated && isAuthenticated) {
      this.redirectAfterAuthenticated();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(loginReset());
  }

  redirectAfterAuthenticated(redirect?: Props["redirect"]) {
    const toUrl = this.getRedirectLink(redirect);
    segmentTrack("Login Authentictated -- Redirecting...", { toUrl });
    hardNavigateTo(toUrl);
  }

  getRedirectLink(redirect?: Props["redirect"]) {
    if (!redirect) {
      return "portal-gateway";
    }

    // short term fix ETX-1123
    return redirect.includes("approve-user-redirect")
      ? redirect
      : segmentAddAnonymousIdToUrl(decodeURIComponent(redirect));
  }

  handleGoogleSignIn = (authCode: AuthCode) => {
    const { dispatch, entry, redirect } = this.props;

    const redirectUrl = this.getRedirectLink(redirect);
    segmentTrack("Attempted Google Login");
    dispatch(
      googleLogin({
        authCode,
        entry: computeDefaultType(entry),
        redirectUrl,
        accountType: undefined,
        onSuccess: undefined,
        noRedirect: false,
      }),
    );
  };

  loginWithPassword = ({ email, password }: { email: string; password: string }) => {
    const { dispatch, entry, redirect, refetchViewer } = this.props;
    const redirectUrl = this.getRedirectLink(redirect);
    segmentTrack("Attempted Password Login");
    return dispatch(
      login({
        email,
        password,
        redirectUrl,
        errorOnFail: true,
        clearPasswordOnError: true,
        entry: computeDefaultType(entry),
        onSuccess: undefined,
      }),
    ).then((mfaData: ReturnType<typeof login>) => {
      refetchViewer();
      return mfaData;
    });
  };

  onClearErrors = () => {
    this.props.dispatch(loginReset());
  };

  render() {
    const { authentication, signUpPortal, email } = this.props;

    const loginAttemptsExceeded = authentication.error.error === LOGIN_ATTEMPTS_EXCEEDED;
    return (
      <Login
        emailFromParams={email}
        onGoogleSignIn={this.handleGoogleSignIn}
        loginWithPassword={this.loginWithPassword}
        loginAttemptsExceeded={loginAttemptsExceeded}
        onClearErrors={this.onClearErrors}
        onSuccess={() => this.redirectAfterAuthenticated(this.props.redirect)}
        signUpPortal={signUpPortal}
      />
    );
  }
}

const Wrapper = memo(
  ({
    entry,
    signUpPortal = null,
  }: {
    entry: (typeof Apps)[keyof typeof Apps];
    signUpPortal?: (typeof Apps)[keyof typeof Apps] | null;
  }) => {
    const intl = useIntl();
    useA11y().useDocumentEntitler({ title: intl.formatMessage(useDocumentTitles().signIn) });
    const authentication = useSelector((state) => state.authentication);
    const [searchParams] = useSearchParams();
    const { refetch } = useViewer();
    const dispatch = useAppDispatch();

    useEffect(() => {
      pendoInitialize();
    }, []);

    return (
      <AccountLogin
        entry={entry}
        isAuthenticated={useIsAuthenticated()}
        dispatch={dispatch}
        authentication={authentication}
        email={searchParams.get("email")}
        redirect={searchParams.get("redirect")}
        refetchViewer={refetch}
        signUpPortal={signUpPortal}
      />
    );
  },
);

export default Wrapper;

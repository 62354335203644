import { type ReactNode, useEffect, useState } from "react";
import { FormattedMessage, useIntl, defineMessages, type IntlShape } from "react-intl";
import classnames from "classnames";

import { DocumentAnchorStrategy } from "graphql_globals";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { SUPPORT_HOST } from "constants/support";
import ActionButton from "common/core/action_button";
import Button from "common/core/button";
import { useForm } from "common/core/form";
import { TextAreaInput, TextInput } from "common/core/form/text";
import { useFeatureFlag } from "common/feature_gating";
import { useMutation } from "util/graphql";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import { Paragraph } from "common/core/typography";
import { useId } from "util/html";
import { IconButton } from "common/core/button/icon_button";
import AlertMessage from "common/core/alert_message";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { FieldErrorMessage } from "common/core/form/error";
import { FormRow } from "common/core/form/layout";

import Styles from "./index.module.scss";
import type { AnnotationAnchor } from "./annotation_anchor_fragment.graphql";
import type { AnnotationDesignationAnchor } from "./annotation_designation_anchor_fragment.graphql";
import UpsertDocumentAnchorMutation from "./upsert_document_anchor_mutation.graphql";
import type { AnnotationInstruction } from "./annotation_instruction_fragment.graphql";
import UpdateAnnotationInstruction from "./update_annotation_instruction_mutation.graphql";
import type { AnnotationDesignationInstruction } from "./annotation_designation_instruction_fragment.graphql";
import UpdateAnnotationDesignationInstruction from "./update_annotation_designation_instruction_mutation.graphql";

const MESSAGES = defineMessages({
  anchorTextValue: {
    id: "5a6610df-cf1f-4412-89ed-7c989f911d18",
    defaultMessage: "anchor text value",
  },
  genericError: {
    id: "7b0f046a-3054-453d-bd9f-39229116cbcb",
    defaultMessage: "Sorry, an error occured. Please try again.",
  },
  documentAnchorTextNotFound: {
    id: "d294e21c-1cae-4f6e-96f4-3dd7bbe0ff85",
    defaultMessage: "This text does not exist within the document.",
  },
  anchorTextSuccess: {
    id: "21156ad3-7bba-4840-b2c1-bb19d4e26b1f",
    defaultMessage: "Anchor text updated",
  },
  fieldInstructionValue: {
    id: "48a74b53-af03-407f-b5ec-5ce920de9e3e",
    defaultMessage: "field instruction value",
  },
  fieldInstructionSuccess: {
    id: "0f292f2b-f8d7-47fd-8c91-506c6654ebc0",
    defaultMessage: "Field instruction updated",
  },
  maxLengthError: {
    id: "d53996f8-14d9-4981-9d5f-584a82026432",
    defaultMessage: "Maximum character limit exceeded. {length}/500",
  },
  cannotBeEdited: {
    id: "0c847928-3a6c-4cbb-a987-14649f3b830c",
    defaultMessage:
      "{isAnnotation, select, true {Annotation} other {Designation}} cannot be edited.",
  },
});

const pushErrorNotification = (intl: IntlShape) => {
  pushNotification({
    type: NOTIFICATION_TYPES.DEFAULT,
    subtype: NOTIFICATION_SUBTYPES.ERROR,
    message: intl.formatMessage(MESSAGES.genericError),
    position: "topCenter",
  });
};

export function Section({
  disabled = false,
  children,
}: {
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <section className={classnames(Styles.section, disabled && Styles.sectionDisabled)}>
      {children}
    </section>
  );
}

export function SectionHeader({
  title,
  automationId = "additional-tools-section-header",
  headerAction = null,
  isOpen,
  toggleSection,
  sectionId,
}:
  | {
      title: ReactNode;
      automationId?: string;
      headerAction: ReactNode;
      isOpen?: never;
      toggleSection?: never;
      sectionId?: never;
    }
  | {
      title: ReactNode;
      automationId?: string;
      headerAction?: never;
      isOpen: boolean;
      toggleSection: (newValue: boolean) => void;
      sectionId: string;
    }) {
  return headerAction ? (
    <div className={Styles.sectionHeader} data-automation-id={automationId}>
      <div className={Styles.sectionHeaderTitle}>{title}</div>
      {headerAction}
    </div>
  ) : (
    <ActionButton
      className={classnames(Styles.sectionHeader, {
        [Styles.sectionHeaderClickable]: Boolean(toggleSection),
      })}
      onClick={() => {
        toggleSection && toggleSection(!isOpen);
      }}
      color="dark"
      aria-expanded={isOpen}
      aria-controls={sectionId}
      automationId={automationId}
    >
      <div className={Styles.sectionHeaderTitle}>{title}</div>
      {Boolean(toggleSection) && <Icon name={isOpen ? "caret-up" : "caret-down"} />}
    </ActionButton>
  );
}

export function DeleteButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      name="delete"
      variant="secondary"
      buttonColor="danger"
      label={
        <FormattedMessage
          id="00907236-78ec-40d1-b85d-0db8495e8447"
          defaultMessage="Delete selected field"
        />
      }
      onClick={onClick}
    />
  );
}

export function Footer({ children }: { children: ReactNode }) {
  return (
    <div className={Styles.footer}>
      <div className={Styles.footerItems}>{children}</div>
    </div>
  );
}

type AdditionalMessage = {
  type: "error" | "warning";
  message: ReactNode;
};

type AdditionalToolsFormControls = {
  additionalMessage: AdditionalMessage | null;
  setAdditionalMessage: (val: AdditionalMessage | null) => void;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  toolName: "field-instructions" | "anchor-text";
};

type AdditionalToolsFormButtonsProps = {
  canCancel: boolean;
  onSave: () => void;
  setValue: () => void;
  disabled: boolean;
} & Pick<AdditionalToolsFormControls, "isEditing" | "setIsEditing" | "toolName">;

function AdditionalToolsFormWrapper({
  children,
  description,
  title,
  toolName,
}: {
  children: (controls: AdditionalToolsFormControls) => ReactNode;
  description: ReactNode;
  title: ReactNode;
  toolName: "field-instructions" | "anchor-text";
}) {
  const sectionId = useId();
  const [showContent, setShowContent] = useState(false);
  const [additionalMessage, setAdditionalMessage] = useState<AdditionalMessage | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Section>
      <SectionHeader
        title={title}
        isOpen={showContent}
        toggleSection={setShowContent}
        sectionId={sectionId}
        automationId={`${toolName}-section-header`}
      />
      {showContent && (
        <div className={Styles.toolsSection} id={sectionId}>
          <Paragraph textColor="subtle" size="small">
            {description}
          </Paragraph>
          {additionalMessage?.type === "warning" && additionalMessage.message}
          {children({
            additionalMessage,
            setAdditionalMessage,
            isEditing,
            setIsEditing,
            isLoading,
            setIsLoading,
            toolName,
          })}
        </div>
      )}
    </Section>
  );
}

function AdditionalToolsFormButtons({
  canCancel,
  isEditing,
  setIsEditing,
  onSave,
  setValue,
  disabled,
  toolName,
}: AdditionalToolsFormButtonsProps) {
  return isEditing ? (
    <div className={Styles.toolsButtons}>
      {canCancel && (
        <Button
          buttonColor="dark"
          variant="tertiary"
          onClick={() => {
            setValue();
            setIsEditing(false);
          }}
        >
          <FormattedMessage id="c04b6d69-aba4-4b2b-bcc6-84b54503bd06" defaultMessage="Cancel" />
        </Button>
      )}
      <Button
        buttonColor="action"
        variant="secondary"
        onClick={onSave}
        disabled={disabled}
        data-automation-id={`${toolName}-save-button`}
      >
        <FormattedMessage id="6ee7a7fd-053b-411d-80c8-2fb525af7f1d" defaultMessage="Save" />
      </Button>
    </div>
  ) : (
    <Button
      buttonColor="action"
      variant="tertiary"
      onClick={() => {
        setIsEditing(true);
      }}
      withIcon={{ name: "pencil-filled", placement: "left" }}
      data-automation-id={`${toolName}-edit-button`}
    >
      <FormattedMessage id="c7571295-9533-4ab9-b8be-20bba32624a0" defaultMessage="Edit" />
    </Button>
  );
}

type AnnotationOrDesignationAnchorText =
  | {
      selectedDesignation: AnnotationDesignationAnchor;
      selectedAnnotation?: never;
    }
  | {
      selectedDesignation?: never;
      selectedAnnotation: AnnotationAnchor;
    };

type AnchorTextFormValues = {
  anchorTextValue: string;
};

function AnchorTextForm({
  selectedAnnotation,
  selectedDesignation,
  additionalMessage,
  setAdditionalMessage,
  isEditing,
  setIsEditing,
  isLoading,
  setIsLoading,
  toolName,
}: AnnotationOrDesignationAnchorText & AdditionalToolsFormControls) {
  const intl = useIntl();

  const existingAnchorText = (selectedDesignation || selectedAnnotation).documentAnchor?.anchorText;
  const upsertDocumentAnchor = useMutation(UpsertDocumentAnchorMutation);
  const { register, watch, setValue } = useForm<AnchorTextFormValues>({
    defaultValues: {
      anchorTextValue: existingAnchorText || "",
    },
  });
  const anchorTextValue = watch("anchorTextValue");

  // we want to allow edit right away if no anchor text yet
  useEffect(() => {
    setIsEditing(!existingAnchorText);
  }, []);

  const onSave = async () => {
    setIsLoading(true);
    setAdditionalMessage(null);
    try {
      const result = await upsertDocumentAnchor({
        variables: {
          input: {
            annotationDesignationId: selectedDesignation?.id,
            annotationId: selectedAnnotation?.id,
            strategy: DocumentAnchorStrategy.FIRST,
            anchorData: { text: anchorTextValue },
          },
        },
      });
      if (result.data?.upsertDocumentAnchor?.message === "multiple_anchor_positions") {
        setAdditionalMessage({
          type: "warning",
          message: (
            <AlertMessage kind="warning">
              <FormattedMessage
                id="f72149c3-1bc9-48bf-a575-0a252f2980b9"
                defaultMessage="This text is not unique. The first instance of this text on the document will be used as the anchor."
              />
            </AlertMessage>
          ),
        });
      }
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        message: intl.formatMessage(MESSAGES.anchorTextSuccess),
        position: "topCenter",
      });
      setIsEditing(false);
    } catch (error) {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        if (error.graphQLErrors[0].message === "document_anchor_text_not_found") {
          setAdditionalMessage({
            type: "error",
            message: intl.formatMessage(MESSAGES.documentAnchorTextNotFound),
          });
        } else {
          pushErrorNotification(intl);
        }
      } else {
        captureException(error);
        pushErrorNotification(intl);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormRow fullWidth>
        <TextInput
          {...register("anchorTextValue")}
          aria-invalid={Boolean(additionalMessage?.type === "error")}
          aria-label={intl.formatMessage(MESSAGES.anchorTextValue)}
          disabled={!isEditing || isLoading}
          data-automation-id="anchor-text-input"
        />
        {additionalMessage?.type === "error" && (
          <FieldErrorMessage
            className={Styles.formError}
            inputName="anchorTextValue"
            message={additionalMessage.message}
          />
        )}
      </FormRow>
      <AdditionalToolsFormButtons
        canCancel={Boolean(existingAnchorText)}
        disabled={(anchorTextValue.length === 0 && !existingAnchorText) || isLoading}
        setValue={() => setValue("anchorTextValue", existingAnchorText || "")}
        onSave={onSave}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        toolName={toolName}
      />
    </>
  );
}

export function AnchorText(props: AnnotationOrDesignationAnchorText) {
  const isFeatureEnabled = useFeatureFlag("anchor-text-fields");

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <AdditionalToolsFormWrapper
      title={
        <FormattedMessage id="1ca7904e-7c42-444a-962c-ff132e6987d9" defaultMessage="Anchor text" />
      }
      description={
        <FormattedMessage
          id="da9a9a90-0530-4304-ae52-b2e08847df18"
          defaultMessage="The field will anchor to the first instance of matching text on the page. <link>Learn more</link>"
          values={{
            link: (text) => (
              <Link href={`${SUPPORT_HOST}/hc/en-us/articles/24817258676375-Anchor-Tags`}>
                {text}
              </Link>
            ),
          }}
        />
      }
      toolName="anchor-text"
    >
      {(controls) => <AnchorTextForm {...props} {...controls} />}
    </AdditionalToolsFormWrapper>
  );
}

type AnnotationOrDesignationFieldInstruction =
  | {
      selectedDesignation: AnnotationDesignationInstruction;
      selectedAnnotation?: never;
    }
  | {
      selectedDesignation?: never;
      selectedAnnotation: AnnotationInstruction;
    };

type FieldInstructionsFormValues = {
  fieldInstruction: string;
};

function FieldInstructionForm({
  selectedAnnotation,
  selectedDesignation,
  additionalMessage,
  setAdditionalMessage,
  isEditing,
  setIsEditing,
  isLoading,
  setIsLoading,
  toolName,
}: AnnotationOrDesignationFieldInstruction & AdditionalToolsFormControls) {
  const intl = useIntl();
  const existingFieldInstruction = (selectedAnnotation || selectedDesignation).instruction;
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FieldInstructionsFormValues>({
    defaultValues: {
      fieldInstruction: existingFieldInstruction || "",
    },
    mode: "onChange",
  });
  const fieldInstructionValue = watch("fieldInstruction");

  // we want to allow edit right away if no field instruction yet
  useEffect(() => {
    setIsEditing(!existingFieldInstruction);
  }, []);

  useEffect(() => {
    errors.fieldInstruction
      ? setAdditionalMessage({ type: "error", message: errors.fieldInstruction.message })
      : setAdditionalMessage(null);
  }, [errors.fieldInstruction]);

  const updateAnnotationInstruction = useMutation(UpdateAnnotationInstruction);
  const updateAnnotationDesignationInstruction = useMutation(
    UpdateAnnotationDesignationInstruction,
  );

  const onSave = async () => {
    setIsLoading(true);
    setAdditionalMessage(null);
    try {
      selectedAnnotation
        ? await updateAnnotationInstruction({
            variables: {
              input: {
                id: selectedAnnotation.id,
                instruction: fieldInstructionValue,
              },
            },
          })
        : await updateAnnotationDesignationInstruction({
            variables: {
              input: {
                id: selectedDesignation.id,
                instruction: fieldInstructionValue,
              },
            },
          });

      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        message: intl.formatMessage(MESSAGES.fieldInstructionSuccess),
        position: "topCenter",
      });
      setIsEditing(false);
    } catch (error) {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        if (error.graphQLErrors[0].code === 403) {
          setAdditionalMessage({
            type: "error",
            message: intl.formatMessage(MESSAGES.cannotBeEdited, {
              isAnnotation: Boolean(selectedAnnotation),
            }),
          });
        } else {
          pushErrorNotification(intl);
        }
      } else {
        captureException(error);
        pushErrorNotification(intl);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormRow fullWidth>
        <TextAreaInput
          {...register("fieldInstruction", {
            maxLength: {
              value: 500,
              message: intl.formatMessage(MESSAGES.maxLengthError, {
                length: fieldInstructionValue.length,
              }),
            },
          })}
          aria-invalid={Boolean(additionalMessage?.type === "error")}
          aria-label={intl.formatMessage(MESSAGES.fieldInstructionValue)}
          disabled={!isEditing || isLoading}
          data-automation-id="field-instructions-input"
        />
        {additionalMessage?.type === "error" && (
          <FieldErrorMessage
            className={Styles.formError}
            inputName="fieldInstruction"
            message={additionalMessage.message}
          />
        )}
      </FormRow>
      <AdditionalToolsFormButtons
        canCancel={Boolean(existingFieldInstruction)}
        setValue={() => setValue("fieldInstruction", existingFieldInstruction || "")}
        onSave={onSave}
        disabled={
          (fieldInstructionValue.length === 0 && !existingFieldInstruction) ||
          Boolean(errors.fieldInstruction) ||
          isLoading
        }
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        toolName={toolName}
      />
    </>
  );
}

export function FieldInstructions(props: AnnotationOrDesignationFieldInstruction) {
  const isFeatureEnabled = useFeatureFlag("field-instructions");

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <AdditionalToolsFormWrapper
      title={
        <FormattedMessage
          id="f7a1e978-c75f-4f75-a87b-40714c9cf612"
          defaultMessage="Field instructions"
        />
      }
      description={
        <FormattedMessage
          id="0ad8e000-3528-4e94-a75a-3c8c7c4dc72e"
          defaultMessage="Enter a custom instruction below. It will be visible to the {role, select, NOTARY {notary} WITNESS {witness and notary} other {signer and notary}} when they select this field. <link>Learn more</link>"
          values={{
            role: props.selectedDesignation?.signerRole.role,
            link: (text) => (
              <Link href={`${SUPPORT_HOST}/hc/en-us/articles/26254896973207-Field-Instructions`}>
                {text}
              </Link>
            ),
          }}
        />
      }
      toolName="field-instructions"
    >
      {(controls) => <FieldInstructionForm {...props} {...controls} />}
    </AdditionalToolsFormWrapper>
  );
}

import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useQuery } from "util/graphql";
import WorkflowModal from "common/modals/workflow_modal";
import { useForm } from "common/core/form";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { AutomaticFormRow, Label } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { defaultRequiredMessage } from "common/core/form/error";
import { AddressInput } from "common/core/form/address";

import OrganizationDetailsQuery, {
  type OrganizationDetails_organization_Organization as Organization,
  type OrganizationDetails_organization_Organization_address as Address,
} from "./organization_details_query.graphql";
import Styles from "./organization_details_modal.module.scss";

export type OrganizationDetailsProps = {
  organizationId: string;
  onCancel: () => void;
};
type OrganizationDetailsInnerProps = {
  onCancel: () => void;
  organization: Organization;
};
type OrganizationDetailsForm = {
  address: Address;
  name: string;
};

function OrganizationDetailsModalInner({ organization, onCancel }: OrganizationDetailsInnerProps) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<OrganizationDetailsForm>({
    defaultValues: {
      address: organization.address,
      name: organization.name || "",
    },
  });

  return (
    <WorkflowModal
      large
      className={Styles.organizationDetailsModal}
      title={
        <FormattedMessage
          id="db94d64d-628b-4a5d-acb6-cc8386f1b34a"
          defaultMessage="Organization details"
        />
      }
      footerSeparator={false}
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          onClick={onCancel}
          disabled={isLoading}
          automationId="organization-details-cancel-btn"
        >
          <FormattedMessage id="8ce6a854-52d9-49a8-bb01-5962150654b1" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isLoading}
          automationId="organization-details-save-btn"
          onClick={() => {
            // ETX-2717 - Mutation save method
            setIsLoading(true);
            saveOrganizationDetails();
            onCancel();
            setIsLoading(false);
          }}
        >
          <FormattedMessage
            id="9e185784-919b-404c-bb50-cdf0cbf751ef"
            defaultMessage="Save changes"
          />
        </Button>,
      ]}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      autoFocus
    >
      <>
        <AutomaticFormRow<OrganizationDetailsForm>
          form={form}
          name="name"
          data-automation-id="organization-details-name"
          label={
            <FormattedMessage
              id="cf163a17-6cd5-4521-86a7-600c5759e083"
              defaultMessage="Business name"
            />
          }
          registerOptions={{ required: defaultRequiredMessage(intl) }}
          as={TextInput}
          required
          fullWidth
        />
        <Label className={Styles.prefix} htmlFor="organization-address">
          <FormattedMessage id="7dd42366-36ed-48c8-995a-4fe636272ce8" defaultMessage="Address" />
        </Label>
        <AddressInput<"address", OrganizationDetailsForm>
          form={form}
          name="address"
          showAddressLookup
          required
        />
        <div>
          <Label className={Styles.prefix}>
            <FormattedMessage
              id="569e3fc2-7c11-4f95-8a48-c5f0769785d8"
              defaultMessage="Organization ID"
            />
          </Label>
          <br />
          {organization.id}
        </div>
      </>
    </WorkflowModal>
  );
}

function saveOrganizationDetails() {}

export function OrganizationDetailsModal({ organizationId, onCancel }: OrganizationDetailsProps) {
  const variables = {
    activeOrgId: organizationId,
  };
  const { data, loading } = useQuery(OrganizationDetailsQuery, { variables });
  if (loading) {
    return <LoadingIndicator className={Styles.loading} />;
  }

  const organization = data!.organization as Organization | undefined;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return <OrganizationDetailsModalInner organization={organization} onCancel={onCancel} />;
}

import "common/form/form.scss";

import AppFrameInnerContainer from "common/app_frame/inner_container";
import { DeprecatedNameEmailTimeZoneForm } from "common/form/name_email_timezone_form";
import { DeprecatedPasswordForm } from "common/form/password_form";

function AdminSettings() {
  return (
    <AppFrameInnerContainer title="Settings" closePath="/">
      <DeprecatedNameEmailTimeZoneForm className="AdminSettings--email-form" />
      <DeprecatedPasswordForm className="AdminSettings--password-form" />
    </AppFrameInnerContainer>
  );
}

export default AdminSettings;

import "./upload_view.scss";

import classnames from "classnames";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";

import { FormattedDate } from "common/core/format/date";
import { DeprecatedCheckboxWithLabel } from "common/form/inputs/checkbox";
import Link from "common/core/link";
import { Heading, Paragraph } from "common/core/typography";
import TablePagination from "common/core/table/pagination";
import { SearchField } from "common/core/search_field";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";

const cx = "AddDocumentToUploaderModalUploadView";

const ITEMS_PER_PAGE = 100;

const MESSAGES = defineMessages({
  searchPlaceholder: {
    id: "eabcb512-72ac-4f5b-aa9a-148e6bc803a0",
    defaultMessage: "Search...",
  },
  searchLabel: {
    id: "ef1a7668-46d5-4898-9544-d025b48ee6af",
    defaultMessage: "Search for a template",
  },
});

type Template = {
  id: string;
  name: string;
  insertedAt: string;
};

type Props = {
  /** ID when user selects template from check boxes */
  onSelectTemplates: (templates: Template["id"], selected: boolean) => void;
  templates: Template[] | null;
  selectedTemplates: Template["id"][];
};

type RowProps = {
  id: Template["id"];
  name: Template["name"];
  insertedAt: Template["insertedAt"];
  onSelectTemplates: Props["onSelectTemplates"];
  selectedTemplates: Props["selectedTemplates"];
};

function TemplateRow({
  id,
  name,
  insertedAt,
  onSelectTemplates,
  selectedTemplates = [],
}: RowProps) {
  const selected = selectedTemplates.findIndex((templateId) => templateId === id) > -1;

  const label = (
    <div>
      {name}
      &nbsp;
    </div>
  );

  return (
    <li key={id} className={classnames(`${cx}--TemplateListing`)}>
      <div>
        <div className={`${cx}--DocTitle`}>
          <span className="ellipsis">
            <DeprecatedCheckboxWithLabel
              id={id}
              checked={selected}
              onChange={() => onSelectTemplates(id, !selected)}
              label={label}
              automationId="document-uploader-template-listing-checkbox"
            />
          </span>
        </div>
        <div className={`${cx}--DocStatus`}>
          <Paragraph size="small" textColor="subtle">
            <FormattedDate value={insertedAt} />
          </Paragraph>
        </div>
      </div>
    </li>
  );
}

export function TemplateView({ onSelectTemplates, templates, selectedTemplates = [] }: Props) {
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  if (!templates) {
    return (
      <div className={`${cx}--TemplateListingLoadingContainer`}>
        <LoadingIndicator />
      </div>
    );
  }

  // Search
  const filteredTemplatesBySearch =
    search !== ""
      ? templates.filter((template) => template.name.toLowerCase().includes(search.toLowerCase()))
      : templates;

  // Pagination
  const lastPage = Math.ceil(filteredTemplatesBySearch.length / ITEMS_PER_PAGE);
  const canPreviousPage = currentPage > 1;
  const canNextPage = currentPage < lastPage;
  const nextPage = () => canNextPage && setCurrentPage(currentPage + 1);
  const previousPage = () => canPreviousPage && setCurrentPage(currentPage - 1);
  const startIndex =
    filteredTemplatesBySearch.length === 0
      ? 0
      : filteredTemplatesBySearch.length < ITEMS_PER_PAGE
        ? 1
        : (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex =
    filteredTemplatesBySearch.length < ITEMS_PER_PAGE
      ? filteredTemplatesBySearch.length
      : currentPage * ITEMS_PER_PAGE > templates.length
        ? templates.length
        : currentPage * ITEMS_PER_PAGE;
  const currentTemplates = filteredTemplatesBySearch.slice(
    ITEMS_PER_PAGE * (currentPage - 1),
    ITEMS_PER_PAGE * currentPage,
  );

  const hasTemplates = Boolean(templates.length);

  return (
    <div className={cx}>
      {hasTemplates ? (
        <>
          <div className={`${cx}--TemplateListingPaginationContainer`}>
            <div className={`${cx}--TemplateListingPaginationContainer--Search`}>
              <SearchField
                onChange={({ value }) => {
                  setSearch(value);
                }}
                value={search}
                placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
                aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                searchOnClear
                size="large"
              />
            </div>
            <TablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              startIndex={startIndex}
              endIndex={endIndex}
              totalCount={filteredTemplatesBySearch.length}
            />
          </div>
          <div className={`${cx}--TemplateListingHeader`}>
            <Heading level="h4" textColor="subtle" textStyle="allCapsLabelSmall">
              <FormattedMessage
                id="316661b1-b3a4-48c2-b2f3-ebdfd585d7c4"
                description="listingHeaderName"
                defaultMessage="Name"
              />
            </Heading>
            <Heading level="h4" textColor="subtle" textStyle="allCapsLabelSmall">
              <FormattedMessage
                id="a995a2db-be0a-47ec-8891-b63f7d614ea2"
                description="listingHeaderAdded"
                defaultMessage="Added"
              />
            </Heading>
          </div>

          {currentTemplates.length ? (
            <ul className={`${cx}--TemplateListingContainer`}>
              {currentTemplates.map((template) => (
                <TemplateRow
                  key={template.id}
                  id={template.id}
                  name={template.name}
                  insertedAt={template.insertedAt}
                  onSelectTemplates={onSelectTemplates}
                  selectedTemplates={selectedTemplates}
                />
              ))}
            </ul>
          ) : (
            <div className={`${cx}--EmptyTemplateSearch`}>
              <FormattedMessage
                id="cb3762ef-ddd9-47e6-9117-8e62ca9996c8"
                defaultMessage="No templates match this search"
              />
              <Button variant="secondary" buttonColor="action" onClick={() => setSearch("")}>
                <FormattedMessage
                  id="dec7cb54-0de1-465e-bd29-7b7cdc347208"
                  defaultMessage="Clear search"
                />
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className={`${cx}--EmptyTemplateStateContainer`}>
          <p className={`${cx}--EmptyStateTitle`}>
            <FormattedMessage
              id="e3234437-03e5-4e71-bd6e-2f8b526bc8b7"
              defaultMessage="Document templates save you time!"
            />
          </p>
          <p className={`${cx}--EmptyTemplateDescription`}>
            <FormattedMessage
              id="e5dd2b5b-46a7-41fb-b778-eea5d0c765f2"
              defaultMessage="Instead of manually tagging documents you send over and over again, simply tag your documents once, and save them as a template for future use."
            />
          </p>
          <Link className={`${cx}--EmptyTemplateMessageUrl`} to="/templates">
            <FormattedMessage
              id="067101be-86f3-497f-a512-fefcc8d37cdc"
              defaultMessage="Create your first document template"
            />
          </Link>
        </div>
      )}
    </div>
  );
}

import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import {
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";

import { OrganizationColumn, SortDirection } from "graphql_globals";
import { encodeSearchParams, newPathWithPreservedSearchParams } from "util/location";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import Pagination from "common/pagination";
import { SortableTable } from "common/table";
import TableRow from "common/table/row";
import { SearchField } from "common/core/search_field";
import Icon from "common/core/icon";
import Button from "common/core/button";
import FormattedPayer from "common/core/format/formatted_payer";
import { usePermissions } from "common/core/current_user_role";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { BinaryToggle } from "common/core/form/binary_toggle";

import { AccountProvisionForm } from "./account_provisioning";
import AdminCompanyListDashboardQuery, {
  type AdminCompanyListDashboard_viewer as Viewer,
  type AdminCompanyListDashboard_viewer_organizations_edges_node as Organization,
} from "./index_query.graphql";
import Styles from "./index.module.scss";

const ITEMS_PER_PAGE = 10;

const MESSAGES = defineMessages({
  searchPlaceholder: {
    id: "6883815c-5905-4088-b25e-e726e16bc599",
    defaultMessage: "Enter an organization name or ID",
  },
  searchLabel: {
    id: "412ab7ec-654c-4665-ae08-f140bd711ddd",
    defaultMessage: "Search for a company by name or ID",
  },
  appFrameTitle: {
    id: "170c4ecc-79cd-4e2c-bcda-94cb0d0f001f",
    defaultMessage: "Organizations",
  },
  rootOrgsOnlyToggle: {
    id: "2d0e6f72-0a58-4343-ba83-ae9e2675c893",
    defaultMessage: "Root Orgs Only",
  },
});

function rowCells(
  company: Organization,
  pathname: string,
  hasPermissionFor: ReturnType<typeof usePermissions>["hasPermissionFor"],
) {
  const { id, name, defaultPayer, organizationTransactions } = company;
  return [
    {
      header: true,
      className: "link-append",
      children: (
        <>
          <div>{name && name.length > 50 ? `${name.substring(0, 47)}...` : name}</div>
          {hasPermissionFor("companyTeamDetails") && (
            <Link to={newPathWithPreservedSearchParams(`/companies/${id}/member-management/user`)}>
              Team <Icon className="icon" name="caret-right" automationId="team-link" />
            </Link>
          )}
        </>
      ),
    },
    {
      className: "link-append",
      children: (
        <>
          <div>{organizationTransactions.totalCount}</div>
          {hasPermissionFor("transactions") && (
            <Link to={`/companies/${id}/transactions`} className="link-full-append--text">
              <FormattedMessage id="c2cc227b-ee06-49e3-acaa-124660be2c8b" defaultMessage="View" />
              <Icon className="icon" name="caret-right" automationId="transactions-link" />
            </Link>
          )}
        </>
      ),
    },
    {
      children: <div>{id}</div>,
    },
    {
      className: "link-append",
      children: (
        <>
          <div>
            <FormattedPayer payer={defaultPayer} />
          </div>
          {hasPermissionFor("companyDetails") && (
            <Link to={`/companies/${id}/details`}>
              <FormattedMessage
                id="e3123264-2dd0-48ab-b486-2fb1327dfa04"
                defaultMessage="Details"
              />
              <Icon className="icon" name="caret-right" automationId="details-link" />
            </Link>
          )}
        </>
      ),
    },
    {
      className: "link-append",
      children: (
        <>
          {hasPermissionFor("companyDetails") && (
            <Link to={`/companies/${id}/logs`}>
              <FormattedMessage
                id="d193fbf9-0273-41fb-9b78-fdb80c4757bb"
                defaultMessage="View Log"
              />
              <Icon className="icon" name="caret-right" automationId="audit-logs-link" />
            </Link>
          )}
        </>
      ),
    },
  ];
}

function buildTableHeadings() {
  return [
    {
      key: "organization",
      children: (
        <span>
          <FormattedMessage
            id="10057848-ef5b-4a9c-9de7-de40bc46f2cc"
            defaultMessage="Organization"
          />
        </span>
      ),
    },
    {
      key: "total-transactions",
      children: (
        <span>
          <FormattedMessage
            id="6b3e78b1-fcfd-482c-a843-df601ebea6d2"
            defaultMessage="Total Transactions"
          />
        </span>
      ),
    },
    {
      key: "company-id",
      children: (
        <span>
          <FormattedMessage
            id="a092d089-ca6c-41b5-95eb-ae69b6689ae8"
            defaultMessage="Organization ID"
          />
        </span>
      ),
    },
    {
      key: "account-type",
      children: (
        <span>
          <FormattedMessage
            id="16ec0253-0a48-4c01-9808-23c164cdca09"
            defaultMessage="Account Type"
          />
        </span>
      ),
    },
    {
      key: "audit-logs",
      children: (
        <span>
          <FormattedMessage id="16ec0253-0a48-4c01-9808-23c164cdca09" defaultMessage="Audit Log" />
        </span>
      ),
    },
  ];
}

function updatePath(pathname: string, pageNumber: number) {
  return pathname
    .replace(/\/page\/[0-9]+\/?$|\/?$/i, `/page/${pageNumber}`)
    .replace(/(^[^/])/, "/$1");
}

function AdminCompanyList({ pageNumber, viewer }: { pageNumber: number; viewer: Viewer }) {
  const { organizations } = viewer;
  const intl = useIntl();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();
  const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("term") || "";
  const searchRootOrgsOnly = searchParams.get("rootOrgsOnly") === "true";
  const maxPageNumber = Math.ceil(organizations.totalCount / ITEMS_PER_PAGE);

  const onPageChange = (pageNumber: number) => {
    // Replace the existing path with a path for the new page
    // and ensure that the path starts at root.
    // Also, update router while maintaining query params
    navigate(`${updatePath(pathname, pageNumber)}${search}`);
  };

  const onSearch = (searchTerm: string) => {
    const search = encodeSearchParams(searchParams, {
      term: searchTerm,
      rootOrgsOnly: (searchParams.get("rootOrgsOnly") === "true").toString(),
    });
    navigate(`${updatePath(pathname, 1)}?${search}`, { replace: true });
  };
  return (
    <AppFrameInnerContainer
      className={Styles.adminCompanyList}
      closePath="/dashboard"
      title={intl.formatMessage(MESSAGES.appFrameTitle)}
      fullWidth
      automationId={"admin-company-list"}
    >
      <Outlet />
      {hasPermissionFor("provisionOrganization") && (
        <Button
          className={Styles.adminCompanyListCreateNewOrg}
          onClick={() => setCreateCompanyModalOpen(true)}
          buttonColor="action"
          variant="primary"
        >
          <FormattedMessage id="18e8c19f-f9c6-469b-ae00-5c3376e118ce" defaultMessage="Create New" />
        </Button>
      )}
      <div className={Styles.searchContainer}>
        <SearchField
          className={Styles.searchField}
          onSearch={onSearch}
          value={searchTerm}
          placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
          aria-label={intl.formatMessage(MESSAGES.searchLabel)}
          searchOnBlur
          searchOnClear
        />
        <BinaryToggle
          automationId="root-orgs-only-toggle"
          label={intl.formatMessage(MESSAGES.rootOrgsOnlyToggle)}
          onChange={() => {
            searchParams.set("rootOrgsOnly", (!searchRootOrgsOnly).toString());
            onSearch(searchTerm);
          }}
          value={searchRootOrgsOnly}
        />
      </div>
      <SortableTable className={Styles.dataTable} headings={buildTableHeadings()}>
        {organizations.edges.map(({ node }) => (
          <TableRow key={node.id} cells={rowCells(node, pathname, hasPermissionFor)} />
        ))}
      </SortableTable>
      {organizations.totalCount ? (
        <Pagination maxPageNumber={maxPageNumber} pageNumber={pageNumber} onChange={onPageChange} />
      ) : null}
      {createCompanyModalOpen && (
        <AccountProvisionForm onClose={() => setCreateCompanyModalOpen(false)} />
      )}
    </AppFrameInnerContainer>
  );
}

function CompaniesListContainer() {
  const { companiesPage } = useParams();
  const [searchParams] = useSearchParams();
  const pageNumber = Number(companiesPage || 1);
  const offset = (pageNumber - 1) * ITEMS_PER_PAGE;
  const { data, loading } = useQuery(AdminCompanyListDashboardQuery, {
    variables: {
      offset,
      sortBy: [{ column: OrganizationColumn.NAME, direction: SortDirection.ASC }],
      searchTerm: searchParams.get("term") || null,
      rootOrgsOnly: searchParams.get("rootOrgsOnly") === "true",
    },
  });
  return loading ? (
    <LoadingIndicator />
  ) : (
    <AdminCompanyList pageNumber={pageNumber} viewer={data!.viewer} />
  );
}

export default CompaniesListContainer;

import "./checkbox.scss";

import { useRef, useLayoutEffect, type ReactNode, type MouseEvent, type ChangeEvent } from "react";
import classnames from "classnames";

type BaseCheckboxProps = {
  id?: string;
  disabled?: boolean;
  checked: boolean;
  automationId?: string;
  className?: string;
  tabIndex?: number;
  onClick?: (e: MouseEvent) => void;
  /**
   * `indeterminate` is the "half" checked state. Its completely independent from
   * checked/unchecked, and when this is truthy, the checkbox will always _look_
   * this way, despite being unchecked or checked (this is how HTML checkboxes work).
   */
  indeterminate?: boolean;
} & (
  | {
      readOnly?: false;
      onChange: (e: ChangeEvent) => void;
    }
  | {
      readOnly: true;
      onChange?: never;
    }
);

type CheckboxProps = BaseCheckboxProps & {
  "aria-label"?: string;
  "aria-labelledby"?: string;
};

type CheckboxWithLabelProps = BaseCheckboxProps & {
  label: ReactNode;
  sublabel?: ReactNode;
  alignTop?: boolean;
  sublabelGrey?: boolean;
};

/**
 * `<Checkbox />` is a simple notarized flavored `<input type="checkbox" />`. This component
 * uses the concept of controlled components. This essentially means you will not be able
 * to use this without some state; both a handler to change the state, `onChange`, and the
 * `checked` state itself are required. See: https://reactjs.org/docs/forms.html
 */

/** @deprecated - please use components in common/core/form */
export function DeprecatedCheckbox({
  id,
  disabled = false,
  indeterminate = false,
  automationId = "checkbox",
  onChange,
  onClick,
  checked,
  className,
  readOnly,
  tabIndex,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledby,
}: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      current.indeterminate = indeterminate;
    }
  }, [indeterminate, ref]);
  const showTick = checked && !indeterminate;
  const iconClasses = `Checkbox--FakeBox${showTick ? " icon-tick-small" : ""}`;
  const cx = classnames("Checkbox", className);

  return (
    <div className={cx}>
      <input
        type="checkbox"
        // The prop `indeterminate` does not work since it can't be server rendered,
        // so we must set it with a ref.
        ref={ref}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        data-automation-id={automationId}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        tabIndex={tabIndex}
        readOnly={readOnly}
      />
      <div className={iconClasses} aria-hidden="true" />
    </div>
  );
}

/**
 * This is a convenience component for the extremely common case of a label to the right
 * of a checkbox.
 */

/** @deprecated - please use components in common/core/form */
export function DeprecatedCheckboxWithLabel({
  label,
  sublabel,
  automationId,
  alignTop,
  disabled,
  sublabelGrey,
  ...checkboxProps
}: CheckboxWithLabelProps) {
  const cx = classnames("CheckboxWithLabel", {
    "CheckboxWithLabel__align-top": alignTop,
    CheckboxWithLabel__disabled: disabled,
  });

  return (
    <label className={cx} data-automation-id={automationId ? `${automationId}-label` : null}>
      <DeprecatedCheckbox {...checkboxProps} disabled={disabled} automationId={automationId} />
      <div className="CheckboxWithLabel--label">
        {label}
        {sublabel && (
          <div
            className={
              sublabelGrey
                ? "CheckboxWithLabel--label--sublabel--grey"
                : "CheckboxWithLabel--label--sublabel"
            }
          >
            {sublabel}
          </div>
        )}
      </div>
    </label>
  );
}

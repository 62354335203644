import { memo, useMemo, useCallback, type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { DeprecatedStyledSelectInput } from "common/form/inputs/select";
import { userFullName } from "util/user";
import { PillsHeader, Pill } from "common/core/pill_tabs";
import { usePermissions } from "common/core/current_user_role";
import Tooltip from "common/core/tooltip";
import { NotarizeNetwork } from "common/core/logo/notarize_network";
import type { V3TransactionProps } from "common/transactions/form/sub_forms/closer_assignment";

import Styles from "./index.module.scss";

export enum DisabledTypes {
  IHN_DISABLED = "IHN_DISABLED",
  DEPRECATED_ODN_DISABLED = "DEPRECATED_ODN_DISABLED",
  ODN_DISABLED = "ODN_DISABLED",
}
export type CustomNotaryOptionsErrorMap = Partial<Record<DisabledTypes, string | ReactNode>>;
export type Closer = { id: string; firstName: string | null; lastName: string | null };

export type Props = {
  notarizeCloserOverride: boolean | null;
  onChangeNotarizeCloserOverride: (newValue: boolean) => void;
  assignee?: string | null;
  onChangeAssignee: (newValue: string) => void;
  isPersonallyKnownToNotary: boolean;
  canUseODN: boolean;
  canUseIHN: boolean;
  customNotaryOptionsErrorMap?: CustomNotaryOptionsErrorMap;
  closers: Closer[];
} & V3TransactionProps;

type BodyProps = {
  canUseODN: boolean;
  notarizeCloserOverride: boolean | null;
  isPersonallyKnownToNotary: boolean;
  closerItems: { label: ReactNode; value: string }[];
  assignee?: string | null;
  onNotaryChange(assignee: string): void;
  disabledCloserAssigneeId?: boolean;
};

export const UNASSIGNED = "unassigned";
const UNASSIGNED_OPTION = Object.freeze({
  value: UNASSIGNED,
  label: <FormattedMessage id="b34e061d-9d9b-4cbd-8fea-869864f3450d" defaultMessage="Unassigned" />,
});
const MESSAGES = defineMessages({
  selectTeamMember: {
    id: "457d2c82-00e1-40e0-80c9-921e34afc169",
    defaultMessage: "Select team member",
  },
});

function sortUser<T extends { firstName: string | null; lastName: string | null }>(
  a: T,
  b: T,
): number {
  return (
    (a.lastName || "").localeCompare(b.lastName || "") ||
    (a.firstName || "").localeCompare(b.firstName || "")
  );
}

const GENERIC_DISABLED_MESSAGES = {
  [DisabledTypes.IHN_DISABLED]: (
    <FormattedMessage
      id="48f095ed-f096-4544-9bc6-c0672058fbda"
      defaultMessage="You haven't onboarded any internal notaries yet."
    />
  ),
  [DisabledTypes.DEPRECATED_ODN_DISABLED]: (
    <FormattedMessage
      id="32dc7634-57ca-4dfe-ad78-fb75464e2567"
      defaultMessage="Notarization with On-demand notarize notary is not available for this transaction."
    />
  ),
  [DisabledTypes.ODN_DISABLED]: (
    <FormattedMessage
      id="c3b8b9d5-0b2b-4c2b-9e4b-8a4e0f0a4a7f"
      defaultMessage="Notarization with the Notarize Network is not available for this transaction."
    />
  ),
};

function CloserAssignmentPillsBody(props: BodyProps) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const hasOverride = props.canUseODN && props.notarizeCloserOverride;
  if (hasOverride && props.isPersonallyKnownToNotary) {
    return (
      <div className={Styles.personallyKnownToNotaryWarning}>
        <FormattedMessage
          id="787b817b-ec3d-4a2c-87e4-da722439197d"
          defaultMessage="Reassigning this transaction to the Notarize Network will require the signer to complete KBA and credential analysis as they will no longer be personally known to the notary."
        />
      </div>
    );
  }

  if (hasOverride) {
    return (
      <FormattedMessage
        id="133e3f31-067d-4dda-b6e1-1acee47e39ae"
        defaultMessage="The Notarize Network can handle this meeting"
      />
    );
  }

  return (
    <DeprecatedStyledSelectInput
      automationId="closer-assignment-select"
      items={props.closerItems}
      placeholder={intl.formatMessage(MESSAGES.selectTeamMember)}
      clearable={false}
      value={props.assignee}
      onChange={props.onNotaryChange}
      aria-label={intl.formatMessage(MESSAGES.selectTeamMember)}
      disabled={!hasPermissionFor("editOrganizationTransactions") || props.disabledCloserAssigneeId}
    />
  );
}

function CloserAssignmentPills({
  closers,
  notarizeCloserOverride,
  onChangeNotarizeCloserOverride,
  assignee,
  onChangeAssignee,
  isPersonallyKnownToNotary,
  canUseODN,
  canUseIHN,
  customNotaryOptionsErrorMap,
  disabledNotarizeCloserOverride,
  disabledCloserAssigneeId,
}: Props) {
  const onNotaryChange = useCallback(
    (newInternalAssignee: string) => {
      // Here we also signal to turn off the closer override. If overflow is not allowed/has been turned off
      // since the original notarize closer was selected, the pills are not visible to change this, so we always
      // do this when user changes internal notary.
      onChangeNotarizeCloserOverride(false);
      onChangeAssignee(newInternalAssignee);
    },
    [onChangeNotarizeCloserOverride, onChangeAssignee],
  );
  const closerItems = useMemo(() => {
    const sortedClosers = closers.slice().sort(sortUser);
    return sortedClosers
      .map((user) => ({ value: user.id, label: userFullName(user) as ReactNode }))
      .concat(UNASSIGNED_OPTION);
  }, [closers]);
  const isNotaryOptionSelected = notarizeCloserOverride !== null && (canUseIHN || canUseODN);

  const { hasPermissionFor } = usePermissions();

  const IhnPill = (
    <Pill
      selected={isNotaryOptionSelected && !notarizeCloserOverride}
      onClick={() => {
        if (canUseIHN) {
          onChangeNotarizeCloserOverride(false);
        }
      }}
      data-automation-id="closer-assignment-tabbutton"
      disabled={
        !hasPermissionFor("editOrganizationTransactions") ||
        !canUseIHN ||
        disabledNotarizeCloserOverride
      }
    >
      <FormattedMessage
        id="473f6f22-0b63-48d9-9d42-c96240b5e7da"
        defaultMessage="Internal notary"
      />
    </Pill>
  );

  const OdnPill = (
    <Pill
      selected={isNotaryOptionSelected && notarizeCloserOverride}
      onClick={() => {
        if (canUseODN) {
          onChangeNotarizeCloserOverride(true);
        }
      }}
      data-automation-id="notarize-closer-override-tabbutton"
      disabled={
        !hasPermissionFor("editOrganizationTransactions") ||
        !canUseODN ||
        disabledNotarizeCloserOverride
      }
    >
      <NotarizeNetwork />
    </Pill>
  );

  return (
    <>
      <PillsHeader>
        {!canUseIHN ? (
          <Tooltip target={IhnPill} placement="bottom">
            {customNotaryOptionsErrorMap?.[DisabledTypes.IHN_DISABLED] ||
              GENERIC_DISABLED_MESSAGES[DisabledTypes.IHN_DISABLED]}
          </Tooltip>
        ) : (
          IhnPill
        )}
        {!canUseODN ? (
          <Tooltip target={OdnPill} placement="bottom">
            {customNotaryOptionsErrorMap?.[DisabledTypes.ODN_DISABLED] ||
              GENERIC_DISABLED_MESSAGES[DisabledTypes.ODN_DISABLED]}
          </Tooltip>
        ) : (
          OdnPill
        )}
      </PillsHeader>
      {isNotaryOptionSelected && (
        <CloserAssignmentPillsBody
          closerItems={closerItems}
          onNotaryChange={onNotaryChange}
          assignee={assignee}
          isPersonallyKnownToNotary={isPersonallyKnownToNotary}
          canUseODN={canUseODN}
          notarizeCloserOverride={notarizeCloserOverride}
          disabledCloserAssigneeId={disabledCloserAssigneeId}
        />
      )}
    </>
  );
}

export default memo(CloserAssignmentPills);

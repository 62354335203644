import "./index.scss";

import { useState, useReducer, useMemo, useEffect, type Dispatch } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLazyQuery, type QueryResult } from "util/graphql/query";
import LoadingIndicator from "common/core/loading_indicator";
import { StandardTable } from "common/table";
import { userFullName } from "util/user";
import TableRow from "common/table/row";
import Icon from "common/core/icon";
import { SearchField } from "common/core/search_field";
import { DeprecatedSelectInput } from "common/form/inputs/select";
import Button from "common/core/button";
import Pagination from "common/pagination";
import Link from "common/core/link";
import { encodeSearchParams } from "util/location";
import { FormattedDate, format } from "common/core/format/date";
import { NetworkStatus, useQuery } from "util/graphql";
import { usePermissions } from "common/core/current_user_role";
import { NotaryAccountStatuses, type Language } from "graphql_globals";
import { useFeatureFlag } from "common/feature_gating";

import NotaryFilter from "./filter_menu";
import { CapacityFilter } from "./filter_menu/capacities";
import {
  MESSAGES,
  ONBOARDING_STATUSES,
  COMPLIANCE_STATUSES,
  PRESENCE_STATUSES,
  ACCOUNT_STATUSES,
  TRAINING_STATUSES,
  NotaryCapacitiesSummary,
  useConfiguration,
} from "./util";
import LazyNotaryListQuery from "./lazy_notary_list_query.graphql";
import NotaryListQuery, {
  type NotaryList,
  type NotaryListVariables,
  type NotaryList_viewer as Viewer,
  type NotaryList_viewer_notaries_edges_node as Notary,
} from "./notary_list_query.graphql";
import NewNotaryModal from "./new";
import NotaryDetails from "./details";

type Option =
  | { type: "reset" }
  | { type: "searchTerm"; value: NotaryListVariables["searchTerm"] }
  | { type: "onboardingStatus"; value: NotaryListVariables["onboardingStatus"] }
  | { type: "complianceStatus"; value: NotaryListVariables["complianceStatus"] }
  | { type: "accountStatus"; value: NotaryListVariables["accountStatus"] }
  | { type: "presenceStatus"; value: NotaryListVariables["presenceStatus"] }
  | { type: "organizationId"; value: NotaryListVariables["organizationId"] }
  | { type: "usStateId"; value: NotaryListVariables["usStateId"] }
  | { type: "tier"; value: NotaryListVariables["tier"] }
  | { type: "capacities"; value: NotaryListVariables["capacities"] }
  | { type: "trainingEnabled"; value: NotaryListVariables["trainingEnabled"] }
  | { type: "languages"; value: NotaryListVariables["languages"] }
  | { type: "offset"; value: NotaryListVariables["offset"] }
  | { type: "commissionNumber"; value: NotaryListVariables["commissionNumber"] };

type DropdownItem = { value: string; label: string };
type NotaryListProps = {
  viewer: Viewer;
  navigate: ReturnType<typeof useNavigate>;
  refetch: QueryResult<NotaryList, NotaryListVariables>["refetch"];
  filterState: NotaryListVariables;
  dispatchFilterState: Dispatch<Option>;
  newNotaryModalOpen: boolean | "loading";
  setNewNotaryModalOpen: (newValue: NotaryListProps["newNotaryModalOpen"]) => void;
  organizationDropdownItems: DropdownItem[];
  hasPermissionFor: ReturnType<typeof usePermissions>["hasPermissionFor"];
};
type NotaryFiltersProps = {
  viewer: Viewer;
  filterState: NotaryListVariables;
  dispatchFilterState: Dispatch<Option>;
  organizationDropdownItems: DropdownItem[];
  onFocusOrganizationFilter: () => void;
};

const ITEMS_PER_PAGE = 10;
const INIT_VARIABLES = Object.freeze({
  first: ITEMS_PER_PAGE,
  offset: 0,
  accountStatus: NotaryAccountStatuses.ENABLED,
});

function notaryFilterStateReducer(
  state: Readonly<NotaryListVariables>,
  option: Option,
): Readonly<NotaryListVariables> {
  if (option.type === "reset") {
    return INIT_VARIABLES;
  }
  return {
    ...state,
    offset: 0,
    [option.type]:
      // we need this because false || undefined evaluates to undefined
      option.type === "trainingEnabled" ? (option.value ?? undefined) : option.value || undefined,
  };
}

function NotaryListItems({
  navigate,
  refetch,
  viewer,
  filterState,
  dispatchFilterState,
  newNotaryModalOpen,
  setNewNotaryModalOpen,
  hasPermissionFor,
  organizationDropdownItems,
}: NotaryListProps) {
  const [selectedNotaryId, setSelectedNotaryId] = useState<string | null>(null);

  const handlePageChange = (page: number) =>
    dispatchFilterState({ type: "offset", value: (page - 1) * ITEMS_PER_PAGE });
  const handleDetailsModalOpen = ({ id, email }: Notary) => {
    setSelectedNotaryId(id);
    const search = email ? encodeSearchParams(new URLSearchParams({ email })) : "";
    navigate(`/notary?${search}`, { replace: true });
  };

  const handleCreateNewNotary = () => {
    setNewNotaryModalOpen(false);
    refetch();
  };

  const handleDetailsModalClose = () => {
    navigate("/notary", { replace: true });
    setSelectedNotaryId(null);
  };

  const { notaries: notariesConnection, notaryUsStates } = viewer;
  const { totalCount } = notariesConnection;
  const notaries = notariesConnection.edges.map((edge) => edge.node);
  const currentPage = (filterState.offset || 0) / ITEMS_PER_PAGE + 1;

  const { headings } = useConfiguration(notaryUsStates);

  return (
    <div>
      <div className="NotaryList--TotalCount">
        <FormattedMessage
          id="9f624f17-4ff9-4220-8a0f-bc5536ff10f1"
          defaultMessage="{totalCount} Total {totalCount, plural, one {Notary} other {Notaries}}"
          values={{ totalCount }}
        />
      </div>
      <StandardTable headings={headings}>
        {notaries.map((notary) => (
          <TableRow
            key={notary.id}
            cells={[
              {
                key: "details",
                children: (
                  <>
                    {hasPermissionFor("notaryDetails") ? (
                      <Link automationId="details" onClick={() => handleDetailsModalOpen(notary)}>
                        <div className="ellipsis notary-info-header">{userFullName(notary)}</div>
                      </Link>
                    ) : (
                      <div className="ellipsis notary-info-header">{userFullName(notary)}</div>
                    )}
                    <div className="ellipsis notary-info-details">{notary.email}</div>
                  </>
                ),
              },
              {
                key: "capacities",
                className: "ellipsis NotaryList--Capacities",
                children: <NotaryCapacitiesSummary capacities={notary.notaryProfile!.capacities} />,
              },
              {
                key: "orgname",
                className: "ellipsis",
                children: notary.organization?.name,
              },
              {
                key: "tier",
                className: "ellipsis",
                children: notary.notaryProfile!.tier,
              },
              {
                key: "usstate",
                className: "ellipsis",
                children: notary.notaryProfile!.usState.name,
              },
              {
                key: "onboarding",
                className: "ellipsis",
                children: ONBOARDING_STATUSES[notary.notaryProfile!.onboardingStatus],
              },
              {
                key: "compliance",
                className: "ellipsis",
                children: COMPLIANCE_STATUSES[notary.notaryProfile!.complianceStatus],
              },
              {
                key: "accountstatus",
                className: "ellipsis",
                children: ACCOUNT_STATUSES[notary.notaryProfile!.accountStatus],
              },
              {
                key: "presence",
                className: "ellipsis",
                children: PRESENCE_STATUSES[notary.notaryProfile!.presenceStatus],
              },
              {
                key: "training",
                className: "ellipsis",
                children:
                  TRAINING_STATUSES[
                    notary.notaryProfile!.trainingEnabled.toString() as "true" | "false"
                  ],
              },
              {
                key: "updatedat",
                className: "ellipsis",
                children: (
                  <>
                    <div className="ellipsis notary-info-header">
                      <FormattedDate value={notary.notaryProfile!.updatedAt} />
                    </div>
                    <div className="ellipsis notary-info-details">
                      {format({ value: notary.notaryProfile!.updatedAt, formatStyle: "p" })}
                    </div>
                  </>
                ),
              },
            ]}
          />
        ))}
      </StandardTable>
      <Pagination
        maxPageNumber={Math.ceil(totalCount / ITEMS_PER_PAGE)}
        onChange={handlePageChange}
        pageNumber={currentPage}
      />
      {selectedNotaryId && (
        <NotaryDetails
          notaryId={selectedNotaryId}
          canUpdateNotary={hasPermissionFor("updateNotary")}
          onClose={handleDetailsModalClose}
          notaryUsStates={notaryUsStates}
        />
      )}
      {newNotaryModalOpen === true && (
        <NewNotaryModal
          notaryUsStates={notaryUsStates}
          organizationDropdownItems={organizationDropdownItems}
          onClose={() => setNewNotaryModalOpen(false)}
          onCreate={handleCreateNewNotary}
        />
      )}
    </div>
  );
}

function NotaryFilters({
  viewer,
  filterState,
  dispatchFilterState,
  organizationDropdownItems,
  onFocusOrganizationFilter,
}: NotaryFiltersProps) {
  const { notaryUsStates } = viewer;
  const totalSelected = Object.entries(filterState).filter(
    ([key, value]) => Boolean(value) && key !== "offset" && key !== "first",
  ).length;
  const handleClearAllFilters = () => dispatchFilterState({ type: "reset" });
  const [organizationProxyFilterValue, setOrganizationProxyFilterValue] = useState("");

  const {
    tierDropdownItems,
    stateDropdownItems,
    presenceStatusDropdownItems,
    onboardingStatusDropdownItems,
    complianceStatusDropdownItems,
    accountStatusDropdownItems,
    trainingDropdownItems,
    languagesDropdownItems,
  } = useConfiguration(notaryUsStates);

  const intl = useIntl();

  const mainFilters = [
    <SearchField
      key="search-term-filter"
      className="NotaryFilterBar--filter--searchTerm"
      onSearch={(value: NotaryListVariables["searchTerm"]) =>
        dispatchFilterState({ type: "searchTerm", value })
      }
      searchOnBlur
      searchOnClear
      value={filterState.searchTerm || ""}
      placeholder={intl.formatMessage(MESSAGES.searchTermPlaceholder)}
      aria-label={intl.formatMessage(MESSAGES.searchTermPlaceholder)}
    />,
    <SearchField
      key="commission-name-filter"
      className="NotaryFilterBar--filter--searchTerm"
      onSearch={(value: NotaryListVariables["commissionNumber"]) =>
        dispatchFilterState({ type: "commissionNumber", value })
      }
      searchOnBlur
      searchOnClear
      value={filterState.commissionNumber || ""}
      placeholder={intl.formatMessage(MESSAGES.commissionNumberPlaceholder)}
      aria-label={intl.formatMessage(MESSAGES.commissionNumberPlaceholder)}
    />,
    organizationDropdownItems.length ? (
      <DeprecatedSelectInput
        key="organization-filter"
        className="NotaryFilterBar--filter--organization"
        items={organizationDropdownItems}
        placeholder={intl.formatMessage(MESSAGES.organizationPlacholder)}
        onChange={(value: NotaryListVariables["organizationId"]) =>
          dispatchFilterState({ type: "organizationId", value })
        }
        value={filterState.organizationId}
        clearable
        searchable
      />
    ) : (
      <SearchField
        key="organization-filter-search"
        className="NotaryFilterBar--filter--organizationSearch"
        value={organizationProxyFilterValue}
        placeholder={intl.formatMessage(MESSAGES.organizationPlacholder)}
        aria-label={intl.formatMessage(MESSAGES.organizationPlacholder)}
        disabled={Boolean(organizationProxyFilterValue)}
        onFocus={() => {
          onFocusOrganizationFilter();
          setOrganizationProxyFilterValue("Loading...");
        }}
      />
    ),
    <CapacityFilter
      key="capacity-filter"
      dispatchFilterState={dispatchFilterState}
      capacities={filterState.capacities}
    />,
    <DeprecatedSelectInput
      key="onboarding-status-filter"
      automationId="onboarding-status-filter"
      className="NotaryFilterBar--filter--onboardingStatus"
      items={onboardingStatusDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.onboardingPlacholder)}
      onChange={(value: NotaryListVariables["onboardingStatus"]) =>
        dispatchFilterState({ type: "onboardingStatus", value })
      }
      value={filterState.onboardingStatus}
      clearable
    />,
    <DeprecatedSelectInput
      key="tier-filter"
      automationId="tier-filter"
      className="NotaryFilterBar--filter--tier"
      items={tierDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.tierPlacholder)}
      onChange={(value: NotaryListVariables["tier"]) =>
        dispatchFilterState({ type: "tier", value })
      }
      value={filterState.tier}
      clearable
    />,
  ];
  const additionalFilters = [
    <DeprecatedSelectInput
      key="state-filter"
      automationId="state-filter"
      className="NotaryFilterBar--filter--usState"
      items={stateDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.statePlacholder)}
      onChange={(value: NotaryListVariables["usStateId"]) =>
        dispatchFilterState({ type: "usStateId", value })
      }
      value={filterState.usStateId}
      clearable
    />,
    <DeprecatedSelectInput
      key="compliance-status-filter"
      automationId="compliance-status-filter"
      className="NotaryFilterBar--filter--complianceStatus"
      items={complianceStatusDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.compliancePlacholder)}
      onChange={(value: NotaryListVariables["complianceStatus"]) =>
        dispatchFilterState({ type: "complianceStatus", value })
      }
      value={filterState.complianceStatus}
      clearable
    />,
    <DeprecatedSelectInput
      key="account-status-filter"
      automationId="account-status-filter"
      className="NotaryFilterBar--filter--accountStatus"
      items={accountStatusDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.accountFilterPlacholder)}
      onChange={(value: NotaryListVariables["accountStatus"]) =>
        dispatchFilterState({ type: "accountStatus", value })
      }
      value={filterState.accountStatus}
      clearable
    />,
    <DeprecatedSelectInput
      key="availability-filter"
      automationId="availability-filter"
      className="NotaryFilterBar--filter--presenceStatus"
      items={presenceStatusDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.availabilityPlacholder)}
      onChange={(value: NotaryListVariables["presenceStatus"]) =>
        dispatchFilterState({ type: "presenceStatus", value })
      }
      value={filterState.presenceStatus}
      clearable
    />,
    <DeprecatedSelectInput
      key="training-filter"
      automationId="training-filter"
      className="NotaryFilterBar--filter--trainingStatus"
      items={trainingDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.trainingPlaceholder)}
      onChange={(value: NotaryListVariables["trainingEnabled"]) =>
        dispatchFilterState({ type: "trainingEnabled", value })
      }
      value={filterState.trainingEnabled}
      clearable
    />,
    <DeprecatedSelectInput
      key="language-filter"
      automationId="language-filter"
      className="NotaryFilterBar--filter--languageFilter"
      items={languagesDropdownItems}
      placeholder={intl.formatMessage(MESSAGES.languagePlaceholder)}
      onChange={(value: Language | null) =>
        dispatchFilterState({ type: "languages", value: value && [value] })
      }
      value={filterState.languages?.[0]}
      clearable
    />,
  ];

  return (
    <NotaryFilter
      mainFilters={mainFilters}
      additionalFilters={additionalFilters}
      totalSelected={totalSelected}
      onClear={handleClearAllFilters}
    />
  );
}

export default function AdminNotaryList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { hasPermissionFor } = usePermissions();
  const [newNotaryModalOpen, setNewNotaryModalOpen] = useState<boolean | "loading">(false);
  const [filterState, dispatchFilterState] = useReducer(notaryFilterStateReducer, {
    ...INIT_VARIABLES,
    searchTerm: searchParams.get("email") || searchParams.get("search"),
  });
  const { data, previousData, networkStatus, refetch } = useQuery(NotaryListQuery, {
    variables: filterState,
  });

  const [getLazyNotaryData, lazyNotaryListResult] = useLazyQuery(LazyNotaryListQuery);
  const { loading: lazyLoading, data: lazyData, called: lazyCalled } = lazyNotaryListResult;
  const lazyViewerPubOrgs = lazyData?.viewer.publicOrganizationsWithNotaries;
  const organizationDropdownItems = useMemo(() => {
    return lazyViewerPubOrgs
      ? lazyViewerPubOrgs
          .map((org) => ({ value: org.id, label: org.name || "" }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  }, [lazyViewerPubOrgs]);
  useEffect(() => {
    if (lazyCalled && !lazyLoading && newNotaryModalOpen === "loading") {
      setNewNotaryModalOpen(true);
    }
  }, [lazyCalled, lazyLoading, newNotaryModalOpen]);

  const adminNotarizeNotaryCreationEnabled = useFeatureFlag(
    "legacy-notarize-notary-creation-permission",
  );
  if (networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }

  const { viewer } = (data || previousData)!;

  return (
    <div className="NotaryList">
      <nav className="NotaryList--subheader">
        <button type="submit" className="NotaryList--subheader--back" onClick={() => navigate("/")}>
          <Icon name="arrow-left" />
        </button>
        <FormattedMessage
          id="d50e0356-4845-4f0e-a6b7-0b250821844c"
          defaultMessage="Notaries"
          tagName="h2"
        />
        <div className="NotaryList-subheader-button-container">
          {hasPermissionFor("createNotary") && adminNotarizeNotaryCreationEnabled && (
            <Button
              buttonColor="action"
              variant="primary"
              isLoading={lazyLoading}
              onClick={() => {
                if (!lazyCalled) {
                  getLazyNotaryData();
                }
                setNewNotaryModalOpen(lazyCalled || "loading");
              }}
              automationId="add-new-notary-button"
            >
              <FormattedMessage
                id="ca267b46-2e18-45f4-a98a-90ab3aea5345"
                defaultMessage="Add a New Notary"
              />
            </Button>
          )}
        </div>
      </nav>
      <NotaryFilters
        viewer={viewer}
        filterState={filterState}
        dispatchFilterState={dispatchFilterState}
        organizationDropdownItems={organizationDropdownItems}
        onFocusOrganizationFilter={getLazyNotaryData}
      />
      {networkStatus === NetworkStatus.setVariables ? (
        <LoadingIndicator />
      ) : (
        <NotaryListItems
          navigate={navigate}
          viewer={viewer}
          refetch={refetch}
          filterState={filterState}
          dispatchFilterState={dispatchFilterState}
          newNotaryModalOpen={newNotaryModalOpen}
          setNewNotaryModalOpen={setNewNotaryModalOpen}
          hasPermissionFor={hasPermissionFor}
          organizationDropdownItems={organizationDropdownItems}
        />
      )}
    </div>
  );
}

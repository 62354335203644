import "./index.scss";

import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { FocusScope } from "@react-aria/focus";
import { type InjectedFormProps } from "redux-form";
import { type ReactNodeLike } from "prop-types";
import type { StripeCardNumberElementChangeEvent } from "@stripe/stripe-js/dist/stripe-js/elements/card-number";
import type { StripeCardExpiryElementChangeEvent } from "@stripe/stripe-js/dist/stripe-js/elements/card-expiry";
import type { StripeCardCvcElementChangeEvent } from "@stripe/stripe-js/dist/stripe-js/elements/card-cvc";

import compose from "util/compose";
import FormGroupErrors from "common/form/group_errors";
import Button from "common/core/button";
import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import TosV2 from "common/tos";
import { getStripeCardElementsUI } from "common/settings/payment/util";
import { PAYMENT_CARD, PAYMENT_ACH } from "constants/organization";
import { getFormValues } from "util/form";
import { Label } from "common/core/form/layout";
import { Heading, Paragraph } from "common/core/typography";
import { Pill } from "common/core/pill_tabs";
import AlertMessage from "common/core/alert_message";
import { useApolloClient } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";

import { verifyAch } from "../ach_util";

const MESSAGES = defineMessages({
  cardName: {
    id: "f0f8d2e6-bc4a-42b8-9527-9fa9a07c027e",
    defaultMessage: "Cardholder Name",
  },
  tosActionNew: {
    id: "d65fb63a-291e-4348-a73a-fb1f81489475",
    defaultMessage: "By changing your plan and clicking 'Submit Payment'",
  },
  delayedCharge: {
    id: "f8ae7489-5408-4507-a609-f61412125386",
    defaultMessage: "Charge will not be made until end of payment period",
  },
  submit: {
    id: "3b23efc2-9623-42b8-8a1a-23cea809e8e3",
    defaultMessage: "Save payment",
  },
  changePayment: {
    id: "fed0179d-8797-4fd6-90ed-b9a9b7dd10fa",
    defaultMessage: "Change Payment",
  },
  useExistingPayment: {
    id: "9f7b0414-453e-4742-9682-be9ef8233245",
    defaultMessage: "Use Existing Payment",
  },
  last4: {
    id: "f9042ece-eeb8-4674-8801-2b909061064e",
    defaultMessage: "Last 4",
  },
  accountName: {
    id: "30c9c95c-e800-4e25-8688-df1b1cc1b329",
    defaultMessage: "Account Name",
  },
  creditCard: {
    id: "c016e2a6-ee8f-4c23-ad23-34494ceb19d3",
    defaultMessage: "Credit card",
  },
  bankAccount: {
    id: "74e6105f-a850-4f03-ad06-801a428b1811",
    defaultMessage: "Bank account",
  },
});

type AchAccount = {
  accountId: string;
  accountName: string;
  token: string;
};
type FormValues = {
  collectPayment: boolean;
  paymentSource: string;
  achAccount: AchAccount;
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
};

type Props = {
  submitting: boolean;
  onSubmit: () => void;
  showSubscriptionTos: boolean;
  showDelayedChargeMessage: boolean;
  currentPaymentSource?: {
    last4: string | null;
    name: string | null;
    type: "AchAccount" | "Card" | null;
  } | null;
  submitButtonLabel: ReactNodeLike;
  stripeError: boolean;
  stripeElementOptionStyles: {
    placeholder: string;
  };
  onClose?: () => void;
  // getFormValues
  formValues: FormValues;
} & InjectedFormProps<FormValues>;

type StripeCardEventType =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

function PaymentModalDetails({
  submitting,
  formValues,
  showSubscriptionTos,
  showDelayedChargeMessage,
  currentPaymentSource,
  onSubmit,
  submitButtonLabel,
  touch,
  change,
  onClose,
  stripeError,
  stripeElementOptionStyles,
}: Props) {
  const intl = useIntl();
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [plaidFocus, setPlaidFocus] = useState(false);
  const [plaidError, setPlaidError] = useState(false);

  const client = useApolloClient();
  const [activeOrganizationId] = useActiveOrganization();
  const [verifying, setVerifying] = useState(false);

  const submitAch = (achData: AchAccount) => {
    change("paymentSource", PAYMENT_ACH);
    change("achAccount", achData);
    // HACK: force onSubmit to happen after redux form handles change action
    // https://github.com/erikras/redux-form/issues/2818
    setTimeout(onSubmit, 0);
  };

  const submitCard = () => {
    change("paymentSource", PAYMENT_CARD);
    // HACK: force onSubmit to happen after redux form handles change action
    // https://github.com/erikras/redux-form/issues/2818
    setTimeout(onSubmit, 0);
  };

  const connectBank = async () => {
    setPlaidError(false);
    setVerifying(true);
    setPlaidFocus(true);
    // the payment modal is containing focus and not allowing the Plaid iframe to work correctly
    try {
      await verifyAch(client, activeOrganizationId!, submitAch, () => {
        setPlaidFocus(false);
      });
    } catch {
      setPlaidError(true);
    } finally {
      setVerifying(false);
    }
  };

  const collectPaymentFunction = () => {
    change("collectPayment", true);
  };

  const changeCardElement = (evt: StripeCardEventType) => {
    change(evt.elementType, evt.empty ? "" : "not empty");
    switch (evt.elementType) {
      case "cardNumber":
        setCardNumberComplete(evt.complete);
        break;
      case "cardExpiry":
        setCardExpiryComplete(evt.complete);
        break;
      case "cardCvc":
        setCardCvcComplete(evt.complete);
        break;
      default:
        return null;
    }
  };
  const { paymentSource, collectPayment } = formValues;
  const cardComplete = cardNumberComplete && cardExpiryComplete && cardCvcComplete;
  const hasConnectedAccount = currentPaymentSource?.type === "AchAccount";

  return (
    <div className="PaymentModalDetails">
      {plaidFocus && (
        <FocusScope contain restoreFocus autoFocus>
          <span></span>
        </FocusScope>
      )}
      {showDelayedChargeMessage && (
        <div
          data-automation-id="payment-delayed-charge-message"
          className="PaymentModalDetails--delayed-charge-message"
        >
          {intl.formatMessage(MESSAGES.delayedCharge)}
        </div>
      )}
      {collectPayment && (
        <>
          <Label>
            <FormattedMessage
              id="bfabfecf-75cd-48d8-b7fb-e34214200cd3"
              defaultMessage="Payment method"
            />
          </Label>
          <div className="PaymentModalDetails--pill-group">
            <Pill
              onClick={() => change("paymentSource", PAYMENT_CARD)}
              aria-label={intl.formatMessage(MESSAGES.creditCard)}
              selected={paymentSource === PAYMENT_CARD}
              className="PaymentModalDetails--pill"
            >
              <FormattedMessage
                id="1bc283ed-c604-4143-9ab4-e051f152e483"
                defaultMessage="Credit card"
              />
            </Pill>
            <Pill
              onClick={() => change("paymentSource", PAYMENT_ACH)}
              aria-label={intl.formatMessage(MESSAGES.bankAccount)}
              selected={paymentSource === PAYMENT_ACH}
              automationId="pill-ach"
            >
              <FormattedMessage
                id="ad81809f-caa8-4791-b827-c45afec7ad3c"
                defaultMessage="Bank account"
              />
            </Pill>
          </div>
        </>
      )}
      {currentPaymentSource && !collectPayment && !submitting && (
        <>
          <DeprecatedFormRow>
            <DeprecatedStyledTextInput
              automationId="current-payment-name"
              value={currentPaymentSource.name}
              label={
                paymentSource === PAYMENT_CARD
                  ? intl.formatMessage(MESSAGES.cardName)
                  : intl.formatMessage(MESSAGES.accountName)
              }
              disabled
            />
          </DeprecatedFormRow>
          <DeprecatedFormRow>
            <DeprecatedStyledTextInput
              automationId="current-payment-last4"
              value={currentPaymentSource.last4}
              label={intl.formatMessage(MESSAGES.last4)}
              disabled
            />
          </DeprecatedFormRow>
        </>
      )}
      {collectPayment &&
        (paymentSource === PAYMENT_CARD ? (
          getStripeCardElementsUI(
            (evt: StripeCardEventType) => changeCardElement(evt),
            touch,
            stripeElementOptionStyles,
          )
        ) : (
          <>
            <Paragraph>
              <FormattedMessage
                id="06b22b64-2044-402e-9a47-781c0d3fb650"
                defaultMessage="Allow your company to pay directly with a bank account. These charges will be batched and processed daily. You will be taken to Plaid to securely connect your account. "
              />
            </Paragraph>
            {hasConnectedAccount && (
              <div className="PaymentModalDetails--connected-bank">
                <Heading level="h2" textColor="subtle" textStyle="allCapsLabelSmall">
                  <FormattedMessage
                    id="2614d7e9-7ac5-414c-add0-aef3a31f1eb6"
                    defaultMessage="Account connected"
                  />
                </Heading>
                <Paragraph className="PaymentModalDetails--connected-bank-name">
                  <strong>
                    <FormattedMessage
                      id="120c4537-4e7a-4424-813f-393a30b6a596"
                      defaultMessage="[{account}]"
                      values={{ account: currentPaymentSource.name }}
                    />
                  </strong>
                </Paragraph>
              </div>
            )}
            <Button
              automationId="payment-submit-ach"
              className="PaymentModalDetails--button"
              onClick={connectBank}
              isLoading={verifying || (submitting && paymentSource === PAYMENT_ACH)}
              disabled={submitting && paymentSource === PAYMENT_CARD}
              buttonColor="action"
              variant="secondary"
            >
              <FormattedMessage
                id="1afb8726-9643-475a-a15d-a9be37e7639f"
                defaultMessage="{action} bank account"
                values={{
                  action: hasConnectedAccount ? "Change" : "Connect",
                }}
              />
            </Button>
            <FormGroupErrors fields={["achAccount"]} />
          </>
        ))}
      {showSubscriptionTos && (
        <div
          data-automation-id="payment-subscription-tos"
          className="PaymentModalDetails--subscription-tos"
        >
          <TosV2 actionText={intl.formatMessage(MESSAGES.tosActionNew)} />
        </div>
      )}
      {!submitting && (stripeError || plaidError) && (
        <AlertMessage kind="danger">
          <FormattedMessage
            id="51a679d2-2dc8-4396-9e9c-378f73dc5bf3"
            defaultMessage="Sorry, something went wrong. Please try again."
          />
        </AlertMessage>
      )}
      <div>
        {collectPayment ? (
          <div className="PaymentModalDetails--buttons">
            <Button
              automationId="payment-submit-card"
              onClick={submitCard}
              isLoading={submitting && paymentSource === PAYMENT_CARD}
              disabled={(submitting && paymentSource === PAYMENT_ACH) || !cardComplete}
              buttonColor="action"
              variant="primary"
            >
              {submitButtonLabel || intl.formatMessage(MESSAGES.submit)}
            </Button>

            {onClose && (
              <Button buttonColor="dark" variant="tertiary" key="cancel" onClick={onClose}>
                <FormattedMessage
                  id="5c4d3f96-c6c8-47cf-8ff6-7fa2fd99d69d"
                  defaultMessage="Cancel"
                />
              </Button>
            )}
          </div>
        ) : (
          <>
            <Button
              automationId="payment-use-existing"
              className="PaymentModalDetails--button"
              onClick={onSubmit}
              isLoading={submitting}
              buttonColor="action"
              buttonSize="large"
              variant="primary"
              fullwidth
            >
              {intl.formatMessage(MESSAGES.useExistingPayment)}
            </Button>
            <Button
              automationId="payment-change"
              className="PaymentModalDetails--button"
              onClick={collectPaymentFunction}
              disabled={submitting}
              buttonColor="action"
              buttonSize="large"
              variant="secondary"
              fullwidth
            >
              {intl.formatMessage(MESSAGES.changePayment)}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default compose(getFormValues("payment"))(PaymentModalDetails);

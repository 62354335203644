import { Field } from "redux-form";

/**
 * Use this component to turn a low-level input component
 * into a "Field" that can interface with the ReduxForm.
 * It will add a required "name" prop so that the new Field
 * will know what data it's connected to, and also handle
 * any new Redux Form provided metaprops (like handlers or
 * the actual input value in the Redux store).
 * See any of the components in common/form/fields/ for examples.
 **/

/** @deprecated - please use components in common/core/form */
export function DeprecatedFieldWrapper(Component, { withMeta = false } = {}) {
  function ComponentWithReduxFormProps(props) {
    const { meta, input, ...nonReduxFormProps } = props;

    // meta = ReduxForm store tracking state of inputs
    // (like active, visited, etc.)
    // input = ReduxForm helpers for the base <input> element
    // everything else = props directly provided to component

    const { onFocus, onChange, onBlur, value, name } = input;

    const reduxFormMetaProps = withMeta ? meta : null;

    return (
      <Component
        value={value}
        name={name}
        onFocus={onFocus}
        // onBlur can have some caveats for certain lower input components:
        // see blur handler in SelectInput for example
        onBlur={onBlur}
        onChange={onChange}
        meta={reduxFormMetaProps}
        {...nonReduxFormProps}
      />
    );
  }

  function ComponentWrappedInField(props) {
    return <Field {...props} component={ComponentWithReduxFormProps} />;
  }

  ComponentWrappedInField.defaultProps = Component.defaultProps;

  ComponentWrappedInField.displayName = Component.displayName || Component.name;

  return ComponentWrappedInField;
}

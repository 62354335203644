import "./index.scss";

import {
  useRef,
  useLayoutEffect,
  useState,
  type ReactNode,
  type MouseEvent,
  type ComponentProps,
} from "react";
import type { Link as RouterLink } from "react-router-dom";
import classnames from "classnames";

import Link from "common/core/link";
import SROnly from "common/core/screen_reader";
import { handleButtonKeyDown } from "util/keyboard_navigation";
import LoadingIndicator from "common/core/loading_indicator";
import { BulkActionCheckbox } from "common/core/bulk_actions/checkbox";

type RowCallout = "warn";

type Column<I> = {
  Header: ReactNode;
  render: (item: I, i: number) => ReactNode;
  collapse?: boolean;
  preventClick?: boolean;
  clearPadding?: boolean;
};

type Props<I> = {
  columns: Column<I>[];
  data: I[];
  totalItems: number;
  loading?: boolean;
  caption?: Exclude<ReactNode, null | undefined>;
  firstColumnHeader?: Exclude<ReactNode, null | undefined>;
  rowInteraction?: {
    getCallout?: (item: I) => RowCallout | undefined;
    onClick: (item: I) => void;
    getLinkProps?: (
      item: I,
    ) => { to: ComponentProps<typeof RouterLink>["to"] } & Partial<ComponentProps<typeof Link>>;
  };
  rowActionRender?: (item: I, index: number) => ReactNode;
  getAutomationId?: (item: I) => string;
  stickyFirstColumn?: boolean;
  isRowSelected?: (item: I) => boolean;
  extraPadding?: boolean;
  stickyHotDogMenu?: boolean;
  noShadow?: boolean;
};

export default function Table<I>(props: Props<I>) {
  const {
    caption,
    columns,
    data,
    loading,
    rowInteraction,
    rowActionRender,
    firstColumnHeader,
    getAutomationId,
    stickyFirstColumn,
    isRowSelected,
    extraPadding,
    stickyHotDogMenu,
    noShadow,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [outlineStyle, setOutlineStyle] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!loading && ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [loading]);

  const clickable = Boolean(rowInteraction);

  if (data.length === 0 && !loading) {
    return <table data-automation-id="table"></table>;
  }
  const tableClasses = classnames("Table", {
    loading,
    actionable: stickyFirstColumn || Boolean(rowActionRender),
    clickable,
    outlined: outlineStyle,
    stickyHotDogMenu,
    addShadow: noShadow ? "" : "add-shadow",
  });
  return (
    <div className={classnames(tableClasses, {})} ref={ref}>
      {loading && <LoadingIndicator />}

      <table data-automation-id="table" className={classnames({ clickable })}>
        {Boolean(caption) && (
          <caption>
            <SROnly>{caption}</SROnly>
          </caption>
        )}
        <thead>
          <tr>
            {/* First table header cell with hidden column title for screen reader */}
            {rowActionRender && (
              <th scope="col" className="collapse">
                {firstColumnHeader && <SROnly>{firstColumnHeader}</SROnly>}
              </th>
            )}
            {columns.map((column, i) => (
              <th
                key={i}
                scope="col"
                data-automation-id={`table-header-${i}`}
                className={classnames({
                  collapse: column.collapse,
                  "clear-padding": column.clearPadding,
                  "extra-padding": extraPadding,
                })}
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, indexRow) => {
            const rowLinkProps = rowInteraction?.getLinkProps?.(item);
            const rowSelected = Boolean(isRowSelected?.(item));
            return (
              <tr
                onFocus={() => {
                  !outlineStyle && setOutlineStyle(true);
                }}
                onBlur={() => {
                  outlineStyle && setOutlineStyle(false);
                }}
                tabIndex={0}
                key={`table-row-${indexRow}`}
                onClick={() => {
                  rowInteraction?.onClick(item);
                }}
                onKeyDown={(e) => {
                  const fn = () => rowInteraction?.onClick(item);
                  handleButtonKeyDown(e, fn);
                }}
                data-automation-id={getAutomationId?.(item) ?? `table-row-${indexRow}`}
                className={rowInteraction?.getCallout?.(item)}
              >
                {/* First table cell */}
                {rowActionRender && (
                  <td className="Table--action collapse">{rowActionRender(item, indexRow)}</td>
                )}

                {columns.map(({ collapse, render, preventClick, clearPadding }, indexCell) => {
                  return (
                    <td
                      style={stickyHotDogMenu ? { zIndex: data.length - indexRow } : {}}
                      data-automation-id={`table-cell-${indexCell}`}
                      key={`table-cell-${indexCell}`}
                      className={classnames({
                        collapse,
                        "row-selected": rowSelected,
                        "clear-padding": clearPadding,
                        "extra-padding": extraPadding,
                      })}
                      onClick={preventClick ? (e) => e.stopPropagation() : undefined}
                    >
                      {clickable && rowLinkProps && !preventClick ? (
                        <Link
                          black
                          tabIndex={-1}
                          automationId={`table-link-${indexRow}-${indexCell}`}
                          onClick={(e: MouseEvent) => e.stopPropagation()}
                          underlined={false}
                          {...rowLinkProps}
                        >
                          {render(item, indexRow)}
                        </Link>
                      ) : (
                        render(item, indexRow)
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

type BulkItem = { id: string };
type BulkTableProps<I extends BulkItem> = Omit<
  Props<I>,
  "stickyFirstColumn" | "rowActionRender" | "firstColumnHeader" | "isRowSelected"
> & {
  toggleItem: (id: string) => void;
  toggleAllItems: () => void;
  selectAllCheckboxState: "unchecked" | "partial" | "checked";
  selectedItemIds: Set<string>;
};

function BulkTable<I extends BulkItem>(props: BulkTableProps<I>) {
  const { toggleItem, toggleAllItems, selectAllCheckboxState, selectedItemIds, ...tableProps } =
    props;
  const allItemsChecked =
    selectAllCheckboxState === "checked" || selectAllCheckboxState === "partial";
  const isRowSelected = (item: I) => selectedItemIds.has(item.id);

  const columns: Props<I>["columns"] = [
    {
      Header: (
        <BulkActionCheckbox
          selectAll
          checked={allItemsChecked}
          indeterminate={selectAllCheckboxState === "partial"}
          onClick={() => toggleAllItems()}
        />
      ),
      render: (item) => (
        <BulkActionCheckbox
          itemId={item.id}
          checked={selectedItemIds.has(item.id)}
          onClick={() => toggleItem(item.id)}
        />
      ),
      collapse: true,
      preventClick: true,
    },
    ...tableProps.columns,
  ];
  return (
    <Table
      {...tableProps}
      columns={columns}
      rowActionRender={undefined}
      stickyFirstColumn
      isRowSelected={isRowSelected}
    />
  );
}

export { BulkTable };

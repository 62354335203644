import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { segmentTrack } from "util/segment";
import { IconButton } from "common/core/button/icon_button";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import PopoutMenu from "common/core/popout_menu";
import Svg from "common/core/svg";
import docIcon from "assets/images/doc.svg";
import rotateIcon from "assets/images/rotate-right.svg";
import downloadIcon from "assets/images/download.svg";
import fullscreenIcon from "assets/images/fullscreen.svg";
import { MobilePageNavigationOption } from "common/pdf/pspdfkit/util";
import { MobilePdfControlOption } from "common/pdf/pspdfkit/zoom";
import TooltipOverlay from "common/core/tooltip/overlay";
import { useId } from "util/html";

import Styles from "./mobile_actions_menu.module.scss";

const MESSAGES = defineMessages({
  addAnnotationsLabel: {
    id: "4f6d7122-7267-4553-bc99-3dbd4bf00f86",
    defaultMessage: "Add annotations",
  },
  additionalOptionsLabel: {
    id: "b8fe188b-3a50-4633-8359-139f9500d2bd",
    defaultMessage: "Additional document control options",
  },
});

type Props = {
  handleRotate?: () => Promise<void>;
  hasAnnotationsOrDesignations: boolean;
  // if this is not passed in we will not show the download button
  handleClickDownload?: () => void;
  canAnnotate: boolean;
  showAnnotationTooltip?: boolean;
  canSign?: boolean;
  handleClickAnnotationTools?: () => void;
  hideMenu: boolean;
};

export const MobileActionsMenu = ({
  handleRotate,
  hasAnnotationsOrDesignations,
  handleClickDownload,
  canAnnotate,
  showAnnotationTooltip,
  canSign,
  handleClickAnnotationTools,
  hideMenu,
}: Props) => {
  const intl = useIntl();
  const showAnnotationsButton = canAnnotate && handleClickAnnotationTools;
  const describedById = useId();
  if (hideMenu) {
    return null;
  }
  return (
    <div className={Styles.actionsWrapper}>
      <PopoutMenu
        placement="topLeft"
        aria-label={intl.formatMessage(MESSAGES.additionalOptionsLabel)}
        aria-describedby={intl.formatMessage(MESSAGES.additionalOptionsLabel)}
        className={Styles.kebabBtn}
      >
        {({ close }) => (
          <>
            {handleClickDownload && (
              <PopoutMenuMultilineItem
                onClick={() => {
                  segmentTrack("[Footer Toolbar] Download documents option clicked");
                  handleClickDownload();
                }}
                primaryContent={
                  <>
                    <Svg className={Styles.svg} src={downloadIcon} aria-hidden="true" alt="" />
                    <FormattedMessage
                      id="9a609fd4-f4cc-44ca-8784-79ac44e039f9"
                      defaultMessage="Download"
                    />
                  </>
                }
              />
            )}
            {handleRotate && (
              <PopoutMenuMultilineItem
                onClick={() => {
                  segmentTrack("[Footer Toolbar] Rotate document option clicked");
                  handleRotate();
                  close();
                }}
                disabled={hasAnnotationsOrDesignations}
                primaryContent={
                  <>
                    <Svg className={Styles.svg} src={rotateIcon} aria-hidden="true" alt="" />
                    <FormattedMessage
                      id="6ec04b44-4044-499e-879a-cc77eb6c1151"
                      defaultMessage="Rotate"
                    />
                  </>
                }
                {...(hasAnnotationsOrDesignations && {
                  secondaryContent: (
                    <FormattedMessage
                      id="25da0a2c-7040-464f-b52b-d12bce4e3025"
                      defaultMessage="Remove fields before rotating"
                    />
                  ),
                })}
              />
            )}
            <PopoutMenuMultilineItem
              onClick={() => segmentTrack("[Footer Toolbar] Page navigation option clicked")}
              primaryContent={
                <MobilePageNavigationOption>
                  <Svg className={Styles.svg} src={docIcon} aria-hidden="true" alt="" />
                  <FormattedMessage
                    id="1474a0bf-8afe-4c2f-9e7a-6999954ef01f"
                    defaultMessage="Page navigation"
                  />
                </MobilePageNavigationOption>
              }
            />
            <PopoutMenuMultilineItem
              onClick={() => segmentTrack("[Footer Toolbar] Focus option clicked")}
              primaryContent={
                <MobilePdfControlOption action="fitToWidth">
                  <Svg className={Styles.svg} src={fullscreenIcon} aria-hidden="true" alt="" />
                  <FormattedMessage
                    id="729386e5-6772-49dc-a58e-f899bb83be70"
                    defaultMessage="Focus"
                  />
                </MobilePdfControlOption>
              }
            />
          </>
        )}
      </PopoutMenu>
      {showAnnotationsButton && (
        <div className={Styles.annotationsBtnWrapper}>
          <IconButton
            className={Styles.annotationsBtn}
            name="add-annotation"
            aria-describedby={describedById}
            variant="tertiary"
            buttonColor="dark"
            onClick={handleClickAnnotationTools}
            label={intl.formatMessage(MESSAGES.addAnnotationsLabel)}
          />
          {showAnnotationTooltip && (
            <div id={describedById} data-automation-id="annotations-tooltip">
              <TooltipOverlay trigger="click" placement="topLeft">
                {canSign ? (
                  <FormattedMessage
                    id="ae6c2fb1-8391-4996-be8a-f24380e76a34"
                    defaultMessage="Fill and sign"
                  />
                ) : (
                  <FormattedMessage
                    id="41a3ae10-d355-4588-8af9-f6eea97605e5"
                    defaultMessage="Add text to your document"
                  />
                )}
              </TooltipOverlay>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { reduxForm } from "redux-form";

import { ReswareInstanceDocumentRole, ReswareInstanceDocumentName } from "graphql_globals";
import compose from "util/compose";
import { composeValidators, getFormValues } from "util/form";
import { DeprecatedTextField } from "common/form/fields/text";
import { validatePositiveInteger, validatePresence, validateIf } from "validators/form";
import Button from "common/core/button";
import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import FormGroupErrors from "common/form/group_errors";
import { normalizeToDigits, normalizeToNumber } from "util/normalize";
import { captureException } from "util/exception";
import { Mutation } from "util/graphql/mutation";
import UpdateReswareInstanceMutation from "common/resware_instance/update_resware_instance_mutation.graphql";
import AlertMessage from "common/core/alert_message";

const messages = defineMessages({
  documentInstructions: {
    id: "3f14a797-74e0-473c-8061-b0fa2cd9fb57",
    description: "resware document instructions",
    defaultMessage: "Configure the documents you are going to send to Proof",
  },
  documentInstructionsHelp: {
    id: "50646258-ac35-4aa5-92d0-270831baad16",
    description: "resware document instructions help",
    defaultMessage:
      "Tell us which DocumentTypeID you are going to send to us and which DocumentTypeID Proof should push back the executed documents as.",
  },
  buyersDocumentTypeId: {
    id: "b3451c70-0754-4f5b-8e4a-5d743f79cfb0",
    description: "resware buyers package DocumentTypeID",
    defaultMessage: "Buyer's Package DocumentTypeID",
  },
  buyersExecutedDocumentTypeId: {
    id: "621ff042-db08-4c8a-b4f2-1a3f9c50c24c",
    description: "executed resware buyers DocumentTypeID",
    defaultMessage: "Executed Buyer's Package DocumentTypeID",
  },
  sellersDocumentTypeId: {
    id: "8b2c12df-029a-4e8d-a45b-82b6943229ae",
    description: "resware sellers package DocumentTypeID",
    defaultMessage: "Seller's Package DocumentTypeID",
  },
  sellersExecutedDocumentTypeId: {
    id: "1489c6c3-5d38-43b7-bfb7-d3bf41e83ea0",
    description: "executed resware sellers DocumentTypeID",
    defaultMessage: "Executed Seller's Package DocumentTypeID",
  },
  role: {
    id: "891b21ff-330b-4a5f-85d2-4b20081a2326",
    description: "resware document role header",
    defaultMessage: "Who signs?",
  },
  addDocumentType: {
    id: "45daea3b-d13d-4e54-8348-67c3a2972c15",
    description: "add resware document",
    defaultMessage: "Add a Document Type",
  },
  sendDocuments: {
    id: "26960daa-0560-4c92-8518-c6c48d00d4d4",
    description: "sendDocuments",
    defaultMessage: "How would you like to receive your documents from Proof?",
  },
  sendMergedDocument: {
    id: "08b01ff8-4648-4730-920c-27bb5f9d5c49",
    description: "sendMergedDocument",
    defaultMessage: "Would you like them merged and sent in a single file?",
  },
  sendIndividualDocuments: {
    id: "01241128-d67b-480d-91c4-d9d906084a5d",
    description: "sendIndividualDocuments",
    defaultMessage: "Would you like them sent individually?",
  },
  receiveInvoices: {
    id: "31fc8aec-7fda-4021-ac15-e5156e8fc774",
    description: "receiveInvoices",
    defaultMessage: "Would you like to receive invoices as a document type?",
  },
  invoiceDocumentTypeId: {
    id: "9df5006a-e70a-4731-b619-e11ca77b760d",
    description: "invoiceDocumentTypeId",
    defaultMessage: "Invoice DocumentTypeID",
  },
  receivePhotoIds: {
    id: "2b1599e2-e457-4729-816b-23334ceec38e",
    description: "receivePhotoIds",
    defaultMessage: "Would you like to receive photo IDs as a document type?",
  },
  photoIdDocumentTypeId: {
    id: "8bbfe0ff-95cd-4b5b-929c-08ae29b346a3",
    description: "photoIdDocumentTypeId",
    defaultMessage: "Photo ID DocumentTypeID",
  },
  receiveRejectedDocuments: {
    id: "e6c12907-0bf2-467d-8a9c-05cdd1368901",
    description: "receive rejected documents",
    defaultMessage:
      "Would you like to receive rejected documents with a different DocumentTypeID than the rest of the closing package?",
  },
  rejectedDocuments: {
    id: "cda7abf6-b24f-43f9-a1e6-acd26c51febb",
    description: "rejected document information",
    defaultMessage:
      "Sometimes a document is rejected during a notary meeting if it has been incorrectly marked as requiring notarization or for some other reason is not applicable to the signer. If this is the case, we can return the rejected document using a DocumentTypeID that you specify.",
  },
  rejectedDocumentTypeId: {
    id: "5dbbe6a4-d8f0-4d31-bc0d-51ca178f4a5c",
    description: "rejected document type id",
    defaultMessage: "Rejected DocumentTypeID",
  },
  setupMergedDocumentType: {
    id: "850680de-c0eb-4339-9b4d-8bdcb12d1501",
    description: "resware proof default document type setup",
    defaultMessage: "Setup the default document type to be used by Proof",
  },
  setupMergedDocumentTypeHelp: {
    id: "2ed9b076-0245-46cf-a5a4-bd49bfc02f0f",
    description: "resware proof default document type setup help",
    defaultMessage:
      "It will be used if you chose to receive your documents merged and sent all at once. " +
      "We will also use this document type to push back test documents.",
  },
  mergedDocumentTypeId: {
    id: "3bba2c74-c64e-4c0e-a780-53cc6baefb64",
    description: "resware proof default document type",
    defaultMessage: "Proof Executed DocumentTypeID",
  },
  save: {
    id: "fc8eb221-a621-4d07-a7d5-1ea043d96549",
    defaultMessage: "Save",
  },
  instanceSaveSuccess: {
    id: "d4e47871-1cfe-4d11-9e77-95405e020376",
    defaultMessage: "ResWare document settings saved successfully.",
  },
  instanceSaveFailure: {
    id: "df42b249-02dc-4532-a23d-e33ed81645df",
    defaultMessage: "Hmm. Looks like something went wrong. Please reach out to Proof support.",
  },
});

function validate(values, props) {
  const { intl } = props;
  const {
    buyersDocumentTypeId,
    buyersExecutedDocumentTypeId,
    sellersDocumentTypeId,
    sellersExecutedDocumentTypeId,
  } = values;

  return composeValidators(
    validatePresence({
      field: "mergedDocumentTypeId",
      label: intl.formatMessage(messages.mergedDocumentTypeId),
    }),
    validatePositiveInteger({
      field: "mergedDocumentTypeId",
      label: intl.formatMessage(messages.mergedDocumentTypeId),
    }),
    validateIf({
      condition: () => buyersExecutedDocumentTypeId,
      validation: validatePresence({
        field: "buyersDocumentTypeId",
        label: intl.formatMessage(messages.buyersDocumentTypeId),
      }),
    }),
    validateIf({
      condition: () => buyersDocumentTypeId,
      validation: validatePresence({
        field: "buyersExecutedDocumentTypeId",
        label: intl.formatMessage(messages.buyersExecutedDocumentTypeId),
      }),
    }),
    validateIf({
      condition: () => sellersExecutedDocumentTypeId,
      validation: validatePresence({
        field: "sellersDocumentTypeId",
        label: intl.formatMessage(messages.sellersDocumentTypeId),
      }),
    }),
    validateIf({
      condition: () => sellersDocumentTypeId,
      validation: validatePresence({
        field: "sellersExecutedDocumentTypeId",
        label: intl.formatMessage(messages.sellersExecutedDocumentTypeId),
      }),
    }),
    validatePresence({
      field: "photoIdDocumentTypeId",
      label: intl.formatMessage(messages.photoIdDocumentTypeId),
    }),
    validatePresence({
      field: "invoiceDocumentTypeId",
      label: intl.formatMessage(messages.invoiceDocumentTypeId),
    }),
  )(values);
}

class ReswareInstanceDocumentsTab extends Component {
  constructor(props) {
    super(props);
    const {
      initialize,
      organization: { reswareInstance },
    } = props;
    const documents =
      reswareInstance.reswareDocuments.length > 0 ? reswareInstance.reswareDocuments : [{}];
    const buyersDocument = documents.find((d) => d.role === ReswareInstanceDocumentRole.BUYER);
    const sellersDocument = documents.find((d) => d.role === ReswareInstanceDocumentRole.SELLER);

    this.state = {
      isSubmitting: false,
      alertMessage: null,
      alertKind: null,
      showPhotoIdDocumentTypeId: reswareInstance.photoIdDocumentTypeId !== null,
      showInvoiceIdDocumentTypeId: reswareInstance.invoiceDocumentTypeId !== null,
      showRejectedDocumentTypeId: reswareInstance.rejectedDocumentTypeId !== null,
    };

    initialize({
      mergedDocumentTypeId: reswareInstance.mergedDocumentTypeId,
      sendMergedDocument: reswareInstance.sendMergedDocument,
      sendIndividualDocuments: reswareInstance.sendIndividualDocuments,
      receiveInvoices: this.state.showInvoiceIdDocumentTypeId,
      invoiceDocumentTypeId: reswareInstance.invoiceDocumentTypeId,
      photoIdDocumentTypeId: reswareInstance.photoIdDocumentTypeId,
      receivePhotoIds: this.state.showPhotoIdDocumentTypeId,
      rejectedDocumentTypeId: reswareInstance.rejectedDocumentTypeId,
      receiveRejectedDocument: this.state.showRejectedDocumentTypeId,
      sellersDocumentTypeId: sellersDocument ? sellersDocument.documentTypeId : null,
      sellersExecutedDocumentTypeId: sellersDocument
        ? sellersDocument.executedDocumentTypeId
        : null,
      buyersDocumentTypeId: buyersDocument ? buyersDocument.documentTypeId : null,
      buyersExecutedDocumentTypeId: buyersDocument ? buyersDocument.executedDocumentTypeId : null,
    });
  }

  onSubmit = ({
    buyersDocumentTypeId,
    buyersExecutedDocumentTypeId,
    sellersDocumentTypeId,
    sellersExecutedDocumentTypeId,
    sendMergedDocument,
    sendIndividualDocuments,
    mergedDocumentTypeId,
    invoiceDocumentTypeId,
    photoIdDocumentTypeId,
    rejectedDocumentTypeId,
  }) => {
    const {
      intl,
      updateReswareInstanceMutateFn,
      organization: {
        reswareInstance: { id },
      },
      organization: { id: organizationId },
    } = this.props;

    const documents = [];
    if (buyersDocumentTypeId && buyersExecutedDocumentTypeId) {
      documents.push({
        name: ReswareInstanceDocumentName.BUYER_CLOSING_PACKAGE,
        documentTypeId: buyersDocumentTypeId,
        executedDocumentTypeId: buyersExecutedDocumentTypeId,
        role: ReswareInstanceDocumentRole.BUYER,
      });
    }

    if (sellersDocumentTypeId && sellersExecutedDocumentTypeId) {
      documents.push({
        name: ReswareInstanceDocumentName.SELLER_CLOSING_PACKAGE,
        documentTypeId: sellersDocumentTypeId,
        executedDocumentTypeId: sellersExecutedDocumentTypeId,
        role: ReswareInstanceDocumentRole.SELLER,
      });
    }

    const input = {
      id,
      organizationId,
      sendMergedDocument,
      sendIndividualDocuments,
      mergedDocumentTypeId,
      invoiceDocumentTypeId,
      photoIdDocumentTypeId,
      rejectedDocumentTypeId,
      reswareInstanceDocuments: documents,
    };

    this.setState({ isSubmitting: true });
    return updateReswareInstanceMutateFn({ variables: { input } })
      .then(() => {
        this.setState({
          alertMessage: intl.formatMessage(messages.instanceSaveSuccess),
          alertKind: "success",
        });
      })
      .catch((err) => {
        captureException(err);
        this.setState({
          alertMessage: intl.formatMessage(messages.instanceSaveFailure),
          alertKind: "danger",
        });
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const {
      handleSubmit,
      intl,
      organization: {
        reswareInstance: { canReceiveInvoice },
      },
      formValues: { sendIndividualDocuments },
    } = this.props;
    const {
      alertMessage,
      alertKind,
      isSubmitting,
      showPhotoIdDocumentTypeId,
      showInvoiceIdDocumentTypeId,
      showRejectedDocumentTypeId,
    } = this.state;

    return (
      <div className="ReswareInstanceDocuments">
        <form
          name="reswareInstanceDocumentsForm"
          onSubmit={handleSubmit(this.onSubmit)}
          data-automation-id={"resware-instance-documents-form"}
        >
          {alertMessage && (
            <AlertMessage className="ReswareInstanceAlertBanner" kind={alertKind}>
              {alertMessage}
            </AlertMessage>
          )}

          <div>{intl.formatMessage(messages.documentInstructions)}</div>
          <div className="ReswareInstanceDocuments--HelpText">
            {intl.formatMessage(messages.documentInstructionsHelp)}
          </div>

          <div className="ReswareInstanceDocuments--Table">
            <div className="ReswareInstanceDocuments--Table--Row">
              <div className="ReswareInstanceDocuments--Table--Cell">
                <DeprecatedTextField
                  id="sellersDocumentTypeId"
                  name="sellersDocumentTypeId"
                  normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                  placeholder={intl.formatMessage(messages.sellersDocumentTypeId)}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="resware-sellers-package-document-type-id"
                />
                <FormGroupErrors fields={["sellersDocumentTypeId"]} />
              </div>
              <div className="ReswareInstanceDocuments--Table--Cell">
                <DeprecatedTextField
                  id="sellersExecutedDocumentTypeId"
                  name="sellersExecutedDocumentTypeId"
                  normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                  placeholder={intl.formatMessage(messages.sellersExecutedDocumentTypeId)}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="resware-sellers-package-executed-type-id"
                />
                <FormGroupErrors fields={["sellersExecutedDocumentTypeId"]} />
              </div>
            </div>
            <div className="ReswareInstanceDocuments--Table--Row">
              <div className="ReswareInstanceDocuments--Table--Cell">
                <DeprecatedTextField
                  id="buyersDocumentTypeId"
                  name="buyersDocumentTypeId"
                  normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                  placeholder={intl.formatMessage(messages.buyersDocumentTypeId)}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="resware-buyers-package-document-type-id"
                />
                <FormGroupErrors fields={["buyersDocumentTypeId"]} />
              </div>
              <div className="ReswareInstanceDocuments--Table--Cell">
                <DeprecatedTextField
                  id="buyersExecutedDocumentTypeId"
                  name="buyersExecutedDocumentTypeId"
                  normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                  placeholder={intl.formatMessage(messages.buyersExecutedDocumentTypeId)}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="resware-buyers-package-executed-type-id"
                />
                <FormGroupErrors fields={["buyersExecutedDocumentTypeId"]} />
              </div>
            </div>
          </div>

          <div className="ReswareInstanceForm--SecondaryTitle">
            {intl.formatMessage(messages.sendDocuments)}
          </div>
          <DeprecatedFormRow className="ReswareInstanceForm--FormRow ReswareInstanceForm--FormRow--RadioButtons">
            <div className="ReswareInstanceForm--FormRow--RadioButtons--Title">
              {intl.formatMessage(messages.sendMergedDocument)}
            </div>
            <DeprecatedRadioButtonField
              id="sendMergedDocument"
              name="sendMergedDocument"
              automationId="no-resware-instance-send-merged-document"
              labelText="No"
              radioValue={false}
              size="small"
            />
            <DeprecatedRadioButtonField
              id="sendMergedDocument"
              name="sendMergedDocument"
              automationId="yes-resware-instance-send-merged-document"
              labelText="Yes"
              radioValue
              size="small"
            />
            <FormGroupErrors fields={["sendMergedDocument"]} />
          </DeprecatedFormRow>

          <DeprecatedFormRow className="ReswareInstanceForm--FormRow ReswareInstanceForm--FormRow--RadioButtons">
            <div className="ReswareInstanceForm--FormRow--RadioButtons--Title">
              {intl.formatMessage(messages.sendIndividualDocuments)}
            </div>
            <DeprecatedRadioButtonField
              id="sendIndividualDocuments"
              name="sendIndividualDocuments"
              automationId="no-resware-instance-send-individual-documents"
              labelText="No"
              radioValue={false}
              size="small"
            />
            <DeprecatedRadioButtonField
              id="sendIndividualDocuments"
              name="sendIndividualDocuments"
              automationId="yes-resware-instance-send-individual-documents"
              labelText="Yes"
              radioValue
              size="small"
            />
            <FormGroupErrors fields={["sendIndividualDocuments"]} />
          </DeprecatedFormRow>

          {sendIndividualDocuments && (
            <DeprecatedFormRow className="ReswareInstanceForm--FormRow ReswareInstanceForm--FormRow--RadioButtons">
              <div className="ReswareInstanceForm--FormRow--RadioButtons--Title">
                {intl.formatMessage(messages.receiveRejectedDocuments)}
              </div>
              <AlertMessage className="ReswareInstanceAlertBanner" kind="info">
                {intl.formatMessage(messages.rejectedDocuments)}
              </AlertMessage>
              <DeprecatedRadioButtonField
                id="receiveRejectedDocument"
                name="receiveRejectedDocument"
                automationId="no-resware-instance-receive-rejected-document"
                labelText="No"
                radioValue={false}
                onChange={(v) => {
                  this.props.change("rejectedDocumentTypeId", null);
                  this.setState({ showRejectedDocumentTypeId: v });
                }}
                size="small"
              />
              <DeprecatedRadioButtonField
                id="receiveRejectedDocument"
                name="receiveRejectedDocument"
                automationId="yes-resware-instance-receive-rejected-document"
                labelText="Yes"
                radioValue
                onChange={(v) => {
                  this.setState({ showRejectedDocumentTypeId: v });
                }}
                size="small"
              />
              <FormGroupErrors fields={["receiveRejectedDocument"]} />
              {showRejectedDocumentTypeId && (
                <>
                  <DeprecatedTextField
                    id="rejectedDocumentTypeId"
                    name="rejectedDocumentTypeId"
                    className="ReswareInstanceDocuments--RejectedDocumentTypeId"
                    data-automation-id="resware-instance-rejected-document-type-id"
                    normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                    placeholder={intl.formatMessage(messages.rejectedDocumentTypeId)}
                    placeholderAsLabel
                    useStyledInput
                  />
                  <FormGroupErrors fields={["rejectedDocumentTypeId"]} />
                </>
              )}
            </DeprecatedFormRow>
          )}

          {canReceiveInvoice && (
            <DeprecatedFormRow className="ReswareInstanceForm--FormRow ReswareInstanceForm--FormRow--RadioButtons">
              <div className="ReswareInstanceForm--FormRow--RadioButtons--Title receiveInvoices">
                {intl.formatMessage(messages.receiveInvoices)}
              </div>
              <DeprecatedRadioButtonField
                id="receiveInvoices"
                name="receiveInvoices"
                automationId="no-resware-instance-receive-invoices"
                labelText="No"
                radioValue={false}
                onChange={(v) => {
                  this.props.change("invoiceDocumentTypeId", null);
                  this.setState({ showInvoiceIdDocumentTypeId: v });
                }}
                size="small"
              />
              <DeprecatedRadioButtonField
                id="receiveInvoices"
                name="receiveInvoices"
                automationId="yes-resware-instance-receive-invoices"
                labelText="Yes"
                radioValue
                onChange={(v) => {
                  this.setState({ showInvoiceIdDocumentTypeId: v });
                }}
                size="small"
              />
              <FormGroupErrors fields={["receiveInvoices"]} />
              {showInvoiceIdDocumentTypeId && (
                <>
                  <DeprecatedTextField
                    id="invoiceDocumentTypeId"
                    name="invoiceDocumentTypeId"
                    className="ReswareInstanceDocuments--InvoiceDocumentTypeId"
                    data-automation-id="resware-instance-invoice-document-type-id"
                    normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                    placeholder={intl.formatMessage(messages.invoiceDocumentTypeId)}
                    placeholderAsLabel
                    useStyledInput
                  />
                  <FormGroupErrors fields={["invoiceDocumentTypeId"]} />
                </>
              )}
            </DeprecatedFormRow>
          )}
          <DeprecatedFormRow className="ReswareInstanceForm--FormRow ReswareInstanceForm--FormRow--RadioButtons">
            <div className="ReswareInstanceForm--FormRow--RadioButtons--Title receivePhotoIds">
              {intl.formatMessage(messages.receivePhotoIds)}
            </div>
            <DeprecatedRadioButtonField
              id="receivePhotoIds"
              name="receivePhotoIds"
              automationId="no-resware-instance-receive-photo-ids"
              labelText="No"
              radioValue={false}
              onChange={(v) => {
                this.props.change("photoIdDocumentTypeId", null);
                this.setState({ showPhotoIdDocumentTypeId: v });
              }}
              size="small"
            />
            <DeprecatedRadioButtonField
              id="receivePhotoIds"
              name="receivePhotoIds"
              automationId="yes-resware-instance-receive-photo-ids"
              labelText="Yes"
              radioValue
              onChange={(v) => {
                this.setState({ showPhotoIdDocumentTypeId: v });
              }}
              size="small"
            />
            <FormGroupErrors fields={["receivePhotoIds"]} />
            {showPhotoIdDocumentTypeId && (
              <>
                <DeprecatedTextField
                  id="photoIdDocumentTypeId"
                  name="photoIdDocumentTypeId"
                  className="ReswareInstanceDocuments--PhotoIdDocumentTypeId"
                  data-automation-id="resware-instance-photo-id-document-type-id"
                  normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                  placeholder={intl.formatMessage(messages.photoIdDocumentTypeId)}
                  placeholderAsLabel
                  useStyledInput
                />
                <FormGroupErrors fields={["photoIdDocumentTypeId"]} />
              </>
            )}
          </DeprecatedFormRow>

          <div className="ReswareInstanceForm--SecondaryTitle">
            {intl.formatMessage(messages.setupMergedDocumentType)}
          </div>
          <div className="ReswareInstanceDocuments--HelpText">
            {intl.formatMessage(messages.setupMergedDocumentTypeHelp)}
          </div>
          <DeprecatedFormRow className="ReswareInstanceForm--FormRow">
            <DeprecatedTextField
              id="mergedDocumentTypeId"
              name="mergedDocumentTypeId"
              className="ReswareInstanceDocuments--MergedDocumentTypeId"
              normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
              data-automation-id="resware-instance-merged-document-type-id"
              placeholder={intl.formatMessage(messages.mergedDocumentTypeId)}
              placeholderAsLabel
              useStyledInput
            />
            <FormGroupErrors fields={["mergedDocumentTypeId"]} />
          </DeprecatedFormRow>
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            className="ReswareInstanceDocuments--Submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </form>
      </div>
    );
  }
}

ReswareInstanceDocumentsTab.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reswareInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      invoiceDocumentTypeId: PropTypes.number,
      mergedDocumentTypeId: PropTypes.number.isRequired,
      photoIdDocumentTypeId: PropTypes.number,
      rejectedDocumentTypeId: PropTypes.number,
      sendMergedDocument: PropTypes.bool.isRequired,
      sendIndividualDocuments: PropTypes.bool.isRequired,
      reswareDocuments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          documentTypeId: PropTypes.number.isRequired,
          executedDocumentTypeId: PropTypes.number.isRequired,
          role: PropTypes.oneOf(Object.values(ReswareInstanceDocumentRole)).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

function ReswareInstanceDocumentsTabWrapper(props) {
  return (
    <Mutation mutation={UpdateReswareInstanceMutation}>
      {(updateReswareInstanceMutateFn) => (
        <ReswareInstanceDocumentsTab
          updateReswareInstanceMutateFn={updateReswareInstanceMutateFn}
          {...props}
        />
      )}
    </Mutation>
  );
}

export default compose(
  injectIntl,
  reduxForm({ form: "reswareInstanceDocumentsForm", validate }),
  getFormValues("reswareInstanceDocumentsForm"),
)(ReswareInstanceDocumentsTabWrapper);

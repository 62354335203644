import { useEffect, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import { EncompassAuthenticationMethods } from "graphql_globals";
import { composeValidators } from "util/form";
import AlertMessage from "common/core/alert_message";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import SubFormSection from "common/form/sub_form/section";
import SubForm from "common/form/sub_form";
import { DeprecatedTextField } from "common/form/fields/text";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedFormRow } from "common/form/elements/row";
import Button from "common/core/button";
import { validateIf, validatePresence } from "validators/form";
import { DeprecatedSelectField } from "common/form/fields/select";

import CreateEncompassInstanceMutation from "./create_encompass_instance_mutation.graphql";
import UpdateEncompassInstanceMutation from "./update_encompass_instance_mutation.graphql";
import type { EncompassInstance } from "./encompass_instance_fragment.graphql";

type Props = {
  organization: {
    id: string;
    encompassInstance: EncompassInstance | null;
  };
};
type FormValues = {
  authenticationMethod: EncompassAuthenticationMethods;
  apiKey: string | null;
  apiSecret: string | null;
  instanceId: string | null;
  username: string | null;
  password: string | null;
};
type InjectedProps = InjectedFormProps<FormValues, Props>;
type InnerProps = InjectedProps & Props;

const AUTHENTICATION_METHOD_ITEMS = Object.freeze([
  {
    value: EncompassAuthenticationMethods.PASSWORD,
    label: "password grant",
  },
  {
    value: EncompassAuthenticationMethods.CLIENT_CREDENTIALS,
    label: "client_credentials grant",
  },
]);

function validate(values: FormValues, props: Props) {
  const { authenticationMethod } = values;
  const {
    organization: { encompassInstance },
  } = props;

  return composeValidators(
    validatePresence({ field: "apiKey", label: "API Client ID" }),
    validateIf({
      field: "apiSecret",
      condition: () => !encompassInstance,
      validation: validatePresence({
        field: "apiSecret",
        label: "API Client Secret",
      }),
    }),
    validatePresence({ field: "instanceId", label: "Instance ID" }),
    validateIf({
      field: "username",
      condition: () => authenticationMethod === EncompassAuthenticationMethods.PASSWORD,
      validation: validatePresence({
        field: "username",
        label: "User Name",
      }),
    }),
    validateIf({
      field: "password",
      condition: () =>
        authenticationMethod === EncompassAuthenticationMethods.PASSWORD && !encompassInstance,
      validation: validatePresence({
        field: "password",
        label: "Password",
      }),
    }),
  )(values);
}

function EncompassCredentialsTab({
  handleSubmit,
  initialize,
  organization: { id, encompassInstance },
}: InnerProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alertKind, setAlertKind] = useState<string | null>(null);
  const defaultAuthenticationMethod = encompassInstance
    ? encompassInstance.authenticationMethod
    : EncompassAuthenticationMethods.PASSWORD;
  const [authenticationMethod, setAuthenticationMethod] = useState<EncompassAuthenticationMethods>(
    defaultAuthenticationMethod,
  );
  const createEncompassInstanceMutateFn = useMutation(CreateEncompassInstanceMutation);
  const updateEncompassInstanceMutateFn = useMutation(UpdateEncompassInstanceMutation);

  useEffect(() => {
    if (encompassInstance) {
      initialize({
        authenticationMethod: encompassInstance.authenticationMethod,
        apiKey: encompassInstance.apiKey,
        instanceId: encompassInstance.instanceId,
        username: encompassInstance.username,
      });
    } else {
      initialize({ authenticationMethod });
    }
  }, []);

  const onSubmit = ({
    authenticationMethod,
    apiKey,
    apiSecret,
    instanceId,
    username,
    password,
  }: FormValues) => {
    const promise: Promise<unknown> = encompassInstance
      ? updateEncompassInstanceMutateFn({
          variables: {
            input: {
              id: encompassInstance.id,
              authenticationMethod,
              apiKey,
              apiSecret,
              instanceId,
              username,
              password,
            },
          },
        })
      : createEncompassInstanceMutateFn({
          variables: {
            input: {
              organizationId: id,
              authenticationMethod,
              apiKey: apiKey ? apiKey : "",
              apiSecret: apiSecret ? apiSecret : "",
              instanceId: instanceId ? instanceId : "",
              username,
              password,
            },
          },
        });

    setIsSubmitting(true);
    return promise
      .then(() => setAlertKind("success"))
      .catch((err: Error) => {
        if (!isGraphQLError(err)) {
          captureException(err);
        }
        setAlertKind("danger");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form
      name="encompassInstanceForm"
      className="EncompassInstanceForm"
      data-automation-id="encompass-instance-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {alertKind === "success" && (
        <AlertMessage kind={alertKind} centerText>
          <FormattedMessage
            id="5f22e948-22e2-4576-bb2b-a14854798f4c"
            defaultMessage="Encompass account saved successfully."
          />
        </AlertMessage>
      )}
      {alertKind === "danger" && (
        <AlertMessage kind={alertKind} centerText>
          <FormattedMessage
            id="916fd68e-97cc-473f-85b6-c6967cc26d9f"
            defaultMessage="Hmm. Looks like we couldn't connect to Encompass. Please confirm that your username and password are correct, and that your API Client ID and Secret are correct."
          />
        </AlertMessage>
      )}
      <div className="EncompassInstanceForm--Title">
        <FormattedMessage
          id="2db45a9d-c0dc-484e-a59e-dc300138df19"
          defaultMessage="Let's setup your Encompass profile"
        />
      </div>
      <SubForm>
        <SubFormSection>
          <div className="EncompassInstanceForm--Title">
            <FormattedMessage
              id="159f387f-c53e-4705-9b7a-3d543f0fb999"
              defaultMessage="Authentication method for your Encompass instance"
            />
          </div>
          <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
            <DeprecatedSelectField
              name="authenticationMethod"
              useStyledInput
              items={AUTHENTICATION_METHOD_ITEMS}
              data-automation-id="encompass-authentication-method"
              clearable={false}
              searchable={false}
              onChange={(e: Event, value: EncompassAuthenticationMethods) => {
                setAuthenticationMethod(value);
              }}
            />
            <FormGroupErrors fields={["authenticationMethod"]} />
          </DeprecatedFormRow>
          <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
            <DeprecatedTextField
              id="apiKey"
              name="apiKey"
              data-automation-id="encompass-api-key"
              placeholder={
                <FormattedMessage
                  id="917dbcea-7306-4d26-806c-815ed090fce9"
                  defaultMessage="API Client ID"
                />
              }
              placeholderAsLabel
              useStyledInput
            />
            <FormGroupErrors fields={["apiKey"]} />
          </DeprecatedFormRow>
          <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
            <DeprecatedTextField
              id="apiSecret"
              name="apiSecret"
              data-automation-id="encompass-api-secret"
              placeholder={
                <FormattedMessage
                  id="fe4026b8-4fd8-4838-aaf7-5ca45b43de0c"
                  defaultMessage="API Client Secret"
                />
              }
              placeholderAsLabel
              useStyledInput
            />
            <FormGroupErrors fields={["apiSecret"]} />
          </DeprecatedFormRow>
          <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
            <DeprecatedTextField
              id="instanceId"
              name="instanceId"
              data-automation-id="encompass-instance-id"
              placeholder={
                <FormattedMessage
                  id="e9cae82a-d6c8-415f-b8bc-b628de3106b3"
                  defaultMessage="Instance ID"
                />
              }
              placeholderAsLabel
              useStyledInput
            />
            <FormGroupErrors fields={["instanceId"]} />
          </DeprecatedFormRow>
          {authenticationMethod === EncompassAuthenticationMethods.PASSWORD && (
            <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
              <DeprecatedTextField
                id="username"
                name="username"
                data-automation-id="encompass-username"
                placeholder={
                  <FormattedMessage
                    id="42a2b540-4bac-4421-8148-2d4acd74f455"
                    defaultMessage="User Name"
                  />
                }
                placeholderAsLabel
                useStyledInput
              />
              <FormGroupErrors fields={["username"]} />
            </DeprecatedFormRow>
          )}
          {authenticationMethod === EncompassAuthenticationMethods.PASSWORD && (
            <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
              <DeprecatedTextField
                id="password"
                name="password"
                data-automation-id="encompass-password"
                placeholder={
                  <FormattedMessage
                    id="998fc174-981e-4366-96a1-91d86cfd58fc"
                    defaultMessage="Password"
                  />
                }
                placeholderAsLabel
                useStyledInput
              />
              <FormGroupErrors fields={["password"]} />
            </DeprecatedFormRow>
          )}
        </SubFormSection>
      </SubForm>
      <Button
        buttonColor="action"
        variant="primary"
        type="submit"
        className="EncompassInstanceFormButton"
        automationId="encompass-instance-form-submit"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        <FormattedMessage id="23bf630a-9286-4d96-9c43-d4532af8dd9b" defaultMessage="Save" />
      </Button>
    </form>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({
  form: "encompassCredentialsForm",
  validate,
});

export default formEnhancer(EncompassCredentialsTab);

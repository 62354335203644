import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";
import { useState, useEffect, useReducer, useRef } from "react";

import Icon from "common/core/icon";
import { isiOSDevice } from "util/support";
import { captureException } from "util/exception";
import { getMediaErrorNameFromException } from "common/video_conference/exception";
import SROnly from "common/core/screen_reader";

import Styles from "./audio_indicator.module.scss";

type StreamDispatchAction =
  | { type: "addStream"; payload: MediaStream }
  | { type: "addRAF"; payload: number };

const BUFFER_LENGTH = 256;
const MESSAGES = defineMessages({
  audioDetectedLabel: {
    id: "cb8acbd6-1304-40ba-9978-5baee9044346",
    defaultMessage: "Microphone audio detected",
  },
});

function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number,
) {
  // copied from https://stackoverflow.com/questions/5294955/how-to-scale-down-a-range-of-numbers-with-a-known-min-and-max-value
  return Math.ceil(((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed);
}

export const Volume = ({
  volume,
  color = "light",
  className,
}: {
  volume: number;
  color?: "primary" | "light";
  className?: string;
}) => {
  // our min px height is 4px, with the max being 16
  // so we need to scale our 0-100 volume percentage to be between 25% and 100%
  // else any volume less than 25% shows no movement
  const scaled = scaleBetween(volume, 25, 110, 5, 100);

  return (
    <div
      className={classnames(
        Styles.volumeContainer,
        scaled > 25 && Styles.activeContainer,
        color === "light" ? Styles.light : Styles.primary,
      )}
    >
      <div
        className={classnames(
          Styles.volume,
          color === "light" ? Styles.light : Styles.primary,
          className,
        )}
        style={{ height: `${scaled}%` }}
      >
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

const INITIAL_STATE = Object.freeze({
  streamList: [],
  rafList: [],
});

const streamReducer = (
  state: { streamList: MediaStream[]; rafList: number[] },
  action: StreamDispatchAction,
) => {
  const { streamList, rafList } = state;
  switch (action.type) {
    case "addStream":
      return { ...state, streamList: [...streamList, action.payload] };
    case "addRAF":
      return { ...state, rafList: [...rafList, action.payload] };
  }
};

export function StandAloneVolumeIndicator({
  deviceId,
  className,
}: {
  deviceId: string | null | undefined;
  className?: string;
}) {
  const isMounted = useRef(false);
  const intl = useIntl();
  const [vol, setVol] = useState(0);
  const [showIcon, setShowIcon] = useState(false);
  const isIOS = isiOSDevice();
  const [streamListState, streamListDispatch] = useReducer(streamReducer, INITIAL_STATE);

  const getMediaStream = async (newMicrophoneId: string) => {
    const strictConstraints = {
      video: false,
      audio: { deviceId: { exact: newMicrophoneId } },
    };
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia(strictConstraints);
      streamListDispatch({ type: "addStream", payload: audioStream });
      const audioCtx = new AudioContext();
      const audioSource = audioCtx.createMediaStreamSource(audioStream);
      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = BUFFER_LENGTH;
      analyser.minDecibels = -125;
      analyser.maxDecibels = -10;
      audioSource.connect(analyser);
      const dataArray = new Uint8Array(BUFFER_LENGTH);

      const volumeCallback = () => {
        analyser.getByteFrequencyData(dataArray);
        const totalVolume = dataArray.reduce((acc, v) => (acc += v), 0);
        const volume = totalVolume / dataArray.length;
        setVol(volume);
        if (showIcon) {
          setShowIcon(false);
        }
      };

      return volumeCallback;
    } catch (err) {
      const error = err as Error | null;
      const errorType = error?.name ? getMediaErrorNameFromException(error) : null;
      if (error && errorType) {
        // eslint-disable-next-line no-console
        console.warn(`Microphone volume device error: ${error.name} - ${error.message}`);
        return;
      }
      captureException(err);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (deviceId && !isIOS) {
      const startAudio = async () => {
        const callback = await getMediaStream(deviceId).then((callback) => {
          if (!isMounted.current) {
            return;
          }
          return callback;
        });
        if (callback) {
          const animateVolume = () => {
            callback();
            const rAF = window.requestAnimationFrame(animateVolume);
            streamListDispatch({ type: "addRAF", payload: rAF });
          };
          animateVolume();
        }
      };
      startAudio();
      return () => {
        streamListState.rafList.forEach((raf) => window.cancelAnimationFrame(raf));
      };
    }
    if (!deviceId || isIOS) {
      setShowIcon(true);
    }
  }, [deviceId]);

  useEffect(() => {
    return () => {
      // stop any media stream tracks we started
      streamListState.streamList.forEach((stream) =>
        stream.getTracks().forEach((track) => track.stop()),
      );
    };
  }, [streamListState.streamList]);

  if (showIcon || !deviceId) {
    return <Icon className={className} name="microphone" />;
  }

  return (
    <div role="status">
      <Volume className={className} color="primary" volume={vol} />
      {vol !== 0 && (
        <SROnly>
          <div>{intl.formatMessage(MESSAGES.audioDetectedLabel)}</div>
        </SROnly>
      )}
    </div>
  );
}

import { memo, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import CollapsibleList from "common/core/collapsible_list";
import Button from "common/core/button";
import AddPointOfContactModal from "common/mortgage/transactions/form/v2/points_of_contact/add_point_of_contact_modal";
import modalScrollContent from "common/core/modal_scroll_content";
import { userCanEditContact } from "util/points_of_contact";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { useActiveOrganization } from "common/account/active_organization";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import PopoutMenu from "common/core/popout_menu";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";

import ContactDetails from "./contact_details";
import type { OrganizationTransactionForTransactionDetailsPointOfContact } from "./index_fragment.graphql";
import CreateOrganizationTransactionContactMutation from "./create_organization_transaction_contact_mutation.graphql";
import DeleteOrganizationTransactionContactMutation from "./delete_organization_transaction_contact_mutation.graphql";
import SendNotificationToOrganizationTransactionContactMutation from "./send_notification_to_organization_transaction_contact_mutation.graphql";
import Styles from "./index.module.scss";
import { CCRecipientDetails, CCRecipientAddModal } from "./cc_recipient_details";

type PointsOfContactProps = {
  organization: Parameters<typeof userCanEditContact>[0]["organization"];
  transaction: OrganizationTransactionForTransactionDetailsPointOfContact;
  canEdit?: boolean;
  ccRecipientsOnly?: boolean;
  refetch: () => Promise<unknown>;
};

function PointsOfContactWrapper({
  children,
  isTxnDetailsRedesign,
}: {
  children: ReactNode;
  isTxnDetailsRedesign: boolean;
}) {
  return isTxnDetailsRedesign ? <>{children}</> : <CollapsibleList>{children}</CollapsibleList>;
}

function PointsOfContactDetails({
  transaction,
  refetch,
  organization,
  canEdit = true,
  ccRecipientsOnly = false,
}: PointsOfContactProps) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [modal, setModal] = useState<"contact" | "cc-recipient" | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeOrganizationId] = useActiveOrganization();
  const createContactMutateFn = useMutation(CreateOrganizationTransactionContactMutation);
  const deleteContactMutateFn = useMutation(DeleteOrganizationTransactionContactMutation);
  const sendNotificationContactMutateFn = useMutation(
    SendNotificationToOrganizationTransactionContactMutation,
  );

  const addButtonText = (
    <FormattedMessage id="46c8c127-e384-4b79-a87a-732ef0270989" defaultMessage="Add new contact" />
  );

  const renderAddContactButton = () => {
    if (!canEdit) {
      return null;
    }
    return ccRecipientsOnly ? (
      <Button
        variant="secondary"
        buttonColor="action"
        withIcon={{ name: "add-1", placement: "left" }}
        onClick={() => setModal("cc-recipient")}
      >
        {addButtonText}
      </Button>
    ) : (
      <PopoutMenu
        target={
          <Button variant="secondary" buttonColor="action">
            {addButtonText}
          </Button>
        }
        placement={isTxnDetailsRedesign ? "topRight" : "bottomLeft"}
        hasDropdownArrow
      >
        {({ close }) => (
          <>
            <PopoutMenuMultilineItem
              onClick={() => {
                setModal("contact");
                close();
              }}
              primaryContent={
                <FormattedMessage
                  id="58285db1-ec64-47cb-86ba-f5a8b6fc2364"
                  defaultMessage="Closing contact"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="d28f3aec-9477-45fb-93bc-47ae2d4e1942"
                  defaultMessage="Closing contacts will be notified via email during every step of the signing process."
                />
              }
              iconName="user-filled"
            />
            <PopoutMenuMultilineItem
              onClick={() => {
                setModal("cc-recipient");
                close();
              }}
              primaryContent={
                <FormattedMessage
                  id="3f2e95f6-314f-421d-a13b-c09958e55cae"
                  defaultMessage="CC contact"
                />
              }
              secondaryContent={
                <FormattedMessage
                  id="2ab5cea3-d584-4512-8ca5-dafc89734879"
                  defaultMessage="CC contacts will be able to view documents after the transaction is complete."
                />
              }
              iconName="email"
            />
          </>
        )}
      </PopoutMenu>
    );
  };
  return (
    <>
      <div
        className={classnames(
          isTxnDetailsRedesign ? Styles.contactDetails : Styles.deprecatedContactDetails,
        )}
        data-automation-id="points-of-contact-details"
      >
        <PointsOfContactWrapper isTxnDetailsRedesign={isTxnDetailsRedesign}>
          {transaction.contacts.map((contact, index) => (
            <ContactDetails
              key={contact.id}
              contact={contact}
              transaction={transaction}
              automationId={`points-of-contact-${index}`}
              onRemove={
                canEdit && userCanEditContact({ organization, contact })
                  ? async () => {
                      await deleteContactMutateFn({
                        variables: { input: { id: contact.id } },
                      });
                      return refetch();
                    }
                  : undefined
              }
              onSendNotification={() =>
                sendNotificationContactMutateFn({ variables: { input: { id: contact.id } } })
              }
            />
          ))}
          {transaction.ccRecipientEmails.map((email, index) => (
            <CCRecipientDetails
              key={email}
              email={email}
              index={index}
              refetch={refetch}
              organizationTransactionId={transaction.id}
              canEdit={canEdit}
            />
          ))}
        </PointsOfContactWrapper>
        {renderAddContactButton()}
      </div>
      {modal === "contact" && (
        <AddPointOfContactModal
          onAddContact={(contact) => {
            setLoading(true);
            createContactMutateFn({
              variables: {
                input: {
                  organizationTransactionId: transaction.id,
                  organizationId: activeOrganizationId,
                  contact: {
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    role: contact.role,
                    title: contact.title,
                    email: contact.email,
                    phoneNumber: contact.phoneNumber,
                    shownToSigner: contact.shownToSigner,
                    accessToTransaction: contact.accessToTransaction,
                  },
                },
              },
            })
              .then(() => refetch())
              .catch(() => {
                captureException(new Error("Couldn't add organization transaction contact"), {
                  contact,
                  transaction,
                });
              })
              .finally(() => {
                setModal(null);
              });
          }}
          onClose={() => setModal(null)}
          loading={loading}
          allowAccessToDocumentsOption={isHybridTransactionType(transaction.transactionType!)}
        />
      )}
      {modal === "cc-recipient" && (
        <CCRecipientAddModal
          onClose={() => {
            setModal(null);
          }}
          refetch={refetch}
          organizationTransactionId={transaction.id}
          ccRecipientEmails={transaction.ccRecipientEmails}
        />
      )}
    </>
  );
}

export default modalScrollContent(memo(PointsOfContactDetails));

import "./index.scss";

import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { MailtoLink } from "common/core/mailto_link";
import { deprecatedMaskPhone } from "common/form/inputs/masked_input";
import { ROLE_LABELS } from "constants/points_of_contact";
import { OrganizationTransactionContactRoleType } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";

import ListStyles from "./list.module.scss";

type ModalProps = Props & {
  onClose: () => void;
};

type Props = {
  contacts: ContactProps[];
  className?: string;
};

type ContactProps = {
  role: OrganizationTransactionContactRoleType;
  title: string | null;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  shownToSigner: boolean;
};

function PointsOfContactList({ contacts }: Props) {
  return (
    <div className={ListStyles.list}>
      {contacts.map((contact, index) => {
        const { role, title, firstName, lastName, email, phoneNumber, shownToSigner } = contact;
        const contactTitle =
          role === OrganizationTransactionContactRoleType.OTHER ? title : ROLE_LABELS[role];
        if (!shownToSigner) {
          return null;
        }
        return (
          <div className={ListStyles.section} key={index}>
            <div className={ListStyles.icon}>
              {firstName.charAt(0)}
              {lastName.charAt(0)}
            </div>
            <div className={ListStyles.mainText}>
              {firstName} {lastName}
            </div>
            <div className={ListStyles.secondaryText}>{contactTitle}</div>
            {email && <MailtoLink emailAddress={email} />}
            {phoneNumber && (
              <div className={ListStyles.phoneText}>{deprecatedMaskPhone(phoneNumber)}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export function PointOfContactSidebar({ contacts, className }: Props) {
  return (
    <div className={classnames("PointOfContactSidebar", className)}>
      <div className="PointOfContactSidebar--header">
        <FormattedMessage
          id="57703022-1f63-485d-869a-e772e10b9395"
          description="header"
          defaultMessage="Contacts"
        />
      </div>
      <PointsOfContactList contacts={contacts} />
    </div>
  );
}

export function PointOfContactModal({ contacts, onClose }: ModalProps) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage id="408b11cc-02b0-47ca-ad35-0ff810ab5005" defaultMessage="Contacts" />
      }
      footerSeparator={false}
    >
      <PointsOfContactList contacts={contacts} />
    </WorkflowModal>
  );
}

import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { DeprecatedPhoneCountryCodeField } from "common/form/fields/phone/country_code";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import PhoneNumberWarningMessage from "common/core/warnings/phone_number_warning_message";

import Styles from "./index.module.scss";

type Props = {
  disabled?: boolean;
  phoneCountryCode?: string;
  phoneNumber?: string;
  phoneError?: Error;
};

function SignupPhoneField(props: Props) {
  const { disabled, phoneCountryCode, phoneNumber, phoneError } = props;

  return (
    <fieldset>
      <legend className={classnames(Styles.phoneNumberLabel, { [Styles.disabled]: disabled })}>
        <FormattedMessage
          id="34464ff9-63d7-4977-b026-7501b36d654e"
          defaultMessage="Business phone number"
        />
      </legend>
      <FormGroup className={Styles.inputWrapper} fields={["phoneCountryCode", "phoneNumber"]}>
        <DeprecatedMultipartRow>
          <DeprecatedMultipartColumn width={3}>
            <DeprecatedPhoneCountryCodeField
              id="phoneCountryCode"
              name="phoneCountryCode"
              disabled={disabled}
              data-automation-id="phone-country-code-field"
              useStyledInputs={false}
            />
          </DeprecatedMultipartColumn>
          <DeprecatedMultipartColumn width={9}>
            <DeprecatedPhoneNumberField
              id="phoneNumber"
              name="phoneNumber"
              isInternational={deprecatedIsInternational({ countryCode: phoneCountryCode })}
              data-automation-id="phone-number-field"
              disabled={disabled}
              useStyledInputs={false}
            />
          </DeprecatedMultipartColumn>
        </DeprecatedMultipartRow>
        <FormGroupErrors fields={["phoneCountryCode", "phoneNumber"]} />
        <PhoneNumberWarningMessage
          phoneNumber={phoneNumber}
          disabled={Boolean(phoneError) || disabled}
          className="SignupPhoneField--typo-warning"
        />
      </FormGroup>
    </fieldset>
  );
}

export default SignupPhoneField;

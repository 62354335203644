import { defineMessages, useIntl } from "react-intl";

import { IconButton } from "common/core/button/icon_button";

import Styles from "./doc_preview_header.module.scss";

type Props = {
  documentName: string | null;
  backPath: () => void;
};

const MESSAGES = defineMessages({
  back: {
    id: "22c39834-ca5f-49e7-88fb-8e4aefaf4870",
    defaultMessage: "Back",
  },
});

function DocumentDetailsPreviewHeader({ documentName, backPath }: Props) {
  const intl = useIntl();
  return (
    <div className={Styles.wrapper}>
      <IconButton
        name="arrow-left"
        onClick={backPath}
        variant="tertiary"
        buttonColor="action"
        label={intl.formatMessage(MESSAGES.back)}
      />
      <span className={Styles.docName}>{documentName}</span>
    </div>
  );
}
export default DocumentDetailsPreviewHeader;

import "./input.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import { DeprecatedTextInput } from "common/form/inputs/text";

/**
 * What we want the "all" option to be keyed on.
 * { value: ALL_KEY, label: this.props.allOptionLabel }
 */
const ALL_KEY = "all";

/** @deprecated - please use components in common/core/form */
export class DeprecatedMultiSelectInputBase extends Component {
  el = null;

  componentDidMount() {
    // HACK: This can be uncommented and used to test styling
    // while the control's menu is open
    // this.el.setState({ isOpen: true });
  }

  handleInputBlur = (ev) => {
    // HACK:
    // https://github.com/erikras/redux-form/issues/2768
    // ReduxForm has an issue (see above) where it will try to dereference the event value
    // of an input and use it to update the form store no matter, even when the component
    // controls its value by other means, like state (as we do in the MultiSelectInput).
    // In such cases, the dereferenced value will be incorrect, and ReduxForm will set
    // a bunk value that will cause the input to become empty.

    // We (in some form) use ReduxForm with all our inputs, though we also use the inputs on their own.
    // As a compromise, we mutate the event value so that ReduxForm will at least use the correct value.
    // This risks unexpected behavior for anyone using the MultiSelectInput without ReduxForm,
    // but it's what has to do for now.

    // See source code in:
    // https://github.com/erikras/redux-form/blob/2811705a22430450540b84cddf429b42b222e28d/src/ConnectedField.js#L168
    // https://github.com/erikras/redux-form/blob/2e2a6b02af6083dfda5606fb596d0a189edb9460/src/events/getValue.js#L18
    ev.target.value = this.props.value;

    if (this.props.onBlur) {
      this.props.onBlur(ev);
    }
  };

  handleFocus = (ev) => {
    if (this.props.onFocus) {
      this.props.onFocus(ev);
    }
  };

  updateValue = (newValues) => {
    const selectedValues = newValues.some((item) => item.value === ALL_KEY)
      ? this.props.items
      : newValues;
    this.props.onChange(selectedValues);
  };

  // Used by EditableInput to manually activate focus in this component
  focusInput = () => {
    this.el.focus();
  };

  setRefs = (el) => {
    this.el = el;
    this.props.inputRef(el);
  };

  render() {
    const {
      className,
      items,
      hiddenInputName,
      readOnly,
      automationId,
      hasAllOption,
      allOptionLabel,
      value,
      openOnFocus,
      ...otherProps
    } = this.props;

    const renderedItems = items.slice();
    if (readOnly) {
      const item = (items || []).find((item) => item.value === value);
      return <DeprecatedTextInput className={className} readOnly value={item?.label} />;
    }

    if (hasAllOption && value.length !== items.length) {
      renderedItems.unshift({ value: ALL_KEY, label: allOptionLabel });
    }

    return (
      <div
        className={className}
        data-automation-id={automationId || this.props["data-automation-id"]}
      >
        <ReactSelect
          {...otherProps}
          multi
          options={renderedItems}
          name={hiddenInputName} // name for hidden <input /> tag
          value={value}
          onChange={this.updateValue}
          onBlur={this.handleInputBlur}
          onFocus={this.handleFocus}
          // For use with hook form
          ref={this.props.hookFormRef || this.setRefs}
          removeSelected
          openOnFocus={openOnFocus}
        />
      </div>
    );
  }
}

DeprecatedMultiSelectInputBase.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  hiddenInputName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  clearRenderer: PropTypes.func,
  openOnFocus: PropTypes.bool,
  multi: PropTypes.bool,
  /** React special props safe version of ref */
  inputRef: PropTypes.func,
};

DeprecatedMultiSelectInputBase.defaultProps = {
  openOnFocus: true,
  readOnly: false,
  searchable: false,
  clearable: false,
  clearRenderer: undefined,
  multi: false,
  matchPos: "any",
  matchProp: "any",
  inputRef: () => {},
};

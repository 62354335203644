import { FormattedMessage } from "react-intl";

import { AVItem } from "common/video_conference/audio_video_settings/container";
import { DeprecatedPhoneNumberInput } from "common/form/inputs/phone/number";
import type { Devices } from "common/selected_devices_controller";

type Props = {
  selectedDevices: Devices;
  onChangeDevices: (devices: Devices) => void;
};

export default function PhoneItem({ onChangeDevices, selectedDevices }: Props) {
  return (
    <AVItem
      // eslint-disable-next-line
      // @ts-ignore
      selectNode={
        <>
          <div className="AudioVideoContainer--Section">
            <FormattedMessage
              id="7467544d-f6de-403d-8dca-8c8c3be0acfe"
              defaultMessage="Proof will call your phone and connect your audio to this meeting"
            />
          </div>
          <DeprecatedPhoneNumberInput
            value={selectedDevices.phone}
            onChange={(phone?: string | null) => onChangeDevices({ phone })}
            label={
              <FormattedMessage
                id="7aa6d1cf-dab6-4aa6-abab-8af131052a5f"
                defaultMessage="Phone number"
              />
            }
          />
        </>
      }
      isSubitem
    />
  );
}
